import { PAGINATION } from "@config/constant"
import { trans } from "@resources/localization"
import { Row, Table, Pagination, Card, Button } from "antd"
import { ColumnsType } from "antd/lib/table"
import { formatDateFull } from "@util/Common"
import React from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAppSelector } from "@hook/useAppSelector"
import { ISkuCombo } from "@domain/version2/SkuCombo"
import SecurityService from "@util/SecurityService"
import { COMBO_SCOPE } from "@config/permission"

export const List: React.FC = () => {
    const navigate = useNavigate()
    const total = useAppSelector((state) => state.skuCombo.pagination.total)
    const currentPage = useAppSelector((state) => state.skuCombo.pagination.currentPage)
    const totalPage = useAppSelector((state) => state.skuCombo.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.skuCombo.pagination.pageSize)
    const listSkuCombo = useAppSelector((state) => state.skuCombo.listSkuCombo)
    const loading = useAppSelector((state) => state.skuCombo.loadingList)

    const [searchParams, setSearchParams] = useSearchParams()

    const columns: ColumnsType<ISkuCombo> = [
        {
            title: trans("skuCombo.code"),
            className: "bold-400",
            dataIndex: "code",
            render: (code: string) => (SecurityService.can(COMBO_SCOPE.COMBO_DETAIL_VIEW) ? <Link to={`/skuCombo/${code}`}>{code}</Link> : code),
            width: "15%",
        },
        {
            title: trans("skuCombo.name"),
            dataIndex: "name",
            className: "bold-400",
            width: "10%",
        },
        {
            title: trans("product.categories"),
            className: "bold-400",
            width: "15%",
            render: (record: ISkuCombo) => <p className="mb-0">{record?.category?.name || ""}</p>,
        },
        {
            title: trans("products.sku_quantity"),
            className: "bold-400",
            width: "10%",
            render: (record: ISkuCombo) => <p className="mb-0">{record?.skus.length || ""}</p>,
        },
        {
            title: trans("products.campaign_quantity"),
            className: "bold-400",
            dataIndex: "campaignQuantity",
            width: "10%",
        },
        {
            title: trans("table.created_by"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: "15%",
        },
        {
            title: trans("table.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (value) => (value ? formatDateFull(value) : "---"),
            width: "15%",
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize: pageSize,
            page: !searchParams.has("size") || Number(oldSearch.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }

        params.tab = "skuCombo"
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                SecurityService.can(COMBO_SCOPE.COMBO_CREATE) && (
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate("/skuCombo/create")
                        }}>
                        {trans("skuCombo.new")}
                    </Button>
                )
            }>
            <Table
                scroll={{
                    x: true,
                }}
                columns={columns}
                dataSource={listSkuCombo}
                rowKey={(record) => record.id}
                pagination={false}
                loading={loading}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
