import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import PaymentStatementApi from "@api/version2/PaymentStatementApi"
import { IPaymentStatement, ICreatePaymentStatementQuery, IPaymentStatementStatus, IPaymentStateQuery } from "@domain/version2/PaymentStatement"
import { PAGINATION } from "@config/constant"

interface State {
    pagination: {
        current: number
        size: number
        total: number
        totalPage: number
    }
    listPaymentStatement: IPaymentStatement[]
    loadingList: boolean
    loadingCreate: boolean
    visibleFormCreatePaymentStatement: boolean
    listPaymentStatementStatus: IPaymentStatementStatus[]
    loadingListStatues: boolean
}

const initState: State = {
    pagination: {
        current: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listPaymentStatement: [],
    loadingList: false,
    loadingCreate: false,
    visibleFormCreatePaymentStatement: false,
    listPaymentStatementStatus: [],
    loadingListStatues: false,
}

export const callGetListPaymentStatement = createAsyncThunk("payment_statement.GET_LIST", async (params: IPaymentStateQuery, thunkApi) => {
    try {
        return await PaymentStatementApi.getListPaymentStatement(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetListPaymentStatementStatuses = createAsyncThunk("payment_statement.GET_STATUS_payment_statement", async (_, thunkApi) => {
    try {
        return await PaymentStatementApi.getListPaymentStatementStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callCreatePaymentStatement = createAsyncThunk("payment_statement.CREATE_BALANCE", async (params: ICreatePaymentStatementQuery, thunkApi) => {
    try {
        return await PaymentStatementApi.createPaymentStatement(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const changeVisibleFormCreatePaymentState = createAction<boolean>("PAYMENT_STATEMENT.CHANGE_ADD")

export const paymentStatementReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetListPaymentStatement.pending, (state) => {
            state.loadingList = true
            state.listPaymentStatement = []
        })
        .addCase(callGetListPaymentStatement.fulfilled, (state, action) => {
            state.listPaymentStatement = action.payload.data ? action.payload.data : []
            state.pagination.current = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callGetListPaymentStatement.rejected, (state) => {
            state.loadingList = false
            state.listPaymentStatement = []
        })

    builder
        .addCase(callGetListPaymentStatementStatuses.pending, (state) => {
            state.loadingListStatues = true
            state.listPaymentStatementStatus
        })

        .addCase(callGetListPaymentStatementStatuses.fulfilled, (state, { payload }) => {
            state.listPaymentStatementStatus = payload?.data || []
            state.loadingListStatues = false
        })
        .addCase(callGetListPaymentStatementStatuses.rejected, (state) => {
            state.loadingListStatues = false
        })

    builder
        .addCase(callCreatePaymentStatement.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreatePaymentStatement.fulfilled, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreatePaymentStatement.rejected, (state) => {
            state.loadingCreate = false
        })

    builder.addCase(changeVisibleFormCreatePaymentState, (state, action: PayloadAction<boolean>) => {
        state.visibleFormCreatePaymentStatement = action.payload
    })
})
