import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import { useSearchParams } from "react-router-dom"
import { AdsList } from "./Ads"
import { CampaignList } from "./CampaignList"
import { callSuggestUser } from "@reducer/version2/user.reducer"
import SecurityService from "@util/SecurityService"
import { ADS_SCOPE, CAMPAIGN_SCOPE } from "@config/permission"
import { filter } from "lodash"

export const Campaign: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [current, setCurrentTab] = useState<string>(
        !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL) && !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW) ? "ads" : "campaign"
    )

    useEffect(() => {
        dispatch(callSuggestUser({ keyword: "" }))
    }, [dispatch])

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.campaign")))
        const oldSearch = Object.fromEntries(searchParams.entries())
        if (searchParams.has("tab")) {
            if (oldSearch?.tab === "campaign") {
                if (SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL) || SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW)) {
                    setCurrentTab("campaign")
                } else {
                    setCurrentTab("ads")
                    setSearchParams({
                        ...oldSearch,
                        tab: "ads",
                    })
                }
            } else if (oldSearch?.tab === "ads") {
                if (SecurityService.can(ADS_SCOPE.ADS_VIEW_ALL) || SecurityService.can(ADS_SCOPE.ADS_VIEW)) {
                    setCurrentTab("ads")
                } else {
                    setCurrentTab("campaign")
                    setSearchParams({
                        ...oldSearch,
                        tab: "campaign",
                    })
                }
            }
        } else {
            const params: any = {
                ...oldSearch,
                tab: !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL) && !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW) ? "ads" : "campaign",
            }
            setSearchParams(params)
            setCurrentTab(!SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL) && !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW) ? "ads" : "campaign")
        }
    }, [dispatch, searchParams])

    const listItems = [
        {
            label: trans("sidebar.campaign"),
            key: "campaign",
            children: <CampaignList />,
            disabled: !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL) && !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW),
        },
        {
            label: trans("ads_list.tab_name"),
            key: "ads",
            children: <AdsList />,
            disabled: !SecurityService.can(ADS_SCOPE.ADS_VIEW_ALL) && !SecurityService.can(ADS_SCOPE.ADS_VIEW),
        },
    ]

    const onChangeTab = (e: string) => {
        const params: any = {
            tab: e,
        }
        setSearchParams(params)
    }

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb breadCrumbs={[...HOME_BREADCRUMB, { name: trans("campaign.title"), link: "/campaign" }]} />
            <Tabs
                className="nav-space"
                activeKey={current}
                onChange={onChangeTab}
                items={filter(listItems, { disabled: false })}
            />
        </DefaultLayout>
    )
}
