import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeVisibleFormAddContact } from "@reducer/version2/contact.reducer"
import { trans } from "@resources/localization"
import { Modal } from "antd"
import React from "react"
import InputForm from "./InputForm"

const AddContact = () => {
    const dispatch = useAppDispatch()
    const visibleFormAddContact = useAppSelector((state) => state.contact2.visibleFormAddContact)

    return (
        <Modal
            destroyOnClose
            title={trans("contact.add_contact")}
            visible={visibleFormAddContact}
            footer={null}
            width={500}
            onCancel={() => dispatch(changeVisibleFormAddContact(false))}>
            <InputForm />
        </Modal>
    )
}

export default AddContact
