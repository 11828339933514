import { SELLING_CHANNEL } from "@config/permission"
import { ISellChannel } from "@domain/version2/SellChannel"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import {
    callDeleteSellChannel,
    callGetSellChannel,
    callUpdateWarehouseOfSellChannel,
    changeVisibleFormAddAccount,
} from "@reducer/version2/sell-channel.reducer"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Button, Card, message, Modal, Select, Space, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { filter } from "lodash"
import React from "react"

export const TableSellChannel: React.FC = () => {
    const dispatch = useAppDispatch()
    const listSellChannel = useAppSelector((state) => state.sellChannel.listSellChannel)
    const loading = useAppSelector((state) => state.sellChannel.loading)
    const loadingDelete = useAppSelector((state) => state.sellChannel.loadingDelete)
    const loadingUpdate = useAppSelector((state) => state.sellChannel.loadingUpdate)
    const listWarehouse = useAppSelector((state) => state.inventory.warehouses)

    const showConfirmDelete = (id: number) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            icon: <i className="fa-regular fa-circle-info"></i>,
            onOk() {
                dispatch(callDeleteSellChannel({ sellingChannelId: id })).then(() => {
                    dispatch(callGetSellChannel())
                    message.success(trans("message.success"))
                })
            },
        })
    }

    const updateSellChannel = (record: ISellChannel, value: number) => {
        dispatch(
            callUpdateWarehouseOfSellChannel({
                sellingChannelId: record?.id,
                warehouseId: value,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                dispatch(callGetSellChannel())
                message.success(trans("message.success"))
            }
        })
    }

    const columns: ColumnsType<ISellChannel> = [
        {
            title: trans("sell_channel.store"),
            align: "left",
            dataIndex: "name",
            key: "store",
            width: "15%",
        },
        {
            title: trans("sell_channel.ecommerce"),
            align: "left",
            dataIndex: "marketplaceCode",
            key: "ecommerce",
            width: "15%",
        },

        {
            title: trans("sell_channel.export_warehouse"),
            align: "left",
            key: "export_warehouse",
            width: "15%",
            render: (record: ISellChannel) => (
                <>
                    {SecurityService.can(SELLING_CHANNEL.SELLING_CHANNEL_LIST_UPDATE) ? (
                        <Select
                            loading={loadingUpdate || loading}
                            onChange={(value) => {
                                updateSellChannel(record, Number(value))
                            }}
                            defaultValue={record?.warehouse?.name}
                            className="w-full">
                            {listWarehouse?.map((item) => (
                                <Select.Option
                                    key={item?.id}
                                    value={item?.id}>
                                    {item?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        <p className="mb-0">{record?.warehouse?.name || ""}</p>
                    )}
                </>
            ),
        },
        {
            title: trans("contact-detail.status"),
            key: "status",
            align: "center",
            width: "15%",
            render: (record: ISellChannel) => <p className="mb-0"> {record?.status === "ACTIVE" ? trans("warehouse.ACTIVE") : trans("warehouse.inactive")}</p>,
        },
        {
            title: trans("sell_channel.connection_expiration"),
            key: "connection_expiration",
            dataIndex: "accessTokenExpiredAt",
            align: "center",
            width: "15%",
            render: (value) => (value ? formatDateFull(value) : "---"),
        },
        {
            title: trans("table.action"),
            className: "bold-400",
            width: "15%",
            dataIndex: SecurityService.can(SELLING_CHANNEL.SELLING_CHANNEL_LIST_UPDATE) ? "action" : "disabledAction",
            render: (_, record: ISellChannel) =>
                SecurityService.can(SELLING_CHANNEL.SELLING_CHANNEL_LIST_UPDATE) && (
                    <Button
                        type="link"
                        loading={loadingDelete}
                        icon={<i className="fa-regular fa-trash"></i>}
                        onClick={() => showConfirmDelete(record?.id)}
                        disabled={!SecurityService.can(SELLING_CHANNEL.SELLING_CHANNEL_LIST_UPDATE)}
                    />
                ),
        },
    ]

    return (
        <Card
            className="space-layout"
            title={trans("sell_channel.title")}
            extra={
                <Space>
                    {SecurityService.can(SELLING_CHANNEL.SELLING_CHANNEL_CREATE) && (
                        <Button
                            type="primary"
                            className="m-0 ml-2"
                            loading={loading}
                            onClick={() => dispatch(changeVisibleFormAddAccount(true))}
                            icon={<i className="fa-solid fa-plus mr-2" />}>
                            {trans("sell_channel.add_account")}
                        </Button>
                    )}
                </Space>
            }>
            <Table
                scroll={{
                    x: true,
                }}
                columns={filter(columns, (iFil: any) => iFil?.dataIndex !== "disabledAction")}
                loading={loading}
                dataSource={listSellChannel}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={false}
            />
        </Card> //
    )
}
