import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import { useSearchParams } from "react-router-dom"
import SecurityService from "@util/SecurityService"
import { filter } from "lodash"
import { Products } from "./Products"
import { SKUCombo } from "./SKUCombo"
import { COMBO_SCOPE, PRODUCT_SCOPE } from "@config/permission"

export const Product: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [current, setCurrentTab] = useState<string>("")

    useEffect(() => {
        dispatch(changePageTitleAction(trans("contact.product")))
        const oldSearch = Object.fromEntries(searchParams.entries())
        if (searchParams.has("tab")) {
            if (oldSearch?.tab === "products") {
                if (SecurityService.can(PRODUCT_SCOPE.PRODUCT_LIST_VIEW)) {
                    setCurrentTab("products")
                } else {
                    setCurrentTab("skuCombo")
                    setSearchParams({
                        ...oldSearch,
                        tab: "skuCombo",
                    })
                }
            } else if (oldSearch?.tab === "skuCombo") {
                if (SecurityService.can(COMBO_SCOPE.COMBO_LIST_VIEW)) {
                    setCurrentTab("skuCombo")
                } else {
                    setCurrentTab("products")
                    setSearchParams({
                        ...oldSearch,
                        tab: "products",
                    })
                }
            }
        } else {
            const params: any = {
                ...oldSearch,
                tab: SecurityService.can(PRODUCT_SCOPE.PRODUCT_LIST_VIEW) ? "products" : "skuCombo",
            }
            setSearchParams(params)
            setCurrentTab("products")
        }
    }, [dispatch, searchParams])

    const listItems = [
        {
            label: trans("contact.product"),
            key: "products",
            children: <Products />,
            disabled: !SecurityService.can(PRODUCT_SCOPE.PRODUCT_LIST_VIEW),
        },
        {
            label: trans("warehouse.sku_combo"),
            key: "skuCombo",
            children: <SKUCombo />,
            disabled: !SecurityService.can(COMBO_SCOPE.COMBO_LIST_VIEW),
        },
    ]

    const onChangeTab = (e: string) => {
        const params: any = {
            tab: e,
        }
        setSearchParams(params)
    }

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb breadCrumbs={[...HOME_BREADCRUMB, { name: trans("contact.product"), link: "/product" }]} />
            <Tabs
                className="nav-space"
                activeKey={current}
                onChange={onChangeTab}
                items={filter(listItems, { disabled: false })}
            />
        </DefaultLayout>
    )
}
