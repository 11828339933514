import { ICampaign } from "@domain/version2/Campaign"
import { IOneSkuCombo } from "@domain/version2/SkuCombo"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, InputNumber, Popover, Row, Typography } from "antd"
import { first, isEmpty } from "lodash"
import React, { useEffect } from "react"
type Props = {
    formRef: any
    currentCampaign: ICampaign
}
export const CreateSkuCombos: React.FC<Props> = ({ formRef, currentCampaign }) => {
    const detailSkuCombo = useAppSelector((state) => state.skuCombo.skuComboDetail)
    const listHeader = [
        { title: trans("skuCombo.name_code"), span: 6 },
        { title: `${trans("ads.quantity")}(${trans("ads.from")})`, span: 5 },
        { title: `${trans("ads.quantity")}(${trans("ads.to")})`, span: 5 },
        { title: trans("ads.price"), span: 5 },
        { title: trans("table.action"), span: 3 },
    ]
    useEffect(() => {
        if (!isEmpty(currentCampaign)) {
            formRef.setFieldsValue({
                pricePolicies: first(currentCampaign?.pricePolicies)?.wholeSalePrice?.map((item) => ({
                    from: item?.from,
                    to: item?.to,
                    price: item?.price,
                })),
            })
        }
    }, [formRef, currentCampaign])
    return (
        <Card
            className="space-layout">
            <div className="bg-gray-200 p-3 border-b-black">
                <Row gutter={15}>
                    {listHeader.map((item: { title: string; span: number }, index: number) => (
                        <Col
                            span={item.span}
                            key={index}>
                            <Typography.Text strong>{item.title}</Typography.Text>
                        </Col>
                    ))}
                </Row>
            </div>
            <Form.List name="pricePolicies">
                {(fields, { add, remove }) => (
                    <div>
                        {fields.map(({ name, ...restField }) => (
                            <Row
                                className="p-2 border-b"
                                key={name}
                                gutter={15}>
                                <Col span={6}>
                                    <Popover
                                        content={
                                            <>
                                                {detailSkuCombo?.skus?.map((item: IOneSkuCombo) => (
                                                    <ul key={item.id}>
                                                        <li className="ml-3 list-disc">{item?.sku?.product?.name || ""}</li>
                                                        <li className="ml-3 list-none">
                                                            {item?.sku?.variant ? Object?.values(item?.sku?.variant)?.join("/") : ""}({item?.sku?.sku || ""})
                                                        </li>
                                                        <li className="ml-3 list-none">
                                                            {item?.quantity || 0} {trans("campaign.items")}
                                                        </li>
                                                    </ul>
                                                ))}
                                            </>
                                        }>
                                        <p className="m-0 cursor-pointer hover:text-blue-400">
                                            {detailSkuCombo?.name || ""} - {detailSkuCombo?.code || ""}
                                        </p>
                                    </Popover>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "from"]}
                                        rules={[{ required: true, message: trans("message.required") }]}>
                                        <InputNumber
                                            parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                            placeholder={trans("ads.from")}
                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre["pricePolicies"][name]?.from !== next["pricePolicies"][name]?.from}>
                                        {({ getFieldValue }) => {
                                            const quantityFrom = getFieldValue(["pricePolicies", name, "from"])
                                            return (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "to"]}
                                                    rules={[
                                                        { required: true, message: trans("message.required") },
                                                        {
                                                            validator(_, value) {
                                                                if (!value || Number(value) >= Number(quantityFrom)) {
                                                                    return Promise.resolve()
                                                                }
                                                                return Promise.reject(new Error(trans("campaign.required_from_to")))
                                                            },
                                                        },
                                                    ]}>
                                                    <InputNumber
                                                        parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                        placeholder={trans("ads.to")}
                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                    />
                                                </Form.Item>
                                            )
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "price"]}
                                        rules={[{ required: true, message: trans("message.required") }]}>
                                        <InputNumber
                                            placeholder={trans("ads.price")}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            parser={(value: any) =>
                                                value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0
                                            }
                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    {name !== 0 && (
                                        <Button
                                            onClick={() => remove(name)}
                                            type="link"
                                            icon={<i className="fa-regular fa-trash" />}
                                        />
                                    )}
                                    {name === fields.length - 1 && name < 4 && (
                                        <Button
                                            onClick={() => add()}
                                            type="default">
                                            {trans("button.add")}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>
                )}
            </Form.List>
        </Card>
    )
}
