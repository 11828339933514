import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Col, Divider, Form, Input, message, Modal, Row, Select, Space } from "antd"
import React from "react"
import { useSearchParams } from "react-router-dom"
import { changeVisibleAddPurchasingAccount, callCreatePurchasingAccount, callListPurchasingAccount } from "@reducer/version2/purchasing-account"

export const AddPurchasingAccount: React.FC = () => {
    const [formRef] = Form.useForm()
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    
    const isShowAdd = useAppSelector((state) => state.purchasingAccount.isShowAdd)
    const loadingCreate = useAppSelector((state) => state.purchasingAccount.loadingCreate)
    const serviceSuggest = useAppSelector((state) => state.purchasingAccount.serviceSuggest)


    const handleSubmit = (values: any) => {
        console.log("values", values);
        
        dispatch(
            callCreatePurchasingAccount({
                ...values,
                service: values?.service,
                username: values?.username?.trim(),
                password: values?.password,
                pinCode: values?.pinCode || "",
            })
        ).then((result: any) => {
            console.log('result', result)
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                dispatch(
                    callListPurchasingAccount({
                        ...Object.fromEntries(searchParams.entries()),
                        size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                        page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                    })
                )
                formRef.resetFields()
                dispatch(changeVisibleAddPurchasingAccount(false))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <Modal
            title={trans("purchasing_account.add")}
            visible={isShowAdd}
            destroyOnClose
            footer={null}
            width={790}
            onCancel={() => {
                formRef.resetFields()
                dispatch(changeVisibleAddPurchasingAccount(false))
            }}>
            <Form
                form={formRef}
                layout="vertical"
                onFinish={handleSubmit}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={trans("inventory.service")}
                            name="service"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select placeholder={trans("purchasing_account.select_service")}>
                                {serviceSuggest?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.id}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("purchasing_account.name")}
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                                {
                                    validator(_, value) {
                                        if (value && value.trim() === "") {
                                            return Promise.reject(new Error(trans("message.required")))
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}>
                            <Input placeholder={trans("purchasing_account.enter_name")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("login.password")}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                                {
                                    validator(_, value) {
                                        if (value && value.trim() === "") {
                                            return Promise.reject(new Error(trans("message.required")))
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}>
                            <Input.Password placeholder={trans("purchasing_account.enter_password")} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className={"ant-col-display-block "}>
                        <Form.Item
                            label={trans("purchasing_account.pin_code")}
                            name="pinCode"
                            className="w-full">
                            <Input.Password placeholder={trans("purchasing_account.enter_pin_code")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row justify="end">
                    <Space>
                        <Button
                            type="default"
                            loading={loadingCreate}
                            onClick={() => {
                                formRef.resetFields()
                                dispatch(changeVisibleAddPurchasingAccount(false))
                            }}>
                            {trans("button.cancel")}
                        </Button>
                        <Button
                            loading={loadingCreate}
                            htmlType="submit"
                            type="primary">
                            {trans("button.add")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
