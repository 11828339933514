import { COUNTRY_CODE } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Form, Input, InputNumber, Row, Space, Spin, Typography, message } from "antd"
import { find, first, isEmpty, sum } from "lodash"
import React, { useEffect, useState } from "react"
import { ListSkuDraft } from "./ListSkuDraft"
import { AddressDraft } from "./AddressDraft"
import { IProductVariants } from "@domain/version2/Product"
import { convertNumberToCurrency } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callImportCreateOrder, callValidateImportOrder } from "@reducer/version2/order.reducer"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
type Props = {
    listDraft: any
    setClose: () => void
}
export const VerifyOrderList: React.FC<Props> = ({ listDraft, setClose }) => {
    const [formRefImport] = Form.useForm()
    const dispatch = useAppDispatch()
    const detailVendor = useAppSelector((state) => state.vendorDetail.detailVendor)
    const listProducts = useAppSelector((state) => state.productVersion2.listProducts)
    const loadingValidateImport = useAppSelector((state) => state.orderVersion2.loadingValidateImport)
    const loadingImportCreate = useAppSelector((state) => state.orderVersion2.loadingCreateImport)
    const [isDoneValidate, setIsDoneValidate] = useState(false)
    const renderTitle = (item: IProductVariants) => (
        <div>
            <p className="m-0">{item?.sku}</p>
            <p className="text-xs m-0">
                ({item?.product?.name} - {!isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : ""})
            </p>
        </div>
    )
    console.log("detailVendor", detailVendor)
    useEffect(() => {
        if (!isEmpty(listDraft)) {
            formRefImport.setFieldsValue({
                dataImports:
                    listDraft?.map((item: any) => ({
                        orderRefCode: item?.orderRefCode,
                        trackingNumber: item?.trackingNumber,
                        fullName: item?.fullName,
                        phoneNumber: item?.phoneNumber?.toString(),
                        province: item?.province,
                        city: item?.city,
                        district: item?.district,
                        area: item?.area,
                        completeAddress: item?.completeAddress,
                        postalCode: item?.postalCode,
                        note: item?.note,
                        courier: item?.courier,
                        totalPayment: item?.totalPayment?.toString()?.replace(/[^\d.]/g, "") || null,
                        deliveryFee: item?.deliveryFee?.toString()?.replace(/[^\d.]/g, "") || null,
                        listCities: [],
                        listDistricts: [],
                        listAreas: [],
                        orderItems: !isEmpty(item?.items)
                            ? item?.items?.map((iSmall: any) => ({
                                  sku: iSmall?.sku,
                                  quantity: iSmall?.quantity?.toString()?.replace(/[^\d]/g, "") || null,
                                  price: iSmall?.unitPrice?.toString()?.replace(/[^\d.]/g, "") || null,
                                  listSkus: !isEmpty(listProducts)
                                      ? listProducts?.map((item) => ({
                                            value: item?.sku,
                                            label: renderTitle(item),
                                            key: item?.sku,
                                            product: item?.product?.name,
                                            variant: !isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : "",
                                        }))
                                      : [],
                              }))
                            : [
                                  {
                                      sku: "",
                                      quantity: "",
                                      price: "",
                                      listSkus: !isEmpty(listProducts)
                                          ? listProducts?.map((item) => ({
                                                value: item?.sku,
                                                label: renderTitle(item),
                                                key: item?.sku,
                                                product: item?.product?.name,
                                                variant: !isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : "",
                                            }))
                                          : [],
                                  },
                              ],
                    })) || [],
            })
        }
    }, [listDraft])
    const validateListDraft = (values: { dataImports: any }) => {
        const dataRequest = values?.dataImports?.map((item: any) => ({
            orderRefCode: item?.orderRefCode,
            trackingNumber: item?.trackingNumber,
            fullName: item?.fullName,
            phoneNumber: item?.phoneNumber,
            country: detailVendor?.countryCode,
            province: item?.province,
            city: item?.city,
            district: item?.district,
            area: item?.area,
            completeAddress: item?.completeAddress,
            postalCode: item?.postalCode,
            note: item?.note,
            courier: item?.courier,
            totalPayment: Number(item?.totalPayment) || 0,
            deliveryFee: Number(item?.deliveryFee) || 0,
            orderItems: item?.orderItems?.map((iSmall: any) => ({
                sku: iSmall?.sku,
                price: Number(iSmall?.price),
                quantity: Number(iSmall?.quantity),
            })),
        }))
        if (!isDoneValidate) {
            dispatch(callValidateImportOrder({ orders: dataRequest })).then((result: any) => {
                console.log("result", result)
                if (result?.payload?.status === 201) {
                    const oldDataImports = formRefImport.getFieldValue("dataImports")
                    if (!isEmpty(result?.payload?.data?.errors)) {
                        const listErrors = result?.payload?.data?.errors
                        const newDataImports = oldDataImports?.map((item: any) => ({
                            ...item,
                            errors: find(listErrors, { orderRefCode: item?.orderRefCode })?.errors || [],
                        }))
                        formRefImport.setFieldsValue({
                            dataImports: newDataImports,
                        })
                        formRefImport.submit()
                    } else {
                        setIsDoneValidate(true)
                    }
                }
            })
        } else {
            dispatch(callImportCreateOrder({ orders: dataRequest })).then((result: any) => {
                if (result?.payload?.status === 201) {
                    message.success(trans("message.success"))
                    formRefImport.resetFields()

                    setClose()
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
        console.log("values", values)
    }
    const exportFileExcel = () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        const fileExtension = ".xlsx"
        const dataExport: any = []
        const dataImports = formRefImport.getFieldValue("dataImports")
        dataImports?.forEach((item: any) => {
            const listSkus: any = item?.orderItems
            const firstSku: any = !isEmpty(listSkus) ? first(listSkus) : {}
            dataExport.push({
                ["Order Ref Code"]: item?.orderRefCode,
                ["Tracking Number"]: item?.trackingNumber,
                ["SKU Code"]: firstSku?.sku,
                ["Quantity"]: firstSku?.quantity,
                ["Unit Price"]: firstSku?.price,
                ["Full Name"]: item?.fullName,
                ["Phone Number"]: item?.phoneNumber,
                ["Courier"]: item?.courier,
                ["Province"]: item?.province,
                ["City"]: item?.city,
                ["District"]: item?.district,
                ["Area"]: item?.area,
                ["Complete Address"]: item?.completeAddress,
                ["Postal Code"]: item?.postalCode,
                ["Note"]: item?.note,
                ["Total Payment"]: Number(item?.totalPayment),
                ["Delivery Fee"]: Number(item?.deliveryFee),
            })
            if (listSkus.length > 1) {
                const secondSkus = listSkus.splice(0, 1)
                secondSkus?.forEach((iSmallSku: any) => {
                    dataExport.push({
                        ["Order Ref Code"]: "",
                        ["Tracking Number"]: "",
                        ["SKU Code"]: iSmallSku?.sku,
                        ["Quantity"]: Number(iSmallSku?.quantity),
                        ["Unit Price"]: Number(iSmallSku?.price),
                        ["Full Name"]: "",
                        ["Phone Number"]: "",
                        ["Courier"]: "",
                        ["Province"]: "",
                        ["City"]: "",
                        ["District"]: "",
                        ["Area"]: "",
                        ["Complete Address"]: "",
                        ["Postal Code"]: "",
                        ["Note"]: "",
                        ["Total Payment"]: "",
                        ["Delivery Fee"]: "",
                    })
                })
            }
        })
        const ws = XLSX.utils.json_to_sheet(dataExport)
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, "export_file_order" + fileExtension)
    }
    const listHeader = [
        { title: trans("order.order_ref_code"), className: "min-w-[150px]", required: true },
        { title: trans("order_detail.tracking_number"), className: "min-w-[150px]", required: false },
        { title: trans("order.sku_code"), className: "min-w-[250px]", required: true },
        { title: trans("order_detail.quantity"), className: "min-w-[100px]", required: true },
        { title: trans("add_product_popup.unitprice"), className: "min-w-[120px]", required: true },
        { title: "", className: "min-w-[100px]", required: false },
        { title: trans("user_list.search_fullname"), className: "min-w-[150px]", required: true },
        { title: trans("subscriber.phone"), className: "min-w-[150px]", required: true },
        { title: trans("contact.country"), className: "min-w-[150px] text-center", required: false },
        { title: trans("contact-detail.courier"), className: "min-w-[150px]", required: true },
        { title: trans("order.postal_code"), className: "min-w-[150px]", required: detailVendor?.countryCode === COUNTRY_CODE.MALAYSIA },
        { title: trans("contact-detail.province"), className: "min-w-[200px]", required: true },
        {
            title: detailVendor.countryCode === COUNTRY_CODE.VIETNAM ? trans("contact-detail.district") : trans("order_list.district"),
            className: "min-w-[200px]",
            required: true,
        },
        {
            title: detailVendor.countryCode === COUNTRY_CODE.VIETNAM ? trans("contact_activity.ward") : trans("contact-detail.district"),
            className: "min-w-[200px]",
            required: detailVendor?.countryCode !== COUNTRY_CODE.MALAYSIA,
        },
        { title: trans("contact-detail.area"), className: "min-w-[200px]", required: false },
        { title: trans("contact-detail.address_detail"), className: "min-w-[200px]", required: true },
        { title: trans("order_detail.note"), className: "min-w-[200px]", required: false },
        { title: trans("order_detail.total_amount"), className: "min-w-[150px] text-right", required: false },
        { title: trans("order.delivery_fee"), className: "min-w-[150px]", required: false },
        { title: trans("order_detail.total_payment"), className: "min-w-[150px]", required: true },
        { title: "", className: "min-w-[60px]", required: false },
    ]
    return (
        <div className="mt-3">
            <Typography.Text strong>{trans("order.draft_list")}</Typography.Text>
            <Form
                form={formRefImport}
                onValuesChange={() => {
                    setIsDoneValidate(false)
                }}
                scrollToFirstError
                onFinish={validateListDraft}>
                <div className="w-full overflow-auto hover:overflow-scroll mt-2">
                    <div className="py-2 flex w-fit border-b bg-gray-300 px-3">
                        {listHeader.map((item: { title: string; className: string; required: boolean }, index: number) => (
                            <div
                                key={index}
                                className={`${item.className} mr-2`}>
                                {item.title}
                                {item?.required && <span className="ml-1 text-red-500">*</span>}
                            </div>
                        ))}
                    </div>
                    <Form.List name="dataImports">
                        {(fields, { remove }) => (
                            <>
                                {!isEmpty(fields) ? (
                                    <div>
                                        {fields.map(({ name, ...restField }) => (
                                            <div
                                                className="py-2 flex px-3 w-fit border-b"
                                                key={name}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre["dataImports"][name]?.errors !== next["dataImports"][name]?.errors}>
                                                    {({ getFieldValue }) => {
                                                        const errors = getFieldValue(["dataImports", name, "errors"]) || []
                                                        return (
                                                            <>
                                                                <div className="min-w-[150px] mr-2">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "orderRefCode"]}
                                                                        rules={[
                                                                            { required: true, message: trans("message.required") },
                                                                            {
                                                                                validator() {
                                                                                    const itemError: any = find(errors, { field: "orderRefCode" }) || {}
                                                                                    if (isEmpty(itemError)) {
                                                                                        return Promise.resolve()
                                                                                    }
                                                                                    return Promise.reject(new Error(itemError?.error))
                                                                                },
                                                                            },
                                                                        ]}>
                                                                        <Input onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")} />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="min-w-[150px] mr-2">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "trackingNumber"]}>
                                                                        <Input onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")} />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="mr-2">
                                                                    <ListSkuDraft
                                                                        parentRestField={{ ...restField }}
                                                                        parentName={name}
                                                                        formRef={formRefImport}
                                                                        errors={errors}
                                                                    />
                                                                </div>
                                                                <AddressDraft
                                                                    formRef={formRefImport}
                                                                    parentRestField={{ ...restField }}
                                                                    parentName={name}
                                                                    errors={errors}
                                                                />
                                                                <div className="min-w-[150px] mr-2 text-right">
                                                                    <Form.Item
                                                                        noStyle
                                                                        shouldUpdate={(pre, next) =>
                                                                            pre["dataImports"][name]?.orderItems !== next["dataImports"][name]?.orderItems
                                                                        }>
                                                                        {({ getFieldValue }) => {
                                                                            const listAmount: any =
                                                                                getFieldValue(["dataImports", name, "orderItems"])?.map(
                                                                                    (item: any) => Number(item?.quantity) * Number(item?.price)
                                                                                ) || []
                                                                            let totalAmount = !isEmpty(listAmount) ? sum(listAmount) : 0
                                                                            totalAmount = +totalAmount.toFixed(2)
                                                                            return (
                                                                                <Typography.Text className="mt-3">
                                                                                    {convertNumberToCurrency(totalAmount)}
                                                                                </Typography.Text>
                                                                            )
                                                                        }}
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="min-w-[150px] mr-2">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "deliveryFee"]}>
                                                                        <InputNumber
                                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            placeholder={trans("order.delivery_fee")}
                                                                            parser={(value: any) =>
                                                                                value
                                                                                    ? parseFloat(
                                                                                          value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")
                                                                                      ).toFixed(2)
                                                                                    : 0
                                                                            }
                                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="min-w-[150px] mr-2">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "totalPayment"]}
                                                                        rules={[{ required: true, message: trans("message.required") }]}>
                                                                        <InputNumber
                                                                            className="w-full"
                                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            placeholder={trans("order_detail.total_payment")}
                                                                            parser={(value: any) =>
                                                                                value
                                                                                    ? parseFloat(
                                                                                          value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")
                                                                                      ).toFixed(2)
                                                                                    : 0
                                                                            }
                                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="min-w-[60px] flex">
                                                                    {name !== 0 && (
                                                                        <Button
                                                                            type="link"
                                                                            className="mr-2"
                                                                            icon={<i className="fa-regular fa-trash"></i>}
                                                                            onClick={() => remove(name)}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </>
                                                        )
                                                    }}
                                                </Form.Item>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        <Spin />
                                    </div>
                                )}
                            </>
                        )}
                    </Form.List>
                </div>
                <Row
                    justify="end"
                    className="mt-3">
                    <Space>
                        <Button
                            type="default"
                            loading={!isDoneValidate ? loadingValidateImport : loadingImportCreate}
                            disabled={!isDoneValidate ? loadingValidateImport : loadingImportCreate}
                            onClick={() => {
                                setClose()
                            }}>
                            {trans("button.cancel_button")}
                        </Button>
                        {isDoneValidate ? (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => exportFileExcel()}>
                                    {trans("order.download_excel")}
                                </Button>
                                <Button
                                    type="primary"
                                    loading={loadingImportCreate}
                                    disabled={loadingImportCreate}
                                    htmlType="submit">
                                    {trans("order.create_order")}
                                </Button>
                            </>
                        ) : (
                            <Button
                                type="primary"
                                loading={loadingValidateImport}
                                disabled={loadingValidateImport}
                                htmlType="submit">
                                {trans("button.validate")}
                            </Button>
                        )}
                    </Space>
                </Row>
            </Form>
        </div>
    )
}
