import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { CAMPAIGN_SCOPE } from "@config/permission"
import { IPricePolicy } from "@domain/version2/Campaign"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetDetailCampaign, callUpdateCampaign } from "@reducer/version2/campaign-detail.reducer"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Button, Form, message, Row, Space } from "antd"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { GeneralInfo } from "./General"
import { CreateProducts } from "./ListProduct"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"
import { CreateSkuCombos } from "./ListSkuCombo"
export const EditCampaign: React.FC = () => {
    const [formRef] = Form.useForm()
    const navigate = useNavigate()
    const { id } = useParams()
    const [statusShow, setStatusShow] = useState<number>(0)
    const detailCampaign = useAppSelector((state) => state.campaignDetailVer2.detailCampaign)
    const loadingDetail = useAppSelector((state) => state.campaignDetailVer2.loadingDetail)
    const loadingUpdate = useAppSelector((state) => state.campaignDetailVer2.loadingUpdateCampaign)
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const userLogin = SecurityService.getUser()
    const disabledUpdate = !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_UPDATE)

    useEffect(() => {
        const search = Object.fromEntries(searchParams.entries())
        if (id) {
            if (
                (userLogin?.sub !== search?.createdBy || !searchParams.has("createdBy")) &&
                !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL) &&
                SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW)
            ) {
                navigate("/campaign?tab=campaign")
            }
        }
    }, [id, searchParams, userLogin])

    useEffect(() => {
        dispatch(changePageTitleAction(trans("campaign.update_campaign")))
        dispatch(callGetDetailCampaign(id || "")).then((result: any) => {
            setStatusShow(result?.payload?.response?.status)
        })
    }, [dispatch])
    console.log("detailCampaign", detailCampaign)
    useEffect(() => {
        if (!isEmpty(detailCampaign)) {
            formRef.setFieldsValue({
                productName: detailCampaign?.product?.name,
                skuName: detailCampaign?.combo?.name,
                ...(detailCampaign?.combo?.name && { type: "combo" }),
                ...(detailCampaign?.product?.name && { type: "product" }),
                name: detailCampaign?.name,
                fbCampaignIds: detailCampaign?.refId ? detailCampaign?.refId?.split(",") : [],
            })
        }
    }, [detailCampaign, formRef])
    const onUpdateCampaign = (values: any) => {
        let allSkus: any = []
        if (values?.type === "product") {
            detailCampaign?.pricePolicies?.forEach((item: IPricePolicy, index: number) => {
                const oneSku: any = values[`pricePolicies${index + 1}`]
                const finalOneSku = oneSku?.map((finalItem: any) => ({
                    ...finalItem,
                    sku: item?.sku,
                }))
                allSkus = [...allSkus, ...finalOneSku]
            })
        }
        if (values?.type === "combo") {
            allSkus = values?.pricePolicies?.map((element: any) => ({
                ...element,
                sku: detailCampaign?.combo?.code,
            }))
        }

        dispatch(
            callUpdateCampaign({
                code: detailCampaign?.code || "",
                body: {
                    product: detailCampaign?.productCode || "",
                    combo: detailCampaign?.comboCode || '',
                    name: values?.name,
                    refId: values?.fbCampaignIds?.join(",") || "",
                    pricePolicies: allSkus,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <DefaultLayout loading={loadingDetail}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            {
                                name: "sidebar.dashboard",
                                link: "/",
                            },
                            {
                                name: "sidebar.campaign",
                                link: "/campaign",
                            },
                            {
                                name: "campaign.update_campaign",
                                link: "",
                            },
                        ]}
                    />
                    <Form
                        form={formRef}
                        onFinish={onUpdateCampaign}
                        layout="vertical">
                        <GeneralInfo formRef={formRef} />
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.type !== next.type}>
                            {({ getFieldValue }) => (
                                <>
                                    {getFieldValue("type") === "product" && <CreateProducts formRef={formRef} />}
                                    {getFieldValue("type") === "combo" && <CreateSkuCombos formRef={formRef} />}
                                </>
                            )}
                        </Form.Item>
                        <div className="mt-3 mx-3">
                            <Row
                                justify="end"
                                className="mt-3">
                                <Space>
                                    <Button
                                        type="default"
                                        onClick={() => navigate("/campaign?tab=campaign")}
                                        loading={loadingUpdate}>
                                        {trans("setting.cancel")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={disabledUpdate}
                                        loading={loadingUpdate}>
                                        {trans("setting.save")}
                                    </Button>
                                </Space>
                            </Row>
                        </div>
                    </Form>
                </>
            )}
        </DefaultLayout>
    )
}
