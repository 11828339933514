import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callListAds } from "@reducer/version2/ads.reducer"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Filter from "./Filter"
import TableAds from "./Table"

export const AdsList: React.FC = () => {
    const dispatch: any = useAppDispatch()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        if (params?.tab === "ads") {
            delete params.call
            delete params.tab
            dispatch(
                callListAds({
                    ...params,
                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    sort: "createdAt:DESC",
                })
            )
        }
    }, [dispatch, searchParams])

    return (
        <div>
                <Filter />
                <TableAds />
        </div>
    )
}
