import lodash from "lodash"
import localStore from "@util/LocalStore"

class SecurityService {
    static can = (permission: string) => {
        const permissions = localStore.getJson("permissions") || []
        return permissions.includes(permission)
    }

    static isLogged = () => {
        const loginSession = localStore.getJson("loginSession")
        const accessToken = lodash.get(loginSession, "accessToken")
        return !!accessToken
    }

    static getUser = () => {
        if (SecurityService.isLogged()) {
            return localStore.getJson("loggedUser")
        }
    }

    static getVendorCode = (): string => {
        return localStore.getItem("vendorCode") as string;
    }
}

export default SecurityService
