import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callProductSuggest, resetDataProductsSuggest, callDetailProduct, resetDataDetail } from "@reducer/version2/product.reducer"
import { callGetDetailSkuCombo, callGetSkuComboSuggest, resetDataDetailSkuCombo } from "@reducer/version2/skuCombo.reducer"
import { trans } from "@resources/localization"
import { optionType } from "@util/Common"
import { AutoComplete, Card, Col, Empty, Form, Input, Row, Select, Spin } from "antd"
import { debounce } from "lodash"
import React, { useMemo } from "react"

type Props = {
    formRef: any
}
export const GeneralInfo: React.FC<Props> = ({ formRef }) => {
    const listProducts = useAppSelector((state) => state.productVersion2.productsSuggest)
    const skuComboSuggest = useAppSelector((state) => state.skuCombo.skuComboSuggest)
    const loadingSkuComboSuggest = useAppSelector((state) => state.skuCombo.loadingSkuComboSuggest)
    const loadingProductSuggest = useAppSelector(state => state.productVersion2.loadingProductSuggest)
    const dispatch = useAppDispatch()
    const onSearchProducts = (searchText: string) => {
        formRef.setFieldsValue({
            productCode: "",
        })
        if (searchText.trim().length >= 3) {
            dispatch(
                callProductSuggest({
                    keyword: searchText.trim(),
                })
            )
        } else {
            dispatch(resetDataProductsSuggest([]))
            dispatch(resetDataDetail([]))
        }
    }
    const onSearchSkuCombo = (searchText: string) => {
        formRef.setFieldsValue({
            combo: "",
        })
        if (searchText.trim().length >= 3) {
            dispatch(
                callGetSkuComboSuggest({
                    keyword: searchText.trim(),
                })
            )
        } else {
            dispatch(callGetSkuComboSuggest({ keyword: "" }))
            dispatch(resetDataDetailSkuCombo({}))
        }
    }
    const options = useMemo(
        () =>
            listProducts?.map((item) => ({
                value: item?.name,
                key: item?.code,
            })),
        [listProducts]
    )
    const optionsSkuCombo = useMemo(
        () =>
            skuComboSuggest?.map((item) => ({
                value: item?.name,
                key: item?.code,
            })),
        [skuComboSuggest]
    )

    return (
        <Card className="space-layout">
            <Row gutter={12}>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        label={trans("campagin.type_create")}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select placeholder={trans("campaign.type_pl")}>
                            {optionType?.map((item) => (
                                <Select.Option
                                    key={item?.value}
                                    value={item?.value}>
                                    {item?.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="productCode"
                        hidden
                    />
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.type !== next.type}>
                        {({ getFieldValue }) => (
                            <Form.Item
                                name="productName"
                                label={trans("campaign.product")}
                                rules={
                                    getFieldValue("type") === "product"
                                        ? [
                                              {
                                                  required: true,
                                                  message: trans("message.required"),
                                              },
                                          ]
                                        : []
                                }>
                                <AutoComplete
                                    notFoundContent={loadingProductSuggest ? <Spin />: <Empty />}
                                    allowClear
                                    onSearch={debounce(onSearchProducts, 1000)}
                                    placeholder={trans("product_list.search")}
                                    options={options}
                                    disabled={getFieldValue("type") !== "product"}
                                    onSelect={(val: any, option: any) => {
                                        dispatch(resetDataDetail({}))
                                        formRef.setFieldsValue({
                                            productCode: option?.key,
                                            combo: '',
                                            skuName: ''
                                        })
                                        dispatch(callDetailProduct(option?.key))
                                    }}
                                    onBlur={() => {
                                        if (!formRef.getFieldValue("productCode")) {
                                            formRef.setFieldsValue({
                                                productName: "",
                                            })
                                            dispatch(resetDataDetail({}))
                                            dispatch(callProductSuggest({keyword: ''}))
                                        }
                                    }}
                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="combo"
                        hidden
                    />
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.type !== next.type}>
                        {({ getFieldValue }) => (
                            <Form.Item
                                name="skuName"
                                label={trans("skuCombo.title")}
                                rules={
                                    getFieldValue("type") === "combo"
                                        ? [
                                              {
                                                  required: true,
                                                  message: trans("message.required"),
                                              },
                                          ]
                                        : []
                                }>
                                <AutoComplete
                                    notFoundContent={loadingSkuComboSuggest ? <Spin /> : <Empty />}
                                    allowClear
                                    onSearch={debounce(onSearchSkuCombo, 1000)}
                                    placeholder={trans("warehouse.sku_combo_search")}
                                    disabled={getFieldValue("type") !== "combo"}
                                    options={optionsSkuCombo}
                                    onSelect={(val: any, option: any) => {
                                        dispatch(resetDataDetailSkuCombo({}))
                                        formRef.setFieldsValue({
                                            combo: option?.key,
                                            productCode: '',
                                            productName: ''
                                        })
                                        dispatch(callGetDetailSkuCombo(option?.key))
                                    }}
                                    onBlur={() => {
                                        if (!formRef.getFieldValue("combo")) {
                                            formRef.setFieldsValue({
                                                skuName: "",
                                            })
                                            dispatch(resetDataDetailSkuCombo({}))
                                            dispatch(callGetSkuComboSuggest({keyword: ''}))
                                        }
                                    }}
                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="name"
                        label={trans("campaign.name")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Input
                            maxLength={256}
                            placeholder={trans("campaign.name_create_pl")}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    name: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="fbCampaignIds"
                        label={trans("campaign.facebook_id")}>
                        <Select
                            mode="tags"
                            allowClear
                            placeholder={trans("campaign.facebook_create_pl")}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}
