import { LIST_CURRENCY } from "@config/constant"
import IContactDetail from "@domain/version2/ContactDetail"
import { IOneSkuCombo } from "@domain/version2/SkuCombo"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { Card, Popover, Table } from "antd"
import { find, first, isEmpty } from "lodash"
import React from "react"
export const ListComboSku: React.FC = () => {
    const contactDetail: IContactDetail = useAppSelector((state) => state.contactDetail2.dataDetail)
    const listPricePolicy = first(contactDetail?.campaignDetail?.pricePolicies)?.wholeSalePrice
    const columns: any = [
        {
            title: trans("skuCombo.title"),
            render: () => (
                <Popover
                    content={
                        <>
                            {contactDetail?.campaignDetail?.combo?.skus?.map((item: IOneSkuCombo) => (
                                <ul key={item.id}>
                                    <li className="ml-3 list-disc">{item?.sku?.product?.name || ""}</li>
                                    <li className="ml-3 list-none">
                                        {item?.sku?.variant ? Object?.values(item?.sku?.variant)?.join("/") : ""}({item?.sku?.sku || ""})
                                    </li>
                                    <li className="ml-3 list-none">
                                        {item?.quantity || 0} {trans("campaign.items")}
                                    </li>
                                </ul>
                            ))}
                        </>
                    }>
                    <p className="m-0 cursor-pointer hover:text-blue-400">
                        {contactDetail?.campaignDetail?.combo?.name || ""} - {contactDetail?.campaignDetail?.combo?.code || ""}
                    </p>
                </Popover>
            ),
        },
        {
            title: trans("contact-detail.quantity"),
            className: "table-vertical-align",
            dataIndex: "from",
            key: "from",
            width:150,
            render: (from: any, record: any) => (!isEmpty(record) ? `${trans("contact.from")} ${record?.from} ${trans("contact.to")} ${record?.to}` : ""),
        },
        {
            title: `${trans("products.price")} (${find(LIST_CURRENCY, { value: contactDetail?.countryCode })?.code || ""})`,
            className: "table-vertical-align text-end",
            dataIndex: "price",
            key: "price",
            width:150,
            render: (price: string) => (price ? convertNumberToCurrency(price) : ""),
        },
    ]
    return (
        <div className="mt-3">
            <Card title={trans("product.price_policy_title")}>
                <Table
                    bordered
                    className="mt-3"
                    rowKey={"id"}
                    columns={columns}
                    dataSource={listPricePolicy || []}
                    pagination={false}
                />
            </Card>
        </div>
    )
}
