import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetProductsByVariants, resetDataProductsByVariants } from "@reducer/version2/product.reducer"
import { trans } from "@resources/localization"
import { AutoComplete, Col, Empty, Form, Row, Spin } from "antd"
import { debounce, find, isEmpty } from "lodash"
import React, { useMemo } from "react"

type Props = {
    formRef: any
    setClose: () => void
}
export const AddSku: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch()
    const [formRef] = Form.useForm()
    const listProducts = useAppSelector((state) => state.productVersion2.listProducts)
    const loading = useAppSelector((state) => state.productVersion2.loadingListProducts)
    const selectedSku = props.formRef.getFieldValue("tableProducts")

    const options = useMemo(
        () =>
            listProducts?.map((item) => ({
                value: `${item?.product?.name} - ${!isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : ""}  - ${item?.sku}`,
                key: item?.sku,
                product: item?.product?.name,
                variant: !isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : "",
            })),

        [listProducts]
    ).filter((el) => !find(selectedSku, { sku: el.key }))

    const handleSearchProducts = (searchText: string) => {
        if (searchText.length >= 3) {
            dispatch(callGetProductsByVariants({ keyword: searchText }))
        }
    }

    const handleSelectProduct = (e: any, option: any) => {
        const product = {
            productName: e,
            quantity: 1,
            sku: option?.key,
            product: option?.product,
            variant: option?.variant,
        }
        const tableProducts = props.formRef.getFieldValue("tableProducts")?.slice() || []
        tableProducts.push(product)
        props.formRef.setFieldValue("tableProducts", tableProducts)
        dispatch(resetDataProductsByVariants([]))
        formRef.setFieldValue("search", "")
        props.setClose()
    }

    return (
        <div className="w-[400px]">
            <Form
                layout="vertical"
                form={formRef}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={trans("skuCombo.sku_name_code")}
                            name="search"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <AutoComplete
                                notFoundContent={loading ? <Spin /> : <Empty />}
                                onSearch={debounce(handleSearchProducts, 1000)}
                                placeholder={trans("products.search_name_code_sku_pl")}
                                options={options}
                                onSelect={handleSelectProduct}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                onBlur={() => formRef.getFieldValue("search") === "" && dispatch(resetDataProductsByVariants([]))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
