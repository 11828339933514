import React, { useEffect, useState } from "react"
import { Button, Card, Form, InputNumber, Modal, notification, Select, Table } from "antd"
import lodash from "lodash"
import { trans } from "@resources/localization"
import { convertNumberToCurrency, formatDateFull, numberFormatter, numberParser, renderVariant } from "@util/Common"
import { useAppSelector } from "@hook/useAppSelector"
import { useAppDispatch } from "@hook/useAppDispatch"
import { addSkuAction, getSkusAction, getSkusByVendorAction, removeSkuAction } from "@reducer/version2/ads-detail.reducer"

export const CrossSku = () => {
    const dispatch = useAppDispatch()
    const [isShowAddSku, setIsShowAddSku] = useState(false)
    const ad = useAppSelector((state) => state.adsDetailVersion2.detailAds)
    const loading = useAppSelector((state) => state.adsDetailVersion2.addSkuLoading)
    const adSkusLoading = useAppSelector((state) => state.adsDetailVersion2.adSkusLoading)
    const skusOfVendor = useAppSelector((state) => state.adsDetailVersion2.skusOfVendor)
    const adSkus = useAppSelector((state) => state.adsDetailVersion2.adSkus)

    const [formAddSku, setFormAddSku] = useState({
        sku: null,
        quantity: null,
        price: null,
    })

    useEffect(() => {
        if (ad.code) {
            dispatch(
                getSkusAction({
                    adCode: ad.code,
                })
            )
        }
    }, [ad])

    useEffect(() => {
        if (isShowAddSku) {
            dispatch(getSkusByVendorAction({}))
        }
    }, [isShowAddSku])

    const handleChangedFormAddSku = (key: string, value: any) => {
        setFormAddSku({
            ...formAddSku,
            [key]: value,
        })
    }

    const handleAddSku = () => {
        dispatch(
            addSkuAction({
                adCode: ad.code,
                sku: formAddSku.sku,
                price: formAddSku.price,
                quantity: formAddSku.quantity,
            })
        ).then((result) => {
            console.log('result', result)
            if (addSkuAction.fulfilled.match(result)) {
                notification.success({
                    message: trans("ad_sku.message.success"),
                })

                dispatch(
                    getSkusAction({
                        adCode: ad.code,
                    })
                )

                setFormAddSku({
                    sku: null,
                    quantity: null,
                    price: null,
                })

                setIsShowAddSku(false)
            } else if (addSkuAction.rejected.match(result)) {
                notification.error({
                    message: trans("ad_sku.message.failed"),
                })
            }
        })
    }

    const handleSearchSkus = (keyword: any) => {
        dispatch(getSkusByVendorAction({ keyword }))
    }

    const handleRemoveSku = (sku: any) => {
        Modal.confirm({
            title: trans("ad_sku.remove_sku_modal"),
            okText: trans("ad_sku.remove_ok_text"),
            cancelText: trans("ad_sku.cancel_text"),
            okButtonProps: {
                loading: adSkusLoading,
                disabled: adSkusLoading,
            },
            cancelButtonProps: {
                loading: adSkusLoading,
                disabled: adSkusLoading,
            },
            onOk: () => {
                dispatch(
                    removeSkuAction({
                        adCode: ad.code,
                        sku: sku.sku,
                    })
                ).then((result) => {
                    if (removeSkuAction.fulfilled.match(result)) {
                        notification.success({
                            message: trans("ad_sku.message.remove_sku_success"),
                        })

                        dispatch(
                            getSkusAction({
                                adCode: ad.code,
                            })
                        )
                    } else if (removeSkuAction.rejected.match(result)) {
                        notification.error({
                            message: trans("ad_sku.message.remove_sku_failed"),
                        })
                    }
                })
            },
        })
    }

    const isSkuAddedToAd = (sku: string, adCode: string) => {
        return !!adSkus.find((x) => x.adCode === adCode && lodash.get(x, "sku.sku") === sku)
    }

    const isSkuAddedToPricePolicies = (sku: string) => {
        return !!ad?.pricePolicies?.find((x) => x.sku === sku)
    }

    const handleCancelAddSku = () => {
        setIsShowAddSku(false)
        setFormAddSku({
            sku: null,
            quantity: null,
            price: null,
        })
    }

    return (
        <Card
            className="space-layout"
            title={trans("ads.gift")}
            extra={
                <Button
                    onClick={() => setIsShowAddSku(true)}
                    type={"primary"}>
                    {trans("ads.add-sku")}
                </Button>
            }>
            <Table
                columns={[
                    {
                        title: "Product",
                        key: "product",
                        render: (row: any) => {
                            return `${lodash.get(row, "sku.product.name")}`
                        },
                    },
                    {
                        title: "Sku",
                        key: "sku",
                        render: (row: any) => {
                            return `${lodash.get(row, "sku.sku")} (${renderVariant(lodash.get(row, "sku.variant"))})`
                        },
                    },
                    {
                        title: trans("ads.quantity"),
                        key: "quantity",
                        dataIndex: ["quantity"],
                    },
                    {
                        title: trans("ads.price"),
                        key: "price",
                        dataIndex: ["price"],
                        render: (value: any) => convertNumberToCurrency(value),
                    },
                    {
                        title: trans("ad_sku.added_by"),
                        key: "addedBy",
                        dataIndex: ["createdBy"],
                    },
                    {
                        title: trans("ad_sku.added_at"),
                        key: "addedAt",
                        dataIndex: ["createdAt"],
                        render: (value: any) => formatDateFull(value),
                    },
                    {
                        title: trans("ad_sku.action"),
                        key: "action",
                        render: (value: any) => {
                            return (
                                <i
                                    className={"fa-solid fa-trash text-primary cursor-pointer"}
                                    onClick={() => handleRemoveSku(value.sku)}
                                />
                            )
                        },
                    },
                ]}
                dataSource={adSkus}
                rowKey={"id"}
                loading={adSkusLoading}
                pagination={false}
            />

            <Modal
                title={trans("ad_sku.add_modal")}
                okText={trans("ad_sku.ok_text")}
                cancelText={trans("ad_sku.cancel_text")}
                visible={isShowAddSku}
                destroyOnClose={true}
                onCancel={() => handleCancelAddSku()}
                onOk={handleAddSku}
                okButtonProps={{
                    loading: loading,
                    disabled: loading,
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading,
                }}>
                <Form layout={"vertical"}>
                    <Form.Item label={"Sku"}>
                        <Select
                            className={"w-full"}
                            value={formAddSku.sku}
                            showSearch={true}
                            onChange={(value) => handleChangedFormAddSku("sku", value)}
                            onSearch={(value) => handleSearchSkus(value)}
                            options={skusOfVendor.map((x: any) => ({
                                disabled: isSkuAddedToAd(x.sku, ad.code as string) || isSkuAddedToPricePolicies(x.sku),
                                key: x.sku,
                                value: x.sku,
                                label: `${lodash.get(x, "product.name")} / ${lodash.get(x, "sku")} (${renderVariant(lodash.get(x, "variant"))})`,
                            }))}
                        />
                    </Form.Item>

                    <Form.Item label={trans("ads.quantity")}>
                        <InputNumber
                            className={"w-full"}
                            min={1}
                            value={formAddSku.quantity}
                            parser={(value) => numberParser(value)}
                            formatter={(value) => numberFormatter(value)}
                            onChange={(value) => handleChangedFormAddSku("quantity", value)}
                        />
                    </Form.Item>

                    <Form.Item label={trans("ads.price")}>
                        <InputNumber
                            className={"w-full"}
                            value={formAddSku.price}
                            parser={(value) => numberParser(value)}
                            formatter={(value) => numberFormatter(value)}
                            onChange={(value) => handleChangedFormAddSku("price", value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    )
}
