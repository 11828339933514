import React, { useEffect } from "react"
import { useAppSelector } from "@hook/useAppSelector"
import { Button, Row, Form, Checkbox, Spin, message } from "antd"
import { useAppDispatch } from "@hook/useAppDispatch"
import SecurityService from "@util/SecurityService"
import { SETTING_SCOPE } from "@config/permission"
import { trans } from "@resources/localization"
import { callGetCourierVendor, callGetListCouriers, callUpdateCourierVendor } from "@reducer/version2/courier-setting.reducer"
import { callVendorDetail } from "@reducer/version2/vendor-detail.reducer"
import { isEmpty } from "lodash"


export const Courier: React.FC = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const couriers = useAppSelector((state) => state.courierSetting.couriers)
    const detailVendor = useAppSelector((state) => state.vendorDetail.detailVendor)
    const courierVendor = useAppSelector((state) => state.courierSetting.courierVendor)
    const loadingCourierVendor = useAppSelector((state) => state.courierSetting.loadingCourierVendor)
    useEffect(() => {
        dispatch(callVendorDetail())
        dispatch(callGetCourierVendor())
    }, [])
    useEffect(() => {
        if (!isEmpty(detailVendor)) {
            dispatch(
                callGetListCouriers({
                    "x-tenant": detailVendor?.code || "",
                    countryCode: detailVendor?.countryCode || "",
                })
            )
        }
    }, [detailVendor])
    useEffect(() => {
        if (!isEmpty(courierVendor)) {
            formRef.setFieldsValue({
                couriers: courierVendor?.map((item) => item?.courierCode),
            })
        }
    }, [courierVendor])

    const onFinish = (values: { couriers: string[] }) => {
        const dataRequest = couriers
            ?.filter((item) => values?.couriers?.includes(item?.code))
            ?.map((iMap) => ({
                courierName: iMap?.name,
                courierCode: iMap?.code,
            }))
        dispatch(
            callUpdateCourierVendor({
                couriers: dataRequest,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                dispatch(callGetCourierVendor())
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <>
            <Form
                form={formRef}
                onFinish={onFinish}>
                <div>
                    <p className="font-medium m-0">{trans("courier_setting.title")}</p>
                    <p className="my-5">{trans("courier_setting.description")}</p>
                    {loadingCourierVendor || isEmpty(couriers) ? (
                        <Spin />
                    ) : (
                        <Form.Item
                            rules={[{ required: true, message: trans("courier_setting.required") }]}
                            name="couriers">
                            <Checkbox.Group
                            className="setting-setup"
                                options={couriers?.map((item) => ({
                                    label: item?.name,
                                    value: item?.code,
                                    disabled: !SecurityService.can(SETTING_SCOPE.COURIER_VIEW),
                                }))}
                            />
                        </Form.Item>
                    )}
                </div>
                {SecurityService.can(SETTING_SCOPE.COURIER_UPDATE) && (
                    <Row justify="end">
                        <Button
                            type="primary"
                            htmlType="submit">
                            {trans("button.save")}
                        </Button>
                    </Row>
                )}
            </Form>
        </>
    )
}
