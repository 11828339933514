import { trans } from "@resources/localization"
import { Card, Table } from "antd"
import React from "react"
export const PackageDraft: React.FC = () => {
    const columns: any[] = [
        {
            title: trans("package.filter_code"),
            className: "bold-400",
            dataIndex: "code",
            key: "code",
        },
        {
            title: trans("order_detail.tracking_number"),
            className: "bold-400",
            dataIndex: "trackingNumber",
            key: "trackingNumber",
        },
        {
            title: `${trans("package-detail.weight")} (g)`,
            className: "bold-400",
            dataIndex: "weight",
            key: "weight",
        },
        {
            title: trans("contact-detail.quantity"),
            className: "bold-400",
            dataIndex: "quantity",
            key: "quantity",
        },
    ]
    return (
        <Card
            className="space-layout"
            title={trans("package-detail.title_package")}>
            <Table
                rowKey={"id"}
                scroll={{ x: true }}
                columns={columns}
                dataSource={[]}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={false}
            />
        </Card>
    )
}
