import React, {useEffect} from "react"
import {useSearchParams} from "react-router-dom"

import {Spin} from "antd"

import appConfig from "@config/app";

import localStore from "@util/LocalStore"

export const Login = () => {

	const [searchParams] = useSearchParams()

	useEffect(() => {
		localStore.setItem("redirectBackUrl", searchParams.get("redirectBackUrl") || btoa("/"))
		localStore.setItem("language", 'en')
		setTimeout(() => {
			// @ts-ignore
			const originUrl = window.location.origin as string;
			window.location.href = `${appConfig.loginUrl}?redirect_uri=${originUrl}/authentication`
		}, 1000)
	})

	return (
		<div className={"mg-t-10 mg-l-10"}>
			Đang chuyển trang, vui lòng đợi một chút.... <Spin />
		</div>
	)
}
