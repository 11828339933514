import { trans } from "@resources/localization"
import Table, { ColumnsType } from "antd/lib/table"
import React from "react"
import { useAppSelector } from "@hook/useAppSelector"

export const TableError: React.FC = () => {
    const dataResponseImport = useAppSelector((state) => state.orderVersion2.dataResponseImport)

    const columns: ColumnsType<any> = [
        {
            title: trans("order_list.lbc_import_line"),
            className: "bold-400",
            dataIndex: "line",
            width: 70,
        },
        {
            title: trans("contact-detail.order_code"),
            className: "bold-400",
            dataIndex: "code",
            width: 160,
            render: (isStatus: boolean) => {
                return isStatus ? isStatus : ""
            },
        },
        {
            title: trans("order_detail.financial_status"),
            dataIndex: "financialStatus",
            render: (financialStatus: string) => (financialStatus ? financialStatus : ""),
        },
        {
            title: trans("order_list.lbc_import_error"),
            className: "bold-400",
            render: (row) => {
                return row?.message || ""
            },
        },
    ]
    return (
        <div>
            <Table
                columns={columns}
                dataSource={dataResponseImport?.errors || []}
                pagination={false}
                scroll={{ y: 600 }}
            />
        </div>
    )
}
