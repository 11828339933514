import { PAGINATION } from "@config/constant"
// import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Row, Table, Pagination, Tooltip, Card, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { formatDateFull, shortenText } from "@util/Common"
import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import SecurityService from "@util/SecurityService"
import { PRODUCT_SCOPE } from "@config/permission"
import { useAppSelector } from "@hook/useAppSelector"
import { find } from "lodash"

export const List: React.FC = () => {
    const total = useAppSelector((state) => state.productVersion2.pagination.total)
    const currentPage = useAppSelector((state) => state.productVersion2.pagination.currentPage)
    const totalPage = useAppSelector((state) => state.productVersion2.pagination.totalPage)
    const pageSize = useAppSelector((state) => state.productVersion2.pagination.pageSize)
    const listProduct = useAppSelector((state) => state.productVersion2.listProductOfVendor)
    const listProductGroups = useAppSelector((state) => state.productGroups.listProductGroups)

    const [searchParams, setSearchParams] = useSearchParams()

    const columns: ColumnsType<any> = [
        {
            title: trans("product_list.code"),
            dataIndex: "code",
            className: "bold-400",
            render: (code: string) => (SecurityService.can(PRODUCT_SCOPE.PRODUCT_DETAIL_VIEW) ? <Link to={`/product/${code}`}>{code}</Link> : code),
            width: 100,
        },
        {
            title: trans("product_list.name"),
            dataIndex: "name",
            className: "bold-400",
            width: 150,
            render: (name: string) => (name.length > 100 ? <Tooltip title={name}>{shortenText(name, 30)}</Tooltip> : name),
        },
        {
            title: trans("product.category"),
            className: "bold-400",
            dataIndex: "productGroupCode",
            render: (productGroupCode) => find(listProductGroups, { code: productGroupCode })?.name || "",
            width: 200,
        },
        {
            title: trans("products.sku_quantity"),
            className: "bold-400",
            dataIndex: "skuQuantity",
            width: 60,
        },
        {
            title: trans("products.campaign_quantity"),
            className: "bold-400",
            dataIndex: "campaignQuantity",
            width: 60,
        },
        {
            title: trans("table.created_by"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: 100,
        },
        {
            title: trans("table.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (value) => (value ? formatDateFull(value) : "---"),
            width: 120,
        },
        {
            title: trans("contact-detail.status"),
            className: "bold-400",
            dataIndex: "isActive",
            width: 60,
            render: (value) => <Tag color={value ? 'green' : 'gray'}>{value ? trans("product_list.active") : trans("product_list.inactive")}</Tag>,
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            size: pageSize,
            page: !searchParams.has("size") || Number(oldSearch.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }

        params.tab = "products"
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}>
            <Table
                scroll={{
                    x: true,
                }}
                columns={columns}
                dataSource={listProduct}
                rowKey={(record) => record.id}
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
