import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetProductSuggestForPackage } from "@reducer/version2/package.reducer"
import { trans } from "@resources/localization"
import { Button, Col, Input, Modal, Row, Table } from "antd"
import { first, isEmpty, map } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
type Props = {
    isOpen: boolean
    formRef: any
    listPackages: any
    setClose: () => void
}
export const ModalSku: React.FC<Props> = ({ isOpen, formRef, listPackages, setClose }) => {
    const dispatch = useAppDispatch()
    const productSuggest = useAppSelector((state) => state.package.productSuggestForPackage)
    const loadingProduct = useAppSelector((state) => state.package.loadingProducts)
    const pageSize: number = useAppSelector((state) => state.package.paginationProduct.pageSize)
    const currentPage: number = useAppSelector((state) => state.package.paginationProduct.currentPage)
    const total: number = useAppSelector((state) => state.package.paginationProduct.total)
    const [searchInput, setSearchInput] = useState("")
    useEffect(() => {
        if (searchInput.trim().length >= 3) {
            dispatch(
                callGetProductSuggestForPackage({
                    page: 1,
                    size: 25,
                    keyword: searchInput.trim(),
                })
            )
        } else if (searchInput.trim() === "") {
            dispatch(
                callGetProductSuggestForPackage({
                    page: 1,
                    size: 25,
                })
            )
        }
    }, [searchInput])
    const columns = [
        {
            title: "",
            className: "bold-400",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "",
            className: "bold-400",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "",
            className: "bold-400",
            dataIndex: "action",
            key: "action",
            width: 100,
            render: (action: any, record: any) => {
                const oldPackages = listPackages?.slice() || []
                const listIds = !isEmpty(oldPackages) ? map(oldPackages, "id") : []
                return (
                    <Button
                        type={listIds.includes(record?.id) ? "default" : "primary"}
                        icon={listIds.includes(record?.id) ? <i className="fa-regular fa-check" /> : <i className="fa-regular fa-plus" />}
                        onClick={() => {
                            if (!listIds.includes(record?.id)) {
                                oldPackages.push({
                                    code: record?.code || "",
                                    name: record?.name || "",
                                    id: record?.id,
                                })
                                formRef.setFieldsValue({
                                    packageItems: oldPackages,
                                })
                            } else {
                                const newPackages = oldPackages.filter((item: any) => item?.id !== record?.id)
                                formRef.setFieldsValue({
                                    packageItems: newPackages,
                                })
                            }
                        }}>
                        {listIds.includes(record?.id) ? trans("package.added") : trans("button.add")}
                    </Button>
                )
            },
        },
    ]
    const handleChangePage = (page: number, newSize: number) => {
        if (searchInput.trim().length >= 3) {
            dispatch(
                callGetProductSuggestForPackage({
                    page: Number(pageSize) === newSize ? page : 1,
                    size: newSize,
                    keyword: searchInput.trim(),
                })
            )
        } else if (searchInput.trim() === "") {
            dispatch(
                callGetProductSuggestForPackage({
                    page: Number(pageSize) === newSize ? page : 1,
                    size: newSize,
                })
            )
        }
    }
    const dataSourceProduct = useMemo(
        () =>
            !isEmpty(productSuggest)
                ? productSuggest?.map((item) => ({
                      code: item?.code || "",
                      name: item?.name || "",
                      id: first(item?.skus)?.id,
                  }))
                : [],
        [productSuggest]
    )
    return (
        <Modal
            width={720}
            open={isOpen}
            title={trans("package.add_product_sku")}
            onCancel={() => setClose()}
            destroyOnClose
            footer={null}>
            <Row>
                <Col span={24}>
                    <Input
                        allowClear
                        suffix={<i className="fa-regular fa-magnifying-glass"></i>}
                        placeholder={trans("package.search_pl")}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </Col>
                <Col
                    span={24}
                    className="mt-3">
                    <Table
                        rowKey={"code"}
                        loading={loadingProduct}
                        columns={columns}
                        showHeader={false}
                        dataSource={dataSourceProduct}
                        className="bd-radius-5"
                        locale={{
                            emptyText: trans("table.empty"),
                        }}
                        pagination={{
                            pageSize: pageSize,
                            total: total,
                            current: currentPage,
                            pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                            showSizeChanger: true,
                            onChange: handleChangePage,
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    )
}
