import { PAGINATION } from "@config/constant"
import IContact from "@domain/version2/Contact"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Button, Card, message, Table, Tag } from "antd"
import { filter, find, isEmpty } from "lodash"
import React, { useMemo, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { changeVisibleFormAddContact, changeVisibleImport, exportContacts } from "@reducer/version2/contact.reducer"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { SettingTable } from "@component/General/SettingTable"

export const TableContact: React.FC = () => {
    const dispatch = useAppDispatch()
    const pageSize: number = useAppSelector((state) => state.contact2.pagination.pageSize)
    const currentPage: number = useAppSelector((state) => state.contact2.pagination.currentPage)
    const total: number = useAppSelector((state) => state.contact2.pagination.total)
    const totalPage: number = useAppSelector((state) => state.contact2.pagination.totalPage)
    const listContact: IContact[] = useAppSelector((state) => state.contact2.listContact)
    const loadingList: boolean = useAppSelector((state) => state.contact2.loadingList)
    const loadingExport: boolean = useAppSelector((state) => state.contact2.loadingExport)
    const [searchParams, setSearchParams] = useSearchParams()
    const listContactStatus = useAppSelector((state) => state.categoriesVersion2?.listContactStatuses)
    const listContactEventStatus = useAppSelector((state) => state.categoriesVersion2?.listContactEventStatuses)
    const [selectedTable, setSelectedTable] = useState<string[]>([])
    const oldSearch = Object.fromEntries(searchParams.entries())
    // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    // useEffect(() => {
    //     setSelectedRowKeys(listContact.filter((contact) => contact.isSpam).map((contact) => contact.id))
    // }, [listContact, loadingList])

    // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    //     setSelectedRowKeys(newSelectedRowKeys)
    // }

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    // }

    // const handleMarkAsSpam = () => {
    //     const newSelected = selectedRowKeys.filter((el) => {
    //         return !find(listContact, { id: el.toString() })?.isSpam
    //     })
    //     Promise.all(newSelected.map((el) => dispatch(callUpdateContactDetailRemark({ contactId: el.toString(), data: { isSpam: true } })))).then((results) => {
    //         if (results.filter((result) => result.payload.status === 200).length === results.length) {
    //             message.success(trans("message.success"))
    //             const params = Object.fromEntries(searchParams.entries())
    //             delete params.call
    //             dispatch(
    //                 callListContacts({
    //                     ...params,
    //                     pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
    //                     page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
    //                     sort: "createdAt:desc",
    //                 })
    //             )
    //         } else {
    //             message.error(trans("message.fail"))
    //             const params = Object.fromEntries(searchParams.entries())
    //             delete params.call
    //             dispatch(
    //                 callListContacts({
    //                     ...params,
    //                     pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
    //                     page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
    //                     sort: "createdAt:desc",
    //                 })
    //             )
    //         }
    //     })
    // }

    const columns: any[] = [
        {
            title: trans("contact-detail.fullname"),
            className: "bold-400",
            dataIndex: "name",
            key: "name",
            default: true,
            render: (name: string, record: IContact) =>
                !SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_ALL) && !SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL) ? (
                    name
                ) : (
                    <Link to={`/contact/${record.id}`}>{name}</Link>
                ),
            width: 150,
        },
        {
            title: trans("contact-detail.campaign"),
            className: "bold-400",
            dataIndex: ["campaignDetail", "name"],
            key: "campaignDetail",
            default: true,
            align: "left",
            width: 150,
        },
        {
            title: trans("contact-detail.product"),
            className: "bold-400",
            width: 150,
            dataIndex: ["productDetail", "name"],
            key: "productDetail",
            algin: "left",
            default: false,
        },
        {
            title: trans("vendor.marketer"),
            className: "bold-400",
            key: "marketer",
            default: true,
            align: "left",
            width: 150,
            render: (record: IContact) => <p className="mb-0">{record?.marketer || ""}</p>,
        },
        {
            title: trans("contact-detail.phone"),
            className: "bold-402",
            dataIndex: "phone",
            key: "phone",
            default: true,
            align: "center",
            width: 120,
        },
        {
            title: trans("contact.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            default: true,
            render: (date: string) => (date ? formatDateFull(date) : ""),
            width: 120,
        },
        {
            title: trans("contact.assign_to"),
            className: "bold-400",
            dataIndex: "assignee",
            key: "assignee",
            default: true,
            align: "left",
            width: 100,
        },
        {
            title: trans("contact.assigned_at"),
            className: "bold-400",
            dataIndex: "assignedAt",
            key: "assignedAt",
            align: "center",
            default: false,
            render: (date: string) => (date ? formatDateFull(date) : ""),
            width: 120,
        },
        {
            title: trans("contact.assigned_by"),
            className: "bold-400",
            dataIndex: "assigner",
            key: "assigner",
            align: "left",
            default: false,
            width: 100,
        },
        {
            title: trans("contact.calls"),
            className: "bold-400",
            dataIndex: "totalCall",
            key: "totalCall",
            default: false,
            width: 60,
            align: "right",
        },
        {
            title: trans("contact.callTime"),
            className: "bold-400",
            dataIndex: "lastCallAt",
            key: "lastCallAt",
            align: "center",
            default: false,
            render: (date: string) => (date ? formatDateFull(date) : ""),
            width: 120,
        },
        {
            title: trans("contact-detail.contact_state"),
            className: "bold-400",
            dataIndex: "status",
            key: "status",
            default: true,
            align: "left",
            render: (status: number) => find(listContactStatus, { code: status })?.description,
            width: 120,
        },
        {
            title: trans("contact.remark"),
            className: "bold-400",
            dataIndex: "isSpam",
            key: "isSpam",
            align: "center",
            default: true,
            render: (isSpam: boolean) => <p className="mb-0">{isSpam ? trans("contact.spam") : ""}</p>,
            width: 80,
        },
        {
            title: trans("sidebar.call_status"),
            className: "bold-400",
            dataIndex: "lastCallStatus",
            key: "lastCallStatus",
            align: "left",
            default: false,
            render: (contactEventStatus: string) => {
                const findContactEventStatus = find(listContactEventStatus, { code: contactEventStatus })
                return findContactEventStatus ? <Tag color={findContactEventStatus?.bgColor}>{findContactEventStatus?.name}</Tag> : ""
            },
            width: 150,
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        setSearchParams(params)
    }
    const dataTrees = useMemo(
        () =>
            !isEmpty(columns)
                ? columns?.map((item: any) => ({
                      title: item?.title,
                      key: item?.key,
                      disabled: item?.default,
                  }))
                : [],
        []
    )
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                <div>
                    {/* {SecurityService.can(CONTACT_SCOPE.CONTACT_REMARK) && (
                        <Button
                            type="primary"
                            onClick={handleMarkAsSpam}
                            className="text-center m-0">
                            {trans("contact.mark_as_spam")}
                        </Button>
                    )} */}
                    {SecurityService.can(CONTACT_SCOPE.CONTACT_CREATE) && (
                        <Button
                            type="primary"
                            className="text-center m-0 ml-2"
                            onClick={() => dispatch(changeVisibleFormAddContact(true))}>
                            {trans("contact.add_contact")}
                        </Button>
                    )}
                    {SecurityService.can(CONTACT_SCOPE.CONTACT_IMPORT) && (
                        <Button
                            type="primary"
                            className="m-0 ml-2"
                            onClick={() => dispatch(changeVisibleImport(true))}
                            icon={<i className="fa-solid fa-cloud-arrow-up mr-2" />}>
                            {trans("contact.import_contact")}
                        </Button>
                    )}
                    <Button
                        type="primary"
                        className="m-0 ml-2"
                        loading={loadingExport}
                        onClick={() =>
                            dispatch(exportContacts({ ...oldSearch, sort: "createdAt:desc" })).then((result: any) => {
                                if (result?.payload?.status === 201) {
                                    message.success(trans("message.success"))
                                } else message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                            })
                        }
                        icon={<i className="fa-solid fa-cloud-arrow-down mr-2" />}>
                        {trans("contact.export_contact")}
                    </Button>
                    {!isEmpty(dataTrees) && (
                        <SettingTable
                            dataTree={dataTrees}
                            keyTable="contactVendor"
                            setSelectedTable={setSelectedTable}
                            selectedTable={selectedTable}
                        />
                    )}
                </div>
            }>
            <Table
                rowKey={"id"}
                scroll={{
                    x: true,
                }}
                // rowSelection={SecurityService.can(CONTACT_SCOPE.CONTACT_REMARK) && rowSelection}
                columns={filter(columns, (iFil: any) => selectedTable?.includes(iFil?.key))}
                loading={loadingList}
                dataSource={listContact || []}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: pageSize,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                }}
            />
        </Card>
    )
}
