import ProductGroupsApi from "@api/version2/ProductGroupsApi"
import { IProductGroups } from "@domain/version2/ProductGroups"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    listProductGroups: IProductGroups[]
}

const initState: State = {
    listProductGroups: [],
}
export const callGetProductGroups = createAsyncThunk("PRODUCT_GROUPS.GET_LIST", async (params: { keyword: string }, thunkApi) => {
    try {
        return await ProductGroupsApi.getProductGroups(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const productGroupsReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetProductGroups.pending, (state) => {
            state.listProductGroups = []
        })
        .addCase(callGetProductGroups.fulfilled, (state, { payload }) => {
            state.listProductGroups = payload.data
        })
        .addCase(callGetProductGroups.rejected, (state) => {
            state.listProductGroups = []
        })
})
