import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetWarehouses } from "@reducer/version2/inventory.reducer"
import { callGetChannels, changeVisibleFormAddAccount } from "@reducer/version2/sell-channel.reducer"
import { trans } from "@resources/localization"
import { Modal } from "antd"
import React, { useEffect } from "react"
import { InputForm } from "./Form"

const AddAccount = () => {
    const dispatch = useAppDispatch()
    const visibleFormAddAccount = useAppSelector((state) => state.sellChannel.visibleAddAccount)
    const vendorDetail = useAppSelector((state) => state.vendorDetail.detailVendor)
    const countryCode = vendorDetail?.countryCode

    useEffect(() => {
        dispatch(callGetWarehouses({ countryCode }))
        dispatch(callGetChannels())
    }, [dispatch])

    return (
        <Modal
            destroyOnClose
            title={trans("sell_channel.add_account")}
            visible={visibleFormAddAccount}
            footer={null}
            width={500}
            onCancel={() => dispatch(changeVisibleFormAddAccount(false))}>
            <InputForm />
        </Modal>
    )
}

export default AddAccount
