import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Button, Col, Form, message, Row, Space, Modal } from "antd"
import { useNavigate } from "react-router-dom"
import { OrderInfo } from "./OrderInfo"
import { Customer } from "./Customer"
import { callGetCourierVendor } from "@reducer/version2/courier-setting.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { getProvinceSuggest, resetDataArea, resetDataCity, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { ICreateOrderRequest } from "@domain/version2/Order"
import { callCreateOrder } from "@reducer/version2/order.reducer"
import { isEmpty, find } from "lodash"
import { PaymentMethod } from "./PaymentMethod"
import moment from "moment"
import { callGetOrderPaymentMethods } from "@reducer/version2/categories.reducer"
import { callVendorDetail } from "@reducer/version2/vendor-detail.reducer"
import SelectOrderType from "./SelectOrderType"
import { callGetSkuComboSuggest } from "@reducer/version2/skuCombo.reducer"

export const CreateOrder: React.FC = () => {
    const [formRef] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const loadingCreateOrder = useAppSelector((state) => state.orderVersion2.loadingCreateOrder)
    const paymentMethods = useAppSelector((state) => state.categoriesVersion2.orderPaymentMethods)
    const [isExpand, setIsExpand] = useState(false)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("order.create_order")))
        dispatch(callGetSkuComboSuggest({ keyword: "" }))
        dispatch(callGetCourierVendor())
        dispatch(callVendorDetail())
        dispatch(callGetOrderPaymentMethods())
    }, [dispatch])

    const countryCode = useAppSelector((state) => state.vendorDetail.detailVendor.countryCode)

    useEffect(() => {
        if (countryCode) {
            dispatch(getProvinceSuggest(countryCode))
        }
    }, [countryCode])

    const prePaidAmountRegex = (value?: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    const renderInfo = (key: string, value: any) => {
        return (
            <div>
                <p className="font-medium m-0">{key}:</p>
                <p>{value}</p>
            </div>
        )
    }

    const popupPaymentConfirm = (values: any) => {
        const method = values?.method
        return (
            <>
                <hr className="mb-3" />
                <Row gutter={20}>
                    <Col span={8}>{renderInfo(trans("order_payment.method"), values?.method ? find(paymentMethods, { code: values.method })?.name : "")}</Col>
                    {["pre_paid_by_cash", "pre_paid_by_bank_transfer"].indexOf(method) >= 0 && (
                        <>
                            <Col span={8}>{renderInfo(trans("order_payment.pre_paid_amount"), prePaidAmountRegex(values.prePaidAmount))}</Col>
                            <Col span={8}>
                                {renderInfo(trans("order_payment.date"), values?.paymentTime ? moment(values?.paymentTime).format("DD/MM/YYYY") : undefined)}
                            </Col>
                            {["pre_paid_by_bank_transfer"].indexOf(method) >= 0 && (
                                <>
                                    <Col span={16}>{renderInfo(trans("order_payment.bank"), values.bankName)}</Col>
                                    <Col span={8}>{renderInfo(trans("order_payment.bank_account_number"), values.bankAccount)}</Col>
                                </>
                            )}
                            <Col span={24}>{renderInfo(trans("order_payment.note"), values.notePayment)}</Col>
                        </>
                    )}
                </Row>
            </>
        )
    }

    const handleSubmitForm = (values: any) => {
        Modal.confirm({
            title: <span className="text-red-500">{trans("order_payment.confirmation")}</span>,
            icon: false,
            content: popupPaymentConfirm(values),
            width: 600,
            className: "payment-method",
            onOk() {
                const data: ICreateOrderRequest = {
                    code: values?.code || undefined,
                    customerName: values?.customerName || undefined,
                    customerPhone: values?.customerPhone || undefined,
                    trackingNumber: values?.trackingNumber || undefined,
                    courierService: values?.courierService || undefined,
                    countryCode: countryCode || undefined,
                    provinceCode: values?.provinceCode || undefined,
                    cityCode: values?.cityCode || undefined,
                    districtCode: values?.districtCode || undefined,
                    ward: values?.wardCode || undefined,
                    address: values?.address || undefined,
                    postalCode: values?.postalCode || undefined,
                    totalPayment: values?.totalPayment || undefined,
                    deliveryFee: values?.deliveryFee || undefined,
                    orderItems: !isEmpty(values?.tableProducts)
                        ? values?.tableProducts.map((el: any) => {
                              const product = {
                                  sku: el?.sku,
                                  quantity: el?.quantity,
                                  price: el?.unitPrice,
                              }
                              return { ...product }
                          })
                        : undefined,
                    orderCombos: !isEmpty(values?.tableCombo)
                        ? values?.tableCombo.map((el: any) => {
                              const combo = {
                                  sku: el?.sku,
                                  quantity: el?.quantity,
                                  price: el?.unitPrice,
                              }
                              return { ...combo }
                          })
                        : undefined,
                    note: values?.note || undefined,
                    confirmations: {
                        method: values?.method || undefined,
                        prePaidAmount: Number(values?.prePaidAmount) || undefined,
                        bankName: values?.bankName || undefined,
                        bankAccount: values?.bankAccount || undefined,
                        paymentTime: values?.paymentTime ? moment(values?.paymentTime).toISOString() : undefined,
                        note: values?.notePayment || undefined,
                    },
                }

                dispatch(callCreateOrder(data)).then((result: any) => {
                    if (result?.payload?.status === 201) {
                        message.success(trans("message.success"))
                        formRef.resetFields()
                        dispatch(resetDataDistrict([]))
                        dispatch(resetDataCity([]))
                        dispatch(resetDataArea([]))
                        navigate("/order")
                    } else {
                        message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                    }
                })
            },
        })
    }

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: "sidebar.dashboard",
                        link: "/",
                    },
                    {
                        name: "order_detail.header",
                        link: "/order",
                    },
                    {
                        name: "order.create_order",
                        link: "",
                    },
                ]}
            />
            <div className="mx-3">
                <Form
                    form={formRef}
                    layout="vertical"
                    // labelCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 8 } }}
                    // wrapperCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 16 } }}
                    labelAlign="left"
                    onFinish={handleSubmitForm}>
                    <Row
                        justify="end"
                        gutter={15}
                        className="mx-3">
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: isExpand ? 24 : 14 }}>
                            <OrderInfo />
                            <SelectOrderType
                                formRef={formRef}
                                setIsExpand={() => {
                                    setIsExpand((old) => !old)
                                }}
                            />
                            <PaymentMethod formRef={formRef} />
                        </Col>
                        <Col
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: isExpand ? 24 : 10 }}>
                            <Customer formRef={formRef} />
                        </Col>

                        <Space className="mr-2 mt-6">
                            <Button
                                type="default"
                                loading={loadingCreateOrder}
                                onClick={() => {
                                    navigate("/order")
                                }}>
                                {trans("contact.cancel")}
                            </Button>
                            <Button
                                type="primary"
                                loading={loadingCreateOrder}
                                htmlType="submit">
                                {trans("order.create_order")}
                            </Button>
                        </Space>
                    </Row>
                </Form>
            </div>
        </DefaultLayout>
    )
}
