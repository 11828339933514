import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { Card, Table } from "antd"
import { filter, find, isEmpty } from "lodash"
import React, { useMemo } from "react"

export const ListTypeFees: React.FC = () => {
    const detailPaymentState = useAppSelector((state) => state.paymentDetail.paymentStateDetail)
    const listCurrencies = useAppSelector((state) => state.categoriesVersion2.listCurrencies)

    const listNewFees = useMemo(() => {
        if (!isEmpty(detailPaymentState)) {
            const oldFees = detailPaymentState?.paymentStatementItem?.map((item: any) => ({
                ...item,
                code: item?.statementPresent?.code,
                parent: item?.statementPresent?.parent,
            }))
            const feesParent = filter(oldFees, { parent: null })
            const convertFees = !isEmpty(feesParent)
                ? feesParent?.map((item: any) => ({
                      ...item,
                      children: filter(oldFees, { parent: item?.code }) || [],
                  }))
                : []
            const newList: any[] = []
            convertFees?.forEach((item: any, index: number) => {
                newList.push({
                    code: item?.code,
                    amount: item?.amount,
                    conversionAmount: item?.conversionAmount,
                    stt: index + 1,
                })
                if (!isEmpty(item?.children)) {
                    item?.children?.forEach((iChild: any, iChildIndex: number) => {
                        newList.push({
                            code: iChild?.code,
                            amount: iChild?.amount,
                            conversionAmount: iChild?.conversionAmount,
                            stt: `${index + 1}.${iChildIndex + 1}`,
                        })
                    })
                }
            })
            return newList
        }
    }, [detailPaymentState])
    const columns: any = [
        {
            title: trans("table.number"),
            className: "bold-400",
            dataIndex: "stt",
            key: "stt",
        },
        {
            title: trans("payment_statement.type"),
            className: "bold-400",
            dataIndex: "code",
            key: "code",
            render: (code: string) => trans(`type_fee.${code}`),
        },
        {
            title: `${trans("payment_statement.original")} (${find(listCurrencies, { code: detailPaymentState?.currencyCode })?.symbol})`,
            className: "bold-400",
            dataIndex: "amount",
            align: "right",
            key: "amount",
            render: (amount: number) => (amount ? convertNumberToCurrency(amount) : 0),
        },
        {
            title: `${trans("payment_statement.amount")} (${find(listCurrencies, { code: detailPaymentState?.conversionCurrencyCode })?.symbol})`,
            className: "bold-400",
            align: "right",
            dataIndex: "conversionAmount",
            key: "conversionAmount",
            render: (conversionAmount: number) => (conversionAmount ? convertNumberToCurrency(conversionAmount) : 0),
        },
    ]

    return (
        <Card className="space-layout" title={trans("payment_statement.finance")}>
        <Table
            columns={columns}
            dataSource={listNewFees || []}
            className="bd-radius-5"
            locale={{
                emptyText: trans("table.empty"),
            }}
            pagination={false}
        />
    </Card>
    )
}
