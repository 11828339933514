import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import { Button, message, Modal, Row, Space } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetPaymentStatementDetail, callReplyPaymentStatement } from "@reducer/version2/paymentState-detail.reducer"
import { InfoPaymentState } from "./InfoPaymentState"
import { ListTypeFees } from "./ListTypeFees"
import { NoteAndFile } from "./NoteAndFile"
import SecurityService from "@util/SecurityService"
import { PAYMENT_STATEMENT_SCOPE } from "@config/permission"
import { callGetListCurrencies } from "@reducer/version2/categories.reducer"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"

export const PaymentStatementDetail: React.FC = () => {
    const loading = useAppSelector((state) => state.paymentDetail.loading)
    const detailPayment = useAppSelector((state) => state.paymentDetail.paymentStateDetail)
    const loadingReply = useAppSelector((state) => state.paymentDetail.loadingReply)
    const navigate = useNavigate()
    const [statusShow, setStatusShow] = useState<number>(0)

    const { id } = useParams()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("payment_statement.detail")))
        if (id) {
            dispatch(callGetPaymentStatementDetail(id || "")).then((result: any) => {
                setStatusShow(result?.payload?.response?.status)
            })
        }
    }, [dispatch, id])
    useEffect(() => {
        dispatch(callGetListCurrencies())
    }, [dispatch])

    const onReply = (type: string) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            icon: <i className="fa-regular fa-circle-info"></i>,
            onOk() {
                dispatch(
                    callReplyPaymentStatement({
                        code: detailPayment?.code || "",
                        body: {
                            statusCode: type,
                        },
                    })
                ).then((result: any) => {
                    if (result?.payload?.status === 200) {
                        message.success(trans("message.success"))
                        dispatch(callGetPaymentStatementDetail(id || ""))
                    } else {
                        message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                    }
                })
            },
        })
    }

    return (
        <DefaultLayout loading={loading}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            {
                                name: "page.home",
                                link: "/",
                            },
                            {
                                name: "sidebar.payment_statement",
                                link: "/payment-statement",
                            },
                            {
                                name: `${detailPayment?.code}`,
                            },
                        ]}
                    />
                    <InfoPaymentState />
                    <ListTypeFees />
                    <NoteAndFile />
                    {detailPayment?.statusCode === "waiting_to_accept" && SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_UPDATE) && (
                        <Row
                            justify="end"
                            className="mt-3 mx-3">
                            <Space>
                                <Button
                                    type="default"
                                    loading={loadingReply}
                                    onClick={() => navigate("/payment-statement")}>
                                    {trans("order_detail.back")}
                                </Button>
                                <Button
                                    type="default"
                                    loading={loadingReply}
                                    onClick={() => onReply("reject")}>
                                    {trans("payment_statement.reject")}
                                </Button>
                                <Button
                                    type="primary"
                                    loading={loadingReply}
                                    onClick={() => onReply("accept")}>
                                    {trans("payment_statement.accept")}
                                </Button>
                            </Space>
                        </Row>
                    )}
                </>
            )}
        </DefaultLayout>
    )
}
