import { IDetailPaymentState } from "@domain/version2/DetailPaymentState"
import { IPaymentStatement, ICreatePaymentStatementQuery, IPaymentStatementStatus, IPaymentStateQuery } from "@domain/version2/PaymentStatement"
import apiClient from "@util/ApiClient"

export default class PaymentStatementApi {
    static getListPaymentStatement(params: IPaymentStateQuery): Promise<{
        data: IPaymentStatement[]
        headers: { [key: string]: any }
    }> {
        return apiClient.get("payment-statements", {
            params,
        })
    }

    static getListPaymentStatementStatus(): Promise<{ data: IPaymentStatementStatus[] }> {
        return apiClient.get("payment-statements/statuses")
    }

    static createPaymentStatement(params: ICreatePaymentStatementQuery): Promise<any> {
        return apiClient.post("payment-statements", params)
    }
    static getDetailPaymentStatement(code: string): Promise<{ data: IDetailPaymentState }> {
        return apiClient.get(`payment-statements/${code}`)
    }
    static replyPaymentStatement(code: string, body: { statusCode: string }): Promise<any> {
        return apiClient.patch(`payment-statements/${code}`, body)
    }
}
