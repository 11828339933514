import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetProductsByVariants, resetDataProductsByVariants } from "@reducer/version2/product.reducer"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { AutoComplete, Button, Col, Divider, Empty, Form, InputNumber, Row, Spin, Typography } from "antd"
import { debounce, isEmpty, sum } from "lodash"
import React, { useMemo } from "react"

interface IProps {
    formRef: any
}
export const Products = (props: IProps) => {
    const listHeader = [
        { title: trans("contact.product"), span: 6 },
        { title: trans("contact-detail.quantity"), span: 6 },
        { title: `${trans("products.price")}`, span: 6 },
        { title: `${trans("contact-detail.price")}`, span: 5 },
        { title: "", span: 1 },
    ]

    const dispatch = useAppDispatch()
    const listProducts = useAppSelector((state) => state.productVersion2.listProducts)
    const loading = useAppSelector((state) => state.productVersion2.loadingListProducts)

    const options = useMemo(
        () =>
            listProducts?.map((item) => ({
                value: `${item?.product?.name} - ${!isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : ""}  - ${item?.sku}`,
                key: item?.sku,
                product: item?.product?.name,
                variant: !isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : "",
            })),

        [listProducts]
    )

    const handleSearchProducts = (searchText: string) => {
        if (searchText.length >= 3) {
            dispatch(callGetProductsByVariants({ keyword: searchText }))
        }
    }

    const handleSelectProduct = (e: any, option: any) => {
        console.log("option", option)
        const product = {
            productName: e,
            quantity: 1,
            unitPrice: 0,
            sku: option?.key,
            product: option?.product,
            variant: option?.variant,
        }
        const tableProducts = props.formRef.getFieldValue("tableProducts")?.slice() || []
        tableProducts.push(product)

        props.formRef.setFieldValue("tableProducts", tableProducts)
        dispatch(resetDataProductsByVariants([]))
        props.formRef.setFieldValue("search", "")
    }

    return (
        <div className="w-full">
            <div className="w-full">
                <Form.Item
                    label={trans("product_list.search")}
                    name="search"
                    className="w-full">
                    <AutoComplete
                        notFoundContent={loading ? <Spin /> : <Empty />}
                        onSearch={debounce(handleSearchProducts, 1000)}
                        placeholder={trans("products.search_name_code_sku_pl")}
                        options={options}
                        onSelect={handleSelectProduct}
                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        onBlur={() => props.formRef.getFieldValue("search") === "" && dispatch(resetDataProductsByVariants([]))}
                    />
                </Form.Item>
            </div>

            <div className="px-2">
                <Row
                    gutter={15}
                    className="bg-gray-50 mt-3 py-3 border-b-black">
                    {listHeader.map((item: { title: string; span: number }, index: number) => (
                        <Col
                            span={item.span}
                            key={index}>
                            <Typography.Text
                                strong
                                className="block text-left">
                                {item.title}
                            </Typography.Text>
                        </Col>
                    ))}
                </Row>

                <div className="">
                    <Form.List name="tableProducts">
                        {(fields, { remove }) =>
                            fields.length > 0 ? (
                                <div>
                                    {fields.map(({ name, ...restField }) => (
                                        <Row
                                            className={`border pt-6 pr-3 ${name !== fields.length - 1 ? "border-b-0" : ""}`}
                                            key={name}
                                            gutter={15}>
                                            <Col span={6}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.tableProducts !== next.tableProducts}>
                                                    {({ getFieldValue }) => (
                                                        <div>
                                                            <p className="m-0">{getFieldValue(["tableProducts", name, "product"])}</p>
                                                            <p className="m-0">{getFieldValue(["tableProducts", name, "variant"])}</p>
                                                            <p className="m-0">{getFieldValue(["tableProducts", name, "sku"])}</p>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item noStyle>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "quantity"]}
                                                        rules={[{ required: true, message: trans("message.required") }]}>
                                                        <InputNumber
                                                            step="1"
                                                            min={1}
                                                            placeholder={trans("contact-detail.quantity")}
                                                            parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item noStyle>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "unitPrice"]}
                                                        rules={[
                                                            { required: true, message: trans("message.required") },
                                                            {
                                                                validator(_, value) {
                                                                    if (Number(value) > 0) {
                                                                        return Promise.resolve()
                                                                    }
                                                                    return Promise.reject(new Error(trans("order.price_gt_0")))
                                                                },
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            step="0.01"
                                                            placeholder={trans("contact-detail.price")}
                                                            parser={(value: any) =>
                                                                value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0
                                                            }
                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) =>
                                                        pre?.tableProducts[name]?.quantity !== next?.tableProducts[name]?.quantity ||
                                                        pre?.tableProducts[name]?.unitPrice !== next?.tableProducts[name]?.unitPrice
                                                    }>
                                                    {({ getFieldValue }) => {
                                                        let price =
                                                            Number(getFieldValue(["tableProducts", name, "quantity"])) *
                                                            Number(getFieldValue(["tableProducts", name, "unitPrice"]))
                                                        price = +price.toFixed(2)
                                                        return <Typography.Text className="block ml-4 mt-1">{convertNumberToCurrency(price)}</Typography.Text>
                                                    }}
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Button
                                                    type="link"
                                                    icon={<i className="fa-light fa-trash"></i>}
                                                    onClick={() => remove(name)}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            ) : (
                                <div className="mt-3">
                                    <Empty />
                                    <Divider />
                                </div>
                            )
                        }
                    </Form.List>
                </div>

                <Row
                    className="mt-3"
                    justify="end">
                    <Col
                        span={14}
                        className="flex mb-3">
                        <Typography.Text className="w-[30%] mb-5 mt-3">{trans("order_detail.total_amount")}:</Typography.Text>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.tableProducts !== next.tableProducts}>
                            {({ getFieldValue }) => {
                                const listAmount: any = getFieldValue("tableProducts")?.map((item: any) => Number(item?.quantity) * Number(item?.unitPrice))
                                let totalAmount = !isEmpty(listAmount) ? sum(listAmount) : 0
                                totalAmount = +totalAmount.toFixed(2)
                                return <Typography.Text className="mt-3 text-start w-[70%]">{convertNumberToCurrency(totalAmount)}</Typography.Text>
                            }}
                        </Form.Item>
                    </Col>
                    <Col
                        span={14}
                        className="flex">
                        <Typography.Text className="w-40 mr-1">{trans("order.delivery_fee")}:</Typography.Text>
                        <Form.Item name="deliveryFee">
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                placeholder={trans("order.delivery_fee")}
                                parser={(value: any) => (value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0)}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={14}
                        className="flex">
                        <Typography.Text className="w-40 mr-1">
                            {trans("order_detail.total_payment")}
                            <span className="text-red-500">*</span>:
                        </Typography.Text>
                        <Form.Item
                            name="totalPayment"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <InputNumber
                                className="w-full"
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                placeholder={trans("order_detail.total_payment")}
                                parser={(value: any) => (value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0)}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
