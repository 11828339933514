import { UploadOutlined } from "@ant-design/icons"
import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeVisibleOrderImport, callImportOrderFinancialStatus, callListOrders } from "@reducer/version2/order.reducer"
import { trans } from "@resources/localization"
import { Button, Col, Form, message, Row, Space, Typography } from "antd"
import { isEmpty } from "lodash"
import React from "react"
import { useSearchParams } from "react-router-dom"

type Props = {
    setIsUpload: (data: boolean) => void
}
export const FormImport: React.FC<Props> = ({ setIsUpload }) => {
    const [searchParams] = useSearchParams()
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const loadingImport = useAppSelector((state) => state.orderVersion2.loadingImport)

    const handleImportDeliveryCase = (values: { file: File }) => {
        if (!values.file) {
            message.error(trans("order_import.required_file"))
        } else if (values.file.size > 5000000) {
            message.error(trans("order.upload_file_less_than"))
        } 
        else {
            dispatch(
                callImportOrderFinancialStatus({
                    file: values.file,
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    if (isEmpty(result?.payload?.data?.errors)) {
                        message.success(`${trans("contact.noti_succ")}`)
                        dispatch(
                            callListOrders({
                                ...Object.fromEntries(searchParams.entries()),
                                pageSize: searchParams.has("per_page") ? Number(searchParams.get("per_page")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                                sort: "createdAt:desc",
                            }))
                        setTimeout(() => {
                            dispatch(changeVisibleOrderImport(false))
                        }, 500)
                    } else {
                        setIsUpload(true)
                        dispatch(
                            callListOrders({
                                ...Object.fromEntries(searchParams.entries()),
                                pageSize: searchParams.has("per_page") ? Number(searchParams.get("per_page")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                                sort: "createdAt:desc",
                            }))
                    }
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    return (
        <Form
            form={formRef}
            onFinish={handleImportDeliveryCase}
            layout="vertical">
            <Row gutter={[0, 15]}>
                <Col span={24}>
                    <Typography.Text >{`- ${trans("package.download_sample_file_here")}: `} </Typography.Text>
                    <a
                        href={require("@resources/files/Order_Financial_Status_Template.xlsx")}
                        rel="noreferrer"
                        download="Order_Financial_Status_Template.xlsx"
                        type="link">
                        {trans("package.download_template")}
                    </a>
                    <Typography.Text className="block">{`- ${trans("order.maximum_file_lines")}: `}
                            <span className="font-bold">500 lines</span>
                    </Typography.Text>
                    <Typography.Text className="block">{`- ${trans("package.maximum_file_size")}: `}
                            <span className="font-bold">5MB</span>
                    </Typography.Text>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="file"
                        hidden
                    />
                    <Button
                        icon={<UploadOutlined />}
                        onClick={() => document.getElementById("uploadFile")?.click()}>
                        {trans("package.choose_file_xlsx")}
                    </Button>
                    <input
                        hidden
                        type="file"
                        id="uploadFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        value=""
                        onChange={(e) => {
                            formRef.setFieldsValue({
                                file: e.target.files ? e.target.files[0] : "",
                            })
                        }}
                    />
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.file !== next.file}>
                        {({ getFieldValue }) =>
                            getFieldValue("file") && (
                                <div className="flex">
                                    <p className="m-0 mt-1 ml-2">{getFieldValue("file")?.name}</p>
                                    <Button
                                        type="link"
                                        icon={<i className="fa-solid fa-trash"></i>}
                                        onClick={() => {
                                            formRef.setFieldsValue({
                                                file: "",
                                            })
                                        }}></Button>
                                </div>
                            )
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end">
                <Space>
                    <Button
                        loading={loadingImport}
                        type="default"
                        onClick={() => {
                            setIsUpload(false)
                            dispatch(changeVisibleOrderImport(false))
                        }}>
                        {trans("button.cancel_button")}
                    </Button>
                    <Button
                        loading={loadingImport}
                        type="primary"
                        htmlType="submit">
                        {trans("campaign.finish")}
                    </Button>
                </Space>
            </Row>
        </Form>
    )
}
