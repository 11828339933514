import DefaultLayout from "@component/Layout/Default"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetWarehouses } from "@reducer/version2/inventory.reducer"
import { callGetSellChannel } from "@reducer/version2/sell-channel.reducer"
import { callVendorDetail } from "@reducer/version2/vendor-detail.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import AddAccount from "./Create"
import { TableSellChannel } from "./Table"

export const SellChannel = () => {
    const dispatch = useAppDispatch()
    const vendorDetail = useAppSelector((state) => state.vendorDetail.detailVendor)
    const countryCode = vendorDetail?.countryCode

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sell_channel.title")))
        dispatch(callGetSellChannel())
        dispatch(callVendorDetail())
    }, [dispatch])

    useEffect(() => {
        if (countryCode) dispatch(callGetWarehouses({ countryCode }))
    }, [dispatch, countryCode])

    return (
        <DefaultLayout loading={false}>
            <TableSellChannel />
            <AddAccount />
        </DefaultLayout>
    )
}
