import * as React from "react"
import { Row, Col, Card } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { formatDateFull } from "@util/Common"

export const InfoProduct = () => {
    const productDetail = useAppSelector((state) => state.productVersion2.detailProduct)

    return (
        <Card
            className="space-layout custom-bottom"
            title={productDetail.name}>
            <Row gutter={15}>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("warehouse.product_code")}:</p>
                    <p className="m-0">{productDetail.code || "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("product.category")}:</p>
                    <p className="m-0">{productDetail?.productGroup?.name || "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("product.manufactory")}:</p>
                    <p className="m-0">{productDetail.manufactory || "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("warehouse.createdBy")}:</p>
                    <p className="m-0">{productDetail.createdBy || "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("warehouse.created_at")}:</p>
                    <p className="m-0">{productDetail?.createdAt ? formatDateFull(productDetail?.createdAt) : "---"}</p>
                </Col>
                <Col
                    xs={24}
                    sm={8}
                    md={4}
                    span={4}>
                    <p className="m-0 font-medium">{trans("contact-detail.status")}:</p>
                    <p className="m-0">{productDetail?.isActive ? trans("product_list.active") : trans("product_list.inactive")}</p>
                </Col>
            </Row>
        </Card>
    )
}
