import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeVisibleImport } from "@reducer/version2/contact.reducer"
import { trans } from "@resources/localization"
import { Modal } from "antd"
import React, { useState } from "react"
import { CreateImport } from "./CreateImport"
import { TableError } from "./TableError"
export const ImportComponent: React.FC = () => {
    const [isUpload, setIsUpload] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const visibleImport = useAppSelector((state) => state.contact2.visibleImport)
    return (
        <Modal
            destroyOnClose
            title={!isUpload ? trans("contact.import_contact") : trans("order_list.import_tracking_number_fail_list_title")}
            visible={visibleImport}
            width={500}
            footer={null}
            onCancel={() => {
                setIsUpload(false)
                dispatch(changeVisibleImport(false))
            }}>
            {!isUpload ? <CreateImport setIsUpload={setIsUpload} /> : <TableError />}
        </Modal>
    )
}
