import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import { Button, Form, message, Row, Space } from "antd"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { GeneralInfo } from "./General"
import { CreateProducts } from "./ListProduct"
import { callGetListSkuComboCategories } from "@reducer/version2/categories.reducer"
import { isEmpty } from "lodash"
import { callCreateSkuCombo } from "@reducer/version2/skuCombo.reducer"

export const CreateSkuCombo: React.FC = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const loadingCreate = useAppSelector((state) => state.skuCombo.loadingCreate)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("skuCombo.title")))
        dispatch(callGetListSkuComboCategories({ keyword: "" }))
    }, [dispatch])

    const onCreateSkuCombo = (values: any) => {
        const data: any = {
            code: values?.code || undefined,
            name: values?.name || undefined,
            category: values?.category || undefined,
            comboSkus: !isEmpty(values?.tableProducts)
                ? values?.tableProducts.map((el: any) => {
                      const product = {
                          skuCode: el?.sku,
                          quantity: el?.quantity,
                      }
                      return { ...product }
                  })
                : undefined,
        }

        dispatch(callCreateSkuCombo(data)).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                formRef.resetFields()
                navigate("/product?tab=skuCombo")
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <DefaultLayout loading={loadingCreate}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: "sidebar.dashboard",
                        link: "/",
                    },
                    {
                        name: "skuCombo.title",
                        link: "/product?tab=skuCombo",
                    },
                    {
                        name: "skuCombo.create",
                        link: "",
                    },
                ]}
            />
            <Form
                form={formRef}
                onFinish={onCreateSkuCombo}
                layout="vertical">
                <GeneralInfo formRef={formRef} />
                <CreateProducts formRef={formRef} />
                <Row
                    justify="end"
                    className="mt-3 mx-3">
                    <Space>
                        <Button
                            loading={loadingCreate}
                            onClick={() => {
                                formRef.resetFields()
                                navigate("/product?tab=skuCombo")
                            }}
                            type="default">
                            {trans("setting.cancel")}
                        </Button>
                        <Button
                            loading={loadingCreate}
                            type="primary"
                            htmlType="submit">
                            {trans("setting.save")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </DefaultLayout>
    )
}
