import React, {useEffect} from 'react'

import {Spin} from "antd";
import localStore from "@util/LocalStore";
import appConfig from "@config/app";

export const Logout = () => {

    useEffect(() => {
        localStore.removeItem('loginSession');
        localStore.removeItem('loggedUser');
        localStore.removeItem('permissions');

        // @ts-ignore
        window.location.href = `${appConfig.logoutUrl}`;
    }, [])


    return (
        <div className={'mg-t-10 mg-l-10'}>Đang chuyển trang, vui lòng đợi một chút.... <Spin/></div>
    );
}
