import { PAGINATION } from "@config/constant"
import { trans } from "@resources/localization"
import { Button, Card, Space, Table } from "antd"
import React from "react"

const History = () => {
    // const pageSize: number = useAppSelector((state) => state.contact2.pagination.pageSize)
    // const currentPage: number = useAppSelector((state) => state.contact2.pagination.currentPage)
    // const total: number = useAppSelector((state) => state.contact2.pagination.total)
    // const totalPage: number = useAppSelector((state) => state.contact2.pagination.totalPage)

    const pageSize = 5
    const currentPage = 3
    const total = 3
    // const totalPage = 3

    const columns: any[] = [
        {
            title: trans("contact-detail.date_time"),
            className: "bold-400",
            align: "left",
            width: "25%",
        },
        {
            title: trans("inventory.action"),
            className: "bold-400",
            align: "left",
            width: "25%",
        },
        {
            title: trans("inventory.quantity"),
            className: "bold-400",
            align: "left",
            width: "15%",
        },
        {
            title: trans("inventory.reason_detail"),
            className: "bold-400",
            algin: "left",
            width: "35%",
        },
    ]
    return (
        <Card
            className="space-layout"
            title={trans("inventory.history")}
            extra={
                <Space>
                    <Button
                        type="primary"
                        loading={false}
                        icon={<i className="fa-solid fa-cloud-arrow-down mr-2" />}>
                        {trans("inventory.excel_export")}
                    </Button>
                </Space>
            }>
            <Table
                rowKey={"id"}
                scroll={{
                    x: true,
                }}
                columns={columns}
                loading={false}
                dataSource={[]}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: pageSize,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                }}
            />
        </Card>
    )
}

export default History
