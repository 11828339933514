import React, {PropsWithChildren} from "react"

import {Breadcrumb as AntBreadCrumb, Col, Row} from "antd"
import {Link} from "react-router-dom"
import {trans} from "@resources/localization"

interface Props extends PropsWithChildren<any> {
    breadCrumbs: Array<{
        name: string,
        link?: string,

    }>
}

export const HeaderAntBreadCrumb = (props: Props) => {
    return (
        <>
            <div className="bread-crumb-container">
                <Row>
                    <Col span={14}>
                        <div className="pd-t-3">
                            <AntBreadCrumb>
                                {
                                    props.breadCrumbs && props.breadCrumbs.map((breadCrumb, index) => {
                                        if (index === 0 || index === (props.breadCrumbs.length - 1)) {
                                            return (
                                                <AntBreadCrumb.Item key={index}>
                                                    {breadCrumb.link ? <Link to={breadCrumb.link}>{trans(breadCrumb.name)}</Link> : trans(breadCrumb.name)}
                                                </AntBreadCrumb.Item>
                                            )
                                        }
                                        return (
                                            <>
                                                <AntBreadCrumb.Item key={index}>
                                                    {breadCrumb.link ? <Link to={breadCrumb.link}>{trans(breadCrumb.name)}</Link> : trans(breadCrumb.name)}
                                                </AntBreadCrumb.Item>
                                            </>
                                        )
                                    })
                                }
                            </AntBreadCrumb>
                        </div>
                    </Col>
                    <Col span={10}>
                        <div className="flr" />
                    </Col>
                </Row>
            </div>
        </>
    )
}
