import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB, PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetListOrderSources, callListOrderStatuses } from "@reducer/version2/categories.reducer"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callListOrders } from "@reducer/version2/order.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { Filter } from "./Filter"
import { TableOrder } from "./Table"
import { callCampaignsSuggest } from "@reducer/version2/campaign.reducer"
import { callSuggestUser } from "@reducer/version2/user.reducer"
import { callProductSuggest } from "@reducer/version2/product.reducer"
import { ImportOrderFinancialStatus } from "./ImportFinancial"
import { fetchOrderFinancialStatuses } from "@reducer/version2/order-detail.reducer"
import { useAppSelector } from "@hook/useAppSelector"

export const Order: React.FC = () => {
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const visibleImport = useAppSelector((state) => state.orderVersion2.isShowImport)
    useEffect(() => {
        dispatch(changePageTitleAction(trans("order.page_title")))
        dispatch(callListOrderStatuses())
        dispatch(callGetListOrderSources())
        dispatch(fetchOrderFinancialStatuses())
        dispatch(callCampaignsSuggest({ keyword: "" }))
        dispatch(callSuggestUser({ keyword: "" }))
        dispatch(callProductSuggest({ keyword: "" }))
    }, [dispatch])
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        dispatch(
            callListOrders({
                ...params,
                pageSize: searchParams.has("per_page") ? Number(searchParams.get("per_page")) : PAGINATION.DEFAULT_PAGE_SIZE,
                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                sort: "createdAt:desc",
            })
        )
    }, [dispatch, searchParams])
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb breadCrumbs={[...HOME_BREADCRUMB, { name: "order_detail.page_title", link: "/order" }]} />
            <Filter />
            <TableOrder />
            {visibleImport && <ImportOrderFinancialStatus />}
        </DefaultLayout>
    )
}
