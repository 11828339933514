import { InventoryApi } from "@api/version2/InventoryApi"
import { PAGINATION } from "@config/constant"
import { IInventoryDetail, Inventory, InventoryQuery, IWarehouse } from "@domain/version2/InventoryApi"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    inventories: Inventory[]
    loadingList: boolean
    pagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    warehouses: IWarehouse[]
    detailInventory: IInventoryDetail
    loadingDetail: boolean
}
const initState: State = {
    inventories: [],
    loadingList: false,
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    warehouses: [],
    detailInventory: {} as IInventoryDetail,
    loadingDetail: false,
}
export const callGetInventories = createAsyncThunk("PAYMENT_STATEMENT.GET_STATUS_CURRENCIES", async (params: InventoryQuery, thunkApi) => {
    try {
        return await InventoryApi.getInventories(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetWarehouses = createAsyncThunk("PAYMENT_STATEMENT.GET_WAREHOUSES", async (payload: { countryCode?: string }, thunkApi) => {
    try {
        console.log(payload.countryCode)
        return await InventoryApi.getWarehouses(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetDetailInventory = createAsyncThunk("PAYMENT_STATEMENT.GET_DETAIL_INVENTORY", async (productId: number, thunkApi) => {
    try {
        return await InventoryApi.getDetailInventory(productId)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const inventoryReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetInventories.pending, (state) => {
            state.loadingList = true
            state.inventories = []
        })

        .addCase(callGetInventories.fulfilled, (state, { payload }) => {
            state.inventories = payload?.data || []
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callGetInventories.rejected, (state) => {
            state.inventories = []
            state.loadingList = false
        })
    builder
        .addCase(callGetWarehouses.pending, (state) => {
            state.warehouses = []
        })
        .addCase(callGetWarehouses.fulfilled, (state, { payload }) => {
            state.warehouses = payload.data
        })
        .addCase(callGetWarehouses.rejected, (state) => {
            state.warehouses = []
        })
    builder
        .addCase(callGetDetailInventory.pending, (state) => {
            state.loadingDetail = true
            state.detailInventory = {} as IInventoryDetail
        })
        .addCase(callGetDetailInventory.fulfilled, (state, { payload }) => {
            state.detailInventory = payload?.data || {}
            state.loadingDetail = false
        })
        .addCase(callGetDetailInventory.rejected, (state) => {
            state.detailInventory = {} as IInventoryDetail
            state.loadingDetail = false
        })
})
