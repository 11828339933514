import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import PaymentStatementApi from "@api/version2/PaymentStatementApi"
import { IDetailPaymentState } from "@domain/version2/DetailPaymentState"

interface State {
    paymentStateDetail: IDetailPaymentState
    loading: boolean
    loadingReply: boolean
}

const initState: State = {
    paymentStateDetail: {},
    loading: false,
    loadingReply: false

}

export const callGetPaymentStatementDetail = createAsyncThunk("GET_PAYMENT.DETAIL", async (code: string, thunkApi) => {
    try {
        return await PaymentStatementApi.getDetailPaymentStatement(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callReplyPaymentStatement = createAsyncThunk("GET_PAYMENT.UPDATE_STATUS", async (payload:{code: string, body:{statusCode: string}}, thunkApi) => {
    try {
        return await PaymentStatementApi.replyPaymentStatement(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const paymentStatementDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetPaymentStatementDetail.pending, (state) => {
            state.loading = true
            state.paymentStateDetail
        })

        .addCase(callGetPaymentStatementDetail.fulfilled, (state, { payload }) => {
            state.paymentStateDetail = payload?.data || {}
            state.loading = false
        })
        .addCase(callGetPaymentStatementDetail.rejected, (state) => {
            state.loading = false
            state.paymentStateDetail = {}
        })
    builder
        .addCase(callReplyPaymentStatement.pending, (state) => {
            state.loadingReply = true
        })
        .addCase(callReplyPaymentStatement.fulfilled, (state) => {
            state.loadingReply = false
        })
        .addCase(callReplyPaymentStatement.rejected, (state) => {
            state.loadingReply = false
        })
})
