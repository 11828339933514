import CurrenciesApi from "@api/version2/CurrenciesApi"
import { ICurrencies } from "@domain/version2/Currencies"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    listCurrencies: ICurrencies[]
    loadingList: boolean
}

const initState: State = {
    listCurrencies: [],
    loadingList: false,
}

export const callGetListCurrencies = createAsyncThunk("payment_statement.GET_STATUS_CURRENCIES", async (_, thunkApi) => {
    try {
        return await CurrenciesApi.getListCurrencies()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const currenciesReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetListCurrencies.pending, (state) => {
            state.loadingList = true
            state.listCurrencies = []
        })

        .addCase(callGetListCurrencies.fulfilled, (state, { payload }) => {
            state.listCurrencies = payload?.data || []
            state.loadingList = false
        })
        .addCase(callGetListCurrencies.rejected, (state) => {
            state.listCurrencies = []
            state.loadingList = false
        })
})
