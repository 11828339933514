import React from "react"
import { Dashboard } from "@screen/Dashboard"
import { Login } from "@screen/Login"
import { Logout } from "@screen/Logout"
import { Authentication } from "@screen/Authentication"
import { AuthenticateRoute } from "@component/AuthenticateRoute"
import {
    ADS_SCOPE,
    CAMPAIGN_SCOPE,
    CONTACT_SCOPE,
    ORDER_SCOPE,
    PAYMENT_STATEMENT_SCOPE,
    USER_SCOPE,
    SETTING_SCOPE,
    INVENTORY_SCOPE,
    PRODUCT_SCOPE,
    SELLING_CHANNEL,
    PACKAGE_SCOPE,
    COMBO_SCOPE,
    PURCHASING_ACCOUNT,
} from "./permission"
//V1
import { User } from "@screen/version1/User"
//V2
import { Campaign } from "@screen/version2/Campaign"
import { CreateCampaign } from "@screen/version2/Campaign/CampaignList/Create"
import { EditCampaign } from "@screen/version2/Campaign/CampaignList/Edit"
import { CreateAds } from "@screen/version2/Campaign/Ads/Create"
import { EditAds } from "@screen/version2/Campaign/Ads/Edit"
import { Order } from "@screen/version2/Order"
import { OrderDetail } from "@screen/version2/Order/Detail"
import { Contact } from "@screen/version2/Contact"
import { DetailContact } from "@screen/version2/Contact/Detail"
import { PaymentStatement } from "@screen/version2/PaymentStatement"
import { PaymentStatementDetail } from "@screen/version2/PaymentStatement/Detail"
import { Setting } from "@screen/version2/Setting"
import { Package } from "@screen/version2/Package"
import { DetailPackage } from "@screen/version2/Package/Detail"
import { SellChannel } from "@screen/version2/SellChannel"
import { CreateOrder } from "@screen/version2/Order/Create"
import { Inventory } from "@screen/version2/Inventory"
import { Product } from "@screen/version2/Product"
import { DetailProduct } from "@screen/version2/Product/Products/Detail"
import { CreateSkuCombo } from "@screen/version2/Product/SKUCombo/Create"
import { DetailSkuCombo } from "@screen/version2/Product/SKUCombo/Detail"
import { CreateInboundShipment } from "@screen/version2/Package/Create"
import { CreateShipment } from "@screen/version2/Package/CreateShipment"
import { PurchasingAccount } from "@screen/version2/PurchasingAccount"
import { DetailInventory } from "@screen/version2/Inventory/Detail"
import { OrderFinancing } from "@screen/version2/OrderFinancing"

export interface IRouteConfig {
    name: string
    path: string
    component: JSX.Element
}

export const routes: Array<IRouteConfig> = [
    {
        name: "dashboard",
        path: "/",
        component: (
            <AuthenticateRoute>
                <Dashboard />
            </AuthenticateRoute>
        ),
    },
    {
        name: "user",
        path: "/user",
        component: (
            <AuthenticateRoute permissions={[USER_SCOPE.STAFF_VIEW_LIST]}>
                <User />
            </AuthenticateRoute>
        ),
    },
    {
        name: "login",
        path: "/login",
        component: <Login />,
    },
    {
        name: "logout",
        path: "/logout",
        component: <Logout />,
    },
    {
        name: "authentication",
        path: "/authentication",
        component: <Authentication />,
    },
    //V2
    {
        name: "campaign",
        path: "/campaign",
        component: (
            <AuthenticateRoute permissions={[CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL, CAMPAIGN_SCOPE.CAMPAIGN_VIEW, ADS_SCOPE.ADS_VIEW, ADS_SCOPE.ADS_VIEW_ALL]}>
                <Campaign />
            </AuthenticateRoute>
        ),
    },
    {
        name: "campaign",
        path: "/campaign/create",
        component: (
            <AuthenticateRoute permissions={[CAMPAIGN_SCOPE.CAMPAIGN_CREATE]}>
                <CreateCampaign />
            </AuthenticateRoute>
        ),
    },
    {
        name: "campaign",
        path: "/campaign/:id",
        component: (
            <AuthenticateRoute permissions={[CAMPAIGN_SCOPE.CAMPAIGN_DETAIL_ALL, CAMPAIGN_SCOPE.CAMPAIGN_DETAIL]}>
                <EditCampaign />
            </AuthenticateRoute>
        ),
    },
    {
        name: "campaign",
        path: "/ads/create",
        component: (
            <AuthenticateRoute permissions={[ADS_SCOPE.ADS_CREATE]}>
                <CreateAds />
            </AuthenticateRoute>
        ),
    },
    {
        name: "campaign",
        path: "/ads/:id",
        component: (
            <AuthenticateRoute permissions={[ADS_SCOPE.ADS_DETAIL_ALL, ADS_SCOPE.ADS_DETAIL]}>
                <EditAds />
            </AuthenticateRoute>
        ),
    },
    {
        name: "contact",
        path: "/contact",
        component: (
            <AuthenticateRoute permissions={[CONTACT_SCOPE.CONTACT_VIEW_ALL, CONTACT_SCOPE.CONTACT_VIEW]}>
                <Contact />
            </AuthenticateRoute>
        ),
    },
    {
        name: "contact",
        path: "/contact/:id",
        component: (
            <AuthenticateRoute permissions={[CONTACT_SCOPE.CONTACT_DETAIL_ALL, CONTACT_SCOPE.CONTACT_DETAIL]}>
                <DetailContact />
            </AuthenticateRoute>
        ),
    },
    {
        name: "order",
        path: "/order",
        component: (
            <AuthenticateRoute permissions={[ORDER_SCOPE.ORDER_VIEW_ALL, ORDER_SCOPE.ORDER_VIEW]}>
                <Order />
            </AuthenticateRoute>
        ),
    },
    {
        name: "order",
        path: "/order/:id",
        component: (
            <AuthenticateRoute permissions={[ORDER_SCOPE.ORDER_DETAIL_ALL, ORDER_SCOPE.ORDER_DETAIL]}>
                <OrderDetail />
            </AuthenticateRoute>
        ),
    },
    {
        name: "createOrder",
        path: "/order/create",
        component: (
            <AuthenticateRoute permissions={[ORDER_SCOPE.ORDER_CREATE_MANUAL]}>
                <CreateOrder />
            </AuthenticateRoute>
        ),
    },
    {
        name: "paymentStatement",
        path: "/payment-statement",
        component: (
            <AuthenticateRoute permissions={[PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_LIST_VIEW]}>
                <PaymentStatement />
            </AuthenticateRoute>
        ),
    },
    {
        name: "paymentStatement",
        path: "/payment-statement/:id",
        component: (
            <AuthenticateRoute permissions={[PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_DETAIL_VIEW]}>
                <PaymentStatementDetail />
            </AuthenticateRoute>
        ),
    },
    {
        name: "package",
        path: "/package",
        component: (
            <AuthenticateRoute permissions={[PACKAGE_SCOPE.PACKAGE_LIST_VIEW]}>
                <Package />
            </AuthenticateRoute>
        ),
    },
    {
        name: "package",
        path: "/package/create",
        component: (
            <AuthenticateRoute permissions={[PACKAGE_SCOPE.PACKAGE_CREATE]}>
                <CreateInboundShipment />
            </AuthenticateRoute>
        ),
    },
    {
        name: "package",
        path: "/package/create-shipment",
        component: (
            <AuthenticateRoute permissions={[PACKAGE_SCOPE.PACKAGE_CREATE]}>
                <CreateShipment />
            </AuthenticateRoute>
        ),
    },
    {
        name: "package",
        path: "/package/:id",
        component: (
            <AuthenticateRoute permissions={[PACKAGE_SCOPE.PACKAGE_DETAIL_VIEW]}>
                <DetailPackage />
            </AuthenticateRoute>
        ),
    },
    {
        name: "Setting",
        path: "/setting",
        component: (
            <AuthenticateRoute permissions={[SETTING_SCOPE.COURIER_VIEW]}>
                <Setting />
            </AuthenticateRoute>
        ),
    },
    {
        name: "sellChannel",
        path: "/sell-channel",
        component: (
            <AuthenticateRoute permissions={[SELLING_CHANNEL.SELLING_CHANNEL_LIST_VIEW]}>
                <SellChannel />
            </AuthenticateRoute>
        ),
    },
    {
        name: "inventory",
        path: "/inventory",
        component: (
            <AuthenticateRoute permissions={[INVENTORY_SCOPE.INVENTORY_LIST_VIEW]}>
                <Inventory />
            </AuthenticateRoute>
        ),
    },
    {
        name: "product",
        path: "/product",
        component: (
            <AuthenticateRoute permissions={[PRODUCT_SCOPE.PRODUCT_LIST_VIEW, COMBO_SCOPE.COMBO_LIST_VIEW]}>
                <Product />
            </AuthenticateRoute>
        ),
    },
    {
        name: "product",
        path: "/product/:id",
        component: (
            <AuthenticateRoute permissions={[PRODUCT_SCOPE.PRODUCT_DETAIL_VIEW]}>
                <DetailProduct />
            </AuthenticateRoute>
        ),
    },
    {
        name: "skuCombo",
        path: "/skuCombo/create",
        component: (
            <AuthenticateRoute permissions={[COMBO_SCOPE.COMBO_CREATE]}>
                <CreateSkuCombo />
            </AuthenticateRoute>
        ),
    },
    {
        name: "skuCombo",
        path: "/skuCombo/:id",
        component: (
            <AuthenticateRoute permissions={[COMBO_SCOPE.COMBO_DETAIL_VIEW]}>
                <DetailSkuCombo />
            </AuthenticateRoute>
        ),
    },
    {
        name: "purchasingAccount",
        path: "/purchasing-account",
        component: (
            <AuthenticateRoute permissions={[PURCHASING_ACCOUNT.PURCHASING_ACCOUNT_VIEW]}>
                <PurchasingAccount />
            </AuthenticateRoute>
        ),
    },
    {
        name: "inventory",
        path: "/inventory/:id",
        component: (
            <AuthenticateRoute permissions={[INVENTORY_SCOPE.INVENTORY_DETAIL_VIEW, INVENTORY_SCOPE.INVENTORY_HISTORY_VIEW]}>
                <DetailInventory />
            </AuthenticateRoute>
        ),
    },
    {
        name: "orderFinancing",
        path: "/order-financing",
        component: (
            <AuthenticateRoute permissions={[]}>
                <OrderFinancing />
            </AuthenticateRoute>
        ),
    },
]
