import SkuComboApi from "@api/version2/SkuComboApi"
import { PAGINATION } from "@config/constant"
import { ISkuCombo, ISkuComboAutocomplete, ISkuComboDetail, ISkuComboQuery, ISkuComboRequest, IUpdateSkuComboRequest } from "@domain/version2/SkuCombo"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    skuComboSuggest: ISkuComboAutocomplete[]
    loadingSkuComboSuggest: boolean
    skuComboDetail: ISkuComboDetail
    loadingDetail: boolean
    pagination: {
        currentPage: number
        pageSize: number
        total: number
        totalPage: number
    }
    listSkuCombo: ISkuCombo[]
    loadingList: boolean
    loadingCreate: boolean
    loadingUpdate: boolean
}
const initState: State = {
    skuComboSuggest: [],
    loadingSkuComboSuggest: false,
    skuComboDetail: {} as ISkuComboDetail,
    loadingDetail: false,
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listSkuCombo: [],
    loadingList: false,
    loadingCreate: false,
    loadingUpdate: false,
}
export const callGetSkuComboSuggest = createAsyncThunk("SKU_COMBO.LIST_AUTOCOMPLETE", async (params: { keyword: string }, thunkApi) => {
    try {
        return await SkuComboApi.getSkuComboAutocomplete(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetDetailSkuCombo = createAsyncThunk("SKU_COMBO.GET_DETAIL", async (code: string, thunkApi) => {
    try {
        return await SkuComboApi.getDetailSkuCombo(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callListSkuCombo = createAsyncThunk("SKU_COMBO.GET_LIST", async (payload: ISkuComboQuery, thunkApi) => {
    try {
        return await SkuComboApi.getListSkuCombo(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const resetDataDetailSkuCombo = createAction<any>("PRODUCT.RESET_DATA_DETAIL")

export const callCreateSkuCombo = createAsyncThunk("SKU_COMBO.CREATE", async (payload: ISkuComboRequest, thunkApi) => {
    try {
        return await SkuComboApi.createSkuCombo(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callUpdateSkuCombo = createAsyncThunk("SKU_COMBO.UPDATE", async (payload: { comboCode: string; body: IUpdateSkuComboRequest }, thunkApi) => {
    try {
        return await SkuComboApi.updateSkuCombo(payload.comboCode, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callDeleteSkuCombo = createAsyncThunk("SKU_COMBO.DELETE", async (payload: { comboCode: string; skuCode: string }, thunkApi) => {
    try {
        return await SkuComboApi.deleteSkuCombo(payload.comboCode, payload.skuCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const skuComboReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetSkuComboSuggest.pending, (state) => {
            state.skuComboSuggest = []
            state.loadingSkuComboSuggest = true
        })
        .addCase(callGetSkuComboSuggest.fulfilled, (state, { payload }) => {
            state.skuComboSuggest = payload.data
            state.loadingSkuComboSuggest = false
        })
        .addCase(callGetSkuComboSuggest.rejected, (state) => {
            state.skuComboSuggest = []
            state.loadingSkuComboSuggest = false
        })
    builder
        .addCase(callGetDetailSkuCombo.pending, (state) => {
            state.loadingDetail = true
            state.skuComboDetail = {} as ISkuComboDetail
        })
        .addCase(callGetDetailSkuCombo.fulfilled, (state, { payload }) => {
            state.skuComboDetail = payload.data
            state.loadingDetail = false
        })
        .addCase(callGetDetailSkuCombo.rejected, (state) => {
            state.skuComboDetail = {} as ISkuComboDetail
            state.loadingDetail = false
        })
    builder.addCase(resetDataDetailSkuCombo, (state, action: PayloadAction<any>) => {
        state.skuComboDetail = action.payload
    })
    builder
        .addCase(callListSkuCombo.pending, (state) => {
            state.loadingList = true
            state.listSkuCombo = []
        })
        .addCase(callListSkuCombo.fulfilled, (state, { payload }) => {
            state.listSkuCombo = payload?.data || []
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callListSkuCombo.rejected, (state) => {
            state.loadingList = false
        })
    builder
        .addCase(callCreateSkuCombo.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreateSkuCombo.fulfilled, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreateSkuCombo.rejected, (state) => {
            state.loadingCreate = false
        })
    builder
        .addCase(callUpdateSkuCombo.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateSkuCombo.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateSkuCombo.rejected, (state) => {
            state.loadingUpdate = false
        })
})
