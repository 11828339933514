import CampaignApi2 from "@api/version2/CampaignApi"
import { ICampaign, ICampaignRequest } from "@domain/version2/Campaign"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    detailCampaign: ICampaign
    loadingDetail: boolean
    loadingUpdateCampaign: boolean
}

const initState: State = {
    detailCampaign: {},
    loadingDetail: false,
    loadingUpdateCampaign: false,
}

export const callGetDetailCampaign = createAsyncThunk("CAMPAIGN_DETAIL.GET_DETAIL_CAMPAIGN", async (payload: string, thunkApi) => {
    try {
        return await CampaignApi2.getDetailCampaign(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callUpdateCampaign = createAsyncThunk("CAMPAIGN_DETAIL.UPDATE_CAMPAIGN", async (payload: { code: string; body: ICampaignRequest }, thunkApi) => {
    try {
        return await CampaignApi2.updateCampaign(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const resetDetailCampaign = createAction<any>("CAMPAIGN_DETAIL.RESET_DETAIL_CAMPAIGN")

export const campaignDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetDetailCampaign.pending, (state) => {
            state.detailCampaign = {}
            state.loadingDetail = true
        })
        .addCase(callGetDetailCampaign.fulfilled, (state, { payload }) => {
            state.detailCampaign = payload.data
            state.loadingDetail = false
        })
        .addCase(callGetDetailCampaign.rejected, (state) => {
            state.detailCampaign = {}
            state.loadingDetail = false
        })
    builder.addCase(resetDetailCampaign, (state, action: PayloadAction<any>) => {
        state.detailCampaign = action.payload
    })
    builder
        .addCase(callUpdateCampaign.pending, (state) => {
            state.loadingUpdateCampaign = true
        })
        .addCase(callUpdateCampaign.fulfilled, (state) => {
            state.loadingUpdateCampaign = false
        })
        .addCase(callUpdateCampaign.rejected, (state) => {
            state.loadingUpdateCampaign = false
        })
})
