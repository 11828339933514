import { IAds } from "@domain/version2/Ads"
import IContact, { IContactQuery, IContactRequest } from "@domain/version2/Contact"
import IContactDetail, { IContactUpdateDetail } from "@domain/version2/ContactDetail"
import apiClient from "@util/ApiClient"

export default class ContactApi {
    static getListContact(params: IContactQuery): Promise<{ data: IContact[]; headers: { [key: string]: any } }> {
        return apiClient.get("contacts", {
            params,
        })
    }

    static getContactDetail(id: string): Promise<{ data: IContactDetail }> {
        return apiClient.get(`contacts/${id}`)
    }

    static importContact(code: string, file: any): Promise<any> {
        const formData: any = new FormData()
        formData.append("file", file)
        formData.append("ad", code)
        return apiClient.post(`contacts/upload`, formData)
    }

    static createContact(body: IContactRequest): Promise<any> {
        return apiClient.post(`contacts`, body)
    }
    static getAdsOfContact(id: string): Promise<{ data: IAds[] }> {
        return apiClient.get(`contacts/${id}/ads`)
    }

    static updateContactDetail(contactId: string, body: IContactUpdateDetail): Promise<any> {
        return apiClient.patch(`contacts/${contactId}`, body)
    }

    static exportContacts(params: IContactQuery): Promise<any> {
        return apiClient.post("contacts/export", params)
    }

    static updateContactDetailRemark(contactId: string, body: { isSpam: boolean }): Promise<any> {
        return apiClient.patch(`contacts/${contactId}/mark-as-spam`, body)
    }
}
