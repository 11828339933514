import React, { useEffect } from "react"
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd"
import { trans } from "@resources/localization"
import { ICampaignQuery } from "@domain/version2/Campaign"
import { useSearchParams } from "react-router-dom"
import { forEach, isEmpty, omitBy } from "lodash"
import { useAppSelector } from "@hook/useAppSelector"
import SecurityService from "@util/SecurityService"
import { CAMPAIGN_SCOPE } from "@config/permission"
import { optionType } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callSuggestUser } from "@reducer/version2/user.reducer"

const Filter = () => {
    const [formRef] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const listUsers = useAppSelector((state) => state.usersVersion2.listSuggestUsers)
    const loadingList = useAppSelector((state) => state.campaignVersion2.loadingList)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch: any = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                createdBy: searchParams?.has("createdBy") ? oldSearch?.createdBy?.split(",") : [],
            })
            if (Object.keys(oldSearch).length === 1 && searchParams.has("tab")) {
                formRef.resetFields()
            }
        } else {
            formRef.resetFields()
        }
    }, [searchParams])

    const handleChangeFilter = (values: ICampaignQuery) => {
        const oldSearch = Object.fromEntries(searchParams.entries())

        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                createdBy: values?.createdBy ? values?.createdBy?.join(",") : undefined,
            }
            params.tab = "campaign"
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "user":
                    dispatch(
                        callSuggestUser({
                            keyword: searchText.trim(),
                        })
                    )
                    break

                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "user":
                    dispatch(
                        callSuggestUser({
                            keyword: "",
                        })
                    )
                    break

                default:
                    break
            }
        }
    }

    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={handleChangeFilter}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={`${trans("campaign.add_name")}/ ${trans("campaign.code")}`}
                            name="keyword">
                            <Input
                                allowClear
                                placeholder={`${trans("campaign.add_name")}/ ${trans("campaign.code")}`}
                                suffix={<i className="fa-regular fa-magnifying-glass"></i>}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("campagin.type_create")}
                            name="type">
                            <Select placeholder={trans("campaign.type_pl")}>
                                {optionType?.map((item) => (
                                    <Select.Option
                                        key={item?.value}
                                        value={item?.value}>
                                        {item?.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL) && (
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}>
                            <Form.Item
                                label={trans("payment_statement.createdBy")}
                                name="createdBy">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    onBlur={() => onSearchAutocomplete("", "user")}
                                    onSearch={(e) => {
                                        onSearchAutocomplete(e, "user")
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    placeholder={trans("placeholder.select_user")}>
                                    {listUsers.map((el) => (
                                        <Select.Option
                                            key={el.id}
                                            value={el.username}>
                                            {el.username}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row justify="start">
                    <Space className="mt-2">
                        <Button
                            htmlType="submit"
                            loading={loadingList}
                            type="primary">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            loading={loadingList}
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({})
                            }}>
                            {trans("button.clear_all_filter")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Card>
    )
}

export default Filter
