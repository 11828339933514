import AdsApi from "@api/version2/AdsApi"
import { IAdsRequest, ILanding, ILandingWeb } from "@domain/version2/Ads"
import { IAdsDetail } from "@domain/version2/AdsDetail"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import ProductApi from "@api/version2/ProductApi"
import { IAdSku } from "@domain/version2/AdSku"

interface State {
    detailAds: IAdsDetail
    loadingDetail: boolean
    loadingUpdate: boolean
    landingUrls: ILanding[]
    loadingUrl: boolean
    landingWeb: ILandingWeb
    addSkuLoading: boolean
    skusOfVendor: Array<any>
    adSkus: Array<IAdSku>
    adSkusLoading: boolean
}

const initState: State = {
    detailAds: {},
    loadingDetail: false,
    loadingUpdate: false,
    landingUrls: [],
    loadingUrl: false,
    landingWeb: {},
    addSkuLoading: false,
    skusOfVendor: [],
    adSkus: [],
    adSkusLoading: false,
}

export const callGetDetailAds = createAsyncThunk("ADS_DETAIL.GET_DETAIL", async (payload: string, thunkApi) => {
    try {
        return await AdsApi.getDetailAds(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callUpdateAds = createAsyncThunk("ADS_DETAIL.UPDATE_ADS", async (payload: { code: string; body: IAdsRequest }, thunkApi) => {
    try {
        return await AdsApi.updateAds(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetLandingUrl = createAsyncThunk("ADS_DETAIL.GET_LANDING", async (code: string, thunkApi) => {
    try {
        return await AdsApi.getLandingUrl(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetLandingWeb = createAsyncThunk("ADS_DETAIL.GET_LANDING_WEB", async (code: string, thunkApi) => {
    try {
        return await AdsApi.getWebhook(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const getSkusByVendorAction = createAsyncThunk("ADS_DETAIL.GET_SKU_BY_VENDOR", async (query: any, thunkApi) => {
    try {
        return await ProductApi.getProductsByVariants(query)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const addSkuAction = createAsyncThunk("ADS_DETAIL.ADD_SKU", async (payload: any, thunkApi) => {
    try {
        return AdsApi.addSku(payload.adCode, {
            sku: payload.sku,
            quantity: payload.quantity,
            price: payload.price,
        })
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const removeSkuAction = createAsyncThunk("ADS_DETAIL.REMOVE_SKU", async (payload: any, thunkApi) => {
    try {
        return AdsApi.removeSku(payload.adCode, payload.sku)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const getSkusAction = createAsyncThunk("ADS_DETAIL.GET_SKUS", async (payload: any, thunkApi) => {
    try {
        return AdsApi.getSkus(payload.adCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const resetDetailAds = createAction<any>("ADS_DETAIL.RESET_DETAIL")

export const adsDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetDetailAds.pending, (state) => {
            state.detailAds = {}
            state.loadingDetail = true
        })
        .addCase(callGetDetailAds.fulfilled, (state, { payload }) => {
            state.detailAds = payload.data
            state.loadingDetail = false
        })
        .addCase(callGetDetailAds.rejected, (state) => {
            state.detailAds = {}
            state.loadingDetail = false
        })
    builder.addCase(resetDetailAds, (state, action: PayloadAction<any>) => {
        state.detailAds = action.payload
    })
    builder
        .addCase(callUpdateAds.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateAds.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateAds.rejected, (state) => {
            state.loadingUpdate = false
        })
    builder
        .addCase(callGetLandingUrl.pending, (state) => {
            state.landingUrls = []
            state.loadingUrl = true
        })
        .addCase(callGetLandingUrl.fulfilled, (state, { payload }) => {
            state.landingUrls = payload?.data
            state.loadingUrl = false
        })
        .addCase(callGetLandingUrl.rejected, (state) => {
            state.landingUrls = []
            state.loadingUrl = false
        })
    builder
        .addCase(callGetLandingWeb.pending, (state) => {
            state.landingWeb = {}
        })
        .addCase(callGetLandingWeb.fulfilled, (state, { payload }) => {
            state.landingWeb = payload?.data
        })
        .addCase(callGetLandingWeb.rejected, (state) => {
            state.landingWeb = {}
        })

    builder
        .addCase(getSkusByVendorAction.pending, (state) => {
            state.addSkuLoading = true
            state.skusOfVendor = []
        })
        .addCase(getSkusByVendorAction.fulfilled, (state, { payload }) => {
            state.addSkuLoading = false
            state.skusOfVendor = payload.data
        })
        .addCase(getSkusByVendorAction.rejected, (state) => {
            state.addSkuLoading = false
            state.skusOfVendor = []
        })

    builder
        .addCase(addSkuAction.pending, (state) => {
            state.addSkuLoading = true
        })
        .addCase(addSkuAction.fulfilled, (state) => {
            state.addSkuLoading = false
        })
        .addCase(addSkuAction.rejected, (state) => {
            state.addSkuLoading = false
        })

    builder
        .addCase(removeSkuAction.pending, (state) => {
            state.addSkuLoading = true
        })
        .addCase(removeSkuAction.fulfilled, (state) => {
            state.addSkuLoading = false
        })
        .addCase(removeSkuAction.rejected, (state) => {
            state.addSkuLoading = false
        })

    builder
        .addCase(getSkusAction.pending, (state) => {
            state.adSkusLoading = true
        })
        .addCase(getSkusAction.fulfilled, (state, { payload }) => {
            state.adSkusLoading = false
            state.adSkus = payload.data
        })
        .addCase(getSkusAction.rejected, (state) => {
            state.adSkusLoading = false
        })
})
