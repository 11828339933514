import { createReducer, createAsyncThunk } from "@reduxjs/toolkit"

import { ICourier, ICourierComplete, ICourierRequest, ICourierVendor } from "@domain/version2/CourierSetting"
import CourierSettingApi from "@api/version2/CourierSetting"

interface State {
    couriers: ICourier[]
    courierVendor: ICourierVendor[]
    loadingCourierVendor: boolean
    loadingUpdate: boolean
    courierSuggests: ICourierComplete[]
}

const initState: State = {
    couriers: [],
    courierVendor: [],
    loadingCourierVendor: false,
    loadingUpdate: false,
    courierSuggests: [],
}

export const callGetListCouriers = createAsyncThunk("COURIER_SETTING.GET_LIST", async (params: { "x-tenant": string; countryCode: string }, thunkApi) => {
    try {
        return await CourierSettingApi.getListCourier(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetCourierVendor = createAsyncThunk("COURIER_SETTING.GET_COURIER_VENDOR", async (_, thunkApi) => {
    try {
        return await CourierSettingApi.getCourierVendor()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateCourierVendor = createAsyncThunk("COURIER_SETTING.UPDATE_COURIER", async (request: ICourierRequest, thunkApi) => {
    try {
        return await CourierSettingApi.createCourier(request)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetCourierSuggest = createAsyncThunk("COURIER_SETTING.GET_COURIER_SUGGEST", async (params: { keyword: string }, thunkApi) => {
    try {
        return await CourierSettingApi.getCourierSuggest(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const courierSettingReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetListCouriers.pending, (state) => {
            state.couriers = []
        })
        .addCase(callGetListCouriers.fulfilled, (state, { payload }) => {
            state.couriers = payload?.data || []
        })
        .addCase(callGetListCouriers.rejected, (state) => {
            state.couriers = []
        })
    builder
        .addCase(callGetCourierVendor.pending, (state) => {
            state.courierVendor = []
            state.loadingCourierVendor = true
        })

        .addCase(callGetCourierVendor.fulfilled, (state, { payload }) => {
            state.courierVendor = payload?.data || []
            state.loadingCourierVendor = false
        })
        .addCase(callGetCourierVendor.rejected, (state) => {
            state.courierVendor = []
            state.loadingCourierVendor = false
        })
    builder
        .addCase(callUpdateCourierVendor.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateCourierVendor.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateCourierVendor.rejected, (state) => {
            state.loadingUpdate = false
        })
    builder
        .addCase(callGetCourierSuggest.pending, (state) => {
            state.courierSuggests = []
        })
        .addCase(callGetCourierSuggest.fulfilled, (state, { payload }) => {
            state.courierSuggests = payload?.data
        })
        .addCase(callGetCourierSuggest.rejected, (state) => {
            state.courierSuggests = []
        })
})
