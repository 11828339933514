import AdsApi from "@api/version2/AdsApi"
import { PAGINATION } from "@config/constant"
import { IAdsQuery, IAdsRequest } from "@domain/version2/Ads"
import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    pagination: {
        currentPage: number
        pageSize: number
        total: number
        totalPage: number
    }
    listAds: any[]
    loadingList: boolean
    loadingCreateAds: boolean
    loadingLanding: boolean
}

const initState: State = {
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listAds: [],
    loadingList: false,
    loadingCreateAds: false,
    loadingLanding: false
}

export const callListAds = createAsyncThunk("ADS.GET_ADS", async (payload:IAdsQuery, thunkApi) => {
    try {
        return await AdsApi.getListAds(payload)
    } catch (error) {
        thunkApi.rejectWithValue(error)
    }
})

export const callCreateAds = createAsyncThunk("ADS.CREATE_ADS", async (payload:IAdsRequest , thunkApi) => {
    try {
        return await AdsApi.createAds(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreateAndUpdateLanding = createAsyncThunk("ADS.CREATE_LANDING", async (payload:{code: string, body: {urls: string[]}} , thunkApi) => {
    try {
        return await AdsApi.createAndUpdateLanding(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const toggleStatusAds = createAction<any>("ADS.UPDATE_STATUS_ADS")

export const adsReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callListAds.pending, (state) => {
            state.loadingList = true
            state.listAds = []
        })
        .addCase(callListAds.fulfilled, (state, { payload }) => {
            state.listAds = payload?.data || []
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callListAds.rejected, (state) => {
            state.loadingList = false
        })

    builder
        .addCase(callCreateAds.pending, (state) => {
            state.loadingCreateAds = true
        })
        .addCase(callCreateAds.fulfilled, (state) => {
            state.loadingCreateAds = false
        })
        .addCase(callCreateAds.rejected, (state) => {
            state.loadingCreateAds = false
        })
    builder
        .addCase(callCreateAndUpdateLanding.pending, (state) => {
            state.loadingLanding = true
        })
        .addCase(callCreateAndUpdateLanding.fulfilled, (state) => {
            state.loadingLanding = false
        })
        .addCase(callCreateAndUpdateLanding.rejected, (state) => {
            state.loadingLanding = false
        })
})
