import { trans } from "@resources/localization"
import { Typography } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import React from "react"

type Props = {
    listErrors: any
}
export const TableErrorOrder: React.FC<Props> = ({ listErrors }) => {
    const columns: ColumnsType<any> = [
        {
            title: trans("order_list.lbc_import_error"),
            className: "bold-400",
            key: "error",
            render: (key, row) => {
                return row || ""
            },
        },
    ]
    return (
        <div>
            <Typography.Text strong>{trans("order.list_errors")}</Typography.Text>
            <Table
                showHeader={false}
                columns={columns}
                dataSource={listErrors}
                pagination={false}
                scroll={{ y: 600 }}
            />
        </div>
    )
}
