import { IOrderQuery } from "@domain/version2/Order"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCampaignsSuggest } from "@reducer/version2/campaign.reducer"
import { callProductSuggest } from "@reducer/version2/product.reducer"
import { callSuggestUser } from "@reducer/version2/user.reducer"
import { trans } from "@resources/localization"
import { filterWithTimezone } from "@util/Common"
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

export const Filter: React.FC = () => {
    const dispatch = useAppDispatch()
    const [formRef] = Form.useForm()
    const [isShowMore, setIsShowMore] = useState<boolean>(false)
    const listOrderStatus = useAppSelector((state) => state.categoriesVersion2.listOrderStatuses)
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.orderVersion2.loadingList)
    const campaignsSuggest = useAppSelector((state) => state.campaignVersion2.campaignsSuggest)
    const listSuggestUsers = useAppSelector((state) => state.usersVersion2.listSuggestUsers)
    const productAutocomplete = useAppSelector((state) => state.productVersion2.productsSuggest)
    const orderFinancialStatuses = useAppSelector((state) => state.orderDetailVersion2.financialStatuses)
    const listOrderSources = useAppSelector((state) => state.categoriesVersion2.listOrderSources)

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("campaignCode") && {
                    campaignCode: oldSearch?.campaignCode?.split(","),
                }),
                ...(searchParams.has("status") && {
                    status: oldSearch?.status?.split(","),
                }),
                ...(searchParams.has("created_from") &&
                    searchParams.has("created_to") && {
                        createdAt: [filterWithTimezone(oldSearch?.createdFrom), filterWithTimezone(oldSearch?.createdTo)],
                    }),
                ...(searchParams.has("marketer") && {
                    marketer: oldSearch.marketer.split(","),
                }),
                ...(searchParams.has("productCode") && {
                    productCode: oldSearch.productCode.split(","),
                }),
                ...(searchParams.has("financial") && {
                    financial: oldSearch?.financial?.split(","),
                }),
                ...(searchParams.has("source") && {
                    source: oldSearch?.source?.split(","),
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [])

    const handleFilter = (values: IOrderQuery) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                code: values?.code ? values.code.trim() : undefined,
                trackingNumber: values?.trackingNumber ? values.trackingNumber.trim() : undefined,
                createdFrom: values?.createdAt ? filterWithTimezone(values?.createdAt[0]).startOf("day").toISOString() : undefined,
                createdTo: values?.createdAt ? filterWithTimezone(values?.createdAt[1]).endOf("day").toISOString() : undefined,
                status: !isEmpty(values?.status) ? values?.status?.join(",") : undefined,
                campaignCode: !isEmpty(values?.campaignCode) ? values?.campaignCode?.join(",") : undefined,
                marketer: values.marketer ? values.marketer.join(",") : undefined,
                productCode: values.productCode ? values.productCode.join(",") : undefined,
                financial: !isEmpty(values?.financial) ? values?.financial?.join(",") : undefined,
                source: !isEmpty(values?.source) ? values?.source?.join(",") : undefined,
            }
            delete params.createdAt
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }

    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "campaign":
                    dispatch(
                        callCampaignsSuggest({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                case "user":
                    dispatch(
                        callSuggestUser({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                case "product":
                    dispatch(
                        callProductSuggest({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "campaign":
                    dispatch(
                        callCampaignsSuggest({
                            keyword: "",
                        })
                    )
                    break
                case "user":
                    dispatch(
                        callSuggestUser({
                            keyword: "",
                        })
                    )
                    break
                case "product":
                    dispatch(
                        callProductSuggest({
                            keyword: "",
                        })
                    )
                    break
                default:
                    break
            }
        }
    }
    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={handleFilter}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("contact-detail.order_code")}
                            name="code">
                            <Input
                                allowClear
                                placeholder={trans("order.order_code_placeholder")}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("order_detail.tracking_number")}
                            name="trackingNumber">
                            <Input
                                allowClear
                                placeholder={trans("order.tracking_number_placeholder")}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("order_list.created_at")}
                            name="createdAt">
                            <DatePicker.RangePicker
                                allowClear
                                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                placeholder={[trans("order_list.from"), trans("order_list.to")]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {isShowMore && (
                    <Row gutter={15}>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}>
                            <Form.Item
                                label={trans("order_list.status")}
                                name="status">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    placeholder={trans("order.status_placeholder")}>
                                    {listOrderStatus?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {trans(`order_status.${item?.code}`)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}>
                            <Form.Item
                                label={trans("order.customer_name")}
                                name="customerName">
                                <Input
                                    allowClear
                                    placeholder={trans("order.name_placeholder")}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}>
                            <Form.Item
                                label={trans("order.customer_phone")}
                                name="customerPhone">
                                <Input
                                    allowClear
                                    placeholder={trans("order.customer_phone")}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact.campaign")}
                                name="campaignCode">
                                <Select
                                    allowClear
                                    showSearch
                                    mode="multiple"
                                    onBlur={() => {
                                        onSearchAutocomplete("", "campaign")
                                    }}
                                    onSearch={(e) => {
                                        onSearchAutocomplete(e, "campaign")
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    placeholder={trans("contact.select_campaign")}>
                                    {campaignsSuggest?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("vendor.marketer")}
                                name="marketer">
                                <Select
                                    placeholder={trans("placeholder.select_marketer")}
                                    allowClear
                                    optionFilterProp="children"
                                    onBlur={() => {
                                        onSearchAutocomplete("", "user")
                                    }}
                                    onSearch={(e) => {
                                        onSearchAutocomplete(e, "user")
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    mode="multiple">
                                    {[{ username: "system", id: "system-1" }, ...listSuggestUsers]?.map((item) => (
                                        <Select.Option
                                            value={item.username}
                                            key={item.id}>
                                            {item?.username}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact-detail.product")}
                                name="productCode">
                                <Select
                                    allowClear
                                    placeholder={trans("contact-detail.product")}
                                    showSearch
                                    onBlur={() => {
                                        onSearchAutocomplete("", "product")
                                    }}
                                    onSearch={(e) => {
                                        onSearchAutocomplete(e, "product")
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                    }}
                                    mode="multiple">
                                    {productAutocomplete?.map((item) => {
                                        return (
                                            <Select.Option
                                                key={item?.id}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}>
                            <Form.Item
                                label={trans("order_detail.financial_status")}
                                name="financial">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    placeholder={trans("order.financial_status_placeholder")}>
                                    {orderFinancialStatuses?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {trans(`order_detail.financial_status_${item?.code}`)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}>
                            <Form.Item
                                label={trans("order.order_source")}
                                name="source">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    placeholder={trans("order.order_source")}>
                                    {listOrderSources?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {trans(`order.type_${item?.code}`)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col span={24}>
                        <Space>
                            <Button
                                loading={loadingList}
                                htmlType="submit"
                                type="primary">
                                {trans("button.filter")}
                            </Button>
                            <Button
                                onClick={() => {
                                    formRef.resetFields()
                                    setSearchParams({})
                                }}
                                type="default"
                                loading={loadingList}>
                                {trans("contact.clear")}
                            </Button>
                            <Button
                                type="link"
                                onClick={() => setIsShowMore((old) => !old)}>
                                {isShowMore ? trans("contact.filter_less") : trans("contact.filter_more")}
                                <i className={`ml-2 fa-solid ${isShowMore ? "fa-chevron-up" : "fa-chevron-down"}`} />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
