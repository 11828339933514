import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { IAllContactDashboard, IStatisticsQuery, IDashboardQuery, IAllOrderDashboard } from "@domain/version2/Dashboard"
import DashboardApi from "@api/version2/DashboardApi"

interface State {
    loadingContact: boolean
    tContactsStatistics: IAllContactDashboard
    yContactsStatistics: IAllContactDashboard
    loadingOrder: boolean
    ordersStatisticsToday: IAllOrderDashboard
    ordersStatisticsYesterday: IAllOrderDashboard
}

const initState: State = {
    loadingContact: false,
    tContactsStatistics: {} as IAllContactDashboard,
    yContactsStatistics: {} as IAllContactDashboard,
    loadingOrder: false,
    ordersStatisticsToday: {} as IAllOrderDashboard,
    ordersStatisticsYesterday: {} as IAllOrderDashboard,
}

export const callGetContactTodayStatistics = createAsyncThunk("DASHBOARD.GET_STATISTICS_CONTACT_TODAY", async (params: IDashboardQuery, thunkApi) => {
    try {
        return await DashboardApi.getStatisticsContacts(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetContactYesterdayStatistics = createAsyncThunk("DASHBOARD.GET_STATISTICS_CONTACT_YESTERDAY", async (params: IDashboardQuery, thunkApi) => {
    try {
        return await DashboardApi.getStatisticsContacts(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetOrderStatisticsToday = createAsyncThunk("DASHBOARD.GET_STATISTICS_ORDERS_TODAY", async (params: IStatisticsQuery, thunkApi) => {
    try {
        return await DashboardApi.getStatisticsOrders(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetOrderStatisticsYesterday = createAsyncThunk("DASHBOARD.GET_STATISTICS_ORDERS_YESTERDAY", async (params: IStatisticsQuery, thunkApi) => {
    try {
        return await DashboardApi.getStatisticsOrders(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const dashboardReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetContactTodayStatistics.pending, (state) => {
            state.loadingContact = true
            state.tContactsStatistics = {} as IAllContactDashboard 
        })
        .addCase(callGetContactTodayStatistics.fulfilled, (state, { payload }) => {
            state.tContactsStatistics = payload?.data || {}
            state.loadingContact = false
        })
        .addCase(callGetContactTodayStatistics.rejected, (state) => {
            state.loadingContact = false
            state.tContactsStatistics = {} as IAllContactDashboard
        })
    builder
        .addCase(callGetContactYesterdayStatistics.pending, (state) => {
            state.loadingContact = true
            state.yContactsStatistics = {} as IAllContactDashboard
        })
        .addCase(callGetContactYesterdayStatistics.fulfilled, (state, { payload }) => {
            state.yContactsStatistics = payload?.data || {}
            state.loadingContact = false
        })
        .addCase(callGetContactYesterdayStatistics.rejected, (state) => {
            state.loadingContact = false
            state.yContactsStatistics = {} as IAllContactDashboard
        })
    builder
        .addCase(callGetOrderStatisticsToday.pending, (state) => {
            state.loadingOrder = true
            state.ordersStatisticsToday = {} as IAllOrderDashboard
        })
        .addCase(callGetOrderStatisticsToday.fulfilled, (state, { payload }) => {
            state.ordersStatisticsToday = payload?.data || {}
            state.loadingOrder = false
        })
        .addCase(callGetOrderStatisticsToday.rejected, (state) => {
            state.loadingOrder = false
            state.ordersStatisticsToday = {} as IAllOrderDashboard
        })

    builder
        .addCase(callGetOrderStatisticsYesterday.pending, (state) => {
            state.loadingOrder = true
            state.ordersStatisticsYesterday = {} as IAllOrderDashboard
        })
        .addCase(callGetOrderStatisticsYesterday.fulfilled, (state, { payload }) => {
            state.ordersStatisticsYesterday = payload?.data || {}
            state.loadingOrder = false
        })
        .addCase(callGetOrderStatisticsYesterday.rejected, (state) => {
            state.loadingOrder = false
            state.ordersStatisticsYesterday = {} as IAllOrderDashboard
        })
})
