import React from "react"
import { Button, Card, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { trans } from "@resources/localization"
import { PAGINATION } from "@config/constant"
import { formatDateShort, formatDateFull } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeVisibleFormCreatePaymentState } from "@reducer/version2/paymentStatement.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { Link, useSearchParams } from "react-router-dom"
import SecurityService from "@util/SecurityService"
import { PAYMENT_STATEMENT_SCOPE } from "@config/permission"
import { IPaymentStatement } from "@domain/version2/PaymentStatement"
import { isEmpty } from "lodash"

export const ListPaymentStatement: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const size: number = useAppSelector((state) => state.paymentStatement.pagination.size)
    const currentPage: number = useAppSelector((state) => state.paymentStatement.pagination.current)
    const total: number = useAppSelector((state) => state.paymentStatement.pagination.total)
    const totalPage: number = useAppSelector((state) => state.paymentStatement.pagination.totalPage)
    const listPaymentStatement = useAppSelector((state) => state.paymentStatement.listPaymentStatement)
    const loadingList = useAppSelector((state) => state.paymentStatement.loadingList)

    const columns: ColumnsType<any> = [
        {
            title: trans("payment_statement.code"),
            className: "bold-400",
            dataIndex: "code",
            width: 100,
            render: (code: string) =>
                SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_DETAIL_VIEW) ? <Link to={`/payment-statement/${code}`}>{code}</Link> : code,
        },
        {
            title: trans("payment_statement.cycle"),
            className: "bold-400",
            width: 200,
            render: (record: IPaymentStatement) => `${formatDateShort(record?.startedAt) || "---"} - ${formatDateShort(record?.endedAt) || "---"}`,
        },
        {
            title: trans("payment_statement.status"),
            className: "bold-400",
            dataIndex: "status",
            width: 150,
            render: (status) => !isEmpty(status) ? <Tag color={status?.color}>{trans(`payment_statement.${status?.code}`)}</Tag> : '',
        },
        {
            title: trans("payment_statement.creation_time"),
            className: "bold-400",
            dataIndex: "createdAt",
            width: 120,
            render: (date) => formatDateFull(date || ""),
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const param: any = {
            ...oldSearch,
            page: !searchParams.has("size") || Number(oldSearch?.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: pageSize,
        }
        setSearchParams(param)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage} (${total})` : ""}
            extra={
                SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_CREATE) && (
                    <Button
                        type="primary"
                        onClick={() => dispatch(changeVisibleFormCreatePaymentState(true))}>
                        {trans("payment_statement.add")}
                    </Button>
                )
            }>
            <Table
                columns={columns}
                scroll={{
                    x: true,
                }}
                dataSource={listPaymentStatement || []}
                loading={loadingList}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: size,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                    locale: {
                        items_per_page: `/ ${trans("contact.page_change")}`,
                    },
                }}
            />
        </Card>
    )
}
