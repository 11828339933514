import { IService } from "@domain/version2/InventoryApi"
import { LIST_CURRENCY } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Card, Table } from "antd"
import { find } from "lodash"
import React from "react"
import { convertNumberToCurrency } from "@util/Common"

interface IProps {
    title: string
    data: IService[]
}

const Service = ({ title, data }: IProps) => {
    const loadingDetail = useAppSelector((state) => state.inventory.loadingDetail)
    const vendor = useAppSelector((state) => state.vendorDetail.detailVendor)

    const columns: any[] = [
        {
            title: trans("inventory.service"),
            className: "bold-400",
            dataIndex: "name",
            width: "50%",
        },
        {
            title: trans("inventory.estimated_fee"),
            className: "bold-400",
            dataIndex: ["servicePrice", "price"],
            render: (price: number) => (
                <>
                    <p className="mb-0 text-red-400">
                        {find(LIST_CURRENCY, { value: vendor?.countryCode })?.type === "left" && (
                            <span>{find(LIST_CURRENCY, { value: vendor?.countryCode })?.title}</span>
                        )}
                        {convertNumberToCurrency(price)}
                        {find(LIST_CURRENCY, { value: vendor?.countryCode })?.type === "right" && (
                            <span>{find(LIST_CURRENCY, { value: vendor?.countryCode })?.title}</span>
                        )}
                    </p>
                </>
            ),
            width: "50%",
        },
    ]

    return (
        <Card
            className="space-layout"
            loading={loadingDetail}
            title={title}>
            <Table
                rowKey={"id"}
                scroll={{
                    x: true,
                }}
                columns={columns}
                loading={false}
                dataSource={data || []}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={false}
                footer={() => {
                    const estimatedTotalFee = data
                        ?.map((service) => service.servicePrice.price)
                        .reduce((price, currentValue) => price + currentValue, 0)
                        .toString()
                    return (
                        <div className="flex h-6">
                            <p className="font-medium w-[50%] ">{trans("inventory.estimated_total_fee")}</p>
                            {data?.length > 0 && (
                                <p className="ml-4 mb-0 text-red-400">
                                    {find(LIST_CURRENCY, { value: vendor?.countryCode })?.type === "left" && (
                                        <span>{find(LIST_CURRENCY, { value: vendor?.countryCode })?.title}</span>
                                    )}
                                    {convertNumberToCurrency(estimatedTotalFee)}
                                    {find(LIST_CURRENCY, { value: vendor?.countryCode })?.type === "right" && (
                                        <span>{find(LIST_CURRENCY, { value: vendor?.countryCode })?.title}</span>
                                    )}
                                </p>
                            )}
                        </div>
                    )
                }}
            />
        </Card>
    )
}

export default Service
