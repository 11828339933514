import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB, PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetInventories, callGetWarehouses } from "@reducer/version2/inventory.reducer"
import { trans } from "@resources/localization"
import { Tabs } from "antd"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Filter } from "./Filter"
import { TableInventory } from "./Table"
export const Inventory: React.FC = () => {
    const [searchParams] = useSearchParams()
    const [currentTab, setCurrentTab] = useState<string>("selling")
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("inventory.title")))
        dispatch(callGetWarehouses({}))
    }, [dispatch])
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        dispatch(
            callGetInventories({
                ...params,
                ...(currentTab === "selling" && { status: "ON_SELL" }),
                ...(currentTab === "stop" && { status: "STOP_SELLING" }),
                ...(currentTab === "shortage" && { lackOfExportGood: true }),
                ...(currentTab === "almost_over" && { nearlySoldOut: true }),
                ...(currentTab === "sold_out" && { outOfStock: true }),
                ...(currentTab === "empty" && { notYetInStock: true }),
                pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
            })
        )
    }, [dispatch, searchParams, currentTab])
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb breadCrumbs={[...HOME_BREADCRUMB, { name: "inventory.title", link: "/inventory" }]} />
            <Filter />
            <Tabs
                onChange={(key) => setCurrentTab(key)}
                className="space-x nav-space"
                activeKey={currentTab}
                items={[
                    { label: trans("warehouse.selling"), key: "selling", children: <TableInventory /> },
                    { label: trans("warehouse.stop_selling"), key: "stop", children: <TableInventory /> },
                    { label: trans("warehouse.stock_shortage"), key: "shortage", children: <TableInventory /> },
                    { label: trans("warehouse.almost_over"), key: "almost_over", children: <TableInventory /> },
                    { label: trans("warehouse.sold_out"), key: "sold_out", children: <TableInventory /> },
                    { label: trans("warehouse.empty"), key: "empty", children: <TableInventory /> },
                ]}
            />
        </DefaultLayout>
    )
}
