import { ICampaign, ICampaignQuery, ICampaignRequest } from "@domain/version2/Campaign"
import apiClient from "@util/ApiClient"

export default class CampaignApi {
    static getListCampaign(params: ICampaignQuery): Promise<{ data: ICampaign[]; headers: { [key: string]: any } }> {
        return apiClient.get(`campaigns`, {
            params,
        })
    }
    static createCampaign(body: ICampaignRequest): Promise<any> {
        return apiClient.post('campaigns', body)
    }
    static getDetailCampaign(code: string): Promise<{ data: ICampaign }> {
        return apiClient.get(`campaigns/${code}`)
    }
    static updateCampaign(code: string, body: ICampaignRequest): Promise<any> {
        return apiClient.patch(`campaigns/${code}`, body)
    }
    static getCampaignsSuggest(params:{keyword: string}): Promise<{ data: ICampaign[] }> {
        return apiClient.get('/campaigns/autocomplete',{
            params
        })
    }
}
