import { ICourier, ILocation } from "@domain/version2/Country"
import apiClient from "@util/ApiClient"

export class CountriesApi {
    static getCouriersAutocomplete(params: { keyword: string; countryCode: string }): Promise<{ data: ICourier[] }> {
        return apiClient.get(`couriers`, {
            params,
        })
    }
    static getCountriesAutocomplete(): Promise<{ data: ILocation[] }> {
        return apiClient.get("countries")
    }
    static getProvincesAutocomplete(countryCode: string): Promise<{ data: ILocation[] }> {
        return apiClient.get(`countries/${countryCode}/provinces`)
    }
    static getLocationAutocomplete(parentCode: string): Promise<{ data: ILocation[] }> {
        return apiClient.get(`countries/${parentCode}/locations`)
    }
}
