import { IUser } from "@domain/version2/User"
import { IUserRequest } from "@domain/version1/User"
import apiClient from "@util/ApiClient"

export default class UserApi {
  static getListUsers(params: {
    username?: string
    page?: number
    size?: number
    Created_at?: string
}): Promise<{ data: IUser[]; headers: { [key: string]: any }; status: number }> {
    return apiClient.get("users", { params })
}
static createUser(body: IUserRequest): Promise<any> {
    return apiClient.patch("users", body)
}

}
