import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import IContact, { IContactQuery, IContactRequest } from "@domain/version2/Contact"
import { IResponseImport } from "@domain/version2/Campaign"
import ContactApi from "@api/version2/ContactApi"

interface State {
    pagination: {
        currentPage: number
        pageSize: number
        total: number
        totalPage: number
    }
    listContact: IContact[]
    loadingList: boolean
    visibleImport: boolean
    visibleFormAddContact: boolean
    loadingImport: boolean
    dataResponseImport: IResponseImport
    loadingCreateContact: boolean
    loadingCreateOneContact: boolean
    loadingExport: boolean
}
const initState: State = {
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listContact: [],
    loadingList: false,
    visibleImport: false,
    visibleFormAddContact: false,
    loadingImport: false,
    dataResponseImport: {},
    loadingCreateContact: false,
    loadingCreateOneContact: false,
    loadingExport: false,
}
export const callListContacts = createAsyncThunk("CONTACT.GET_LIST", async (payload: IContactQuery, thunkApi) => {
    try {
        return await ContactApi.getListContact(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callImportContact = createAsyncThunk("CONTACT.IMPORT_CONTACT", async (payload: { code: string; file: any }, thunkApi) => {
    try {
        return await ContactApi.importContact(payload.code, payload.file)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreateContact = createAsyncThunk("CONTACT.CREATE_CONTACT", async (request: IContactRequest, thunkApi) => {
    try {
        return await ContactApi.createContact(request)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const exportContacts = createAsyncThunk("CONTACT.EXPORT", async (payload: IContactQuery, thunkApi) => {
    try {
        return await ContactApi.exportContacts(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const changeVisibleImport = createAction<boolean>("CONTACT.CHANGE_IMPORT")
export const changeVisibleFormAddContact = createAction<boolean>("CONTACT.CHANGE_ADD")

export const contactReducer2 = createReducer(initState, (builder) => {
    builder
        .addCase(callListContacts.pending, (state) => {
            state.loadingList = true
            state.listContact = []
        })
        .addCase(callListContacts.fulfilled, (state, { payload }) => {
            state.listContact = payload?.data || []
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callListContacts.rejected, (state) => {
            state.loadingList = false
        })
    builder.addCase(changeVisibleImport, (state, action: PayloadAction<boolean>) => {
        state.visibleImport = action.payload
    })

    builder.addCase(changeVisibleFormAddContact, (state, action: PayloadAction<boolean>) => {
        state.visibleFormAddContact = action.payload
    })
    builder
        .addCase(callImportContact.pending, (state) => {
            state.loadingImport = true
            state.dataResponseImport = {}
        })
        .addCase(callImportContact.fulfilled, (state, { payload }) => {
            state.loadingImport = false
            state.dataResponseImport = payload.data
        })
        .addCase(callImportContact.rejected, (state) => {
            state.loadingImport = false
            state.dataResponseImport = {}
        })

    builder
        .addCase(callCreateContact.pending, (state) => {
            state.loadingCreateOneContact = true
        })
        .addCase(callCreateContact.fulfilled, (state) => {
            state.loadingCreateOneContact = false
        })
        .addCase(callCreateContact.rejected, (state) => {
            state.loadingCreateOneContact = false
        })

    builder
        .addCase(exportContacts.pending, (state) => {
            state.loadingExport = true
        })
        .addCase(exportContacts.fulfilled, (state) => {
            state.loadingExport = false
        })
        .addCase(exportContacts.rejected, (state) => {
            state.loadingExport = false
        })
})
