import React, { PropsWithChildren, useEffect } from "react"
import { Button, Card, Col, InputNumber, message, Row, Table, Typography } from "antd"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeDataOrderUpdate, changeDataUpdateOrderItem, fetchUpdateOrderItem, getOrderDetail } from "@reducer/version2/order-detail.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { find, isEmpty } from "lodash"
import { ItemOrder, ItemOrderRequest } from "@domain/version2/OrderDetail"
import { ColumnsType } from "antd/lib/table"
import { LIST_CURRENCY, STATUS_CAN_UPDATE } from "@config/constant"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { IProduct } from "@domain/version2/Product"

interface Props extends PropsWithChildren<any> {
    toggleFullSizeOrderItem: () => any
    onUpdateOrder: (key: string, value: any) => void
}

export const Product = (props: Props) => {
    const dispatch = useAppDispatch()
    const { onUpdateOrder } = props
    const orderDetail = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loadingUpdateOrderItem = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrderItem)
    const dataNeedChange = useAppSelector((state) => state.orderDetailVersion2.dataUpdateOrderItem)
    const dataUpdateOrder = useAppSelector((state) => state.orderDetailVersion2.dataOrderUpdate)
    const loadingUpdateOrder = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrder)

    useEffect(() => {
        if (!isEmpty(orderDetail?.items)) {
            const dataNeedChange: ItemOrderRequest[] =
                orderDetail?.items?.map((item: ItemOrder) => ({
                    sku: item?.sku,
                    price: item?.price,
                    quantity: item?.quantity,
                    type: item?.type
                })) || []
            dispatch(changeDataUpdateOrderItem(dataNeedChange || []))
        }
    }, [orderDetail])
    const onChangeDetailOrder = (index: number) => {
        const dataCurrentChange = dataNeedChange[index]
        if(dataCurrentChange?.type === 'gift' || (dataCurrentChange?.type !== 'gift' &&  dataCurrentChange?.price && dataCurrentChange?.quantity)){
            dispatch(
                fetchUpdateOrderItem({
                    code: orderDetail?.code || "",
                    body: {
                        orderItems: dataNeedChange,
                        orderCombos: [],
                    },
                })
            ).then((result) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(orderDetail?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    const columns: ColumnsType<any> = [
        {
            title: trans("sidebar.products"),
            dataIndex: "product",
            key: "product",
            width: "24%",
            render: (product: IProduct, record: ItemOrder) => (
                <div>
                    <p className="m-0">{product?.name}</p>
                    <p className="m-0">{record?.variant ? Object.values(record?.variant)?.join("/") : "---"}</p>
                    <p className="m-0">{record?.sku}</p>
                </div>
            ),
        },
        {
            title: () => <p className="m-0">{trans("order_detail.quantity")}</p>,
            dataIndex: "quantity",
            key: "quantity",
            width: "25%",
            render: (quantity: number, record: ItemOrder, index: number) => (
                <>
                    <InputNumber
                        min={0}
                        width="w-full"
                        value={dataNeedChange[index]?.quantity}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        disabled={
                            !STATUS_CAN_UPDATE.includes(orderDetail?.status || "") ||
                            !SecurityService.can(ORDER_SCOPE.ORDER_ITEM_UPDATE) ||
                            loadingUpdateOrderItem
                        }
                        onChange={(e) => {
                            const newChangeArr: ItemOrderRequest[] = dataNeedChange?.slice()
                            newChangeArr[index] = {
                                ...dataNeedChange[index],
                                quantity: Number(e),
                            }
                            dispatch(changeDataUpdateOrderItem(newChangeArr))
                        }}
                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                        onPressEnter={() => onChangeDetailOrder(index)}
                    />
                    <Typography.Text type="danger">{!dataNeedChange[index]?.quantity  && record?.type !== 'gift' && trans("message.required")}</Typography.Text>
                </>
            ),
        },
        {
            title: () => (
                <>
                    <p className="m-0">{trans("order_detail.price")}</p>
                    {/* {orderDetail?.country?.currencyUnit && <p className="m-0">({orderDetail?.country?.currencyUnit || ""})</p>} */}
                    {orderDetail?.country?.currencyUnit && <p className="m-0">{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code || ""})`}</p>}
                </>
            ),
            dataIndex: "price",
            key: "price",
            width: "25%",
            render: (price: number, record: ItemOrder, index: number) => (
                <>
                    <InputNumber
                        min={0}
                        width="w-full"
                        value={dataNeedChange[index]?.price}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        disabled={
                            !STATUS_CAN_UPDATE.includes(orderDetail?.status || "") ||
                            !SecurityService.can(ORDER_SCOPE.ORDER_ITEM_UPDATE) ||
                            loadingUpdateOrderItem
                        }
                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                        onChange={(e) => {
                            const newChangeArr: ItemOrderRequest[] = dataNeedChange?.slice() || []
                            newChangeArr[index] = {
                                ...dataNeedChange[index],
                                price: Number(e),
                            }
                            dispatch(changeDataUpdateOrderItem(newChangeArr))
                        }}
                        onPressEnter={() => onChangeDetailOrder(index)}
                    />
                    <Typography.Text type="danger">{!dataNeedChange[index]?.price && record?.type !== 'gift' && trans("message.required")}</Typography.Text>
                </>
            ),
        },
        {
            title: () => (
                <>
                    <p className="m-0">{trans("order_detail.total")}</p>
                    {/* {orderDetail?.country?.currencyUnit && <p className="m-0">({orderDetail?.country?.currencyUnit || ""})</p>} */}
                    {orderDetail?.country?.currencyUnit && <p className="m-0">{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code || ""})`}</p>}
                </>
            ),
            key: "totalAmount",
            width: "25%",
            render: (totalAmount: number, record: ItemOrder, index: number) => {
                let price = Number(dataNeedChange[index]?.price) * Number(dataNeedChange[index]?.quantity)
                price = +price.toFixed(2)
                return dataNeedChange[index]?.price && dataNeedChange[index]?.quantity ? convertNumberToCurrency(price) : "---"
            },
        },
        {
            title: () => (
                <Button
                    type={"link"}
                    icon={
                        <i
                            className="fa fa-arrows-alt"
                            aria-hidden="true"
                        />
                    }
                    onClick={props.toggleFullSizeOrderItem}
                />
            ),
            dataIndex: "action",
            key: "action",
            width: 60,
        },
    ]
    const renderFooter = () => {
        return (
            <Row
                justify="end"
                gutter={[0, 15]}>
                <Col
                    span={12}
                    offset={12}
                    className="flex">
                    <Typography.Text className="w-80 mr-1 font-medium">
                        {trans("order_detail.total_amount")}
                        {/* {orderDetail?.country?.currencyUnit && <span>({orderDetail?.country?.currencyUnit || ""})</span>} */}
                        {orderDetail?.country?.currencyUnit && <span>{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code || ""})`}</span>}
                    </Typography.Text>
                    <Typography.Text className="w-full">{orderDetail?.totalAmount ? convertNumberToCurrency(orderDetail?.totalAmount) : "---"}</Typography.Text>
                </Col>
                <Col
                    span={12}
                    offset={12}
                    className="flex">
                    <Typography.Text className="w-80 mr-1 font-medium">
                        {trans("order.delivery_fee")}
                        {/* {orderDetail?.country?.currencyUnit && <span>({orderDetail?.country?.currencyUnit || ""})</span>} */}
                        {orderDetail?.country?.currencyUnit && <span>{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code || ""})`}</span>}
                    </Typography.Text>
                    <InputNumber
                        min={0}
                        width="w-full"
                        value={dataUpdateOrder?.deliveryFee}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) => {
                            dispatch(
                                changeDataOrderUpdate({
                                    deliveryFee: Number(e),
                                })
                            )
                        }}
                        onPressEnter={() => onUpdateOrder("deliveryFee", "")}
                        disabled={
                            !STATUS_CAN_UPDATE.includes(orderDetail?.status || "") ||
                            !SecurityService.can(ORDER_SCOPE.ORDER_DELIVERY_FEE_UPDATE) ||
                            loadingUpdateOrder
                        }
                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                    />
                </Col>
                <Col
                    span={12}
                    offset={12}
                    className="flex">
                    <Typography.Text className="w-80 mr-1 font-medium">
                        {trans("order_detail.total_payment")}
                        {/* {orderDetail?.country?.currencyUnit && <span>({orderDetail?.country?.currencyUnit || ""})</span>} */}
                        {orderDetail?.country?.currencyUnit && <span>{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code || ""})`}</span>}
                    </Typography.Text>
                    <InputNumber
                        min={0}
                        width="w-full"
                        value={dataUpdateOrder?.totalPayment}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                        onChange={(e) => {
                            dispatch(
                                changeDataOrderUpdate({
                                    totalPayment: Number(e),
                                })
                            )
                        }}
                        onPressEnter={() => onUpdateOrder("totalPayment", "")}
                        disabled={
                            !STATUS_CAN_UPDATE.includes(orderDetail?.status || "") ||
                            !SecurityService.can(ORDER_SCOPE.ORDER_TOTAL_PAYMENT_UPDATE) ||
                            loadingUpdateOrder
                        }
                    />
                </Col>
            </Row>
        )
    }
    return (
        <Card title={trans("order.product_detail")}>
            <Table
                columns={columns}
                dataSource={orderDetail?.items || []}
                rowKey={"id"}
                loading={loadingUpdateOrderItem}
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
                footer={() => renderFooter()}
            />
        </Card>
    )
}
