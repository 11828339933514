import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import { Form } from "antd"
import React, { useEffect } from "react"
import { GeneralShipment } from "./GeneralShipment"
import { PackageDraft } from "./PackageDraft"
import { callGetPurchasingAccountM28 } from "@reducer/version2/inbound-shipment.reducer"
import { callGetWareHouses } from "@reducer/version2/package.reducer"
export const CreateShipment: React.FC = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("package.shipment_title")))
        dispatch(callGetPurchasingAccountM28())
        dispatch(callGetWareHouses())
    }, [dispatch])
    const [formRef] = Form.useForm()
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    ...HOME_BREADCRUMB,
                    {
                        name: "package.title",
                        link: "/package",
                    },
                    {
                        name: "package.shipment_title",
                        link: "",
                    },
                ]}
            />
            <Form layout="vertical" form={formRef}>
              <GeneralShipment />
              <PackageDraft />
            </Form>
        </DefaultLayout>
    )
}
