import { IAds, IAdsQuery, IAdsRequest, ILanding, ILandingWeb } from "@domain/version2/Ads"
import { IAdsDetail } from "@domain/version2/AdsDetail"
import apiClient from "@util/ApiClient"
import { IAdSku } from "@domain/version2/AdSku"
import { IAddSkuRequest } from "@request/ad/add-sku.request"
import { IUpdateSkuRequest } from "@request/ad/update-sku.request"

export default class AdsApi {
    static getListAds(params: IAdsQuery): Promise<{ data: IAds[]; headers: { [key: string]: any } }> {
        return apiClient.get("ads", { params })
    }

    static createAds(body: IAdsRequest): Promise<any> {
        return apiClient.post("ads", body)
    }

    static getDetailAds(adsCode: string): Promise<{ data: IAdsDetail }> {
        return apiClient.get(`ads/${adsCode}`)
    }
    static updateAds(code: string, body: IAdsRequest): Promise<any> {
        return apiClient.patch(`ads/${code}`, body)
    }
    static createAndUpdateLanding(code: string, body: { urls: string[] }): Promise<any> {
        return apiClient.patch(`ads/${code}/landings`, body)
    }
    static getLandingUrl(adsCode: string): Promise<{ data: ILanding[] }> {
        return apiClient.get(`ads/${adsCode}/landings`)
    }
    static getWebhook(adsCode: string): Promise<{ data: ILandingWeb }> {
        return apiClient.get(`ads/${adsCode}/webhook`)
    }
    static getSkus(adCode: string): any {
        return apiClient.get(`ads/${adCode}/skus`)
    }

    static addSku(adCode: string, payload: IAddSkuRequest): Promise<IAdSku> {
        return apiClient.post(`ads/${adCode}/skus`, payload)
    }

    static updateSku(adCode: string, sku: string, payload: IUpdateSkuRequest): Promise<IAdSku> {
        return apiClient.patch(`ads/${adCode}/skus/${sku}`, payload)
    }

    static removeSku(adCode: string, sku: string): Promise<IAdSku> {
        return apiClient.delete(`ads/${adCode}/skus/${sku}`)
    }
}
