import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Card, Col, Row, Tag, Timeline } from "antd"
import React from "react"
import { Link } from "react-router-dom"

export const Event: React.FC = () => {
    const contactDetail = useAppSelector((state) => state.contactDetail2.dataDetail)
    const listContactEventStatuses = contactDetail?.contactEvent
    const contactEventStatus = useAppSelector((state) => state.categoriesVersion2.listContactEventStatuses)
    const newListContactEventStatuses = listContactEventStatuses
        ? listContactEventStatuses
              .map((el) => {
                  for (let i = 0; i < contactEventStatus.length; i++)
                      if (el.status === contactEventStatus[i].code) return { ...el, bgColor: contactEventStatus[i].bgColor, name: contactEventStatus[i].name }
              })
              .reverse()
        : []

    return (
        <Card title={trans("contact-detail.activity")}>
            <Timeline>
                {newListContactEventStatuses?.map((item: any, index: any) => (
                    <Row
                        gutter={12}
                        key={index}>
                        <Col span={8}>
                            <div className="text-black">{formatDateFull(item.createdAt || "")}</div>
                        </Col>
                        <Col span={16}>
                            <Timeline.Item>
                                <Tag color={item?.bgColor}>{item?.name}</Tag>
                                <div className="text-stone-800">
                                    {item?.ad ? `${trans("contact.ads")}:` : ""} {item?.ad}
                                </div>
                                {item?.order && (
                                    <Link
                                        className="block"
                                        to={`/order/${item?.order}`}>
                                        {item?.order}
                                    </Link>
                                )}
                                {item?.orderNote ||
                                    (!item?.reason && item?.note && <div className="italic text-gray-400">{item?.orderNote || item?.note}</div>)}
                                {item.scheduleDate && (
                                    <div className="text-gray-400">
                                        {trans("contact-detail.appointment")}: {formatDateFull(item.scheduleDate || "")}
                                    </div>
                                )}
                                <div className="italic text-gray-400">
                                    {item?.reason ? trans(`order_status_cancelled.${item?.reason}`) : ""}
                                    {item?.reason && item?.note && <span>: {item?.note}</span>}
                                </div>
                            </Timeline.Item>
                        </Col>
                    </Row>
                ))}
            </Timeline>
        </Card>
    )
}
