import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import { IUser, IUserQuery, IUserRequest } from "@domain/version2/User"
import UserApi from "@api/version2/UserApi"
import { PAGINATION } from "@config/constant"

interface State {
    listSuggestUsers: IUser[]
    users: IUser[]
    loadingUser: boolean
    pagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    allUserSuggest: IUser[]
    loadingCreate: boolean
}

const initState: State = {
    listSuggestUsers: [],
    users: [],
    loadingUser: false,
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    allUserSuggest: [],
    loadingCreate: false
}

export const callSuggestUser = createAsyncThunk("USER.SUGGEST_USER", async (param: { keyword: string }, thunkApi) => {
    try {
        return await UserApi.getSuggestUser(param)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetUsers = createAsyncThunk("USER.GET_USERS", async (param: IUserQuery, thunkApi) => {
    try {
        return await UserApi.getUsers(param)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetAllUsers = createAsyncThunk("USER.GET_ALL_USERS", async (param: {keyword: string}, thunkApi) => {
    try {
        return await UserApi.getAllUsers(param)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreateUser = createAsyncThunk("USER.CREATE_USER", async (body: IUserRequest, thunkApi) => {
    try {
        return await UserApi.createUser(body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const resetListSuggestUser = createAction<any>("USER.RESET_SUGGEST_USER")

export const userReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callSuggestUser.pending, (state) => {
            state.listSuggestUsers = []
        })
        .addCase(callSuggestUser.fulfilled, (state, { payload }) => {
            state.listSuggestUsers = payload.data ? payload.data : []
        })
        .addCase(callSuggestUser.rejected, (state) => {
            state.listSuggestUsers = []
        })
    builder.addCase(resetListSuggestUser, (state, action: PayloadAction<any>) => {
            state.listSuggestUsers = action.payload
        })
    builder
        .addCase(callGetUsers.pending, (state) => {
            state.loadingUser = true
            state.users = []
        })
        .addCase(callGetUsers.fulfilled, (state, { payload }) => {
            state.users = payload.data ? payload.data : []
            state.pagination.currentPage = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingUser = false
        })
        .addCase(callGetUsers.rejected, (state) => {
            state.loadingUser = false
            state.users = []
        })
    builder
        .addCase(callGetAllUsers.pending, (state) => {
            state.allUserSuggest = []
        })
        .addCase(callGetAllUsers.fulfilled, (state, { payload }) => {
            state.allUserSuggest = payload.data ? payload.data : []
        })
        .addCase(callGetAllUsers.rejected, (state) => {
            state.allUserSuggest = []
        })
    builder
        .addCase(callCreateUser.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreateUser.fulfilled, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreateUser.rejected, (state) => {
            state.loadingCreate = false
        })
    
})
