import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB, PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetListPaymentStatement, callGetListPaymentStatementStatuses } from "@reducer/version2/paymentStatement.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import CreatePaymentStatement from "./Create"
import { Filter } from "./Filter"
import { ListPaymentStatement } from "./Table"

export const PaymentStatement: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const vendorCode = SecurityService.getVendorCode()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.payment_statement")))
        dispatch(callGetListPaymentStatementStatuses())
    }, [dispatch])

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        dispatch(
            callGetListPaymentStatement({
                ...params,
                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                vendorCode,
            })
        )
    }, [dispatch, searchParams])

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    ...HOME_BREADCRUMB,
                    {
                        name: "payment_statement.title",
                        link: "/payment-statement",
                    },
                ]}
            />
            <Filter />
            <ListPaymentStatement />
            <CreatePaymentStatement />
        </DefaultLayout>
    )
}
