import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Card, Col, Row } from "antd"
import { first } from "lodash"
import React from "react"
import Service from "./Service"

const Detail = () => {
    const inventoryDetail = useAppSelector((state) => state.inventory.detailInventory)
    const loadingDetail = useAppSelector((state) => state.inventory.loadingDetail)
    const services = [
        { title: trans("inventory.warehouse_import_service"), data: inventoryDetail?.importServices || [] },
        { title: trans("inventory.warehousing_service"), data: inventoryDetail?.storageServices || [] },
        { title: trans("inventory.warehouse_export_service"), data: inventoryDetail?.exportServices || [] },
        { title: trans("inventory.returning_warehouse_import_service"), data: inventoryDetail?.returnServices || [] },
    ]

    return (
        <>
            <Card
                className="space-layout"
                loading={loadingDetail}
                title={trans("inventory.product_sku_detail")}>
                <Row gutter={12}>
                    <Col span={6}>
                        <p className="font-medium">{trans("inventory.product_sku_code")}</p>
                    </Col>
                    <Col span={18}>{inventoryDetail?.product?.code || ""}</Col>
                    <Col span={6}>
                        <p className="font-medium">{trans("inventory.product_sku_name")}</p>
                    </Col>
                    <Col span={18}>{inventoryDetail?.product?.name || ""}</Col>
                    <Col span={6}>
                        <p className="font-medium">{trans("inventory.product_category")}</p>
                    </Col>
                    <Col span={18}>{inventoryDetail?.categories?.name}</Col>
                    <Col span={6}>
                        <p className="font-medium">{trans("inventory.safety_stock_level")}</p>
                    </Col>
                    <Col span={18}>{first(inventoryDetail?.product?.skus)?.safetyStock || ""}</Col>
                </Row>
            </Card>

            <Card
                className="space-layout"
                loading={loadingDetail}
                title={trans("inventory.transport_services")}>
                <Row gutter={12}>
                    <Col span={6}>
                        <p className="font-medium">
                            {trans("inventory.service")}: <span className="font-normal">{first(inventoryDetail?.transportServices)?.name || ""}</span>
                        </p>
                    </Col>
                    <Col span={24}>
                        <p className="font-medium">
                            {trans("inventory.gross_weight")} (g):{" "}
                            {first(inventoryDetail?.product?.skus)?.weight ? (inventoryDetail.product.skus[0].weight * 1000).toString() : ""}
                        </p>
                    </Col>
                    <Col span={3}>
                        <p className="font-medium">{trans("inventory.dimension")} (mm):</p>
                    </Col>
                    <Col span={7}>
                        <p className="font-medium">
                            {trans("inventory.length")} (mm):{" "}
                            {first(inventoryDetail?.product?.skus)?.length ? (inventoryDetail.product.skus[0].length * 1000).toString() : ""}
                        </p>
                    </Col>
                    <Col span={7}>
                        <p className="font-medium">
                            {trans("inventory.width")} (mm):{" "}
                            {first(inventoryDetail?.product?.skus)?.width ? (inventoryDetail.product.skus[0].width * 1000).toString() : ""}
                        </p>
                    </Col>
                    <Col span={7}>
                        <p className="font-medium">
                            {trans("inventory.height")} (mm):{" "}
                            {first(inventoryDetail?.product?.skus)?.height ? (inventoryDetail.product.skus[0].height * 1000).toString() : ""}
                        </p>
                    </Col>
                </Row>
            </Card>
            {services.map((service, index) => (
                <Service
                    key={index}
                    title={service.title}
                    data={service.data}
                />
            ))}
        </>
    )
}

export default Detail
