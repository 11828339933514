import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB, PAGINATION } from "@config/constant"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { changePageTitleAction } from "@reducer/common.reducer"
import { useAppDispatch } from "@hook/useAppDispatch"
import { trans } from "@resources/localization"
import { TablePurchasingAccount } from "./Table" 
import { AddPurchasingAccount } from "./Create"
import { callListPurchasingAccount, callGetServiceSuggest } from  "@reducer/version2/purchasing-account"

export const PurchasingAccount: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("purchasing_account.title")))
        dispatch(callGetServiceSuggest())
    }, [dispatch])
    useEffect(() => {
        dispatch(
            callListPurchasingAccount({
                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
            })
        )
    }, [dispatch, searchParams])

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    ...HOME_BREADCRUMB,
                    {
                        name: "purchasing_account.title",
                        link: "/purchasing-account",
                    },
                ]}
            />
            <TablePurchasingAccount />
            <AddPurchasingAccount />
        </DefaultLayout>
    )
}
