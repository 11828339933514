import { trans } from "@resources/localization"
import { Button, Card, Col, Divider, Empty, Form, InputNumber, Popover, Row, Typography } from "antd"
import React, { useState } from "react"
import { AddSku } from "./AddSku"
type Props = {
    formRef: any
}
export const CreateProducts: React.FC<Props> = ({ formRef }) => {
    const [open, setOpen] = useState<boolean>(false)

    const listHeader = [
        { title: trans("products.variant"), span: 7 },
        { title: trans("ads.sku"), span: 7 },
        { title: `${trans("ads.quantity")}`, span: 7 },
        { title: trans("table.action"), span: 3 },
    ]

    return (
        <Card
            className="space-layout"
            title={`${trans("ads.sku")}`}
            extra={
                <Popover
                    content={
                        <AddSku
                            formRef={formRef}
                            setClose={() => {
                                setOpen(false)
                            }}
                        />
                    }
                    title={trans("skuCombo.new_sku")}
                    placement="leftTop"
                    trigger="click"
                    open={open}
                    onOpenChange={() => setOpen((old) => !old)}>
                    <Button type="primary">{trans("skuCombo.new_sku")}</Button>
                </Popover>
            }>
            <div className="">
                <Row
                    gutter={15}
                    className="bg-gray-200 p-6 border-b-black">
                    {listHeader.map((item: { title: string; span: number }, index: number) => (
                        <Col
                            span={item.span}
                            key={index}>
                            <Typography.Text strong>{item.title}</Typography.Text>
                        </Col>
                    ))}
                </Row>

                <div className="">
                    <Form.List name="tableProducts">
                        {(fields, { remove }) =>
                            fields.length > 0 ? (
                                <div>
                                    {fields.map(({ name, ...restField }) => (
                                        <Row
                                            className={`border pt-6 pr-3 ${name !== fields.length - 1 ? "border-b-0" : ""}`}
                                            key={name}
                                            gutter={15}>
                                            <Col span={7}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.tableProducts !== next.tableProducts}>
                                                    {({ getFieldValue }) => (
                                                        <div>
                                                            <p className="m-0">
                                                                {`${getFieldValue(["tableProducts", name, "product"])} -
                                                                ${getFieldValue(["tableProducts", name, "variant"])}`}
                                                            </p>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={7}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.tableProducts !== next.tableProducts}>
                                                    {({ getFieldValue }) => (
                                                        <div>
                                                            <p className="m-0">{getFieldValue(["tableProducts", name, "sku"])}</p>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={7}>
                                                <Form.Item noStyle>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "quantity"]}
                                                        rules={[
                                                            { required: true, message: trans("message.required") },
                                                            {
                                                                validator(_, value) {
                                                                    if (Number(value) > 0) {
                                                                        return Promise.resolve()
                                                                    }
                                                                    return Promise.reject(new Error(trans("order.quantity_gt_0")))
                                                                },
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            step="1"
                                                            placeholder={trans("contact-detail.price")}
                                                            parser={(value: any) =>
                                                                value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(0) : 0
                                                            }
                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Button
                                                    type="link"
                                                    icon={<i className="fa-light fa-trash"></i>}
                                                    onClick={() => remove(name)}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            ) : (
                                <div className="mt-3">
                                    <Empty />
                                    <Divider />
                                </div>
                            )
                        }
                    </Form.List>
                </div>
            </div>
        </Card>
    )
}
