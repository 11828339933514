import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import IDetailVendor, { IVendorConfigs } from "@domain/version2/DetailVendor"
import VendorApi from "@api/version2/vendorApi"

interface State {
    detailVendor: IDetailVendor
    loadingDetailVendor: boolean
    vendorConfigs: IVendorConfigs
}

const initState: State = {
    detailVendor: {},
    loadingDetailVendor: false,
    vendorConfigs: {} as IVendorConfigs,
}

export const callVendorDetail = createAsyncThunk("VENDOR_DETAIL", async (_, thunkApi) => {
    try {
        return await VendorApi.getVendorDetail()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const getVendorConfigs = createAsyncThunk("VENDOR_CONFIGS", async (_, thunkApi) => {
    try {
        return await VendorApi.getVendorConfig()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const vendorDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callVendorDetail.pending, (state) => {
            state.loadingDetailVendor = true
            state.detailVendor = {}
        })
        .addCase(callVendorDetail.fulfilled, (state, { payload }) => {
            state.detailVendor = payload.data ? payload.data : {}
            state.loadingDetailVendor = false
        })
        .addCase(callVendorDetail.rejected, (state) => {
            state.loadingDetailVendor = false
        })

    builder
        .addCase(getVendorConfigs.pending, (state) => {
            state.vendorConfigs = {} as IVendorConfigs
        })
        .addCase(getVendorConfigs.fulfilled, (state, { payload }) => {
            state.vendorConfigs = payload.data ? payload.data : ({} as IVendorConfigs)
        })
        .addCase(getVendorConfigs.rejected, (state) => {
            state.vendorConfigs = {} as IVendorConfigs
        })
})
