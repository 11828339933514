import React, { useMemo, useState } from "react"
import { AutoComplete, Button, Empty, Form, InputNumber, Spin } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { filter, find, isEmpty } from "lodash"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetProductsByVariants } from "@reducer/version2/product.reducer"
import { IProductVariants } from "@domain/version2/Product"
type Props = {
    parentRestField: any
    parentName: number
    formRef: any
    errors: any
}
export const ListSkuDraft: React.FC<Props> = ({ parentRestField, parentName, formRef, errors }) => {
    const dispatch = useAppDispatch()
    const loadingProduct = useAppSelector((state) => state.productVersion2.loadingListProducts)
    const [currentIndex, setCurrentIndex] = useState<any>()

    const renderTitle = (item: IProductVariants) => (
        <div>
            <p className="m-0">{item?.sku}</p>
            <p className="text-xs m-0">
                ({item?.product?.name} - {!isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : ""})
            </p>
        </div>
    )
    const itemErrors = useMemo(
        () => (!isEmpty(errors) ? filter(errors, (iFil: any) => iFil?.field === "items")?.map((item: any) => item?.error) : []),
        [errors]
    )

    const handleSearchProducts = (searchText: string) => {
        if (searchText.trim().length >= 3) {
            dispatch(callGetProductsByVariants({ keyword: searchText })).then((result: any) => {
                const oldOrderItems = formRef.getFieldValue(["dataImports", parentName, "orderItems"])
                if (!isEmpty(result?.payload?.data)) {
                    oldOrderItems[currentIndex] = {
                        ...oldOrderItems[currentIndex],
                        listSkus:
                            result?.payload?.data?.map((item: IProductVariants) => ({
                                value: item?.sku,
                                label: renderTitle(item),
                                key: item?.sku,
                                product: item?.product?.name,
                                variant: !isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : "",
                            })) || [],
                    }
                } else {
                    oldOrderItems[currentIndex] = {
                        ...oldOrderItems[currentIndex],
                        listSkus: [],
                    }
                }
                formRef.setFieldValue(["dataImports", parentName, "orderItems"], oldOrderItems)
            })
        } else if (searchText.trim() === "") {
            dispatch(callGetProductsByVariants({ keyword: "" })).then((result: any) => {
                if (!isEmpty(result?.payload?.data)) {
                    const oldOrderItems = formRef.getFieldValue(["dataImports", parentName, "orderItems"])
                    console.log('oldOrderItems', oldOrderItems)
                    console.log('currentIndex', currentIndex)
                    oldOrderItems[currentIndex] = {
                        ...oldOrderItems[currentIndex],
                        listSkus:
                            result?.payload?.data?.map((item: IProductVariants) => ({
                                value: item?.sku,
                                label: renderTitle(item),
                                key: item?.sku,
                                product: item?.product?.name,
                                variant: !isEmpty(item?.variant) ? Object?.values(item?.variant)?.join("/") : "",
                            })) || [],
                    }
                    formRef.setFieldValue(["dataImports", parentName, "orderItems"], oldOrderItems)
                }
            })
        }
    }
    console.log('current', currentIndex)
    return (
        <Form.List
            {...parentRestField}
            name={[parentName, "orderItems"]}>
            {(fields, { remove, add }) => (
                <div>
                    {fields?.map(({ name, ...restField }) => (
                        <div
                            className="pb-1 pt-0 flex"
                            key={name}>
                            <div className="min-w-[250px] mr-2">
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(pre, next) =>
                                        pre["dataImports"][parentName]?.orderItems[name]?.listSkus !==
                                            next["dataImports"][parentName]?.orderItems[name]?.listSkus ||
                                        pre["dataImports"][parentName]?.orderItems !== next["dataImports"][parentName]?.orderItems
                                    }>
                                    {({ getFieldValue }) => {
                                        const currentList = getFieldValue(["dataImports", parentName, "orderItems", name, "listSkus"])
                                        return (
                                            <Form.Item
                                                {...restField}
                                                name={[name, "sku"]}
                                                rules={[
                                                    { required: true, message: trans("message.required") },
                                                    {
                                                        validator(_, value) {
                                                            const itemError: any = find(itemErrors, { item: value }) || {}
                                                            if (isEmpty(itemError)) {
                                                                return Promise.resolve()
                                                            }
                                                            return Promise.reject(new Error(`${itemError?.item || ""} ${itemError?.error || ""}`))
                                                        },
                                                    },
                                                ]}
                                                className="w-full">
                                                <AutoComplete
                                                    allowClear
                                                    key={"value"}
                                                    notFoundContent={loadingProduct && currentIndex === name ? <Spin /> : <Empty />}
                                                    onSearch={(e) => {
                                                        setCurrentIndex(name)
                                                        handleSearchProducts(e)
                                                    }}
                                                    placeholder={trans("products.search_name_code_sku_pl")}
                                                    options={currentList}
                                                    onSelect={() => {
                                                        const oldOrderItems = formRef.getFieldValue("dataImports")
                                                        oldOrderItems[parentName] = {
                                                            ...oldOrderItems[parentName],
                                                            errors: [...filter(errors, (iFil: any) => iFil.field === "items"), ...itemErrors.splice(name, 1)],
                                                        }
                                                        formRef.setFieldsValue({
                                                            dataImports: oldOrderItems,
                                                        })
                                                    }}
                                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                />
                                            </Form.Item>
                                        )
                                    }}
                                </Form.Item>
                            </div>
                            <div className="min-w-[100px] mr-2">
                                <Form.Item
                                    {...restField}
                                    name={[name, "quantity"]}
                                    rules={[{ required: true, message: trans("message.required") }]}>
                                    <InputNumber
                                        step="1"
                                        min={1}
                                        placeholder={trans("contact-detail.quantity")}
                                        parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                    />
                                </Form.Item>
                            </div>
                            <div className="min-w-[120px] mr-2">
                                <Form.Item
                                    {...restField}
                                    name={[name, "price"]}
                                    rules={[{ required: true, message: trans("message.required") }]}>
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        step="0.01"
                                        placeholder={trans("contact-detail.price")}
                                        parser={(value: any) =>
                                            value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0
                                        }
                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                    />
                                </Form.Item>
                            </div>
                            <div className="min-w-[100px] flex">
                                {name !== 0 && (
                                    <Button
                                        type="link"
                                        className="mr-2"
                                        icon={<i className="fa-regular fa-trash"></i>}
                                        onClick={() => remove(name)}
                                    />
                                )}
                                {name === fields.length - 1 && (
                                    <Button
                                        type="link"
                                        icon={<i className="fa-regular fa-circle-plus"></i>}
                                        onClick={() => add()}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Form.List>
    )
}
