import { ISkuCombo, ISkuComboQuery, ISkuComboRequest, IUpdateSkuComboRequest } from "@domain/version2/SkuCombo"
import apiClient from "@util/ApiClient"
import { ISkuComboAutocomplete, ISkuComboDetail } from "@domain/version2/SkuCombo"
import { AxiosResponse } from "axios"
export default class SkuComboApi {
    static getListSkuCombo(params: ISkuComboQuery): Promise<{ data: ISkuCombo[]; headers: { [key: string]: any } }> {
        return apiClient.get(`combos`, { params })
    }
    static getSkuComboAutocomplete(params: { keyword: string }): Promise<AxiosResponse<ISkuComboAutocomplete[]>> {
        return apiClient.get(`combos/autocomplete`, {
            params,
        })
    }
    static getDetailSkuCombo(code: string): Promise<AxiosResponse<ISkuComboDetail>> {
        return apiClient.get(`combos/${code}`)
    }
    static createSkuCombo(body: ISkuComboRequest): Promise<any> {
        return apiClient.post(`combos`, body)
    }
    static updateSkuCombo(comboCode: string, body: IUpdateSkuComboRequest): Promise<any> {
        return apiClient.patch(`combos/${comboCode}`, body)
    }
    static deleteSkuCombo(comboCode: string, skuCode: string): Promise<any> {
        return apiClient.delete(`combos/${comboCode}/skus/${skuCode}`)
    }
}
