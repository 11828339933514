import { createReducer, createAction, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import PurchasingAccountApi from '@api/version2/PurchasingAccountApi';
import { IPurchasingAccount, IServiceSuggest, IPurchasingAccountCreateRequest } from "@domain/version2/PurchasingAccount"

interface State {
    pagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    listPurchasingAccount: IPurchasingAccount[]
    loadingList: boolean
    isShowAdd: boolean
    loadingCreate: boolean
    loadingDelete: boolean
    serviceSuggest: IServiceSuggest[]
}
const initState: State = {
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listPurchasingAccount: [],
    loadingList: false,
    isShowAdd: false,
    loadingCreate: false,
    loadingDelete: false,
    serviceSuggest: [],
}

export const changeVisibleAddPurchasingAccount = createAction<boolean>("PURCHASING_ACCOUNT.CHANGE_VISIBLE_ADD")

export const callListPurchasingAccount = createAsyncThunk("PURCHASING_ACCOUNT.GET_LIST", async (params: { size: number, page: number }, thunkApi) => {
    try {
        return await PurchasingAccountApi.getPurchasingAccounts(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetServiceSuggest = createAsyncThunk("PURCHASING_ACCOUNT.GET_SERVICE", async (_, thunkApi) => {
    try {
        return await PurchasingAccountApi.getServicesSuggest()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreatePurchasingAccount = createAsyncThunk("PURCHASING_ACCOUNT.CREATE", async (body: IPurchasingAccountCreateRequest, thunkApi) => {
    try {
        return await PurchasingAccountApi.createPurchasingAccount(body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callDeletePurchasingAccount = createAsyncThunk("PURCHASING_ACCOUNT.DELETE", async (payload: { id: number }, thunkApi) => {
    try {
        return await PurchasingAccountApi.deletePurchasingAccount(payload.id)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})


export const purchasingAccountReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callListPurchasingAccount.pending, (state) => {
            state.loadingList = true
            state.listPurchasingAccount = []
        })
        .addCase(callListPurchasingAccount.fulfilled, (state, { payload }) => {
            state.loadingList = false
            state.listPurchasingAccount = payload?.data || []
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callListPurchasingAccount.rejected, (state) => {
            state.loadingList = false
            state.listPurchasingAccount = []
        })
    builder
        .addCase(changeVisibleAddPurchasingAccount, (state, action: PayloadAction<boolean>) => {
            state.isShowAdd = action.payload
        })
    builder
        .addCase(callGetServiceSuggest.pending, (state) => {
            state.serviceSuggest = []
        })
        .addCase(callGetServiceSuggest.fulfilled, (state, { payload }) => {
            state.serviceSuggest = payload.data ? payload.data : []
        })
        .addCase(callGetServiceSuggest.rejected, (state) => {
            state.serviceSuggest = []
        })
    builder
        .addCase(callCreatePurchasingAccount.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreatePurchasingAccount.fulfilled, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreatePurchasingAccount.rejected, (state) => {
            state.loadingCreate = false
        })
    builder
        .addCase(callDeletePurchasingAccount.pending, (state) => {
            state.loadingDelete = true
        })
        .addCase(callDeletePurchasingAccount.fulfilled, (state) => {
            state.loadingDelete = false
        })
        .addCase(callDeletePurchasingAccount.rejected, (state) => {
            state.loadingDelete = false
        })
})
