import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import { IUser, IUserRequest } from "@domain/version1/User"
import UserApi from "@api/version1/UserApi"

interface State {
    userPagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    isShowAddUser: boolean
    loadingListUsers: boolean
    listUsers: IUser[]
    loadingCreateUser: boolean
}

const initState: State = {
    userPagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    isShowAddUser: false,
    loadingListUsers: false,
    listUsers: [],
    loadingCreateUser: false,
}
export const changeAddUserVisible = createAction<boolean>("VENDOR.CHANGE_ADD_USER_VISIBLE")

export const callListUsers = createAsyncThunk(
    "USER.LIST_USERS",
    async (payload: { params: { username?: string; page?: number; size?: number; Created_at?: string } }, thunkApi) => {
        try {
            return await UserApi.getListUsers(payload.params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callCreateUser = createAsyncThunk("USER.CREATE_USER", async (payload: IUserRequest, thunkApi) => {
    try {
        return await UserApi.createUser(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const userReducer = createReducer(initState, (builder) => {
    builder.addCase(changeAddUserVisible, (state, action: PayloadAction<boolean>) => {
        state.isShowAddUser = action.payload
    })
    builder
        .addCase(callListUsers.pending, (state) => {
            state.loadingListUsers = true
            state.listUsers = []
        })
        .addCase(callListUsers.fulfilled, (state, { payload }) => {
            state.listUsers = payload.data ? payload.data : []
            state.userPagination.currentPage = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.userPagination.size = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.userPagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.userPagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingListUsers = false
        })
        .addCase(callListUsers.rejected, (state) => {
            state.loadingListUsers = false
        })
    builder
        .addCase(callCreateUser.pending, (state) => {
            state.loadingCreateUser = true
        })
        .addCase(callCreateUser.fulfilled, (state) => {
            state.loadingCreateUser = false
        })
        .addCase(callCreateUser.rejected, (state) => {
            state.loadingCreateUser = false
        })
})
