import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Header } from "./Header"
import { ContactInfo } from "./ContactInfo"
import { Product } from "./Product"
import { Event } from "./Event"
import { Col, Row, Tabs } from "antd"
import { useParams } from "react-router-dom"
import { useAppSelector } from "@hook/useAppSelector"
import { HOME_BREADCRUMB } from "@config/constant"
import { callGetAdsOfContact, callGetContactDetail } from "@reducer/version2/contact-detail.reducer"
import IContactDetail from "@domain/version2/ContactDetail"
import { callListContactEventStatuses } from "@reducer/version2/categories.reducer"
import { getAreaSuggest, getCitySuggest, getDistrictSuggest, getProvinceSuggest } from "@reducer/version2/countries.reducer"
import { isEmpty } from "lodash"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"
import { ListComboSku } from "./ListComboSku"

export const DetailContact: React.FC = () => {
    const dispatch = useAppDispatch()
    const [statusShow, setStatusShow] = useState<number>(0)
    const id = useParams().id as string
    const loadingDetail = useAppSelector((state) => state.contactDetail2.loadingDetail)
    const contactDetail: IContactDetail = useAppSelector((state) => state.contactDetail2.dataDetail)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.contact_detail")))
        dispatch(callListContactEventStatuses())
        dispatch(callGetContactDetail(id)).then((result: any) => {
            setStatusShow(result?.payload?.response?.status)
        })
        dispatch(callGetAdsOfContact(id))
    }, [dispatch, id])

    useEffect(() => {
        if (!isEmpty(contactDetail)) {
            dispatch(getProvinceSuggest(contactDetail?.countryCode || ""))
            if (contactDetail?.provinceCode) {
                dispatch(getCitySuggest(contactDetail?.provinceCode || ""))
            }
            if (contactDetail?.cityCode) {
                dispatch(getDistrictSuggest(contactDetail?.cityCode || ""))
            }
            if (contactDetail?.districtCode) {
                dispatch(getAreaSuggest(contactDetail?.districtCode))
            }
        }
    }, [contactDetail])

    return (
        <DefaultLayout loading={loadingDetail}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            ...HOME_BREADCRUMB,
                            {
                                name: "contact.title",
                                link: "/contact",
                            },
                            {
                                name: contactDetail?.name || "",
                                link: "",
                            },
                        ]}
                    />
                    <Header />
                    <div className="space-x">
                        <Tabs
                            className="nav-space"
                            items={[
                                {
                                    label: trans("contact-detail.summary"),
                                    key: "1",
                                    children: (
                                        <Row
                                            gutter={12}
                                            className="mt-3">
                                            <Col
                                                xs={{ span: 24 }}
                                                sm={{ span: 24 }}
                                                md={{ span: 24 }}
                                                lg={{ span: 12 }}>
                                                <ContactInfo />
                                                {
                                                    contactDetail?.campaignDetail?.productCode && (
                                                        <Product />
                                                    )
                                                }
                                                {
                                                    contactDetail?.campaignDetail?.comboCode && (
                                                        <ListComboSku />
                                                    )
                                                }
                                            </Col>
                                            <Col
                                                sm={{ span: 24 }}
                                                xs={{ span: 24 }}
                                                md={{ span: 24 }}
                                                lg={{ span: 12 }}>
                                                <Event />
                                            </Col>
                                        </Row>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </>
            )}
        </DefaultLayout>
    )
}
