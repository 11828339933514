import React from "react"
import { Button, Card, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { trans } from "@resources/localization"
import { PAGINATION } from "@config/constant"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAppSelector } from "@hook/useAppSelector"
import { ICampaign } from "@domain/version2/Campaign"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { CAMPAIGN_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { resetDataProductsSuggest } from "@reducer/version2/product.reducer"

const TableCampaign = () => {
    const pageSize: number = useAppSelector((state) => state.campaignVersion2.pagination.pageSize)
    const currentPage: number = useAppSelector((state) => state.campaignVersion2.pagination.currentPage)
    const total: number = useAppSelector((state) => state.campaignVersion2.pagination.total)
    const totalPage: number = useAppSelector((state) => state.campaignVersion2.pagination.totalPage)
    const listCampaign = useAppSelector((state) => state.campaignVersion2.listCampaign)
    const loadingList = useAppSelector((state) => state.campaignVersion2.loadingList)
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        params.tab = "campaign"
        setSearchParams(params)
    }

    const columns: ColumnsType<ICampaign> = [
        {
            title: trans("campaign.code"),
            className: "bold-400",
            dataIndex: "code",
            key: "code",
            width: 100,
            render: (code: string, record: ICampaign) =>
                !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_DETAIL_ALL) && !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_DETAIL) ? (
                    code
                ) : (
                    <Link to={`/campaign/${code}?createdBy=${record?.createdBy}`}>{code}</Link>
                ),
        },
        {
            title: trans("campaign.name"),
            className: "bold-400",
            dataIndex: "name",
            key: "name",
            width: 150,
        },
        {
            title:trans("campagin.type_create"),
            className: "bold-400",
            dataIndex: 'type',
            key: 'type',
            width: 150,
            render:(type, record: ICampaign) => record?.productCode ? trans("campaign.product") : trans("skuCombo.title")
        },
        {
            title: `${trans("campaign.product")}/${trans("skuCombo.title")}`,
            className: "bold-400",
            dataIndex: 'product',
            key: 'product',
            width: 150,
            render:(product, record: ICampaign) => record?.productCode ? record?.product?.name : record?.combo?.name
        },
        {
            title: trans("contact.ads"),
            className: "bold-400",
            dataIndex: "adsCount",
            width: 60,
            align: "right",
        },
        {
            title: trans("campaign.contact"),
            className: "bold-400",
            dataIndex: "contactsCount",
            width: 60,
            align: "right",
        },
        {
            title: trans("campaign.create_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            width: 120,
            render: (date) => (date ? formatDateFull(date) : ""),
        },
        {
            title: trans("campaign.create_by"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: 100,
        },
    ]
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_CREATE) && (
                    <Button
                        type="primary"
                        onClick={() => {
                            dispatch(resetDataProductsSuggest([]))
                            navigate("/campaign/create")
                        }}>
                        {trans("campaign.new_campaign")}
                    </Button>
                )
            }>
            <Table
                rowKey={"id"}
                scroll={{ x: true }}
                loading={loadingList}
                columns={columns}
                dataSource={listCampaign}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: pageSize,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                }}
            />
        </Card>
    )
}

export default TableCampaign
