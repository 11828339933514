import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { ICampaign, IPricePolicy } from "@domain/version2/Campaign"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCreateAds } from "@reducer/version2/ads.reducer"
import { callCampaignsSuggest, resetCampaignsSuggest } from "@reducer/version2/campaign.reducer"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import { Button, Form, message, Row, Space } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { GeneralInfo } from "./General"
import { CreateProducts } from "./ListProduct"
import { CreateSkuCombos } from "./ListSkuCombo"
import { resetDataDetailSkuCombo } from "@reducer/version2/skuCombo.reducer"
export const CreateAds: React.FC = () => {
    const [currentCampaign, setCurrentCampaign] = useState<ICampaign>({})
    const loadingCreate = useAppSelector((state) => state.adsVersion2.loadingCreateAds)
    const detailSkuCombo = useAppSelector(state => state.skuCombo.skuComboDetail)
    const [formRef] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("ads.create")))
        dispatch(resetCampaignsSuggest([]))
        dispatch(
            callCampaignsSuggest({
                keyword: "",
            })
        )
    }, [dispatch])
    const onCreateAds = (values: any) => {
        let allSkus: any = []
        if(currentCampaign?.productCode){
            currentCampaign?.pricePolicies?.forEach((item: IPricePolicy, index: number) => {
                const oneSku: any = values[`pricePolicies${index + 1}`]
                const finalluOneSku = oneSku?.map((finalItem: any) => ({
                    ...finalItem,
                    sku: item?.sku,
                }))
                allSkus = [...allSkus, ...finalluOneSku]
            })
        }
        if(currentCampaign?.comboCode){
            allSkus = values?.pricePolicies?.map((element: any) => ({
                ...element,
                sku: detailSkuCombo?.code,
            }))
        }
        dispatch(
            callCreateAds({
                landingUrls: values?.landingUrls || [],
                campaignCode: values?.campaignCode,
                name: values?.name,
                refId: values?.facebookAdIds?.join(","),
                pricePolicies: allSkus,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                setTimeout(() => {
                    dispatch(resetCampaignsSuggest([]))
                    dispatch(resetDataDetailSkuCombo({}))
                    setCurrentCampaign({})
                    formRef.resetFields()
                    navigate("/campaign?tab=ads")
                }, 500)
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: "sidebar.dashboard",
                        link: "/",
                    },
                    {
                        name: "sidebar.campaign",
                        link: "/campaign",
                    },
                    {
                        name: "ads.create",
                        link: "",
                    },
                ]}
            />
            <Form
                onFinish={onCreateAds}
                form={formRef}
                initialValues={{
                    default: true,
                }}
                layout="vertical">
                <GeneralInfo
                    formRef={formRef}
                    setCurrentCampaign={setCurrentCampaign}
                />
                {currentCampaign?.comboCode && (
                    <CreateSkuCombos
                        formRef={formRef}
                        currentCampaign={currentCampaign}
                    />
                )}
                {currentCampaign?.productCode && (
                    <CreateProducts
                        formRef={formRef}
                        currentCampaign={currentCampaign}
                    />
                )}

                <div className="mt-3 mx-3">
                    <Row
                        justify="end"
                        className="mt-3">
                        <Space>
                            <Button
                                type="default"
                                loading={loadingCreate}
                                onClick={() => {
                                    dispatch(resetCampaignsSuggest([]))
                                    navigate("/campaign?tab=ads")
                                }}>
                                {trans("setting.cancel")}
                            </Button>
                            <Button
                                type="primary"
                                loading={loadingCreate}
                                htmlType="submit">
                                {trans("setting.save")}
                            </Button>
                        </Space>
                    </Row>
                </div>
            </Form>
        </DefaultLayout>
    )
}
