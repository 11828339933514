import ProductApi from "@api/version2/ProductApi"
import { PAGINATION } from "@config/constant"
import { IDetailProduct, IProduct, IProductsOfVendor, IProductVariants } from "@domain/version2/Product"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    productsSuggest: IProduct[]
    loadingProductSuggest: boolean
    detailProduct: IDetailProduct
    listProducts: IProductVariants[]
    loadingListProducts: boolean
    listProductOfVendor: IProductsOfVendor[]
    pagination: {
        currentPage: number
        pageSize: number
        total: number
        totalPage: number
    }
    loadingListProductsOfVendor: boolean
}

const initState: State = {
    productsSuggest: [],
    loadingProductSuggest: false,
    detailProduct: {},
    listProducts: [],
    loadingListProducts: false,
    listProductOfVendor: [],
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    loadingListProductsOfVendor: false,
}
export const callProductSuggest = createAsyncThunk("PRODUCT.PRODUCT_SUGGEST", async (params: { keyword: string }, thunkApi) => {
    try {
        return await ProductApi.getProductsSuggest(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callDetailProduct = createAsyncThunk("PRODUCT.PRODUCT_DETAIL", async (code: string, thunkApi) => {
    try {
        return await ProductApi.getDetailProduct(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const resetDataProductsSuggest = createAction<any>("PRODUCT.RESET_DATA_PRODUCT_SUGGEST")
export const resetDataDetail = createAction<any>("PRODUCT.RESET_DATA_DETAIL")
export const resetDataProductsByVariants = createAction<any>("PRODUCT.RESET_DATA_PRODUCT_BY_VARIANTS")

export const callGetProductsByVariants = createAsyncThunk("PRODUCT.PRODUCT_BY_VARIANT", async (params: { keyword: string }, thunkApi) => {
    try {
        return await ProductApi.getProductsByVariants(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetProductsOfVendor = createAsyncThunk("PRODUCT.PRODUCT_OF_VENDOR", async (params: any, thunkApi) => {
    try {
        return await ProductApi.getProductsOfVendor(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const productReducer = createReducer(initState, (builder) => {
    builder.addCase(resetDataProductsSuggest, (state, action: PayloadAction<any>) => {
        state.productsSuggest = action.payload
    })
    builder.addCase(resetDataDetail, (state, action: PayloadAction<any>) => {
        state.detailProduct = action.payload
    })
    builder.addCase(resetDataProductsByVariants, (state, action: PayloadAction<any>) => {
        state.listProducts = action.payload
    })
    builder
        .addCase(callProductSuggest.pending, (state) => {
            state.loadingProductSuggest = true
            state.productsSuggest = []
            
        })
        .addCase(callProductSuggest.fulfilled, (state, { payload }) => {
            state.loadingProductSuggest = false
            state.productsSuggest = payload.data
        })
        .addCase(callProductSuggest.rejected, (state) => {
            state.loadingProductSuggest = false
            state.productsSuggest = []
        })
    builder
        .addCase(callDetailProduct.pending, (state) => {
            state.detailProduct = {}
        })
        .addCase(callDetailProduct.fulfilled, (state, { payload }) => {
            state.detailProduct = payload.data
        })
        .addCase(callDetailProduct.rejected, (state) => {
            state.detailProduct = {}
        })
    builder
        .addCase(callGetProductsByVariants.pending, (state) => {
            state.loadingListProducts = true
            state.listProducts = []
        })
        .addCase(callGetProductsByVariants.fulfilled, (state, { payload }) => {
            state.listProducts = payload.data
            state.loadingListProducts = false
        })
        .addCase(callGetProductsByVariants.rejected, (state) => {
            state.listProducts = []
            state.loadingListProducts = false
        })
    builder
        .addCase(callGetProductsOfVendor.pending, (state) => {
            state.loadingListProductsOfVendor = true
            state.listProductOfVendor = []
        })
        .addCase(callGetProductsOfVendor.fulfilled, (state, { payload }) => {
            state.listProductOfVendor = payload.data
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingListProductsOfVendor = false
        })
        .addCase(callGetProductsOfVendor.rejected, (state) => {
            state.listProductOfVendor = []
            state.loadingListProductsOfVendor = false
        })
})
