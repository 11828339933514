import { COMBO_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callDeleteSkuCombo, callGetDetailSkuCombo } from "@reducer/version2/skuCombo.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Button, Card, Col, Divider, Empty, Form, InputNumber, message, Modal, Popover, Row, Typography } from "antd"
import React, { useState } from "react"
import { AddSku } from "./AddSku"
type Props = {
    formRef: any
}
export const ListProduct: React.FC<Props> = ({ formRef }) => {
    const dispatch: any = useAppDispatch()
    const skuComboDetail = useAppSelector((state) => state.skuCombo.skuComboDetail)
    const [open, setOpen] = useState<boolean>(false)
    const skuUpdate = SecurityService.can(COMBO_SCOPE.COMBO_DETAIL_UPDATE)

    const listHeader = [
        { title: trans("products.variant"), span: skuUpdate ? 7 : 8 },
        { title: trans("ads.sku"), span: skuUpdate ? 7 : 8 },
        { title: `${trans("ads.quantity")}`, span: skuUpdate ? 7 : 8 },
        ...(skuUpdate ? [{ title: trans("table.action"), span: 3 }] : []),
    ]
    const showConfirmDeleteCombo = (comboCode: string, skuCode: string ) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            icon: <i className="fa-regular fa-circle-info"></i>,
            onOk() {
                dispatch(callDeleteSkuCombo({ comboCode, skuCode })).then((result: any) => {
                    if (result?.payload?.status === 200) {
                        message.success(trans("message.success"))
                       dispatch(callGetDetailSkuCombo(skuComboDetail?.code))
                    } else {
                        message.error(
                            result?.payload?.response?.data?.message || trans("message.fail")
                        )
                    }
                })
            },
        })
    }

    return (
        <Card
            className="space-layout"
            title={`${trans("ads.sku")}`}
            extra={
                skuUpdate && (
                    <Popover
                        content={
                            <AddSku
                                formRef={formRef}
                                setClose={() => {
                                    setOpen(false)
                                }}
                            />
                        }
                        title={trans("skuCombo.new_sku")}
                        placement="leftTop"
                        trigger="click"
                        open={open}
                        onOpenChange={() => setOpen((old) => !old)}>
                        <Button type="primary">{trans("skuCombo.new_sku")}</Button>
                    </Popover>
                )
            }>
            <div className="">
                <Row
                    gutter={15}
                    className="bg-gray-200 p-6 border-b-black">
                    {listHeader.map((item: { title: string; span: number }, index: number) => (
                        <Col
                            span={item.span}
                            key={index}>
                            <Typography.Text strong>{item.title}</Typography.Text>
                        </Col>
                    ))}
                </Row>

                <div className="">
                    <Form.List name="tableProducts">
                        {(fields, { remove }) =>
                            fields.length > 0 ? (
                                <div>
                                    {fields.map(({ name, ...restField }) => (
                                        <Row
                                            className={`border pt-6 pr-3 ${name !== fields.length - 1 ? "border-b-0" : ""}`}
                                            key={name}
                                            gutter={15}>
                                            <Col span={skuUpdate ? 7 : 8}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.tableProducts !== next.tableProducts}>
                                                    {({ getFieldValue }) => (
                                                        <div>
                                                            <p className="m-0 ml-6">
                                                                {`${getFieldValue(["tableProducts", name, "product"])} -
                                                                ${getFieldValue(["tableProducts", name, "variant"])}`}
                                                            </p>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={skuUpdate ? 7 : 8}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.tableProducts !== next.tableProducts}>
                                                    {({ getFieldValue }) => (
                                                        <div>
                                                            <p className="m-0">{getFieldValue(["tableProducts", name, "sku"])}</p>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={skuUpdate ? 7 : 8}>
                                                <Form.Item noStyle>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "quantity"]}
                                                        rules={[
                                                            { required: true, message: trans("message.required") },
                                                            {
                                                                validator(_, value) {
                                                                    if (Number(value) > 0) {
                                                                        return Promise.resolve()
                                                                    }
                                                                    return Promise.reject(new Error(trans("order.quantity_gt_0")))
                                                                },
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            disabled={!skuUpdate}
                                                            step="1"
                                                            placeholder={trans("contact-detail.price")}
                                                            parser={(value: any) =>
                                                                value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(0) : 0
                                                            }
                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>
                                            {skuUpdate && (
                                                <Col span={1}>
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(pre, next) => pre.tableProducts !== next.tableProducts}>
                                                        {({ getFieldValue }) => (
                                                            <div>
                                                                <Button
                                                                    type="link"
                                                                    icon={<i className="fa-light fa-trash"></i>}
                                                                    onClick={() => {
                                                                        const skuCode = getFieldValue(["tableProducts", name, "sku"])
                                                                        const comboCode = skuComboDetail?.code
                                                                        if (getFieldValue(["tableProducts", name, "type"]) === 'old'){
                                                                            showConfirmDeleteCombo(comboCode, skuCode)
                                                                        }else{
                                                                            remove(name)
                                                                        }
                                                                    }}
                                                                    disabled={formRef.getFieldValue("tableProducts").length === 1}
                                                                />
                                                            </div>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Row>
                                    ))}
                                </div>
                            ) : (
                                <div className="mt-3">
                                    <Empty />
                                    <Divider />
                                </div>
                            )
                        }
                    </Form.List>
                </div>
            </div>
        </Card>
    )
}
