import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeAddUserVisible } from "@reducer/version1/user.reducer"
import { callGetAllUsers, resetListSuggestUser, callCreateUser, callGetUsers } from "@reducer/version2/user.reducer"
import { trans } from "@resources/localization"
import { convertNonAccentVietnamese } from "@util/Common"
import { AutoComplete, Button, Divider, Form, message, Modal, Row, Space, Spin } from "antd"
import { debounce, find, isEmpty } from "lodash"
import React, { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
export const FormAddUser: React.FC = () => {
    const [searchParams] = useSearchParams()
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const isShowAddUser = useAppSelector((state) => state.usersVersion1.isShowAddUser)
    const listSuggestUsers = useAppSelector((state) => state.usersVersion2.allUserSuggest)
    const loadingCreateUser = useAppSelector((state) => state.usersVersion2.loadingCreate)

    useEffect(() => {
        dispatch(callGetAllUsers({ keyword: "" }))
    }, [dispatch])
    const onSearchUser = (searchText: string) => {
        if (searchText.length >= 3) {
            const userName = convertNonAccentVietnamese(searchText)
            dispatch(
                callGetAllUsers({
                    keyword: userName,
                })
            )
        } else if (!searchText) {
            dispatch(callGetAllUsers({ keyword: "" }))
        }
    }
    const options = useMemo(
        () =>
            listSuggestUsers?.map((item) => ({
                value: item?.username,
            })),
        [listSuggestUsers]
    )
    const onCreateUser = (values: { username: string }) => {
        dispatch(
            callCreateUser({
                username: values.username,
                isDelete: false,
            })
        ).then((result: any) => {
            if (result?.error) {
                message.error(result?.payload?.response?.data?.message)
            } else {
                dispatch(
                    callGetUsers({
                        ...Object.fromEntries(searchParams.entries()),
                        size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    })
                )
                formRef.resetFields()
                dispatch(resetListSuggestUser([]))
                dispatch(changeAddUserVisible(false))
            }
        })
    }
    return (
        <Modal
            visible={isShowAddUser}
            footer={null}
            destroyOnClose
            width={500}
            title={trans("vendor.add_user_title")}
            onCancel={() => {
                formRef.resetFields()
                dispatch(resetListSuggestUser([]))
                dispatch(changeAddUserVisible(false))
            }}>
            <Form
                form={formRef}
                onFinish={onCreateUser}
                layout="vertical">
                <Form.Item
                    name="username"
                    label={trans("vendor.search_user")}
                    rules={[{ required: true, message: trans("vendor.user_validate") }]}>
                    <AutoComplete
                        notFoundContent={<Spin />}
                        options={options}
                        onSearch={debounce(onSearchUser, 1000)}
                        placeholder={trans("vendor.search_user")}
                    />
                </Form.Item>
                <Divider />
                <Row justify="end">
                    <Space>
                        <Button
                            type="default"
                            onClick={() => {
                                formRef.resetFields()
                                dispatch(resetListSuggestUser([]))
                                dispatch(changeAddUserVisible(false))
                            }}
                            loading={loadingCreateUser}>
                            {trans("button.cancel")}
                        </Button>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.username !== next.username}>
                            {({ getFieldValue }) => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loadingCreateUser}
                                    disabled={!getFieldValue("username") || isEmpty(find(options, { value: getFieldValue("username") }))}>
                                    {trans("button.save")}
                                </Button>
                            )}
                        </Form.Item>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
