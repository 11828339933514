import React from "react"
import { trans } from "@resources/localization"
import { Card, Col, Row, Typography } from "antd"
import { useAppSelector } from "@hook/useAppSelector"
import { formatDateFull } from "@util/Common"
export const InfoVendor = () => {
    const detailVendor = useAppSelector((state) => state.vendorDetail.detailVendor)
    return (
        <Card
            className="space-layout"
            title={detailVendor?.name || ""}>
            <Row>
                <Col span={5}>
                    <Typography.Text className="block">{trans("vendor.code")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {detailVendor?.code || ""}
                    </Typography.Text>
                </Col>
                <Col span={4}>
                    <Typography.Text className="block">{trans("vendor.manager")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {detailVendor?.manager}
                    </Typography.Text>
                </Col>
                <Col span={4}>
                    <Typography.Text className="block">{trans("table.created_by")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {detailVendor?.createdBy}
                    </Typography.Text>
                </Col>
                <Col span={5}>
                    <Typography.Text className="block">{trans("table.created_at")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {formatDateFull(detailVendor?.createdAt || "")}
                    </Typography.Text>
                </Col>
                <Col span={5}>
                    <Typography.Text className="block">{trans("vendor.country")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {detailVendor?.country?.name || ""}
                    </Typography.Text>
                </Col>
            </Row>
        </Card>
    )
}
