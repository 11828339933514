import { trans } from "@resources/localization"
import moment from "moment-timezone"
import lodash from "lodash"

export const formatDate = (d: any, format: string) => {
    const timezone = localStorage.getItem("timezone") || "Asia/Ho_Chi_Minh"
    return moment.utc(d).tz(timezone).format(format)
}

export const formatDateShort = (d: string) => {
    return formatDate(d, "DD/MM/YYYY")
}

export const formatDateMedium = (d: string) => {
    return formatDate(d, "DD/MM/YYYY HH:mm")
}

export const formatDateFull = (d: string) => {
    return formatDate(d, "DD/MM/YYYY HH:mm:ss")
}
export const filterWithTimezone = (d: any) => {
    const timezone = localStorage.getItem("timezone") || "Asia/Ho_Chi_Minh"
    return moment(d).tz(timezone)
}

export const convertNonAccentVietnamese = (str: string) => {
    str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A")
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E")
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I")
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
    str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O")
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U")
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y")
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    str = str.replace(/Đ/g, "D")
    str = str.replace(/đ/g, "d")
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "") // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, "") // Â, Ê, Ă, Ơ, Ư
    return str
}

export const convertNumberToCurrency = (text: string | number) => {
    if (text === "") return ""
    if (text === 0 || text === "0") return 0
    const newCurrency = text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return newCurrency
}
export const shortenText = (text: string, number: number, suffix?: string) => {
    if (text?.length > number) return `${text.slice(0, number)}...${suffix || ""}`
    return text
}
export const renderStatusInventory = (key: string) => {
    switch (key) {
        case "ON_SELL":
            return trans("warehouse.selling")
        case "STOP_SELLING":
            return trans("warehouse.stop_selling")
        default:
            return ""
    }
}

export interface Variant {
    [key: string]: [value: string]
}

export const renderVariant = (variant: Variant) => {
    if (lodash.isObject(variant)) {
        const text: Array<string> = []
        lodash.forEach(variant, (value, key) => {
            text.push(`${key}: ${value}`)
        })

        return text.join(", ")
    }

    return ""
}

export const numberParser = (value: any) => {
    return value?.toString()?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:"'<>?\\]/g, "")
}

export const numberFormatter = (value: any) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export const optionType = [
    { title: trans("campaign.product"), value: "product" },
    { title: trans("skuCombo.title"), value: "combo" },
]

