import { Modal } from "antd"
import React, { useEffect, useState } from "react"
import { trans } from "@resources/localization"
import { CreateImport } from "./CreateImport"
import { VerifyOrderList } from "./VerifyOrderList"
import { useAppSelector } from "@hook/useAppSelector"
import { useAppDispatch } from "@hook/useAppDispatch"
import { getProvinceSuggest } from "@reducer/version2/countries.reducer"
import { callGetCourierVendor } from "@reducer/version2/courier-setting.reducer"
import { callGetProductsByVariants } from "@reducer/version2/product.reducer"
import { isEmpty } from "lodash"
import { TableErrorOrder } from "./TableError"

type Props = {
    isOpenModal: boolean
    setClose: () => void
}
export const ImportOrder: React.FC<Props> = ({ isOpenModal, setClose }) => {
    const [isUpload, setIsUpload] = useState<boolean>(false)
    const [listDraft, setListDraft] = useState({ data: [], errors: [] })
    const countryCode = useAppSelector((state) => state.vendorDetail.detailVendor.countryCode)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(callGetCourierVendor())
        dispatch(callGetProductsByVariants({ keyword: "" }))
    }, [])
    useEffect(() => {
        if (countryCode) {
            dispatch(getProvinceSuggest(countryCode))
        }
    }, [countryCode])
    return (
        <Modal
            destroyOnClose
            title={!isUpload ? trans("order.import_order") : trans("order.draft_list")}
            open={isOpenModal}
            width={!isUpload ? 500 : 1440}
            footer={null}
            maskClosable={false}
            onCancel={() => {
                setClose()
            }}>
            {!isUpload ? (
                <CreateImport
                    setIsUpload={setIsUpload}
                    setClose={setClose}
                    setListDraft={setListDraft}
                />
            ) : (
                <>
                    {!isEmpty(listDraft?.errors) && <TableErrorOrder listErrors={listDraft?.errors} />}
                    {!isEmpty(listDraft?.data) && (
                        <VerifyOrderList
                            listDraft={listDraft?.data}
                            setClose={setClose}
                        />
                    )}
                </>
            )}
        </Modal>
    )
}
