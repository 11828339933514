import React from "react"

import { Card, Col, Row } from "antd"
import { trans } from "@resources/localization"

import { useAppSelector } from "@hook/useAppSelector"
import { formatDateFull } from "@util/Common"

export const PackageInfo = () => {
    const packageDetail = useAppSelector((state) => state.packageDetail.dataDetail)
    const loadingDetail = useAppSelector((state) => state.packageDetail.loadingDetail)

    return (
        <>
            <Card loading={loadingDetail}>
                <Row gutter={[15, 15]}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                        className="flex justify-between">
                        <div className="font-medium">{`#${packageDetail?.code} `}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("package.created_at")}</p>
                        <div className="font-medium">{packageDetail?.createdAt ? formatDateFull(packageDetail?.createdAt) : ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("package.status")}</p>
                        <div className="font-medium">{packageDetail?.status}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("package.tracking")}</p>
                        <div className="font-medium">{packageDetail?.trackingNumber || ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("package-detail.weight")}</p>
                        <div className="font-medium">{`${Number(packageDetail?.weight)*1000} (g)` || ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <p className="m-0">{trans("package-detail.warehouse_address")}</p>
                        <div className="font-medium">{packageDetail?.destination_warehouse?.address || ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <p className="m-0">{trans("package-detail.note")}</p>
                        <div className="font-medium">{packageDetail?.note}</div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
