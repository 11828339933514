import { UploadOutlined } from "@ant-design/icons"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callImportFilePackage } from "@reducer/version2/package-detail.reducer"
import { trans } from "@resources/localization"
import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import { compact, find, first, isEmpty, map } from "lodash"
import React, { useState } from "react"
type Props = {
    open: boolean
    setIsOpen: () => void
    formRef: any
}
export const ImportSku: React.FC<Props> = ({ open, setIsOpen, formRef }) => {
    const dispatch = useAppDispatch()
    const [isResult, setIsResult] = useState(false)
    const [listErrors, setListErrors] = useState([])
    const [listSuccess, setListSuccess] = useState([])
    const productSuggest = useAppSelector((state) => state.package.productSuggestForPackage)
    const loadingProduct = useAppSelector((state) => state.package.loadingProducts)
    const onUploadFileImport = (files: any) => {
        const file = files[0]
        const size = file?.size
        if (size <= 5000000) {
            dispatch(callImportFilePackage(file)).then((result: any) => {
                if (result?.payload?.status === 201) {
                    const dataErrors = result?.payload?.data?.errors
                    const dataRawSuccess = result?.payload?.data?.success
                    const productSuggestCode = map(productSuggest, "code")
                    const finallySkuCodeArr = dataRawSuccess.filter((iFil: any) => productSuggestCode.includes(iFil?.skuCode)) || []
                    const errorSkuCodeArr: any = dataRawSuccess.filter((iFil: any) => !productSuggestCode.includes(iFil?.skuCode)) || []
                    const mappingError = !isEmpty(errorSkuCodeArr)
                        ? errorSkuCodeArr?.map((item: any) => ({
                              ...item,
                              error: trans("package.error_sku"),
                          }))
                        : []
                    const finallyErrors: any = [...dataErrors, ...mappingError]
                    setIsResult(true)
                    setListErrors(finallyErrors)
                    setListSuccess(finallySkuCodeArr)
                }else{
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        } else {
            message.error(trans("order.upload_file_less_than"))
        }
    }
    const onSaveAddSku = () =>{
        const listPackageItems: any = formRef.getFieldValue('packageItems') || []
        const listSuccessCode: any = !isEmpty(listSuccess) ? map(listSuccess,'skuCode') : []
        const differentPackages = listPackageItems?.filter((iFil: any) => !listSuccessCode.includes(iFil?.code))
        const newItems = listSuccess?.map((item: any)=>{
            const findItem = find(productSuggest, {code: item?.skuCode})
            if(!isEmpty(findItem)){
                return {
                    code: findItem?.code,
                    id: first(findItem?.skus)?.id,
                    name: findItem?.name,
                    quantity: item?.quantity
                }
            }
        })
        const secondItems = compact(newItems)
        const thirdItems = secondItems?.map((item)=>({
            ...item,
            quantity: item?.quantity + (find(listPackageItems, {code: item?.code})?.quantity || 0)
        }))
        const finallyPackageItems = [
            ...thirdItems,
            ...differentPackages
        ]
        formRef.setFieldsValue({
            packageItems: finallyPackageItems
        })
        setListErrors([])
        setListSuccess([])
        setIsOpen()
    }
    const columnsError: any[] = [
        {
            title: trans("order_list.lbc_import_line"),
            className: "bold-400",
            dataIndex: "line",
            key: "line",
            width: 100,
        },
        {
            title: trans("package.sku_code"),
            className: "bold-400",
            key: "skuCode",
            dataIndex: "skuCode",
            width: 180,
        },
        {
            title: trans("order_list.lbc_import_error"),
            className: "bold-400",
            key: "error",
            dataIndex: "error",
        },
    ]
    const columnsSuccess: any[] = [
        {
            title: trans("package.sku_code"),
            className: "bold-400",
            dataIndex: "skuCode",
            width: 200,
        },
        {
            title: trans("package.sku_name"),
            className: "bold-400",
            dataIndex: "skuCode",
            key: "name",
            render: (skuCode: string) => find(productSuggest, { code: skuCode })?.name || "",
        },
        {
            title: trans("contact-detail.quantity"),
            className: "bold-400",
            dataIndex: "quantity",
            width: 100,
        },
    ]
    return (
        <Modal
            open={open}
            destroyOnClose
            title={trans("package.product_sku")}
            footer={null}
            width={790}
            onCancel={() => setIsOpen()}>
            <Row gutter={[0, 15]}>
                <Col span={24}>
                    <a
                        href={require("@resources/files/Import_SKU_Template.xlsx")}
                        rel="noreferrer"
                        download="Import_SKU_Template.xlsx"
                        type="link">
                        {trans("package.download_sample_file_here")}
                    </a>
                    <p className="m-0 font-medium">{trans("package.maximum_file_limit")}: 1000 SKU</p>
                    <p>{trans("package.maximum_file_size")}: 5MB</p>
                </Col>
                <Col span={24}>
                    <Button
                        loading={loadingProduct}
                        icon={<UploadOutlined />}
                        onClick={() => document.getElementById("uploadFile")?.click()}>
                        {trans("package.choose_file")}
                    </Button>
                    <input
                        hidden
                        type="file"
                        id="uploadFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        value=""
                        onChange={(e) => onUploadFileImport(e.target.files)}
                    />
                </Col>
            </Row>
            {isResult && (
                <div>
                    <p className="mt-3 font-medium">{trans("package.list_success")}</p>
                    <Table
                        scroll={{
                            y: 520,
                        }}
                        pagination={false}
                        className="mt-3"
                        columns={columnsSuccess}
                        dataSource={listSuccess}
                    />
                    <p className="mt-3 font-medium">{trans("package.list_fail")}</p>
                    <Table
                        scroll={{
                            y: 520,
                        }}
                        pagination={false}
                        className="mt-3"
                        columns={columnsError}
                        dataSource={listErrors}
                    />
                </div>
            )}
            <Row
                justify="end"
                className="mt-3">
                <Space>
                    <Button
                        type="default"
                        onClick={() => {
                            setListErrors([])
                            setListSuccess([])
                            setIsResult(false)
                            setIsOpen()
                        }}>
                        {trans("button.cancel_button")}
                    </Button>
                    <Button
                        disabled={isEmpty(listSuccess)}
                        type="primary"
                        htmlType="submit"
                        onClick={() => onSaveAddSku()}
                        >
                        {trans("button.save")}
                        
                    </Button>
                </Space>
            </Row>
        </Modal>
    )
}
