import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeVisibleAddPurchasingAccount, callDeletePurchasingAccount, callListPurchasingAccount } from "@reducer/version2/purchasing-account"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Button, Space, Table, Modal, Typography, Card, Tag, message } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSearchParams } from "react-router-dom"
import { PURCHASING_ACCOUNT } from "@config/permission"
import  { IPurchasingService, IPurchasingAccount } from "@domain/version2/PurchasingAccount"
import { isEmpty } from "lodash"

export const TablePurchasingAccount: React.FC = () => {
    const dispatch = useAppDispatch()
    const size: number = useAppSelector((state) => state.purchasingAccount.pagination.size)
    const currentPage: number = useAppSelector((state) => state.purchasingAccount.pagination.currentPage)
    const total: number = useAppSelector((state) => state.purchasingAccount.pagination.total)
    const totalPage: number = useAppSelector((state) => state.purchasingAccount.pagination.totalPage)
    const listPurchasingAccount: IPurchasingAccount[] = useAppSelector((state) => state.purchasingAccount.listPurchasingAccount)
    const loadingListUsers = useAppSelector((state) => state.purchasingAccount.loadingList)
    const [searchParams, setSearchParams] = useSearchParams()

    const columns: ColumnsType<any> = [
        {
            title: trans("purchasing_account.name"),
            className: "bold-400",
            dataIndex: "username",
        },
        {
            title: trans("inventory.service"),
            className: "bold-400",
            dataIndex: "purchasingService",
            render: (purchasingService: IPurchasingService, record: IPurchasingAccount) => purchasingService ? record?.purchasingService?.name : "---"
        },
        {
            title: trans("subscriber.status"),
            className: "bold-400",
            dataIndex: "status",
            render: (status) => !isEmpty(status) ? <Tag color="#00bb00">{trans(`purchasing_account.${status}`)}</Tag> : '---',
        },
        {
            title: "",
            className: "bold-400",
            dataIndex: "delete",
            width: 60,
            render: (text, record: IPurchasingAccount) => (
                SecurityService.can(PURCHASING_ACCOUNT.PURCHASING_ACCOUNT_DELETE) && <Button
                    type="link"
                    icon={<i className="fa-regular fa-trash"></i>}
                    onClick={() => showConfirmDelete(record)}
                />
            ),
        },
    ]
    const showConfirmDelete = (item: IPurchasingAccount) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            icon: <i className="fa-regular fa-circle-info"></i>,
            onOk() {
                dispatch(
                    callDeletePurchasingAccount({
                        id: item.id
                    })
                ).then((result: any) => {
                    if (result?.payload?.status === 200) {
                        message.success(trans("message.success"))
                        dispatch(
                            callListPurchasingAccount({
                                ...Object.fromEntries(searchParams.entries()),
                                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                            })
                        )
                    } else {
                        message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                        dispatch(
                            callListPurchasingAccount({
                                ...Object.fromEntries(searchParams.entries()),
                                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                            })
                        )
                    }
                })
            },
        })
    }
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            page: !oldSearch.size || Number(oldSearch.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: pageSize,
        }
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 && <Typography.Text>{`${trans("contact.page")} ${currentPage}/${totalPage}(${total})`}</Typography.Text>}
            extra={
                <Space>
                    {SecurityService.can(PURCHASING_ACCOUNT.PURCHASING_ACCOUNT_CREATE) && (
                        <Button
                            type="primary"
                            onClick={() => {
                                dispatch(changeVisibleAddPurchasingAccount(true))
                            }}>
                            {trans("purchasing_account.add")}
                        </Button>
                    )}
                </Space>
            }>
            <Table
                rowKey={"id"}
                columns={columns}
                dataSource={listPurchasingAccount}
                loading={loadingListUsers}
                className="bd-radius-5"
                pagination={{
                    pageSize: size,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                }}
            />
        </Card>
    )
}
