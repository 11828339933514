import { LIST_CURRENCY } from "@config/constant"
import { IPricePolicy } from "@domain/version2/Contact"
import IContactDetail from "@domain/version2/ContactDetail"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { Card, Table, Typography } from "antd"
import { find } from "lodash"
import React from "react"
export const Product: React.FC = () => {
    const contactDetail: IContactDetail = useAppSelector((state) => state.contactDetail2.dataDetail)
    const listPricePolicy = contactDetail?.campaignDetail?.pricePolicies as IPricePolicy[]

    const columns: any = [
        {
            title: trans("ads.properties"),
            render: (record: IPricePolicy) => (
                <div>
                    <p>{record?.sku}</p>
                    {/* <p className="text-gray-400">type: {record?.sku?.display}</p> */}
                </div>
            ),
        },
        {
            title: trans("contact-detail.quantity"),
            className: "table-vertical-align",
            render: (record: IPricePolicy) => (
                <>
                    {record?.wholeSalePrice?.map((item: { to: number; from: number; price: number }, index: number) => (
                        <div key={index}>
                            {trans("contact.from")} <span className="ml-1">{item?.from}</span> {trans("contact.to")}
                            <span className="ml-1">{item?.to}</span>
                        </div>
                    ))}
                </>
            ),
        },
        {
            title: `${trans("products.price")} (${find(LIST_CURRENCY, { value: contactDetail?.countryCode })?.code || ""})`,
            className: "table-vertical-align text-end",
            render: (record: IPricePolicy) => (
                <>
                    {record?.wholeSalePrice?.map((item: { to: number; from: number; price: number }, index: number) => (
                        <div key={index}>{item?.price ? convertNumberToCurrency(item.price || 0) : 0}</div>
                    ))}
                </>
            ),
        },
    ]
    return (
        <div className="mt-3">
            <Card title={trans("product.price_policy_title")}>
                <Typography.Text>{contactDetail?.campaignDetail?.product?.code} - {contactDetail?.campaignDetail?.product?.name}</Typography.Text>
                <Typography.Text className="block mt-3">{trans("product.price_policy_table")}</Typography.Text>
                <Table
                    className="mt-3"
                    rowKey={"id"}
                    columns={columns}
                    dataSource={listPricePolicy || []}
                    pagination={false}
                />
            </Card>
        </div>
    )
}
