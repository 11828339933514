import { trans } from "@resources/localization"
import { Button, Card, Col, Form, InputNumber, Row, Space, Typography } from "antd"
import React, { useState } from "react"
import { ImportSku } from "./CreateImport"
import { ModalSku } from "./ModalSku"
type Props = {
    formRef: any
}
export const ListProductInbound: React.FC<Props> = ({ formRef }) => {
    const [openModal, setOpenModal] = useState(false)
    const [open, setOpen] = useState(false)
    const listHeader = [
        { title: trans("package.sku_code"), span: 6 },
        { title: trans("package.sku_name"), span: 10 },
        { title: trans("contact-detail.quantity"), span: 6 },
        { title: trans("table.action"), span: 2 },
    ]
    return (
        <Card
            className="space-layout"
            title={trans("package.product_sku")}
            extra={
                <Space>
                    <Button
                        type="primary"
                        onClick={() => setOpenModal(true)}
                        icon={<i className="fa-light fa-cloud-arrow-up mr-2"></i>}>
                        {trans("package.import_file")}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setOpen((old) => !old)}>
                        {trans("package.add_product_sku")}
                    </Button>
                </Space>
            }>
            <div className="bg-gray-200 p-3 border-b-black">
                <Row gutter={15}>
                    {listHeader.map((item: { title: string; span: number }, index: number) => (
                        <Col
                            span={item.span}
                            key={index}>
                            <Typography.Text strong>{item.title}</Typography.Text>
                        </Col>
                    ))}
                </Row>
            </div>
            <Form.List name="packageItems">
                {(fields, { remove }) => (
                    <div>
                        {fields.map(({ name, ...restField }) => (
                            <Row
                                className="p-2 border-b"
                                key={name}
                                gutter={15}>
                                <Col span={6}>
                                    <Typography.Text>{formRef.getFieldValue(["packageItems", name, "code"])}</Typography.Text>
                                </Col>
                                <Col span={10}>
                                    <Typography.Text>{formRef.getFieldValue(["packageItems", name, "name"])}</Typography.Text>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "quantity"]}
                                        rules={[{ required: true, message: trans("message.required") }]}>
                                        <InputNumber
                                            min={1}
                                            parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        onClick={() => remove(name)}
                                        type="link"
                                        icon={<i className="fa-regular fa-trash" />}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </div>
                )}
            </Form.List>
            {openModal && (
                <ImportSku
                    open={openModal}
                    setIsOpen={() => setOpenModal(false)}
                    formRef={formRef}
                />
            )}
            <Form.Item
                noStyle
                shouldUpdate={(pre, next) => pre.packageItems !== next.packageItems}>
                {({ getFieldValue }) => (
                    <ModalSku
                        isOpen={open}
                        formRef={formRef}
                        listPackages={getFieldValue("packageItems")}
                        setClose={() => setOpen(false)}
                    />
                )}
            </Form.Item>
        </Card>
    )
}
