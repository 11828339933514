import { ICourier, ICourierComplete, ICourierRequest, ICourierVendor } from "@domain/version2/CourierSetting"
import apiClient from "@util/ApiClient"

export default class CourierSettingApi {
    static getListCourier(params: {'x-tenant': string, countryCode: string}): Promise<{ data: ICourier[] }> {
        return apiClient.get(`couriers`, {
            params
        })
    }
    static getCourierVendor(): Promise<{ data: ICourierVendor[] }> {
        return apiClient.get(`vendors/couriers`)
    }
    static createCourier(body: ICourierRequest): Promise<any> {
        return apiClient.post(`vendors/couriers`, body)
    }
    static getCourierSuggest(params:{keyword: string}): Promise<{ data: ICourierComplete[] }> {
        return apiClient.get(`couriers/autocomplete`, {params})
    }
}

