import { UploadOutlined } from "@ant-design/icons"
import { PAGINATION } from "@config/constant"
import { IAds } from "@domain/version2/Ads"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callListAds } from "@reducer/version2/ads.reducer"
import { callImportContact, callListContacts, changeVisibleImport } from "@reducer/version2/contact.reducer"
import { trans } from "@resources/localization"
import { Button, Col, Form, message, Row, Select, Space } from "antd"
import { isEmpty } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
type Props = {
    setIsUpload: (data: boolean) => void
}
export const CreateImport: React.FC<Props> = ({ setIsUpload }) => {
    const [searchParams] = useSearchParams()
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const listAds = useAppSelector((state) => state.adsVersion2.listAds)
    const loadingImport = useAppSelector((state) => state.contact2.loadingImport)
    useEffect(() => {
        dispatch(
            callListAds({
                page: 1,
                pageSize: 10000,
                sort: "createdAt:desc",
                active: true,
            })
        )
    }, [dispatch])
    const onCreateImportContact = (values: { file: any; adsCode: string }) => {
        if (!values.file) {
            message.error(trans("contact.required_file"))
        } else {
            dispatch(
                callImportContact({
                    code: values.adsCode,
                    file: values.file,
                })
            ).then((result: any) => {
                if (result?.payload?.status === 201) {
                    if (isEmpty(result?.payload?.data?.errors)) {
                        message.success(`${trans("contact.noti_succ")} ${result?.payload?.data?.successCount} ${trans("contact-detail.contact")}`)
                        setTimeout(() => {
                            dispatch(changeVisibleImport(false))
                        }, 500)
                    } else {
                        setIsUpload(true)
                    }
                    if (result?.payload?.data?.successCount >= 1) {
                        // fetch list contact when api success
                        const params = Object.fromEntries(searchParams.entries())
                        delete params.call
                        dispatch(
                            callListContacts({
                                ...params,
                                pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                sort: "updatedAt:desc",
                            })
                        )
                    }
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    return (
        <Form
            form={formRef}
            onFinish={onCreateImportContact}
            layout="vertical">
            <Row gutter={[0, 15]}>
                <Col span={24}>
                    <Form.Item
                        name="file"
                        hidden
                    />
                    <Button
                        icon={<UploadOutlined />}
                        onClick={() => document.getElementById("uploadFile")?.click()}>
                        {trans("campaign.choose_file")}
                    </Button>
                    <input
                        hidden
                        type="file"
                        id="uploadFile"
                        accept="text/csv"
                        value=""
                        onChange={(e) => {
                            formRef.setFieldsValue({
                                file: e.target.files ? e.target.files[0] : "",
                            })
                        }}
                    />
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.file !== next.file}>
                        {({ getFieldValue }) => <div className="mt-3">{getFieldValue("file")?.name}</div>}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <a
                        href={require("@resources/files/Contact_Import_Template.csv")}
                        rel="noreferrer"
                        download="Contact_Import_Template.csv"
                        type="link">
                        {trans("contact.download_import_template")}
                    </a>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="adsCode"
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            showSearch
                            filterOption={(input: any, option: any) => {
                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                            }}
                            filterSort={(optionA: any, optionB: any) => {
                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                            }}
                            placeholder={trans("contact.select_ads")}>
                            {listAds?.map((item: IAds) => (
                                <Select.Option
                                    value={item?.code}
                                    key={item?.id}>
                                    {item?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end">
                <Space>
                    <Button
                        loading={loadingImport}
                        type="default"
                        onClick={() => {
                            setIsUpload(false)
                            dispatch(changeVisibleImport(false))
                        }}>
                        {trans("button.cancel_button")}
                    </Button>
                    <Button
                        loading={loadingImport}
                        type="primary"
                        htmlType="submit">
                        {trans("button.save")}
                    </Button>
                </Space>
            </Row>
        </Form>
    )
}
