import React, { useMemo, useState } from "react"
import { Button, Card, Menu, Popover, Table } from "antd"
import { trans } from "@resources/localization"
import { PAGINATION } from "@config/constant"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAppSelector } from "@hook/useAppSelector"
import { formatDateFull } from "@util/Common"
import { filter, isEmpty } from "lodash"
import { SettingTable } from "@component/General/SettingTable"
import { IPackage } from "@domain/version2/Package"
import { PACKAGE_SCOPE } from "@config/permission"
import SecurityService from "@util/SecurityService"
import { PlusCircleOutlined } from "@ant-design/icons"

export const TablePackage: React.FC = () => {
    const pageSize: number = useAppSelector((state) => state.package.pagination.pageSize)
    const currentPage: number = useAppSelector((state) => state.package.pagination.currentPage)
    const total: number = useAppSelector((state) => state.package.pagination.total)
    const totalPage: number = useAppSelector((state) => state.package.pagination.totalPage)
    const listPackage = useAppSelector((state) => state.package.packages)
    const loadingList = useAppSelector((state) => state.package.loadingList)

    const [open, setOpen] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedTable, setSelectedTable] = useState<string[]>([])
    const oldSearch = Object.fromEntries(searchParams.entries())

    const navigate = useNavigate()

    const handleChangePage = (page: number, pageSize: number) => {
        const params: any = {
            ...oldSearch,
            page: !searchParams.has("size") || Number(oldSearch?.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: pageSize,
        }
        setSearchParams(params)
    }

    const columns: any[] = [
        {
            title: trans("package.code"),
            className: "bold-400",
            dataIndex: "code",
            key: "code",
            default: true,
            width: 150,
            fixed: "left",
            render: (code: string, record: IPackage) =>
                !SecurityService.can(PACKAGE_SCOPE.PACKAGE_DETAIL_VIEW) ? code : <Link to={`/package/${record.id}`}>{code}</Link>,
        },
        {
            title: trans("package.tracking"),
            className: "bold-400",
            dataIndex: "trackingNumber",
            key: "trackingNumber",
            default: true,
            width: 120,
            align: "center",
            render: (trackingNumber: string) => (trackingNumber ? trackingNumber : "---"),
        },
        {
            title: trans("package.declare_quantity"),
            className: "bold-400",
            dataIndex: "numberOfDeclarations",
            key: "numberOfDeclarations",
            default: true,
            width: 80,
            align: "center",
            render: (numberOfDeclarations: number) => (numberOfDeclarations ? numberOfDeclarations : "---"),
        },
        {
            title: trans("package.actual_quantity"),
            className: "bold-400",
            dataIndex: "numberOfActuals",
            key: "numberOfActuals",
            default: true,
            width: 80,
            align: "center",
            render: (numberOfActuals: number) => (numberOfActuals ? numberOfActuals : "---"),
        },
        {
            title: trans("package.fee"),
            className: "bold-400",
            dataIndex: "fee",
            key: "fee",
            default: false,
            width: 80,
            align: "center",
            render: (fee: number) => (fee ? fee : "---"),
        },
        {
            title: trans("package.warehouse"),
            className: "bold-400",
            dataIndex: "warehouse",
            key: "warehouse",
            default: true,
            width: 100,
            render: (warehouse: number, record: IPackage) => (warehouse ? record?.warehouse?.name : "---"),
        },
        {
            title: trans("package.status"),
            className: "bold-400",
            dataIndex: "packageStatus",
            key: "packageStatus",
            default: true,
            width: 100,
            render: (packageStatus: string) => (packageStatus ? trans(`package.status_${packageStatus}`) : "---"),
        },
        {
            title: trans("package.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            key: "createdAt",
            default: true,
            width: 200,
            render: (date: string) => (date ? formatDateFull(date) : ""),
        },
    ]
    const dataTrees = useMemo(
        () =>
            !isEmpty(columns)
                ? columns?.map((item: any) => ({
                      title: item?.title,
                      key: item?.key,
                      disabled: item?.default,
                  }))
                : [],
        []
    )
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                <div>
                    <Popover
                        content={
                            <Menu
                                items={[
                                    {
                                        label: trans("package.create_handicraft"),
                                        key: "manual",
                                        icon: <PlusCircleOutlined />,
                                        onClick: () => {
                                            navigate("/package/create")
                                        },
                                    },
                                    {
                                        label: trans("package.shipment_title"),
                                        key: "auto",
                                        icon: <PlusCircleOutlined />,
                                        onClick: () => {
                                            navigate("/package/create-shipment")
                                        },
                                    },
                                ]}
                            />
                        }
                        placement="bottom"
                        trigger="click"
                        open={open}
                        onOpenChange={() => setOpen((old) => !old)}>
                        {SecurityService.can(PACKAGE_SCOPE.PACKAGE_CREATE) && (
                            <Button
                                type="primary"
                                className="mg-r-10 _filter_button_filter">
                                {trans("package.create")}
                            </Button>
                        )}
                    </Popover>
                    <SettingTable
                        dataTree={dataTrees}
                        keyTable="packageVendor"
                        setSelectedTable={setSelectedTable}
                        selectedTable={selectedTable}
                    />
                </div>
            }>
            <Table
                rowKey={"id"}
                scroll={{ x: true }}
                loading={loadingList}
                columns={filter(columns, (iFil: any) => selectedTable?.includes(iFil?.key))}
                dataSource={listPackage}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: pageSize,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                }}
            />
        </Card>
    )
}
