// import { useAppDispatch } from "@hook/useAppDispatch"
// import { useAppSelector } from "@hook/useAppSelector"
import { IComboCategories } from "@domain/version2/Categories"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Card, Col, Form, Input, Row, Select } from "antd"
// import { debounce } from "lodash"
import React from "react"

type Props = {
    formRef: any
}
export const GeneralInfo: React.FC<Props> = ({ formRef }) => {
    const listComboCategories = useAppSelector((state) => state.categoriesVersion2.listSkuComboCategories)

    return (
        <Card className="space-layout">
            <Row gutter={12}>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="code"
                        label={trans("skuCombo.code")}>
                        <Input
                            maxLength={256}
                            placeholder={trans("skuCombo.code_pl")}
                            onChange={(e: any) => {
                                const text: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:"'<>?\\]/g, "")
                                formRef.setFieldsValue({
                                    code: text,
                                })
                            }}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    code: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="name"
                        label={trans("skuCombo.name")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Input
                            maxLength={256}
                            placeholder={trans("skuCombo.name_pl")}
                            onChange={(e: any) => {
                                const text: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:"'<>?\\]/g, "")
                                formRef.setFieldsValue({
                                    name: text,
                                })
                            }}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    name: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="category"
                        label={trans("skuCombo.category")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            allowClear
                            placeholder={trans("skuCombo.category_pl")}>
                            {listComboCategories?.map((el: IComboCategories) => (
                                <Select.Option
                                    key={el.id}
                                    value={el.code}>
                                    {el.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}
