import { ICampaign } from "@domain/version2/Campaign"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCampaignsSuggest } from "@reducer/version2/campaign.reducer"
import { callDetailProduct } from "@reducer/version2/product.reducer"
import { callGetDetailSkuCombo } from "@reducer/version2/skuCombo.reducer"
import { trans } from "@resources/localization"
import { AutoComplete, Card, Col, Form, Input, Row, Select, Spin } from "antd"
import { debounce, find } from "lodash"
import React, { useMemo } from "react"
type Props = {
    formRef: any
    setCurrentCampaign: (data: ICampaign) => void
}
export const GeneralInfo: React.FC<Props> = ({ formRef, setCurrentCampaign }) => {
    const listCampaigns = useAppSelector((state) => state.campaignVersion2.campaignsSuggest)
    const dispatch = useAppDispatch()
    const onSearchCampaigns = (searchText: string) => {
        if (searchText.trim().length >= 3) {
            dispatch(
                callCampaignsSuggest({
                    keyword: searchText.trim(),
                })
            )
        }
    }
    const optionsCampaign = useMemo(
        () =>
            listCampaigns?.map((item) => ({
                value: item?.name,
                key: item?.code,
            })),
        [listCampaigns]
    )
    return (
        <Card className="space-layout">
            <Row gutter={12}>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="campaignCode"
                        hidden
                    />
                    <Form.Item
                        name="campaignName"
                        label={trans("order_list.campaign")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <AutoComplete
                            notFoundContent={<Spin />}
                            allowClear
                            onSearch={debounce(onSearchCampaigns, 1000)}
                            placeholder={trans("contact.select_campaign")}
                            options={optionsCampaign}
                            onSelect={(val: any, option: any) => {
                                const current = find(listCampaigns, { code: option?.key })
                                formRef.setFieldsValue({
                                    campaignCode: option?.key,
                                })
                                setCurrentCampaign(current || {})
                                if(current?.comboCode){
                                    dispatch(callGetDetailSkuCombo(current?.comboCode || ''))
                                }
                                if(current?.productCode){
                                    dispatch(callDetailProduct(current?.productCode || ""))
                                }

                            }}
                            onClear={() => {
                                formRef.setFieldsValue({
                                    campaignName: "",
                                })
                                setCurrentCampaign({})
                            }}
                            onBlur={() => {
                                if (!formRef.getFieldValue("campaignCode")) {
                                    formRef.setFieldsValue({
                                        campaignName: "",
                                    })
                                    setCurrentCampaign({})
                                    dispatch(callCampaignsSuggest({keyword: ''}))
                                }
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="name"
                        label={trans("ads.name")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Input
                            maxLength={256}
                            placeholder={trans("ads.name_pl")}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    name: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="facebookAdIds"
                        label={trans("ads.facebook_ads")}>
                        <Select
                            placeholder={trans("campaign.facebook_create_pl")}
                            mode="tags"
                            options={[]}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="landingUrls"
                        label={trans("ads.landing_url")}>
                        <Select
                            placeholder={trans("ads.landing_url_pl")}
                            mode="tags"
                            options={[]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}
