import React, { useEffect, useState } from "react"

import { useParams } from "react-router-dom"

import { message, Tabs } from "antd"

import { useAppDispatch } from "@hook/useAppDispatch"

import { changePageTitleAction } from "@reducer/common.reducer"
import {
    getOrderDetail,
    fetchUpdateOrder,
    changeDataOrderUpdate,
    changeEditNoteOrder,
    fetchOrderFinancialStatuses,
} from "@reducer/version2/order-detail.reducer"

import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { trans } from "@resources/localization"
import { Summary } from "./Summary/Summary"
import { OrderDetailHeader } from "./Header"
import { useAppSelector } from "@hook/useAppSelector"
import { fetchOrderStatuses } from "@reducer/version2/order.reducer"
import { isEmpty } from "lodash"
import { getAreaSuggest, getCitySuggest, getDistrictSuggest, getProvinceSuggest } from "@reducer/version2/countries.reducer"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"
import { callGetCourierVendor } from "@reducer/version2/courier-setting.reducer"
import AddReasonCancel from "./AddReasonCancel"
import { callGetDetailSkuCombo } from "@reducer/version2/skuCombo.reducer"
import FinancialInfomation from "./FinancialInformation"

export const OrderDetail = () => {
    const { id } = useParams()
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loading = useAppSelector((state) => state.orderDetailVersion2.loadingDetail)
    const dataOrderUpdate = useAppSelector((state) => state?.orderDetailVersion2?.dataOrderUpdate)
    const [statusShow, setStatusShow] = useState<number>(0)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("order.product_detail")))
        dispatch(fetchOrderStatuses())
        dispatch(fetchOrderFinancialStatuses())
        dispatch(callGetCourierVendor())
        if (id) {
            dispatch(getOrderDetail(id || "")).then((result: any) => {
                setStatusShow(result?.payload?.response?.status)
            })
        }
    }, [dispatch, id])
    useEffect(() => {
        if (!isEmpty(detailOrder)) {
            dispatch(callGetCourierVendor())
            dispatch(getProvinceSuggest(detailOrder?.country?.code || ""))
            if (detailOrder?.province?.code) {
                dispatch(getCitySuggest(detailOrder?.province?.code || ""))
            }
            if (detailOrder?.city?.code) {
                dispatch(getDistrictSuggest(detailOrder?.city?.code || ""))
            }
            if (detailOrder?.district?.code) {
                dispatch(getAreaSuggest(detailOrder?.district?.code))
            }
            if (detailOrder?.campaign?.comboCode) {
                dispatch(callGetDetailSkuCombo(detailOrder?.campaign?.comboCode))
            }
        }
    }, [detailOrder])
    useEffect(() => {
        if (!isEmpty(detailOrder)) {
            dispatch(
                changeDataOrderUpdate({
                    customerName: detailOrder?.customerName,
                    customerPhone: detailOrder?.customerPhone,
                    courierService: detailOrder?.courierServiceCode,
                    country: detailOrder?.country?.name,
                    province: detailOrder?.province?.code,
                    city: detailOrder?.city?.code,
                    district: detailOrder?.district?.code,
                    ward: detailOrder?.ward,
                    address: detailOrder?.address,
                    postalCode: detailOrder?.postalCode,
                    totalPayment: detailOrder?.totalPayment,
                    deliveryFee: detailOrder?.deliveryFee,
                    note: detailOrder?.note,
                })
            )
        }
    }, [dispatch, detailOrder])

    const onUpdateOrder = (key: string, value: any) => {
        dispatch(
            fetchUpdateOrder({
                code: detailOrder?.code || "",
                body: {
                    [key]: value,
                    ...(key === "totalPayment" && {
                        totalPayment: dataOrderUpdate?.totalPayment || 0,
                    }),
                    ...(key === "deliveryFee" && {
                        deliveryFee: dataOrderUpdate?.deliveryFee || 0,
                    }),
                },
            })
        ).then((result) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(getOrderDetail(id || ""))
                dispatch(changeEditNoteOrder(false))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <DefaultLayout loading={loading}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            {
                                name: "sidebar.dashboard",
                                link: "/",
                            },
                            {
                                name: "order.page_title",
                                link: "/order",
                            },
                            {
                                name: detailOrder?.code || "",
                                link: "",
                            },
                        ]}
                    />
                    <OrderDetailHeader />
                    <AddReasonCancel />
                    <div className="mx-3">
                        <Tabs
                            className="nav-space"
                            defaultActiveKey="summary">
                            <Tabs.TabPane
                                tab={trans("order_detail.summary")}
                                key={"summary"}>
                                <Summary onUpdateOrder={onUpdateOrder} />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={trans("order_financing.finnacial_information")}
                                key={"financial"}>
                                <FinancialInfomation />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </>
            )}
        </DefaultLayout>
    )
}
