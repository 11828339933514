import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"
import { InfoProduct } from "./InfoProduct"
import { PropertiesList } from "./Properties"
import { callDetailProduct } from "@reducer/version2/product.reducer"

export const DetailProduct: React.FC = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const [statusShow, setStatusShow] = useState<number>(0)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("contact-detail.product_detail")))
    }, [dispatch])

    useEffect(() => {
        if (id) {
            dispatch(callDetailProduct(id)).then((result: any) => {
                setStatusShow(result?.payload?.response?.status)
            })
        }
    }, [dispatch])

    const PRODUCT_BREADCRUMB = [
        { name: trans("sidebar.dashboard"), link: "/" },
        { name: trans("products.product"), link: "/product" },
        { name: "contact-detail.product_detail" },
    ]

    return (
        <DefaultLayout loading={false}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb breadCrumbs={PRODUCT_BREADCRUMB} />
                    <InfoProduct />
                    <PropertiesList />
                </>
            )}
        </DefaultLayout>
    )
}
