import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB, PAGINATION } from "@config/constant"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { changePageTitleAction } from "@reducer/common.reducer"
import { useAppDispatch } from "@hook/useAppDispatch"
import { trans } from "@resources/localization"
import { TablePackage } from "./Table"
import { Filter } from "./Filter"
import { callGetPackages, callGetWareHouses, callGetStatuses } from "@reducer/version2/package.reducer"
import { callVendorDetail } from "@reducer/version2/vendor-detail.reducer"

export const Package: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.package")))
        dispatch(callVendorDetail())
        dispatch(callGetWareHouses())
        dispatch(callGetStatuses())
    }, [])

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        dispatch(
            callGetPackages({
                ...params,
                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
            })
        )
    }, [dispatch, searchParams])
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    ...HOME_BREADCRUMB,
                    {
                        name: "package.title",
                        link: "/package",
                    },
                ]}
            />
            <Filter />
            <TablePackage />
        </DefaultLayout>
    )
}
