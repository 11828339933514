import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callListCampaigns } from "@reducer/version2/campaign.reducer"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Filter from "./Filter"
import TableCampaign from "./Table"

export const CampaignList: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        delete oldSearch.call
        if (oldSearch?.tab === "campaign") {
            const params = oldSearch
            delete params.tab
            dispatch(
                callListCampaigns({
                    ...params,
                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    sort: "createdAt:DESC",
                })
            )
        }
    }, [dispatch, searchParams])

    return (
        <div>
            <Filter />
            <TableCampaign />
        </div>
    )
}
