import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetProductsOfVendor } from "@reducer/version2/product.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { PAGINATION } from "@config/constant"
import { Filter } from "./Filter"
import { List } from "./ListProduct"
import { callSuggestUser } from "@reducer/version2/user.reducer"
import { callGetProductGroups } from "@reducer/version2/product-groups.reducer"

export const Products: React.FC = () => {
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        if (params.tab === "products") {
            dispatch(changePageTitleAction(trans("products.product")))
            dispatch(callSuggestUser({ keyword: "" }))
            dispatch(callGetProductGroups({ keyword: "" }))
            delete params.createdAt
            delete params.tab

            dispatch(
                callGetProductsOfVendor({
                    ...params,
                    size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    sort: "createdAt:desc",
                })
            )
        }
    }, [dispatch, searchParams])

    return (
        <div>
            <Filter />
            <List />
        </div>
    )
}
