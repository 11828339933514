import CampaignApi from "@api/version2/CampaignApi"
import { PAGINATION } from "@config/constant"
import { ICampaign, ICampaignQuery, ICampaignRequest } from "@domain/version2/Campaign"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    pagination: {
        currentPage: number
        pageSize: number
        total: number
        totalPage: number
    }
    listCampaign: ICampaign[]
    loadingList: boolean
    loadingCreateCampaign: boolean
    campaignsSuggest: ICampaign[]
}

const initState: State = {
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listCampaign: [],
    loadingList: false,
    loadingCreateCampaign: false,
    campaignsSuggest: [],
}

export const callListCampaigns = createAsyncThunk("CAMPAIGN.GET_LIST", async (payload: ICampaignQuery, thunkApi) => {
    try {
        return await CampaignApi.getListCampaign(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreateCampaign = createAsyncThunk("CAMPAIGN.CREATE_CAMPAIGN", async (payload: ICampaignRequest, thunkApi) => {
    try {
        return await CampaignApi.createCampaign(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCampaignsSuggest = createAsyncThunk("CAMPAIGN.GET_CAMPAIGN_SUGGEST", async (params: { keyword: string }, thunkApi) => {
    try {
        return await CampaignApi.getCampaignsSuggest(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const resetCampaignsSuggest = createAction<any>("CAMPAIGN.RESET_CAMPAIGNS_SUGGEST")

export const campaignReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callListCampaigns.pending, (state) => {
            state.loadingList = true
            state.listCampaign = []
        })
        .addCase(callListCampaigns.fulfilled, (state, { payload }) => {
            state.listCampaign = payload?.data || []
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callListCampaigns.rejected, (state) => {
            state.loadingList = false
        })
    builder
        .addCase(callCreateCampaign.pending, (state) => {
            state.loadingCreateCampaign = true
        })
        .addCase(callCreateCampaign.fulfilled, (state) => {
            state.loadingCreateCampaign = false
        })
        .addCase(callCreateCampaign.rejected, (state) => {
            state.loadingCreateCampaign = false
        })
    builder.addCase(resetCampaignsSuggest, (state, action: PayloadAction<any>) => {
        state.campaignsSuggest = action.payload
    })
    builder
        .addCase(callCampaignsSuggest.pending, (state) => {
            state.campaignsSuggest = []
        })
        .addCase(callCampaignsSuggest.fulfilled, (state, { payload }) => {
            state.campaignsSuggest = payload.data
        })
        .addCase(callCampaignsSuggest.rejected, (state) => {
            state.campaignsSuggest = []
        })
})
