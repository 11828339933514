import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import { useParams, useSearchParams } from "react-router-dom"
import SecurityService from "@util/SecurityService"
import { filter } from "lodash"
import Detail from "./Detail"
import History from "./History"
import { callGetDetailInventory } from "@reducer/version2/inventory.reducer"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"
import { INVENTORY_SCOPE } from "@config/permission"
import { callVendorDetail } from "@reducer/version2/vendor-detail.reducer"

export const DetailInventory: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [current, setCurrentTab] = useState<string>(SecurityService?.can(INVENTORY_SCOPE.INVENTORY_DETAIL_VIEW) ? "detail" : "history")
    const [statusShow, setStatusShow] = useState<number>(0)
    const id = useParams().id

    useEffect(() => {
        dispatch(changePageTitleAction(trans("inventory.detail_title")))

        if (id) {
            const oldSearch = Object.fromEntries(searchParams.entries())

            if (searchParams.has("tab")) {
                if (oldSearch?.tab === "detail") {
                    if (SecurityService.can(INVENTORY_SCOPE.INVENTORY_DETAIL_VIEW)) {
                        setCurrentTab("detail")
                        dispatch(callGetDetailInventory(Number(id))).then((result: any) => {
                            setStatusShow(result?.payload?.response?.status)
                        })
                        dispatch(callVendorDetail())
                    } else {
                        setCurrentTab("history")
                        setSearchParams({
                            ...oldSearch,
                            tab: "history",
                        })
                    }
                } else if (oldSearch?.tab === "history") {
                    if (SecurityService.can(INVENTORY_SCOPE.INVENTORY_HISTORY_VIEW)) {
                        setCurrentTab("history")
                    } else {
                        setCurrentTab("detail")
                        setSearchParams({
                            ...oldSearch,
                            tab: "detail",
                        })
                    }
                }
            } else {
                const params: any = {
                    ...oldSearch,
                    tab: "detail",
                }
                setSearchParams(params)
                setCurrentTab("detail")
            }
        }
    }, [dispatch, searchParams])

    const listItems = [
        {
            label: trans("inventory.product_sku_detail"),
            key: "detail",
            children: <Detail />,
            disabled: !SecurityService.can(INVENTORY_SCOPE.INVENTORY_DETAIL_VIEW),
        },
        {
            label: trans("inventory.history"),
            key: "history",
            children: <History />,
            disabled: !SecurityService.can(INVENTORY_SCOPE.INVENTORY_HISTORY_VIEW),
        },
    ]

    const onChangeTab = (e: string) => {
        const params: any = {
            tab: e,
        }
        setSearchParams(params)
    }

    return (
        <DefaultLayout loading={false}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[...HOME_BREADCRUMB, { name: trans("inventory.title"), link: "/inventory" }, { name: trans("inventory.detail_title") }]}
                    />
                    <Tabs
                        className="nav-space"
                        activeKey={current}
                        onChange={onChangeTab}
                        items={filter(listItems, { disabled: false })}
                    />
                </>
            )}
        </DefaultLayout>
    )
}
