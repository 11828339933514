import { IDetailVendorResponse, IVendorConfigs } from "@domain/version2/DetailVendor"
import apiClient from "@util/ApiClient"

export default class VendorApi {
    static getVendorDetail(): Promise<IDetailVendorResponse> {
        return apiClient.get(`vendors/current`)
    }

    static getVendorConfig(): Promise<{ data: IVendorConfigs }> {
        return apiClient.get(`vendors/configs`)
    }
}
