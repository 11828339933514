import { IStatisticsQuery, IDashboardQuery, IAllOrderDashboard, IAllContactDashboard } from "@domain/version2/Dashboard"
import apiClient from "@util/ApiClient"

export default class DashboardApi {
    static getStatisticsContacts(params: IDashboardQuery): Promise<{ data: IAllContactDashboard }> {
        return apiClient.get(`statistics/contacts`, {
            params,
        })
    }
    static getStatisticsOrders(params: IStatisticsQuery): Promise<{ data: IAllOrderDashboard }> {
        return apiClient.get(`statistics/orders`, {
            params,
        })
    }
}
