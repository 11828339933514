import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
// import { useSearchParams } from "react-router-dom"
import { Filter } from "./Filter"
import Statistic from "./Statistic"
import { List } from "./Table"

export const OrderFinancing: React.FC = () => {
    // const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("order_financing.title")))
    }, [dispatch])

    // useEffect(() => {
    //     const params = Object.fromEntries(searchParams.entries())
    //     dispatch(
    //         callListOrders({
    //             ...params,
    //             pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
    //             page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
    //             sort: "createdAt:desc",
    //         })
    //     )
    // }, [dispatch, searchParams])

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb breadCrumbs={[...HOME_BREADCRUMB, { name: "order_financing.title", link: "/order-financing" }]} />
            <Filter />
            <Statistic />
            <List />
        </DefaultLayout>
    )
}
