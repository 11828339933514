import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { PackageApi } from "@api/version2/PackageApi"
import { IDetailPackage } from "@domain/version2/PackageDetail"

interface State {
    dataDetail: IDetailPackage
    loadingDetail: boolean
    loadingUpdatePackage: boolean
    loadingImport: boolean
}
const initState: State = {
    dataDetail: {}  as IDetailPackage,
    loadingDetail: false,
    loadingUpdatePackage: false,
    loadingImport: false
}

export const callGetPackageDetail = createAsyncThunk("PACKAGE_DETAIL.GET_PACKAGE_DETAIL", async (code: string, thunkApi) => {
    try {
        return await PackageApi.getPackageDetail(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdatePackage = createAsyncThunk("PACKAGE_DETAIL.UPDATE_PACKAGE", async (id: number, thunkApi) => {
    try {
        return await PackageApi.updatePackage(id)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callImportFilePackage = createAsyncThunk("PACKAGE.IMPORT_FILE", async (file: any, thunkApi) => {
    try {
        return await PackageApi.importPackageSku(file)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const packageDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetPackageDetail.pending, (state) => {
            state.dataDetail = {} as IDetailPackage
            state.loadingDetail = true
        })
        .addCase(callGetPackageDetail.fulfilled, (state, { payload }) => {
            state.dataDetail = payload?.data
            state.loadingDetail = false
        })
        .addCase(callGetPackageDetail.rejected, (state) => {
            state.dataDetail = {} as IDetailPackage
            state.loadingDetail = false
        })
    builder
        .addCase(callUpdatePackage.pending, (state) => {
            state.loadingUpdatePackage = true
        })
        .addCase(callUpdatePackage.fulfilled, (state) => {
            state.loadingUpdatePackage = false
        })
        .addCase(callUpdatePackage.rejected, (state) => {
            state.loadingUpdatePackage = false
        })
    builder
        .addCase(callImportFilePackage.pending, (state) => {
            state.loadingImport = true
        })
        .addCase(callImportFilePackage.fulfilled, (state) => {
            state.loadingImport = false
        })
        .addCase(callImportFilePackage.rejected, (state) => {
            state.loadingImport = false
        })
})
