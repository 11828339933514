import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import IContactDetail, { IContactUpdateDetail } from "@domain/version2/ContactDetail"
import ContactApi from "@api/version2/ContactApi"
import { IAds } from "@domain/version2/Ads"
interface State {
    dataDetail: IContactDetail
    loadingDetail: boolean
    adsOfContact: IAds[]
    loadingUpdateContact: boolean
    loadingUpdateContactRemark: boolean
}
const initState: State = {
    dataDetail: {} as IContactDetail,
    loadingDetail: false,
    adsOfContact: [],
    loadingUpdateContact: false,
    loadingUpdateContactRemark: false,
}

export const callGetContactDetail = createAsyncThunk("CONTACT_DETAIL.GET_CONTACT_DETAIL", async (id: string, thunkApi) => {
    try {
        return await ContactApi.getContactDetail(id)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetAdsOfContact = createAsyncThunk("CONTACT_DETAIL.GET_ADS", async (id: string, thunkApi) => {
    try {
        return await ContactApi.getAdsOfContact(id)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callUpdateContactDetail = createAsyncThunk(
    "CONTACT_DETAIL.UPDATE_INFO",
    async (payload: { contactId: string; data: IContactUpdateDetail }, thunkApi) => {
        try {
            return await ContactApi.updateContactDetail(payload.contactId, payload.data)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callUpdateContactDetailRemark = createAsyncThunk(
    "CONTACT_DETAIL.UPDATE_REMARK",
    async (payload: { contactId: string; data: { isSpam: boolean } }, thunkApi) => {
        try {
            return await ContactApi.updateContactDetailRemark(payload.contactId, payload.data)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const resetDataDetail = createAction<any>("CONTACT_DETAIL.RESET_DATA_DETAIL")

export const contactDetailReducer2 = createReducer(initState, (builder) => {
    builder
        .addCase(callGetContactDetail.pending, (state) => {
            state.dataDetail = {} as IContactDetail
            state.loadingDetail = true
        })
        .addCase(callGetContactDetail.fulfilled, (state, { payload }) => {
            state.dataDetail = payload?.data
            state.loadingDetail = false
        })
        .addCase(callGetContactDetail.rejected, (state) => {
            state.dataDetail = {} as IContactDetail
            state.loadingDetail = false
        })

    builder
        .addCase(callGetAdsOfContact.pending, (state) => {
            state.adsOfContact = []
        })
        .addCase(callGetAdsOfContact.fulfilled, (state, { payload }) => {
            state.adsOfContact = payload?.data
        })
        .addCase(callGetAdsOfContact.rejected, (state) => {
            state.adsOfContact = []
        })

    builder.addCase(resetDataDetail, (state, action: PayloadAction<any>) => {
        state.dataDetail = action.payload
    })

    builder
        .addCase(callUpdateContactDetail.pending, (state) => {
            state.loadingUpdateContact = true
        })
        .addCase(callUpdateContactDetail.fulfilled, (state) => {
            state.loadingUpdateContact = false
        })
        .addCase(callUpdateContactDetail.rejected, (state) => {
            state.loadingUpdateContact = false
        })
    builder
        .addCase(callUpdateContactDetailRemark.pending, (state) => {
            state.loadingUpdateContactRemark = true
        })
        .addCase(callUpdateContactDetailRemark.fulfilled, (state) => {
            state.loadingUpdateContactRemark = false
        })
        .addCase(callUpdateContactDetailRemark.rejected, (state) => {
            state.loadingUpdateContactRemark = false
        })
})
