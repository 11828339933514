import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetUrlConnectChannel, changeVisibleFormAddAccount } from "@reducer/version2/sell-channel.reducer"
import { trans } from "@resources/localization"
import { Button, Form, message, Select, Space } from "antd"
import React, { useEffect } from "react"

export const InputForm = () => {
    const dispatch = useAppDispatch()
    const [formRef] = Form.useForm()
    const listWarehouse = useAppSelector((state) => state.inventory.warehouses)
    const listChannels = useAppSelector((state) => state.sellChannel.listChannels)
    const currentUrl = window.location.href
    const redirectUrl = useAppSelector((state) => state.sellChannel.urlConnectChannel)

    useEffect(() => {
        if (redirectUrl !== "")
            setTimeout(() => {
                window.location.replace(redirectUrl)
            }, 500)
    }, [dispatch, redirectUrl])

    const onCreateContact = (values: any) => {
        dispatch(
            callGetUrlConnectChannel({
                url: currentUrl,
                sellingChannel: values.sellingChannel,
                warehouseId: values.warehouseId,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                setTimeout(() => {
                    formRef.resetFields()
                    dispatch(changeVisibleFormAddAccount(false))
                }, 500)
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <div>
            <Form
                form={formRef}
                onFinish={onCreateContact}
                layout="vertical">
                <Form.Item
                    name="sellingChannel"
                    label={trans("sell_channel.ecommerce")}
                    rules={[{ required: true, message: trans("message.required") }]}>
                    <Select
                        allowClear
                        loading={false}
                        showSearch
                        filterOption={(input: any, option: any) => {
                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                        }}
                        filterSort={(optionA: any, optionB: any) => {
                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                        }}
                        placeholder={trans("sell_channel.ecommerce_placeholder")}>
                        {listChannels.map((el) => (
                            <Select.Option
                                key={el}
                                value={el}>
                                {el}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="warehouseId"
                    label={trans("sell_channel.export_warehouse")}
                    rules={[{ required: true, message: trans("message.required") }]}>
                    <Select
                        allowClear
                        loading={false}
                        showSearch
                        filterOption={(input: any, option: any) => {
                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                        }}
                        // filterSort={(optionA: any, optionB: any) => {
                        //     return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                        // }}
                        placeholder={trans("sell_channel.export_warehouse_placeholder")}>
                        {listWarehouse.map((el) => (
                            <Select.Option
                                key={el.id}
                                value={el.id}>
                                {el.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>{" "}
                <Space className="flex justify-end">
                    <Button
                        htmlType="button"
                        onClick={() => dispatch(changeVisibleFormAddAccount(false))}>
                        {trans("button.cancel_button")}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit">
                        {trans("sell_channel.add_connection")}
                    </Button>
                </Space>
            </Form>
        </div>
    )
}
