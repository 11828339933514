import { IContactLocalQuery } from "@domain/version2/Contact"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import "@resources/less/page/contact.less"
import moment from "moment"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const [isShowMore, setIsShowMore] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.package.loadingList)
    const wareHouses = useAppSelector((state) => state.package.wareHouses)
    const statuses = useAppSelector((state) => state.package.statuses)

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("createdAtFrom") && searchParams.has('createdAtTo') && {
                    createdAt: [moment(oldSearch?.createdAtFrom), moment(oldSearch.createdAtTo)],
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [])

    const handleChangeFilter = (values: IContactLocalQuery) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                createdAtFrom: values?.createdAt ? moment(values?.createdAt[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'): undefined,
                createdAtTo: values?.createdAt ? moment(values?.createdAt[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined,
            }
            delete params.createdAt
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }

    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={handleChangeFilter}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("package.code")}
                            name="code">
                            <Input
                                allowClear
                                placeholder={trans("package.filter_code")}
                                suffix={<i className="fa-regular fa-magnifying-glass"></i>}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("package.code_sku")}
                            name="sku">
                            <Input
                                allowClear
                                placeholder={trans("package.filter_code_sku")}
                                suffix={<i className="fa-regular fa-magnifying-glass"></i>}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("package.tracking")}
                            name="trackingNumber">
                            <Input
                                allowClear
                                placeholder={trans("package.filter_tracking")}
                                suffix={<i className="fa-regular fa-magnifying-glass"></i>}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {isShowMore && (
                    <Row gutter={15}>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("package.status")}
                            name="packageStatus">
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                placeholder={trans("package.filter_select_status")}>
                                {!isEmpty(statuses) &&
                                    statuses?.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item?.code}>
                                            {trans(`package.status_${item?.code}`)}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("package.receiving_warehouse")}
                            name="warehouse">
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                placeholder={trans("package.filter_receiving_warehouse")}>
                                {!isEmpty(wareHouses) &&
                                    wareHouses?.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item?.id}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("package.created_at")}
                            name="createdAt">
                            <DatePicker.RangePicker
                                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                allowClear
                                placeholder={[trans("package.from"), trans("package.to")]}
                            />
                        </Form.Item>
                    </Col>
                    </Row>
                )}
                <Row>
                    <Col span={18}>
                        <Space>
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={loadingList}>
                                {trans("button.filter")}
                            </Button>
                            <Button
                                type="default"
                                onClick={() => {
                                    formRef.resetFields()
                                    setSearchParams({})
                                }}
                                loading={loadingList}>
                                {trans("contact.clear")}
                            </Button>
                            <Button
                                type="link"
                                onClick={() => setIsShowMore((old) => !old)}>
                                {isShowMore ? trans("contact.filter_less") : trans("contact.filter_more")}
                                <i className={`ml-2 fa-solid ${isShowMore ? "fa-chevron-up" : "fa-chevron-down"}`} />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
