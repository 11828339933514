import { ADS_SCOPE } from "@config/permission"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Card, Col, Form, Input, Row, Select } from "antd"
import React from "react"
type Props = {
    formRef: any
}
export const GeneralInfo: React.FC<Props> = ({ formRef }) => {
    const disableUpdate = !SecurityService.can(ADS_SCOPE.ADS_UPDATE)
    return (
        <Card className="space-layout">
            <Row gutter={12}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="campaignCode"
                        hidden
                    />
                    <Form.Item
                        name="campaignName"
                        label={trans("order_list.campaign")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="name"
                        label={trans("ads.name")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Input
                            maxLength={256}
                            placeholder={trans("ads.name_pl")}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    name: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            disabled={disableUpdate}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="facebookAdIds"
                        label={trans("ads.facebook_ads")}>
                        <Select
                            placeholder={trans("campaign.facebook_create_pl")}
                            mode="tags"
                            options={[]}
                            disabled={disableUpdate}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="urls"
                        label={trans("ads.landing_url")}>
                        <Select
                            placeholder={trans("ads.landing_url_pl")}
                            mode="tags"
                            options={[]}
                        />
                    </Form.Item>
                </Col>
                {/* <Col xs={{span: 24}} sm={{span: 12}} md={{span: 12}} lg={{span: 8}}>
                    <Space>
                    <div className="flex items-center">
                        <Form.Item
                            name="status"
                            label=" "
                            valuePropName="checked">
                            <Switch disabled={disableUpdate} />
                        </Form.Item>
                        <Typography.Text className="mb-5 mt-6 ml-2">{trans("products.active")}</Typography.Text>
                    </div>
                    <div className="flex items-center ml-8">
                        <Form.Item
                            name="default"
                            label=" "
                            valuePropName="checked">
                            <Switch disabled={disableUpdate} />
                        </Form.Item>
                        <Typography.Text className="mb-5 mt-6 ml-2">{trans("ads_list.default")}</Typography.Text>
                    </div>
                    </Space>
                </Col> */}
            </Row>
        </Card>
    )
}
