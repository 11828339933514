import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetListCurrencies } from "@reducer/version2/currency.reducer"
import { changeVisibleFormCreatePaymentState } from "@reducer/version2/paymentStatement.reducer"
import { trans } from "@resources/localization"
import { Modal } from "antd"
import React, { useEffect } from "react"
import InputForm from "./InputForm"

const CreatePaymentStatement = () => {
    const dispatch = useAppDispatch()
    const visibleFormCreatePaymentStatement = useAppSelector((state) => state.paymentStatement.visibleFormCreatePaymentStatement)

    useEffect(() => {
        dispatch(callGetListCurrencies())
    }, [dispatch])

    return (
        <Modal
            destroyOnClose
            title={trans("payment_statement.add")}
            visible={visibleFormCreatePaymentStatement}
            footer={null}
            width={500}
            onCancel={() => dispatch(changeVisibleFormCreatePaymentState(false))}>
            <InputForm />
        </Modal>
    )
}

export default CreatePaymentStatement
