import { trans } from "@resources/localization"
import { Col, Form, Row, Input, Select, Space, Button, DatePicker, Card } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useAppSelector } from "@hook/useAppSelector"
import { useSearchParams } from "react-router-dom"
import { filterWithTimezone } from "@util/Common"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const paymentStatementStatuses = useAppSelector((state) => state.paymentStatement.listPaymentStatementStatus)
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.paymentStatement.loadingList)

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const search = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...search,
                ...(searchParams.has("status") && {
                    status: search?.status?.split(","),
                }),
                ...(searchParams.has("createdAtFrom") && searchParams.has("createdAtTo") && {
                    createdAt: [filterWithTimezone(search?.createdAtFrom), filterWithTimezone(search?.createdAtTo)],
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [searchParams])

    const handleSubmit = (values: any) => {
        setSearchParams(() => {
            const oldSearch = Object.fromEntries(searchParams.entries())
            let params: any = {
                ...oldSearch,
                ...values,
                ...(values?.createdAt &&
                    values?.createdAt[0] && {
                        createdAtFrom: filterWithTimezone(values?.createdAt[0]).startOf('day').toISOString(),
                    }),
                ...(values?.createdAt &&
                    values?.createdAt[1] && {
                        createdAtTo: filterWithTimezone(values?.createdAt[1]).endOf('day').toISOString(),
                    }),
                status: values?.status ? values?.status?.join(",") : undefined,
            }
            delete params.createdAt
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }

    return (
        <Card className="space-layout">
        <Form
            form={formRef}
            onFinish={handleSubmit}
            layout="vertical">
            <Row gutter={12}>
                <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                        label={trans("payment_statement.code")}
                        name="code">
                        <Input
                            allowClear
                            placeholder={trans("payment_statement.code_filter")}
                            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                        name="createdAt"
                        label={trans("payment_statement.creation_time")}>
                        <DatePicker.RangePicker
                            format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                            allowClear
                            placeholder={[trans("order_list.from"), trans("order_list.to")]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                        name="status"
                        label={trans("payment_statement.status")}>
                        <Select
                            allowClear
                            mode="multiple"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => {
                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                            }}
                            filterSort={(optionA: any, optionB: any) => {
                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                            }}
                            placeholder={trans("payment_statement.status_filter")}>
                            {paymentStatementStatuses.map((item: any, key) => (
                                <Select.Option
                                    key={key}
                                    value={item?.code}>
                                    {trans(`payment_statement.${item?.code}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Space>
                        <Button
                            type="primary"
                            loading={loadingList}
                            htmlType="submit">
                            {trans("payment_statement.filter")}
                        </Button>
                        <Button
                            type="default"
                            loading={loadingList}
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({})
                            }}>
                            {trans("payment_statement.clear")}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
        </Card>
    )
}
