import React, { PropsWithChildren } from "react"

import { Button, Card, Input, Form, Space, Row, Col, Typography } from "antd"
import { trans } from "@resources/localization"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeDataOrderUpdate, changeEditNoteOrder } from "@reducer/version2/order-detail.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { STATUS_CAN_UPDATE } from "@config/constant"

interface Props extends PropsWithChildren<any> {
    onUpdateOrder: (key: string, value: any) => void
}

export const Note = (props: Props) => {
    const [formRef] = Form.useForm()
    const { onUpdateOrder } = props
    const dispatch = useAppDispatch()
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2?.orderDetail)
    const isEditNoteOrder = useAppSelector((state) => state.orderDetailVersion2.isUpdateNote)
    const loadingUpdateOrder = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrder)
    const dataUpdateOrder = useAppSelector((state) => state.orderDetailVersion2.dataOrderUpdate)
    const renderButton = () => {
        if (isEditNoteOrder) {
            return (
                <Space>
                    <Button
                        type="primary"
                        loading={loadingUpdateOrder}
                        onClick={() => formRef.submit()}>
                        {trans("button.update")}
                    </Button>
                    <Button
                        loading={loadingUpdateOrder}
                        type="default"
                        onClick={() => {
                            formRef.resetFields()
                            dispatch(
                                changeDataOrderUpdate({
                                    ...dataUpdateOrder,
                                    note: "",
                                })
                            )
                            dispatch(changeEditNoteOrder(false))
                        }}>
                        {trans("button.cancel")}
                    </Button>
                </Space>
            )
        }
        if (SecurityService.can(ORDER_SCOPE.ORDER_NOTE_UPDATE) && STATUS_CAN_UPDATE.includes(detailOrder?.status || "")) {
            return (
                <Button
                    type="default"
                    loading={loadingUpdateOrder}
                    icon={
                        <i
                            className="fa fa-pencil mr-2"
                            aria-hidden="true"></i>
                    }
                    onClick={() => dispatch(changeEditNoteOrder(true))}>
                    {trans("button.edit")}
                </Button>
            )
        }
    }

    const renderTextMode = () => {
        if (isEditNoteOrder) {
            return (
                <Form
                    form={formRef}
                    initialValues={{
                        note: detailOrder?.note,
                    }}
                    onFinish={(values) => {
                        onUpdateOrder("note", values.note)
                    }}
                    onValuesChange={(values) => {
                        dispatch(
                            changeDataOrderUpdate({
                                ...values,
                            })
                        )
                    }}>
                    <Form.Item name="note">
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Form>
            )
        }
        return (
            <Row className="">
                <Col span={24}>
                    <Typography.Text className={"font-medium"}>{`${trans("order.order_note")}: `}</Typography.Text>
                    <Typography.Text>{detailOrder?.note}</Typography.Text>
                </Col>
                {detailOrder?.status === "CANCELLED" && (
                    <Col span={24}>
                        <Typography.Text className={"font-medium"}>{`${trans("order.cancel_note")}: `}</Typography.Text>
                        <Typography.Text>
                            {detailOrder?.cancelReason
                                ? detailOrder?.cancelReason
                                : detailOrder?.subStatus
                                ? trans(`order_status_cancelled.${detailOrder?.subStatus.split(":")[1]}`)
                                : ""}
                        </Typography.Text>
                    </Col>
                )}
            </Row>
        )
    }
    return (
        <>
            <Card
                className="space-y"
                title={trans("order_detail.note")}
                extra={renderButton()}>
                {renderTextMode()}
            </Card>
        </>
    )
}
