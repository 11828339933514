import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import Table, { ColumnsType } from "antd/lib/table"
import React from "react"

export const TableError: React.FC = () => {
    const dataResponseImport = useAppSelector((state) => state.contact2.dataResponseImport)

    const columns: ColumnsType<any> = [
        {
            title: trans("order_list.lbc_import_line"),
            className: "bold-400",
            dataIndex: "line",
            width: 100,
        },
        {
            title: trans("order_list.lbc_import_error"),
            className: "bold-400",
            render: (row) => {
                return row?.message || ""
            },
        },
    ]
    return (
        <div>
            {dataResponseImport?.successCount && dataResponseImport?.successCount > 0 ? (
                <p>{`${trans("contact.noti_succ")} ${dataResponseImport?.successCount} ${trans("contact-detail.contact")}`}</p>
            ) : (
                ""
            )}
            <Table
                columns={columns}
                dataSource={dataResponseImport?.errors || []}
                pagination={false}
                scroll={{ y: 600 }}
            />
        </div>
    )
}
