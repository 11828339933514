import { Modal, Typography } from "antd"
import React, { useState } from "react"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { changeVisibleOrderImport } from "@reducer/version2/order.reducer"
import { FormImport } from "./FormImport"
import { TableError } from "./TableError"

export const ImportOrderFinancialStatus: React.FC = () => {
    const [isUpload, setIsUpload] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const visibleImport = useAppSelector((state) => state.orderVersion2.isShowImport)

    return (
        <Modal
            destroyOnClose
            title={!isUpload ? trans("order.import_financial") : <Typography.Text type="danger">{trans("order_list.import_tracking_number_fail_list_title")}</Typography.Text>}
            open={visibleImport}
            width={770}
            footer={null}
            onCancel={() => {
                setIsUpload(false)
                dispatch(changeVisibleOrderImport(false))
            }}>
            {!isUpload ? <FormImport setIsUpload={setIsUpload} /> : <TableError />}
        </Modal>
    )
}