import { PAGINATION } from "@config/constant"
// import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Row, Table, Pagination, Card } from "antd"
import { ColumnsType } from "antd/lib/table"
import { formatDateFull } from "@util/Common"
import React from "react"
import { useSearchParams } from "react-router-dom"
// import SecurityService from "@util/SecurityService"
// import { PRODUCT_SCOPE } from "@config/permission"
// import { useAppSelector } from "@hook/useAppSelector"
// import { find } from "lodash"

export const List: React.FC = () => {
    const total = 4
    const currentPage = 4
    const totalPage = 4
    const pageSize = 4
    // const total = useAppSelector((state) => state.productVersion2.pagination.total)
    // const currentPage = useAppSelector((state) => state.productVersion2.pagination.currentPage)
    // const totalPage = useAppSelector((state) => state.productVersion2.pagination.totalPage)
    // const pageSize = useAppSelector((state) => state.productVersion2.pagination.pageSize)

    // const listProduct = useAppSelector((state) => state.productVersion2.listProductOfVendor)
    // const listProductGroups = useAppSelector((state) => state.productGroups.listProductGroups)

    const [searchParams, setSearchParams] = useSearchParams()

    const columns: ColumnsType<any> = [
        {
            title: trans("contact-detail.order_code"),
            dataIndex: "orderCode",
            className: "bold-400",
            width: "10%",
        },
        {
            title: trans("warehouse.sku_code"),
            dataIndex: "skuCode",
            className: "bold-400",
            width: "10%",
        },
        {
            title: trans("order_financing.order_amount"),
            className: "bold-400",
            dataIndex: "orderAmount",
            width: "10%",
        },
        {
            title: trans("order_financing.received_cod"),
            className: "bold-400",
            dataIndex: "skuQuantity",
            width: "10%",
        },
        {
            title: trans("order_financing.total_fee"),
            className: "bold-400",
            width: "10%",
            dataIndex: "campaignQuantity",
        },
        {
            title: trans("order_financing.balance"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: "10%",
        },
        {
            title: trans("order_detail.financial_status"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: "10%",
        },
        {
            title: trans("table.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (value) => (value ? formatDateFull(value) : "---"),
            width: "10%",
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize: pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        setSearchParams(params)
    }
    return (
        <Card
            className="space-layout"
            // title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}>
            title={`${trans("contact.page")} ${currentPage}/${totalPage}(${total})`}>
            <Table
                scroll={{
                    x: true,
                }}
                columns={columns}
                dataSource={[]}
                rowKey={(record) => record.id}
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/${trans("contact.page")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
