import React from "react"

import { Result } from "antd"
import { trans } from "@resources/localization"

export const AccessDenied = () => {
    return (
        <Result
            status="403"
            title="403"
            subTitle={trans("login.notPermission")}
        />
    )
}
