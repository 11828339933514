import React from "react"
import { PAGINATION } from "@config/constant"
import { trans } from "@resources/localization"
import { Button, Card } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import { useAppSelector } from "@hook/useAppSelector"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { IAds } from "@domain/version2/Ads"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { ADS_SCOPE } from "@config/permission"
import { ICampaign } from "@domain/version2/Campaign"

const TableAds = () => {
    const navigate = useNavigate()
    const pageSize: number = useAppSelector((state) => state.adsVersion2.pagination.pageSize)
    const currentPage: number = useAppSelector((state) => state.adsVersion2.pagination.currentPage)
    const total: number = useAppSelector((state) => state.adsVersion2.pagination.total)
    const totalPage: number = useAppSelector((state) => state.adsVersion2.pagination.totalPage)
    const listAds = useAppSelector((state) => state.adsVersion2.listAds)
    const loadingList = useAppSelector((state) => state.adsVersion2.loadingList)
    const [searchParams, setSearchParams] = useSearchParams()

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            pageSize,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        params.tab = "ads"
        setSearchParams(params)
    }

    const columns: ColumnsType<IAds> = [
        {
            title: trans("ads.code"),
            className: "bold-400",
            dataIndex: "code",
            width: 100,
            render: (code: string, record: IAds) =>
                !SecurityService.can(ADS_SCOPE.ADS_DETAIL_ALL) && !SecurityService.can(ADS_SCOPE.ADS_DETAIL) ? (
                    code
                ) : (
                    <Link to={`/ads/${record?.code}?createdBy=${record?.createdBy}`}>{code}</Link>
                ),
        },
        {
            title: trans("ads.name"),
            className: "bold-400",
            dataIndex: "name",
            width: 150,
        },
        {
            title:trans("ads.type"),
            className: "bold-400",
            dataIndex: 'type',
            key: 'type',
            width: 150,
            render:(type, record: IAds) => record?.campaign?.productCode ? trans("campaign.product") : trans("skuCombo.title")
        },
        {
            title: `${trans("campaign.product")}/${trans("skuCombo.title")}`,
            className: "bold-400",
            dataIndex: 'product',
            key: 'product',
            width: 150,
            render:(product, record: IAds) => record?.campaign?.productCode ? record?.campaign?.product?.name : record?.campaign?.combo?.name
        },
        {
            title: trans("ads.campaign"),
            className: "bold-400",
            dataIndex: "campaign",
            width: 150,
            render: (campaign: ICampaign) => <p className="mb-0">{campaign?.name}</p>,
        },
        // {
        //     title: trans("ads.contact"),
        //     className: "bold-400",
        //     dataIndex: "quantityOfContacts",
        //     width: "5%",
        // },
        {
            title: trans("ads.create_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            width: 120,
            render: (date) => (date ? formatDateFull(date) : ""),
        },
        {
            title: trans("ads.create_by"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: 100,
        },
    ]

    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                SecurityService.can(ADS_SCOPE.ADS_CREATE) && (
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate("/ads/create")
                        }}>
                        {trans("ads.new_ads")}
                    </Button>
                )
            }>
            <Table
                rowKey={"id"}
                scroll={{ x: true }}
                loading={loadingList}
                columns={columns}
                dataSource={listAds}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: pageSize,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                }}
            />
        </Card>
    )
}

export default TableAds
