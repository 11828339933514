import { COMBO_SCOPE } from "@config/permission"
import { IComboCategories } from "@domain/version2/Categories"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Card, Col, Form, Input, Row, Select, Switch, Typography } from "antd"
// import { debounce } from "lodash"
import React from "react"

type Props = {
    formRef: any
}
export const GeneralInfo: React.FC<Props> = ({ formRef }) => {
    const skuComboDetail = useAppSelector((state) => state.skuCombo.skuComboDetail)
    const listComboCategories = useAppSelector((state) => state.categoriesVersion2.listSkuComboCategories)
    const skuUpdate = SecurityService.can(COMBO_SCOPE.COMBO_DETAIL_UPDATE)

    return (
        <Card
            className="space-layout"
            title={skuComboDetail?.name || ""}>
            <Row
                gutter={[15, 15]}
                className="mb-5">
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Typography.Text
                        className="block"
                        strong>
                        {trans("table.created_by")}
                    </Typography.Text>
                    <Typography.Text className="block mt-3">{skuComboDetail?.createdBy || ""}</Typography.Text>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Typography.Text
                        className="block"
                        strong>
                        {trans("table.created_at")}
                    </Typography.Text>
                    <Typography.Text className="block mt-3">{skuComboDetail?.createdAt ? formatDateFull(skuComboDetail?.createdAt) : ""}</Typography.Text>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    className="flex">
                    <Form.Item
                        name="isActive"
                        label={trans("contact-detail.status")}
                        style={{ fontWeight: "bold" }}
                        valuePropName="checked">
                        <Switch disabled={!skuUpdate} />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.isActive !== next.isActive}>
                        {({ getFieldValue }) => (
                            <Typography.Text className="mt-9 ml-2">
                                {getFieldValue("isActive") ? trans("user_list.active") : trans("user_list.inactive")}
                            </Typography.Text>
                        )}
                    </Form.Item>
                </Col>

                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="code"
                        style={{ fontWeight: "bold" }}
                        label={trans("skuCombo.code")}>
                        <Input
                            maxLength={256}
                            style={{ fontWeight: "normal" }}
                            // onChange={(e: any) => {
                            //     const text: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "")
                            //     formRef.setFieldsValue({
                            //         name: text,
                            //     })
                            // }}
                            // onBlur={(e) => {
                            //     formRef.setFieldsValue({
                            //         name: e.target.value.trim(),
                            //     })
                            // }}
                            // onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="name"
                        label={trans("skuCombo.name")}
                        style={{ fontWeight: "bold" }}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Input
                            maxLength={256}
                            placeholder={trans("skuCombo.name_pl")}
                            style={{ fontWeight: "normal" }}
                            disabled={!skuUpdate}
                            onChange={(e: any) => {
                                const text: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:"'<>?\\]/g, "")
                                formRef.setFieldsValue({
                                    name: text,
                                })
                            }}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    name: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="category"
                        label={trans("skuCombo.category")}
                        style={{ fontWeight: "bold" }}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            allowClear
                            disabled={!skuUpdate}
                            style={{ fontWeight: "normal" }}
                            placeholder={trans("skuCombo.category_pl")}>
                            {listComboCategories?.map((el: IComboCategories) => (
                                <Select.Option
                                    key={el.id}
                                    value={el.code}>
                                    {el.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}
