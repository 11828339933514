const appConfig = {
    apiUrl: process.env.REACT_APP_FOBIZ_API_ROOT,
    apiUrl1: `${process.env.REACT_APP_FOBIZ_API_ROOT}/v1`,
    apiUrl2: `${process.env.REACT_APP_FOBIZ_API_ROOT}/v2`,
    loginUrl: process.env.REACT_APP_FOBIZ_LOGIN_ROOT,
    logoutUrl: process.env.REACT_APP_FOBIZ_LOGOUT_ROOT,
    authenticationUrl: window.location.origin + "/authentication",
    env: process.env.REACT_APP_ENV,
}

export default appConfig
