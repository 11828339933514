import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callUpdateUserInfo } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import { Button, message, Select } from "antd"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
type Props = {
    goBack: () => void
}
export const TimezoneSetting: React.FC<Props> = ({ goBack }) => {
    const timezones = useAppSelector((state) => state.common.timezones)
    const userInfo = useAppSelector((state) => state.common.userInfo)
    const [currentTimezone, setCurrentTimezone] = useState("")
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!isEmpty(userInfo)) {
            setCurrentTimezone(userInfo?.zoneinfo || '')
        }
    }, [userInfo])
    const onChangeTimezone = (value: string) =>{
        dispatch(callUpdateUserInfo({
            zoneInfo: value 
        })).then((result: any) =>{
            if(result?.payload?.status === 200){
                localStorage.setItem('zoneinfo',value)
                message.success(trans('message.success'))
                window.location.reload()
            }else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <div className="w-56">
            <div className="flex items-center">
                <Button
                    type="link"
                    onClick={() => goBack()}
                    icon={<i className="fa-sharp fa-solid fa-chevrons-left"></i>}
                />

                <h5 className="font-medium m-0">{trans("setting.time_zone")}</h5>
            </div>
            <p className="m-0">{trans("setting.time_zone_select")}</p>
            <Select
                onChange={(value) => {
                    onChangeTimezone(value)
                    setCurrentTimezone(value)}}
                value={currentTimezone}
                className="w-full">
                {timezones?.map((item) => (
                    <Select.Option
                        key={item?.id}
                        value={item?.code}>
                        {item?.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    )
}
