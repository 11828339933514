import { trans } from "@resources/localization"
import { optionType } from "@util/Common"
import { Button, Card, Col, Form, Row, Select } from "antd"
import React from "react"
import { Combo } from "./Combo"
import { Products } from "./Products"

interface IProps {
    formRef: any
    setIsExpand: () => void
}

const SelectOrderType = (props: IProps) => {
    const handleSelectOrderType = () => {
        props.formRef.setFieldsValue({
            search: "",
            searchCombo: "",
            tableProducts: [],
            tableCombo: [],
            totalPayment: undefined,
            deliveryFee: undefined,
        })
    }

    return (
        <div className="mt-3">
            <Card
                title={trans("order.detail_info")}
                extra={
                    <Button
                        type={"link"}
                        icon={
                            <i
                                className="fa fa-arrows-alt"
                                aria-hidden="true"></i>
                        }
                        onClick={props.setIsExpand}
                    />
                }>
                <Row>
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}>
                        <Form.Item
                            label={trans("order.order_type")}
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select
                                placeholder={trans("order.order_type_pl")}
                                onSelect={handleSelectOrderType}>
                                {optionType?.map((item) => (
                                    <Select.Option
                                        key={item?.value}
                                        value={item?.value}>
                                        {item?.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.type !== next.type}>
                        {({ getFieldValue }) => (
                            <>
                                {getFieldValue("type") === "product" && <Products formRef={props.formRef} />}
                                {getFieldValue("type") === "combo" && <Combo formRef={props.formRef} />}
                            </>
                        )}
                    </Form.Item>
                </Row>
            </Card>
        </div>
    )
}

export default SelectOrderType
