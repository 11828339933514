import { AccessDenied } from "@component/AccessDenied"
import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { Notfound } from "@component/Notfound"
import { COMBO_SCOPE } from "@config/permission"
import { IUpdateSkuComboRequest } from "@domain/version2/SkuCombo"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
// import { useAppSelector } from "@hook/useAppSelector"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetListSkuComboCategories } from "@reducer/version2/categories.reducer"
import { callGetDetailSkuCombo, callUpdateSkuCombo } from "@reducer/version2/skuCombo.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Button, Form, message, Row, Space } from "antd"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GeneralInfo } from "./General"
import { ListProduct } from "./ListProduct"

export const DetailSkuCombo: React.FC = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const skuComboDetail = useAppSelector((state) => state.skuCombo.skuComboDetail)
    const loadingDetail = useAppSelector((state) => state.skuCombo.loadingDetail)
    const loadingUpdate = useAppSelector((state) => state.skuCombo.loadingUpdate)
    const navigate = useNavigate()
    const [statusShow, setStatusShow] = useState<number>(0)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("skuCombo.title")))
        dispatch(callGetListSkuComboCategories({ keyword: "" }))
        if (id)
            dispatch(callGetDetailSkuCombo(id)).then((result: any) => {
                setStatusShow(result?.payload?.response?.status)
            })
    }, [id, dispatch])

    useEffect(() => {
        if (!isEmpty(skuComboDetail)) {
            const tableProducts = skuComboDetail?.skus.map((el) =>({
                product: el?.sku?.product?.name || '',
                quantity: el.quantity,
                sku: el?.skuCode,
                variant: Object.values(el?.sku?.variant).join("/"),
                type: 'old'
            }))
            formRef.setFieldsValue({
                name: skuComboDetail?.name,
                code: skuComboDetail?.code,
                category: skuComboDetail?.categoryCode,
                isActive: skuComboDetail?.isActive,
                tableProducts,
            })
        }
    }, [skuComboDetail, dispatch])

    const onUpdateSkuCombo = (values: any) => {
        const data: IUpdateSkuComboRequest = {
            name: values?.name || undefined,
            category: values?.category || undefined,
            comboSkus: !isEmpty(values?.tableProducts)
                ? values?.tableProducts.map((el: any) => {
                      const product = {
                          skuCode: el?.sku,
                          quantity: el?.quantity,
                      }
                      return { ...product }
                  })
                : undefined,

            isActive: values?.isActive !== undefined ? values?.isActive : undefined,
        }

        dispatch(callUpdateSkuCombo({ comboCode: values?.code, body: data })).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <DefaultLayout loading={loadingDetail}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}

            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            {
                                name: "sidebar.dashboard",
                                link: "/",
                            },
                            {
                                name: "skuCombo.title",
                                link: "/product?tab=skuCombo",
                            },
                            {
                                name: skuComboDetail?.name,
                                link: "",
                            },
                        ]}
                    />
                    <Form
                        form={formRef}
                        onFinish={onUpdateSkuCombo}
                        layout="vertical">
                        <GeneralInfo formRef={formRef} />
                        <ListProduct formRef={formRef} />
                        {SecurityService.can(COMBO_SCOPE.COMBO_DETAIL_UPDATE) && (
                            <Row
                                justify="end"
                                className="mt-3 mx-3">
                                <Space>
                                    <Button
                                        loading={loadingUpdate}
                                        onClick={() => {
                                            formRef.resetFields()
                                            navigate("/product?tab=skuCombo")
                                        }}
                                        type="default">
                                        {trans("setting.cancel")}
                                    </Button>
                                    <Button
                                        loading={loadingUpdate}
                                        type="primary"
                                        htmlType="submit">
                                        {trans("setting.save")}
                                    </Button>
                                </Space>
                            </Row>
                        )}
                    </Form>
                </>
            )}
        </DefaultLayout>
    )
}
