import React, { PropsWithChildren, useEffect, useState } from "react"

import { Col, Row } from "antd"
import { Product } from "./Product"
import { ShippingAddress } from "./ShippingAddress"
import { Note } from "./Note"
import { useAppSelector } from "@hook/useAppSelector"
import { SkuComboOrder } from "./SkuCombo"
import { isEmpty } from "lodash"

interface IColInfo {
    xs: number
    lg: number
    xl: number
}

interface Props extends PropsWithChildren<any> {
    onUpdateOrder: (key: string, value: any) => void
}

export const Summary = (props: Props) => {
    const { onUpdateOrder } = props
    const [isFullSizeOrderItem, setIsFullSizeOrderItem] = useState<boolean>(false)
    const [colLeft, setColLeft] = useState<IColInfo>({ xs: 24, lg: 13, xl: 15 })
    const [colRight, setColRight] = useState<IColInfo>({ xs: 24, lg: 11, xl: 9 })
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loading = useAppSelector((state) => state.orderDetailVersion2.loadingDetail)

    useEffect(() => {
        setColLeft({
            xs: 24,
            lg: isFullSizeOrderItem ? 24 : 13,
            xl: isFullSizeOrderItem ? 24 : 15,
        })

        setColRight({
            xs: 24,
            lg: isFullSizeOrderItem ? 24 : 11,
            xl: isFullSizeOrderItem ? 24 : 9,
        })
    }, [isFullSizeOrderItem])

    const toggleFullSizeOrderItem = () => {
        setIsFullSizeOrderItem(!isFullSizeOrderItem)
    }
    return (
        <>
            <div className="mt-3">
                <Row gutter={12}>
                    <Col {...colLeft}>
                        {!loading &&
                            (!isEmpty(detailOrder?.combos) ? (
                                <SkuComboOrder
                                    toggleFullSizeOrderItem={toggleFullSizeOrderItem}
                                    onUpdateOrder={onUpdateOrder}
                                />
                            ) : (
                                <Product
                                    toggleFullSizeOrderItem={toggleFullSizeOrderItem}
                                    onUpdateOrder={onUpdateOrder}
                                />
                            ))}
                    </Col>
                    <Col {...colRight}>
                        <ShippingAddress />

                        <Note onUpdateOrder={onUpdateOrder} />
                    </Col>
                </Row>
            </div>
        </>
    )
}
