import { CALL_OPERATIONS } from "@config/constant"
import { IContactLocalQuery } from "@domain/version2/Contact"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import "@resources/less/page/contact.less"
import { IUser } from "@domain/version2/User"
import { filterWithTimezone } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callCampaignsSuggest } from "@reducer/version2/campaign.reducer"
import { callSuggestUser } from "@reducer/version2/user.reducer"
import { callProductSuggest } from "@reducer/version2/product.reducer"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const [isShowMore, setIsShowMore] = useState<boolean>(false)
    const campaignsSuggest = useAppSelector((state) => state.campaignVersion2.campaignsSuggest)
    const listContactStatuses = useAppSelector((state) => state.categoriesVersion2.listContactStatuses)
    const listContactEventStatuses = useAppSelector((state) => state.categoriesVersion2.listContactEventStatuses)
    const loadingList = useAppSelector((state) => state.contact2.loadingList)
    const listSuggestUsers = useAppSelector((state) => state.usersVersion2.listSuggestUsers)
    const [searchParams, setSearchParams] = useSearchParams()
    const productAutocomplete = useAppSelector((state) => state.productVersion2.productsSuggest)

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("campaign") && { campaign: Number(oldSearch?.campaign) }),
                ...(searchParams.has("createdAtFrom") &&
                    searchParams.has("createdAtTo") && {
                        createdAt: [filterWithTimezone(oldSearch?.createdAtFrom), filterWithTimezone(oldSearch?.createdAtTo)],
                    }),
                ...(searchParams.has("assignedAtFrom") &&
                    searchParams.has("assignedAtTo") && {
                        assignedAt: [filterWithTimezone(oldSearch?.assignedAtFrom), filterWithTimezone(oldSearch?.assignedAtTo)],
                    }),
                ...(searchParams.has("lastCallFrom") &&
                    searchParams.has("lastCallTo") && {
                        lastCall: [filterWithTimezone(oldSearch?.lastCallFrom), filterWithTimezone(oldSearch?.lastCallTo)],
                    }),
                ...(searchParams.has("contactStatuses") && {
                    contactStatuses: oldSearch.contactStatuses.split(",")?.map((item) => Number(item)),
                }),
                ...(searchParams.has("callStatuses") && {
                    callStatuses: oldSearch.callStatuses.split(",")?.map((item) => Number(item)),
                }),
                ...(searchParams.has("assignee") && {
                    assignee: oldSearch.assignee.split(","),
                }),
                ...(searchParams.has("assigner") && {
                    assigner: oldSearch.assigner.split(","),
                }),
                ...(searchParams.has("marketer") && {
                    marketer: oldSearch.marketer.split(","),
                }),
                ...(searchParams.has("productCode") && {
                    productCode: oldSearch.productCode.split(","),
                }),
                ...(searchParams.has("isSpam") && { isSpam: oldSearch?.isSpam === "true" }),
            })
        } else {
            formRef.resetFields()
        }
    }, [])

    const handleChangeFilter = (values: IContactLocalQuery) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                ...(values.call === "equals"
                    ? {
                          totalCallFrom: values.totalCallFrom,
                          totalCallTo: values.totalCallFrom,
                      }
                    : {
                          totalCallFrom: values.totalCallFrom || undefined,
                          totalCallTo: values.totalCallTo || undefined,
                      }),
                createdAtFrom: values?.createdAt ? filterWithTimezone(values?.createdAt[0]).startOf("day").toISOString() : undefined,
                createdAtTo: values?.createdAt ? filterWithTimezone(values?.createdAt[1]).endOf("day").toISOString() : undefined,
                assignedAtFrom: values?.assignedAt ? filterWithTimezone(values?.assignedAt[0]).startOf("day").toISOString() : undefined,
                assignedAtTo: values.assignedAt ? filterWithTimezone(values?.assignedAt[1]).endOf("day").toISOString() : undefined,
                lastCallFrom: values?.lastCall ? filterWithTimezone(values?.lastCall[0]).startOf("day").toISOString() : undefined,
                lastCallTo: values?.lastCall ? filterWithTimezone(values.lastCall[1]).endOf("day").toISOString() : undefined,
                contactStatuses: values.contactStatuses ? values.contactStatuses.join(",") : undefined,
                callStatuses: values.callStatuses ? values.callStatuses.join(",") : undefined,
                assignee: values.assignee ? values.assignee.join(",") : undefined,
                assigner: values.assigner ? values.assigner.join(",") : undefined,
                marketer: values.marketer ? values.marketer.join(",") : undefined,
                productCode: values.productCode ? values.productCode.join(",") : undefined,
            }
            delete params.createdAt
            delete params.assignedAt
            delete params.lastCall
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "campaign":
                    dispatch(
                        callCampaignsSuggest({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                case "user":
                    dispatch(
                        callSuggestUser({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                case "product":
                    dispatch(
                        callProductSuggest({
                            keyword: searchText.trim(),
                        })
                    )
                    break
                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "campaign":
                    dispatch(
                        callCampaignsSuggest({
                            keyword: "",
                        })
                    )
                    break
                case "user":
                    dispatch(
                        callSuggestUser({
                            keyword: "",
                        })
                    )
                    break
                case "product":
                    dispatch(
                        callProductSuggest({
                            keyword: "",
                        })
                    )
                    break
                default:
                    break
            }
        }
    }
    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={handleChangeFilter}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("contact.fullname_phone_number")}
                            name="keyword">
                            <Input
                                allowClear
                                placeholder={trans("contact.fullname_phone_number")}
                                suffix={<i className="fa-regular fa-magnifying-glass"></i>}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("contact.campaign")}
                            name="campaign">
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                onBlur={() => {
                                    onSearchAutocomplete("", "campaign")
                                }}
                                onSearch={(e) => {
                                    onSearchAutocomplete(e, "campaign")
                                }}
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                placeholder={trans("contact.select_campaign")}>
                                {campaignsSuggest?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}>
                        <Form.Item
                            label={trans("contact.created_at")}
                            name="createdAt">
                            <DatePicker.RangePicker
                                allowClear
                                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                placeholder={[trans("contact.from"), trans("contact.to")]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {isShowMore && (
                    <Row gutter={15}>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact.assign_to")}
                                name="assignee">
                                <Select
                                    placeholder={trans("contact.select_assign_to")}
                                    allowClear
                                    optionFilterProp="children"
                                    onBlur={() => {
                                        onSearchAutocomplete("", "user")
                                    }}
                                    onSearch={(e) => {
                                        onSearchAutocomplete(e, "user")
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    mode="multiple">
                                    {listSuggestUsers?.map((item: IUser) => (
                                        <Select.Option
                                            value={item?.username}
                                            key={item.id}>
                                            {item?.username}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact.assigned_at")}
                                name="assignedAt">
                                <DatePicker.RangePicker
                                    allowClear
                                    format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                    placeholder={[trans("contact.from"), trans("contact.to")]}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact.assigned_by")}
                                name="assigner">
                                <Select
                                    placeholder={trans("contact.select_assign_by")}
                                    allowClear
                                    onBlur={() => {
                                        onSearchAutocomplete("", "user")
                                    }}
                                    onSearch={(e) => {
                                        onSearchAutocomplete(e, "user")
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    mode="multiple">
                                    {[{ username: "system", id: "system-1" }, ...listSuggestUsers]?.map((item) => (
                                        <Select.Option
                                            value={item.username}
                                            key={item.id}>
                                            {item?.username}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact.callTime")}
                                name="lastCall">
                                <DatePicker.RangePicker
                                    allowClear
                                    format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                    placeholder={[trans("contact.from"), trans("contact.to")]}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact-detail.contact_state")}
                                name="contactStatuses">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    placeholder={trans("contact.stage")}>
                                    {!isEmpty(listContactStatuses) &&
                                        listContactStatuses?.map((item: any) => (
                                            <Select.Option
                                                key={item?.code}
                                                value={item?.code}>
                                                {item?.description}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("sidebar.call_status")}
                                name="callStatuses">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    placeholder={trans("sidebar.call_status")}>
                                    {!isEmpty(listContactEventStatuses) &&
                                        listContactEventStatuses?.map((item: any) => (
                                            <Select.Option
                                                key={item?.id}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact.calls")}
                                name="call">
                                <Select
                                    allowClear
                                    placeholder={trans("contact.calls")}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    onChange={() => {
                                        formRef.setFieldsValue({
                                            totalCallFrom: "",
                                            totalCallTo: "",
                                        })
                                    }}>
                                    {CALL_OPERATIONS.map((item) => (
                                        <Select.Option
                                            key={item.value}
                                            value={item.value}>
                                            {trans(item.key)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                noStyle
                                className="p-0"
                                shouldUpdate={(pre, next) => pre.call !== next.call}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue("call") === "bt") {
                                        return (
                                            <Row>
                                                <Col span={11}>
                                                    <Form.Item
                                                        className="w-full"
                                                        name="totalCallFrom">
                                                        <InputNumber
                                                            min={0}
                                                            parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    span={2}
                                                    className="flex justify-center">
                                                    ~
                                                </Col>
                                                <Col span={11}>
                                                    <Form.Item
                                                        className="w-full"
                                                        name="totalCallTo">
                                                        <InputNumber
                                                            min={0}
                                                            parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    } else if (getFieldValue("call")) {
                                        return (
                                            <Form.Item name={getFieldValue("call") === "lte" ? "totalCallTo" : "totalCallFrom"}>
                                                <InputNumber
                                                    min={0}
                                                    parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                />
                                            </Form.Item>
                                        )
                                    }
                                }}
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("vendor.marketer")}
                                name="marketer">
                                <Select
                                    placeholder={trans("placeholder.select_marketer")}
                                    allowClear
                                    optionFilterProp="children"
                                    onBlur={() => {
                                        onSearchAutocomplete("", "user")
                                    }}
                                    onSearch={(e) => {
                                        onSearchAutocomplete(e, "user")
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    mode="multiple">
                                    {[{ username: "system", id: "system-1" }, ...listSuggestUsers]?.map((item) => (
                                        <Select.Option
                                            value={item.username}
                                            key={item.id}>
                                            {item?.username}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact-detail.product")}
                                name="productCode">
                                <Select
                                    allowClear
                                    placeholder={trans("contact-detail.product_pl")}
                                    showSearch
                                    optionFilterProp="children"
                                    onBlur={() => {
                                        onSearchAutocomplete("", "product")
                                    }}
                                    onSearch={(e) => {
                                        onSearchAutocomplete(e, "product")
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "")?.toLowerCase()?.localeCompare((optionB?.children ?? "")?.toLowerCase())
                                    }}
                                    mode="multiple">
                                    {productAutocomplete?.map((item) => {
                                        return (
                                            <Select.Option
                                                key={item?.id}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}>
                            <Form.Item
                                label={trans("contact.remark")}
                                name="isSpam">
                                <Select
                                    allowClear
                                    placeholder={trans("contact.remark")}>
                                    <Select.Option
                                        key={1}
                                        value={true}>
                                        {trans("contact.spam")}
                                    </Select.Option>
                                    <Select.Option
                                        key={2}
                                        value={false}>
                                        {trans("contact.blanks")}
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col span={18}>
                        <Space>
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={loadingList}>
                                {trans("button.filter")}
                            </Button>
                            <Button
                                type="default"
                                onClick={() => {
                                    formRef.resetFields()
                                    setSearchParams({})
                                }}
                                loading={loadingList}>
                                {trans("contact.clear")}
                            </Button>
                            <Button
                                type="link"
                                onClick={() => setIsShowMore((old) => !old)}>
                                {isShowMore ? trans("contact.filter_less") : trans("contact.filter_more")}
                                <i className={`ml-2 fa-solid ${isShowMore ? "fa-chevron-up" : "fa-chevron-down"}`} />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
