import React, { useEffect } from "react"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCreateContact, callListContacts, changeVisibleFormAddContact } from "@reducer/version2/contact.reducer"
import { trans } from "@resources/localization"
import { Button, Form, Input, message, Select, Space } from "antd"
import { isEmpty } from "lodash"
import SecurityService from "@util/SecurityService"
import TextArea from "antd/lib/input/TextArea"
import { useSearchParams } from "react-router-dom"
import { callListAds } from "@reducer/version2/ads.reducer"
import { PAGINATION } from "@config/constant"
import { CONTACT_SCOPE } from "@config/permission"

const InputForm = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const listAds = useAppSelector((state) => state.adsVersion2.listAds)
    const loadingAds = useAppSelector((state) => state.adsVersion2.loadingList)
    const [formRef] = Form.useForm()
    const loadingCreate = useAppSelector((state) => state.contact2.loadingCreateOneContact)

    useEffect(() => {
        dispatch(
            callListAds({
                page: 1,
                pageSize: 100000,
                sort: "createdAt:desc",
                active: true,
            })
        )
    }, [])

    const onCreateContact = (values: any) => {
        dispatch(
            callCreateContact({
                ad: values?.campaigns,
                name: values?.fullname,
                phone: values?.phone,
                note: values?.note || "",
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                setTimeout(() => {
                    dispatch(
                        callListContacts({
                            pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                            page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                            sort: "createdAt:desc",
                        })
                    )
                    formRef.resetFields()
                    dispatch(changeVisibleFormAddContact(false))
                }, 500)
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <div>
            <Form
                form={formRef}
                onFinish={onCreateContact}
                layout="vertical">
                <Form.Item
                    name="campaigns"
                    label={trans("contact.ads")}
                    rules={[{ required: true, message: trans("message.required") }]}>
                    <Select
                        allowClear
                        loading={loadingAds}
                        showSearch
                        filterOption={(input: any, option: any) => {
                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                        }}
                        filterSort={(optionA: any, optionB: any) => {
                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                        }}
                        placeholder={trans("contact.select_ads")}>
                        {!isEmpty(listAds) &&
                            listAds?.map((item) => (
                                <Select.Option
                                    key={item?.id}
                                    value={item?.code}>
                                    {item?.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="fullname"
                    label={trans("user_list.fullname")}
                    rules={[
                        { required: true, message: trans("message.required") },
                        { max: 128, message: `${trans("message.warning_quantity_max")} 128` },
                    ]}>
                    <Input
                        placeholder={trans("user_list.fullname")}
                        onChange={(e: any) => {
                            const fullname: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "")
                            formRef.setFieldsValue({
                                fullname,
                            })
                        }}
                        onBlur={(e) => {
                            formRef.setFieldsValue({
                                fullname: e.target.value.trim(),
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label={trans("contact.phone")}
                    rules={[{ required: true, message: trans("message.required") }]}>
                    <Input
                        placeholder={trans("contact.phone")}
                        onChange={(e: any) => {
                            const phone: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "").replace(/[a-zA-Z]/g, "")
                            formRef.setFieldsValue({
                                phone,
                            })
                        }}
                        onBlur={(e) => {
                            formRef.setFieldsValue({
                                phone: e.target.value.trim(),
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="note"
                    label={trans("contact.note")}
                    rules={[{ max: 256, message: `${trans("message.warning_quantity_max")} 256` }]}>
                    <TextArea
                        rows={3}
                        placeholder={trans("contact.note")}
                    />
                </Form.Item>
                <Space className="flex justify-end">
                    <Button
                        loading={loadingCreate}
                        htmlType="button"
                        onClick={() => dispatch(changeVisibleFormAddContact(false))}>
                        {trans("button.cancel_button")}
                    </Button>
                    <Button
                        type="primary"
                        disabled={!SecurityService.can(CONTACT_SCOPE.CONTACT_CREATE)}
                        loading={loadingCreate}
                        htmlType="submit">
                        {trans("button.save")}
                    </Button>
                </Space>
            </Form>
        </div>
    )
}

export default InputForm
