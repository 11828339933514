import React from "react"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import {
    ADS_SCOPE,
    CAMPAIGN_SCOPE,
    CONTACT_SCOPE,
    ORDER_SCOPE,
    PAYMENT_STATEMENT_SCOPE,
    USER_SCOPE,
    SETTING_SCOPE,
    INVENTORY_SCOPE,
    PRODUCT_SCOPE,
    PACKAGE_SCOPE,
    SELLING_CHANNEL,
    COMBO_SCOPE,
    PURCHASING_ACCOUNT,
} from "./permission"

export type showFunc = () => boolean

export interface IMenuItem {
    key: string
    title: string
    icon: JSX.Element
    url: string
    show: boolean
    children?: Array<IMenuItem>
}

export const menuItems: IMenuItem[] = [
    {
        key: "dashboard",
        title: trans("sidebar.dashboard"),
        icon: <i className="fas fa-home" />,
        url: "/",
        show: true,
    },
    {
        key: "user",
        title: trans("sidebar.detail_vendor"),
        icon: <i className="fa fa-user" />,
        url: "/user",
        show: SecurityService.can(USER_SCOPE.STAFF_VIEW_LIST),
    },
    {
        key: "inventory",
        title: trans("inventory.title"),
        icon: <i className="fa-solid fa-warehouse"></i>,
        url: "/inventory",
        show: SecurityService.can(INVENTORY_SCOPE.INVENTORY_LIST_VIEW),
    },
    {
        key: "campaign",
        title: trans("sidebar.campaign"),
        icon: <i className="fa-solid fa-bullhorn"></i>,
        url: "/campaign",
        show:
            SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW_ALL) ||
            SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_VIEW) ||
            SecurityService.can(ADS_SCOPE.ADS_VIEW_ALL) ||
            SecurityService.can(ADS_SCOPE.ADS_VIEW),
    },
    {
        key: "contact",
        title: trans("contact.title"),
        icon: <i className="fa-solid fa-address-card" />,
        url: "/contact",
        show: SecurityService.can(CONTACT_SCOPE.CONTACT_VIEW_ALL) || SecurityService.can(CONTACT_SCOPE.CONTACT_VIEW),
    },
    {
        key: "order",
        title: trans("order.page_title"),
        icon: <i className="fa-solid fa-cart-shopping" />,
        url: "/order",
        show: SecurityService.can(ORDER_SCOPE.ORDER_VIEW_ALL) || SecurityService.can(ORDER_SCOPE.ORDER_VIEW),
    },
    {
        key: "paymentStatement",
        title: trans("sidebar.payment_statement"),
        icon: <i className="fa-solid fa-file-invoice" />,
        url: "/payment-statement",
        show: SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_LIST_VIEW),
    },
    {
        key: "package",
        title: trans("sidebar.package"),
        icon: <i className="fa-solid fa-cubes" />,
        url: "/package",
        show: SecurityService.can(PACKAGE_SCOPE.PACKAGE_LIST_VIEW),
    },
    {
        key: "purchasingAccount",
        title: trans("purchasing_account.title"),
        icon: <i className="fa-solid fa-user-gear" />,
        url: "/purchasing-account",
        show: SecurityService.can(PURCHASING_ACCOUNT.PURCHASING_ACCOUNT_VIEW),
    },
    {
        key: "sellChannel",
        title: trans("sell_channel.title"),
        icon: <i className="fa-solid fa-store" />,
        url: "/sell-channel",
        show: SecurityService.can(SELLING_CHANNEL.SELLING_CHANNEL_LIST_VIEW),
    },
    {
        key: "Setting",
        title: trans("sidebar.setting"),
        icon: <i className="fa-solid fa-gears" />,
        url: "/setting",
        show: SecurityService.can(SETTING_SCOPE.COURIER_VIEW),
    },
    {
        key: "product",
        title: trans("sidebar.product"),
        icon: <i className="fa-solid fa-cube"></i>,
        url: "/product",
        show: SecurityService.can(PRODUCT_SCOPE.PRODUCT_LIST_VIEW) || SecurityService.can(COMBO_SCOPE.COMBO_LIST_VIEW),
    },
    {
        key: "orderFinancing",
        title: trans("order_financing.title"),
        icon: <i className="fa-solid fa-chart-waterfall" />,
        url: "/order-financing",
        show: true,
    },
]
