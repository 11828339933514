import DashboardCard from "@screen/Dashboard/DashboardCard"
import { trans } from "@resources/localization"
import { Col, Row, Typography } from "antd"
import React, { useMemo } from "react"
import { useAppSelector } from "@hook/useAppSelector"
import { IOrderDashboard } from "@domain/version2/Dashboard"

type Props = {
    titleHeader: string,
    today: IOrderDashboard
    yesterday: IOrderDashboard
}

const Orders = ({titleHeader, today, yesterday}: Props) => {
    const loadingOrder = useAppSelector((state) => state.dashboard.loadingOrder)

    const cardOrderData = useMemo(
        () => [
            {
                colSize: 8,
                title: trans("dashboard.new_order"),
                dataToday: today?.newOrders || 0,
                dataYesterday: yesterday?.newOrders || 0,
                icon: "fa-file",
                bgIcon: "bg-[#e0f4fc]",
            },
            {
                colSize: 8,
                title: trans("order_status.PICKED_UP"),
                dataToday: today?.pickedUpOrders || 0,
                dataYesterday: yesterday?.pickedUpOrders || 0,
                icon: "fa-file",
                bgIcon: "bg-[#e0f4fc]",
            },
            {
                colSize: 8,
                title: trans("order_status.DRAFT"),
                dataToday: today?.draftOrders || 0,
                dataYesterday: yesterday?.draftOrders || 0,
                icon: "fa-file",
                bgIcon: "bg-[#e0f4fc]",
            },
            {
                colSize: 8,
                title: trans("order_status.CANCELLED"),
                dataToday: today?.cancelledOrders || 0,
                dataYesterday: yesterday?.cancelledOrders || 0,
                icon: "fa-file",
                bgIcon: "bg-[#e0f4fc]",
            },
            {
                colSize: 8,
                title: trans("dashboard.new_delivered_order"),
                dataToday: today?.deliveredOrders || 0,
                dataYesterday: yesterday?.deliveredOrders || 0,
                icon: "fa-file",
                bgIcon: "bg-[#e0f4fc]",
            },
            {
                colSize: 8,
                title: trans("dashboard.new_scheduled_order"),
                dataToday: today?.scheduledDeliveryOrders || 0,
                dataYesterday: yesterday?.scheduledDeliveryOrders || 0,
                icon: "fa-file",
                bgIcon: "bg-[#e0f4fc]",
            },
        ],
        [today, yesterday]
    )

    return (
        <div className="mx-3 mt-5">
            <Typography.Title level={4}>{titleHeader}</Typography.Title>

            <Row gutter={15}>
                {cardOrderData?.map((col: any) => (
                    <Col
                        key={col.title}
                        sm={24}
                        lg={col.colSize}>
                        <DashboardCard
                            title={col.title}
                            dataToday={col.dataToday}
                            dataYesterday={col.dataYesterday}
                            icon={col.icon}
                            bgIcon={col.bgIcon}
                            loading={loadingOrder}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default Orders
