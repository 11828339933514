import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { ADS_SCOPE, CAMPAIGN_SCOPE } from "@config/permission"
import { IPricePolicyAds } from "@domain/version2/Ads"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetDetailAds, callGetLandingUrl, callGetLandingWeb, callUpdateAds } from "@reducer/version2/ads-detail.reducer"
import { callCreateAndUpdateLanding } from "@reducer/version2/ads.reducer"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Button, Card, Form, message, Row, Space, Typography } from "antd"
import { isEmpty, map } from "lodash"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { GeneralInfo } from "./General"
import { CreateProducts } from "./ListProduct"
import { callDetailProduct } from "@reducer/version2/product.reducer"
import appConfig from "@config/app"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"
import { CreateSkuCombos } from "./ListSkuCombo"
import { CrossSku } from "@screen/version2/Campaign/Ads/Edit/CrossSku"

export const EditAds: React.FC = () => {
    const [formRef] = Form.useForm()
    const [statusShow, setStatusShow] = useState<number>(0)
    const dispatch = useAppDispatch()
    const detailAds = useAppSelector((state) => state.adsDetailVersion2.detailAds)
    const loadingDetail = useAppSelector((state) => state.adsDetailVersion2.loadingDetail)
    const loadingUpdateAds = useAppSelector((state) => state.adsDetailVersion2.loadingUpdate)
    const userLogin = SecurityService.getUser()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { id } = useParams()
    const disableUpdate = !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_UPDATE)
    const landingUrls = useAppSelector((state) => state.adsDetailVersion2.landingUrls)
    const loadingUrl = useAppSelector((state) => state.adsDetailVersion2.loadingUrl)
    const ladingWeb = useAppSelector((state) => state.adsDetailVersion2.landingWeb)
    useEffect(() => {
        const search = Object.fromEntries(searchParams.entries())
        if (id) {
            if (
                (userLogin?.sub !== search?.createdBy || !searchParams.has("createdBy")) &&
                !SecurityService.can(ADS_SCOPE.ADS_VIEW_ALL) &&
                SecurityService.can(ADS_SCOPE.ADS_VIEW)
            ) {
                navigate("/campaign?tab=ads")
            }
        }
    }, [id, searchParams, userLogin])

    useEffect(() => {
        dispatch(changePageTitleAction(trans("ads.update")))
        dispatch(callGetDetailAds(id || "")).then((result: any) => {
            setStatusShow(result?.payload?.response?.status)
        })
    }, [dispatch])
    useEffect(() => {
        if (!isEmpty(detailAds)) {
            dispatch(callGetLandingUrl(detailAds?.code || ""))
            dispatch(callGetLandingWeb(detailAds?.code || ""))
        }
    }, [detailAds])
    useEffect(() => {
        if (!isEmpty(detailAds)) {
            formRef.setFieldsValue({
                campaignName: detailAds?.campaign?.name,
                name: detailAds?.name,
                facebookAdIds: detailAds?.refId ? detailAds?.refId?.split(",") : [],
                ...(!isEmpty(landingUrls) && {
                    urls: map(landingUrls, "url") || [],
                }),
            })
            dispatch(callDetailProduct(detailAds?.campaign?.productCode || ""))
        }
    }, [detailAds, formRef, landingUrls])

    const onUpdateAds = (values: any) => {
        let allSkus: any = []
        if (detailAds?.campaign?.productCode) {
            detailAds?.pricePolicies?.forEach((item: IPricePolicyAds, index: number) => {
                const oneSku: any = values[`pricePolicies${index + 1}`]
                const finalOneSku = oneSku?.map((finalItem: any) => ({
                    ...finalItem,
                    sku: item?.sku,
                }))
                allSkus = [...allSkus, ...finalOneSku]
            })
        }
        if (detailAds?.campaign?.comboCode) {
            allSkus = values?.pricePolicies?.map((element: any) => ({
                ...element,
                sku: detailAds?.campaign?.comboCode,
            }))
        }
        if (!isEmpty(values?.urls)) {
            dispatch(
                callCreateAndUpdateLanding({
                    code: detailAds?.code || "",
                    body: {
                        urls: values.urls,
                    },
                })
            )
        }
        dispatch(
            callUpdateAds({
                code: detailAds?.code || "",
                body: {
                    campaignCode: values?.campaignCode,
                    name: values?.name,
                    refId: values?.facebookAdIds?.join(","),
                    pricePolicies: allSkus,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <DefaultLayout loading={loadingDetail || loadingUrl}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            {
                                name: "sidebar.dashboard",
                                link: "/",
                            },
                            {
                                name: "sidebar.campaign",
                                link: "/campaign",
                            },
                            {
                                name: "ads.update",
                                link: "",
                            },
                        ]}
                    />

                    <Form
                        layout="vertical"
                        form={formRef}
                        onFinish={onUpdateAds}>
                        <GeneralInfo formRef={formRef} />
                        {detailAds?.campaign?.comboCode && <CreateSkuCombos formRef={formRef} />}
                        {detailAds?.campaign?.productCode && (
                            <>
                                <CreateProducts formRef={formRef} />
                                <CrossSku />
                            </>
                        )}
                        <Card
                            className="space-layout"
                            title="Webhook Url">
                            <p className="font-medium">{trans("ads.api_url")}:</p>
                            <Typography.Paragraph
                                copyable={{
                                    icon: [
                                        <i
                                            key={0}
                                            className="fa-light fa-copy"></i>,
                                        <i
                                            key={1}
                                            className="fa-light fa-check"></i>,
                                    ],
                                }}>
                                {`${appConfig.apiUrl2}/webhooks/${ladingWeb?.webhookId}`}
                            </Typography.Paragraph>
                            <p className="font-medium">{trans("ads.token")}:</p>
                            <Typography.Paragraph
                                copyable={{
                                    icon: [
                                        <i
                                            key={0}
                                            className="fa-light fa-copy"></i>,
                                        <i
                                            key={1}
                                            className="fa-light fa-check"></i>,
                                    ],
                                }}>
                                {`{"token":"${ladingWeb?.token}"}`}
                            </Typography.Paragraph>
                        </Card>
                        <div className="my-3 mx-3">
                            <Row
                                justify="end"
                                className="mt-3">
                                <Space>
                                    <Button
                                        loading={loadingUpdateAds}
                                        type="default"
                                        onClick={() => navigate("/campaign?tab=ads")}>
                                        {trans("setting.cancel")}
                                    </Button>
                                    <Button
                                        loading={loadingUpdateAds}
                                        disabled={disableUpdate}
                                        type="primary"
                                        htmlType="submit">
                                        {trans("setting.save")}
                                    </Button>
                                </Space>
                            </Row>
                        </div>
                    </Form>
                </>
            )}
        </DefaultLayout>
    )
}
