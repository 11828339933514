import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Card, Col, Form, Input, InputNumber, Row, Select } from "antd"
import { find } from "lodash"
import React from "react"
export const InfoInboundShipment: React.FC = () => {
    const wareHouses = useAppSelector((state) => state.package.wareHouses)
    const couriers = useAppSelector((state) => state.courierSetting.courierSuggests)
    return (
        <Card
            className="space-layout"
            title={trans("package.inbound_info")}>
            <Row gutter={12}>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="warehouse"
                        label={trans("package.warehouse")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select allowClear placeholder={trans("package.filter_receiving_warehouse")}>
                            {wareHouses?.map((item) => (
                                <Select.Option
                                    key={item?.id}
                                    value={item?.id}>
                                    {item?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.warehouse !== next.warehouse}>
                        {({ getFieldValue }) => {
                            const oneWarehouse = find(wareHouses, { id: getFieldValue("warehouse") })
                            return (
                                <Form.Item
                                    name="warehouseAddress"
                                    label={trans("package-detail.warehouse_address")}>
                                    <p>{oneWarehouse?.address || ""}</p>
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="courier"
                        label={trans("contact-detail.courier")}>
                        <Select allowClear placeholder={trans("order_list.courier_placeholder")}>
                            {couriers?.map((item) => (
                                <Select.Option
                                    key={item?.code}
                                    value={item?.m28Code}>
                                    {item?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="trackingNumber"
                        label={trans("stock_receipt.tracking_number")}>
                        <Input onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")} />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="weight"
                        label={`${trans("package-detail.weight")} (g)`}>
                        <InputNumber
                            min={0}
                            parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="note"
                        label={trans("order_detail.note")}>
                        <Input.TextArea
                            rows={1}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}
