import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Card, Typography } from "antd"
import React from "react"
export const NoteAndFile: React.FC = () => {
    const detailPaymentState = useAppSelector((state) => state?.paymentDetail?.paymentStateDetail)
    return (
        <Card className="space-layout">
            <div>
                <p className="font-medium">{trans("contact.note")}</p>
                <p>{detailPaymentState?.note}</p>
            </div>
            <div className="flex">
                <Typography.Text className="mr-2">{trans("payment_state.file")}:</Typography.Text>
                <a
                    download
                    target="_blank"
                    href={detailPaymentState?.urlDownloadFile}
                    rel="noreferrer">
                    {detailPaymentState?.fileImportPath}
                </a>
            </div>
        </Card>
    )
}
