import React from "react"
import { Card, Col, message, Row, Select } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeVisibleFormReasonCancel, fetchUpdateOrderStatus, getOrderDetail, fetchUpdateOrderFinancialStatus } from "@reducer/version2/order-detail.reducer"
import { formatDateFull } from "@util/Common"
import { STATUS_CAN_UPDATE } from "@config/constant"
import { callGetSubOrderStatus } from "@reducer/version2/categories.reducer"
import { find } from "lodash"
import moment from "moment"

export const OrderDetailHeader: React.FC = () => {
    const dispatch = useAppDispatch()
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loadingUpdateStatus = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrderStatus)
    const orderStatuses = useAppSelector((state) => state.orderVersion2.statuses)    
    const orderFinancialStatuses = useAppSelector((state) => state.orderDetailVersion2.financialStatuses)
    const loadingUpdateOrderFinancialStatus = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrderFinancialStatus)

    const onChangeStatus = (status: string) => {
        if (status === "CANCELLED") {
            dispatch(callGetSubOrderStatus(status))
            dispatch(changeVisibleFormReasonCancel(true))
        }else{
            dispatch(
                fetchUpdateOrderStatus({
                    code: detailOrder?.code || "",
                    body: {
                        status: status || "",
                    },
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(detailOrder?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    const onChangeFinancialStatus = (status: string) => {
        if (status) {
            dispatch(
                fetchUpdateOrderFinancialStatus({
                    code: detailOrder?.code || "",
                    body: {
                        financial: status || "",
                    },
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(detailOrder?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }

    const paymentMethod = detailOrder?.paymentTransactions?.find((item: any) => item.method)?.method

    const prePaidAmountRegex = (value?: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    const paymentConfirmation = (method?: string) => {
        if (method === "cod") {
            return (
                <div>
                    <span className="font-bold">COD</span>
                    {` - ${trans("order_payment.cod_confirmation")}`}
                </div>
            )
        } else if (method === "pre_paid_by_cash") {
            const cash = find(detailOrder?.paymentTransactions, { method: "pre_paid_by_cash" })
            return (
                <div>
                    <span className="font-bold">{trans("order_payment.pre_paid_by_cash")}</span>
                    {` - ${trans("order_payment.customer_prepaid")}`} <span className="font-bold">{prePaidAmountRegex(cash?.prePaidAmount)}</span>
                    {` ${trans("order_payment.on")}`}{" "}
                    <span className="font-bold">{cash?.paymentTime ? moment(cash?.paymentTime).format("DD/MM/YYYY") : undefined}</span>
                    {` ${trans("order_payment.by")}`} <span className="font-bold">{cash?.methodDetail?.name}</span>
                    {`, ${trans("package-detail.note")} :`} <span className="font-bold">{cash?.note}</span>
                </div>
            )
        } else if (method === "pre_paid_by_bank_transfer") {
            const bank = find(detailOrder?.paymentTransactions, { method: "pre_paid_by_bank_transfer" })
            return (
                <div>
                <span className="font-bold">{trans("order_payment.pre_paid_by_bank")}</span>
                    {` - ${trans("order_payment.customer_prepaid")}`} <span className="font-bold">{prePaidAmountRegex(bank?.prePaidAmount)}</span>
                    {` ${trans("order_payment.on")}`}{" "}
                    <span className="font-bold">{bank?.paymentTime ? moment(bank?.paymentTime).format("DD/MM/YYYY") : undefined}</span>
                    {` ${trans("order_payment.by")}`} <span className="font-bold">{bank?.methodDetail?.name}</span>
                    {`, ${trans("order_payment.bank")}:`} <span className="font-bold">{bank?.bankName}</span>
                    {`, ${trans("order_payment.bank_account_number")}:`} <span className="font-bold">{bank?.bankAccount}</span>
                    {`, ${trans("package-detail.note")}:`} <span className="font-bold">{bank?.note}</span>
                </div>
            )
        } else {
            return "---"
        }
    }

    return (
        <>
            <Card className="space-layout custom-bottom">
                <Row gutter={[15, 15]}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="font-medium m-0">{trans("order.code")}</p>
                        <div>{detailOrder?.code}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="font-medium m-0">{trans("order.campaign")}</p>
                        <div>{detailOrder?.campaign?.name || ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="font-medium m-0">{trans("order_detail.ads")}</p>
                        <div>{detailOrder?.ads?.name || ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="font-medium m-0">{trans("order_detail.due_date")}</p>
                        <div>{detailOrder?.deliveryDate ? formatDateFull(detailOrder?.deliveryDate) : "---"}</div>
                    </Col>
                    <Col span={18}>
                        <Row gutter={[15, 15]}>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <p className="font-medium m-0">{trans("order_detail.status")}</p>
                                {SecurityService.can(ORDER_SCOPE.ORDER_STATUS_UPDATE) && STATUS_CAN_UPDATE.includes(detailOrder?.status || "") ? (
                                    <Select
                                        loading={loadingUpdateStatus}
                                        value={detailOrder?.status}
                                        className="block w-11/12"
                                        onChange={(e) => {
                                            onChangeStatus(e)
                                        }}>
                                        {orderStatuses.map((x: any, index: number) => (
                                            <Select.Option
                                                key={x.code}
                                                value={x.code}
                                                disabled={
                                                    index < orderStatuses.findIndex((x: any) => x.code === detailOrder?.status) ||
                                                    ![...STATUS_CAN_UPDATE, "CANCELLED"].includes(x.code)
                                                }>
                                                {trans(`order_status.${x?.code}`)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <div>{trans(`order_status.${detailOrder?.status}`)}</div>
                                )}
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <p className="font-medium m-0">{trans("order_detail.created_time")}</p>
                                <div>{detailOrder?.createdAt ? formatDateFull(detailOrder?.createdAt) : ""}</div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <p className="font-medium m-0">{trans("order_detail.created_by")}</p>
                                <div>{detailOrder?.createdBy}</div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <p className="font-medium m-0">{trans("order_detail.financial_status")}</p>
                                {SecurityService.can(ORDER_SCOPE.ORDER_FINANCIAL_STATUS_UPDATE) ? (
                                    <Select
                                        loading={loadingUpdateOrderFinancialStatus}
                                        value={detailOrder?.financial}
                                        className="block w-11/12"
                                        onChange={(e) => {
                                            onChangeFinancialStatus(e)
                                        }}>
                                        {orderFinancialStatuses.map((item: any) => (
                                            <Select.Option
                                                key={item.code}
                                                value={item.code}>
                                                {trans(`order_detail.financial_status_${item?.code}`)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <div>{trans(`order_detail.financial_status_${detailOrder?.financial}`)}</div>
                                )}
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <p className="font-medium m-0">{trans("order_detail.tracking_number")}</p>
                                <div>{detailOrder?.trackingNumber || "---"}</div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <p className="font-medium m-0">{trans("vendor.marketer")}</p>
                                <div>{detailOrder?.campaign?.createdBy || ""}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <p className="font-medium m-0">{trans("order_payment.confirmation")}</p>
                        {paymentConfirmation(paymentMethod)}
                    </Col>
                </Row>
            </Card>
        </>
    )
}
