import { SettingTable } from "@component/General/SettingTable"
import { PAGINATION } from "@config/constant"
import { INVENTORY_SCOPE } from "@config/permission"
import { Inventory } from "@domain/version2/InventoryApi"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { convertNumberToCurrency, formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Card, Table, Tag } from "antd"
import { filter, isEmpty } from "lodash"
import React, { useMemo, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
export const TableInventory: React.FC = () => {
    const [selectedTable, setSelectedTable] = useState<string[]>([])
    const [searchParams, setSearchParams] = useSearchParams()
    const pageSize = useAppSelector((state) => state.inventory.pagination.size)
    const total = useAppSelector((state) => state.inventory.pagination.total)
    const currentPage = useAppSelector((state) => state.inventory.pagination.currentPage)
    const inventories = useAppSelector((state) => state.inventory.inventories)
    const loading = useAppSelector((state) => state.inventory.loadingList)
    const columns: any[] = [
        {
            title: trans("warehouse.sku_code"),
            className: "bold-400",
            dataIndex: "code",
            key: "code",
            default: true,
            align: "left",
            width: 80,
            render: (code: string, record: Inventory) =>
                !SecurityService.can(INVENTORY_SCOPE.INVENTORY_DETAIL_VIEW) && !SecurityService.can(INVENTORY_SCOPE.INVENTORY_HISTORY_VIEW) ? (
                    code
                ) : (
                    <Link to={`/inventory/${record.id}?tab=detail`}>{code}</Link>
                ),
        },
        {
            title: trans("inventory.sku_name"),
            className: "bold-400",
            dataIndex: "name",
            key: "name",
            default: true,
            align: "left",
            width: 120,
        },
        {
            title: trans("warehouse.channel_sale"),
            className: "bold-400",
            dataIndex: "marketPlace",
            key: "marketplace",
            align: "center",
            default: false,
            width: 120,
            render: (marketPlace: any, record: Inventory) => (
                <>
                    {record?.marketPlace?.map((item) => (
                        <p
                            className="m-0 mb-2"
                            key={item?.id}>
                            {item?.name}
                        </p>
                    ))}
                </>
            ),
        },
        {
            title: trans("warehouse.warehouse"),
            className: "bold-400",
            dataIndex: "warehouse",
            key: "warehouse",
            default: true,
            align: "left",
            width: 120,
            render: (warehouse: any, record: Inventory) =>
                !isEmpty(record?.skus[0]?.warehouseStocks) && (
                    <>
                        {record?.skus[0]?.warehouseStocks?.map((item, wareIndex) => (
                            <p
                                className="m-0 mb-2"
                                key={wareIndex}>
                                {item?.warehouse?.name}
                            </p>
                        ))}
                    </>
                ),
        },
        {
            title: trans("order_detail.status"),
            className: "bold-400",
            dataIndex: "status",
            key: "status",
            default: true,
            width: 80,
            render: (status: string) =>
                status === "ON_SELL" ? <Tag color="#27ae60">{trans("warehouse.selling")}</Tag> : <Tag color="#7F8C8D">{trans("warehouse.stop_selling")}</Tag>,
        },
        {
            title: trans("inventory.title"),
            className: "bold-400",
            dataIndex: "realQuantity",
            key: "realQuantity",
            align: "right",
            default: true,
            width: 120,
            render: (realQuantity: any, record: Inventory) =>
                !isEmpty(record?.skus[0]?.warehouseStocks) && (
                    <>
                        {record?.skus[0]?.warehouseStocks?.map((item, wareIndex) => (
                            <p
                                className="m-0 mb-2"
                                key={wareIndex}>
                                {item?.realQuantity}
                            </p>
                        ))}
                    </>
                ),
        },
        {
            title: trans("warehouse.waiting_export"),
            className: "bold-400",
            dataIndex: "packingQuantity",
            key: "packingQuantity",
            align: "right",
            default: true,
            width: 120,
            render: (packingQuantity: any, record: Inventory) =>
                !isEmpty(record?.skus[0]?.warehouseStocks) && (
                    <>
                        {record?.skus[0]?.warehouseStocks?.map((item, wareIndex) => (
                            <p
                                className="m-0 mb-2"
                                key={wareIndex}>
                                {item?.packingQuantity}
                            </p>
                        ))}
                    </>
                ),
        },
        {
            title: trans("warehouse.waiting_import"),
            className: "bold-400",
            dataIndex: "purchasingQuantity",
            key: "purchasingQuantity",
            default: true,
            width: 120,
            align: "right",
            render: (packing_quantity: any, record: Inventory) =>
                !isEmpty(record?.skus[0]?.warehouseStocks) && (
                    <>
                        {record?.skus[0]?.warehouseStocks?.map((item, wareIndex) => (
                            <p
                                className="m-0 mb-2"
                                key={wareIndex}>
                                {item?.purchasingQuantity}
                            </p>
                        ))}
                    </>
                ),
        },
        {
            title: trans("warehouse.availability"),
            className: "bold-400",
            dataIndex: "availableInventory",
            key: "availableInventory",
            align: "right",
            default: true,
            width: 120,
            render: (availableInventory: any, record: Inventory) =>
                !isEmpty(record?.skus[0]?.warehouseStocks) && (
                    <>
                        {record?.skus[0]?.warehouseStocks?.map((item, wareIndex) => (
                            <p
                                className="m-0 mb-2"
                                key={wareIndex}>
                                {item?.availableInventory}
                            </p>
                        ))}
                    </>
                ),
        },
        {
            title: trans("warehouse.total_cost"),
            className: "bold-400",
            dataIndex: "totalStorageFee",
            key: "totalStorageFee",
            default: true,
            align: "right",
            width: 100,
            render: (totalStorageFee: any, record: Inventory) =>
                !isEmpty(record?.skus[0]?.warehouseStocks) && (
                    <>
                        {record?.skus[0]?.warehouseStocks?.map((item, wareIndex) => (
                            <p
                                className="m-0 mb-2"
                                key={wareIndex}>
                                {item?.totalStorageFee ? convertNumberToCurrency(item?.totalStorageFee) : 0}
                            </p>
                        ))}
                    </>
                ),
        },
        {
            title: trans("vendor.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            key: "createdAt",
            default: false,
            align: "left",
            width: 120,
            render: (date: string) => (date ? formatDateFull(date) : ""),
        },
    ]
    const dataTrees = useMemo(
        () =>
            !isEmpty(columns)
                ? columns?.map((item: any) => ({
                      title: item?.title,
                      key: item?.key,
                      disabled: item?.default,
                  }))
                : [],
        [columns]
    )
    const handleChangePage = (newPage: number, newSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            page: !searchParams.has("pageSize") || Number(oldSearch.pageSize) === newSize ? newPage : PAGINATION.DEFAULT_CURRENT_PAGE,
            pageSize: newSize,
        }
        setSearchParams(params)
    }
    return (
        <Card
            className="space-y"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${total}(${total})` : ""}
            extra={
                !isEmpty(dataTrees) && (
                    <SettingTable
                        dataTree={dataTrees}
                        keyTable="inventoryVendor"
                        setSelectedTable={setSelectedTable}
                        selectedTable={selectedTable}
                    />
                )
            }>
            <Table
                rowKey={"id"}
                scroll={{
                    x: true,
                }}
                columns={filter(columns, (iFil: any) => selectedTable?.includes(iFil?.key))}
                dataSource={inventories || []}
                loading={loading}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: pageSize,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                }}
            />
        </Card>
    )
}
