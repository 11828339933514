import { trans } from "@resources/localization"
import { Card, Skeleton } from "antd"
import React from "react"

interface Props {
    title: string
    dataToday: string | number
    dataYesterday: string | number | undefined
    icon: any
    bgIcon: string
    loading?: boolean
}

const DashboardCard = (props: Props) => {
    return (
        <div className="mt-3">
            <Card
                bordered={false}
                style={{
                    borderRadius: "5px",
                    overflow: "hidden",
                    boxShadow: " #e0e0e080 2px 2px 4px",
                }}>
                {props.loading ? (
                    <Skeleton active />
                ) : (
                    <div className="">
                        <div className="w-full flex justify-between mb-5">
                            <div className="">
                                <div className="ml-1 mb-3">{props.title}</div>
                                <div className="text-[#1890ff] text-7xl">{props.dataToday}</div>
                            </div>
                            <div className={`rounded-full h-0 w-0 md:h-24 md:w-24 ${props.bgIcon} flex justify-center items-center flex-shrink-0`}>
                                <i className={`fa-light ${props.icon} text-white text-6xl`} />
                            </div>
                        </div>
                        <hr />
                        <div className="h-8">
                            <div className="text-slate-500 mt-5 ">
                                {props.dataYesterday !== undefined ? `${trans("dashboard.yesterday")}: ${props.dataYesterday}` : ""}
                            </div>
                        </div>
                    </div>
                )}
            </Card>
        </div>
    )
}

export default DashboardCard
