import { trans } from "@resources/localization"
import { Card, Col, Row } from "antd"
import React from "react"

const Statistic = () => {
    return (
        <Card
            className="space-layout"
            title={trans("statistics.title")}>
            <Row gutter={15}>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("order_financing.total_order_amount")}:</p>
                    <p className="ml-1"></p>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("order_financing.total_received_cod")}:</p>
                    <p className="ml-1"></p>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("order_financing.total_fee")}:</p>
                    <p className="ml-1"></p>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("order_financing.balance")}:</p>
                    <p className="ml-1"></p>
                </Col>
            </Row>
        </Card>
    )
}

export default Statistic
