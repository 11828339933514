// import { useAppSelector } from "@hook/useAppSelector"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetOrderPurchasingAccount } from "@reducer/version2/inbound-shipment.reducer"
import { trans } from "@resources/localization"
import { Card, Col, Form, Row, Select } from "antd"
import React from "react"
export const GeneralShipment: React.FC = () => {
    const dispatch = useAppDispatch()
    // const wareHouses = useAppSelector((state) => state.package.wareHouses)
    const purchasingAccounts = useAppSelector((state) => state.inboundShipment.purchasingAccount)
    console.log('purchasingAccounts', purchasingAccounts)
    return (
        <Card
            className="space-layout"
            title={trans("package.inbound_info")}>
            <Row gutter={12}>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="warehouse"
                        label={trans("package.shipment_account")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            allowClear
                            placeholder={trans("package.shipment_account_pl")}
                            onSelect={(e: any) => {
                                dispatch(callGetOrderPurchasingAccount({ purchasing_account_id: e }))
                            }}>
                            {purchasingAccounts?.map((item) => (
                                <Select.Option
                                    key={item?.id}
                                    value={item?.id}>
                                   {item?.username}-{item?.purchasing_service?.data?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="warehouse"
                        label={trans("package.shipment_order")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            allowClear
                            placeholder={trans("package.shipment_order_pl")}>
                            <Select.Option value="1">1</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}>
                    <Form.Item
                        name="warehouse"
                        label={trans("warehouse.title")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            allowClear
                            placeholder={trans("warehouse.select_warehouse")}>
                            <Select.Option value="1">1</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}
