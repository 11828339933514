import { InventoryQuery } from "@domain/version2/InventoryApi"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { filterWithTimezone } from "@util/Common"
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

export const Filter: React.FC = () => {
    const warehouses = useAppSelector(state => state.inventory.warehouses)
    const [searchParams, setSearchParams] = useSearchParams()
    const [formRef] = Form.useForm()
    
    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("createdAtFrom") &&
                    searchParams.has("createdAtTo") && {
                        createdAt: [filterWithTimezone(oldSearch?.createdFrom), filterWithTimezone(oldSearch?.createdTo)],
                    }),
            })
        } else {
            formRef.resetFields()
        }
    }, [])

    const onHandleFilter = (values: InventoryQuery) =>{
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                createdAtFrom: values?.createdAt ? filterWithTimezone(values?.createdAt[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined,
                createdAtTo: values?.createdAt ? filterWithTimezone(values?.createdAt[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined,
            }
            delete params.createdAt
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    return (
        <Card className="space-layout custom-bottom">
        <Form
            form={formRef}
            onFinish={onHandleFilter}
            layout="vertical">
            <Row gutter={12}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        label={trans("warehouse.sku_code")}
                        name="skuCode">
                        <Input
                            allowClear
                            placeholder={trans("warehouse.sku_code_pl")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        label={trans("inventory.sku_name")}
                        name="name">
                        <Input
                            allowClear
                            placeholder={trans("inventory.sku_name_pl")}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        label={trans("vendor.created_at")}
                        name="createdAt">
                        <DatePicker.RangePicker
                            allowClear
                            format={['DD/MM/YYYY','DD/MM/YYYY']}
                            placeholder={[trans("order_list.from"), trans("order_list.to")]}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        label={trans("warehouse.warehouse")}
                        name="warehouseCode">
                          <Select placeholder={trans('warehouse.select_warehouse')}>
                            {warehouses?.map((item) => (
                            <Select.Option key={item.id} value={item.code}>{item.name}</Select.Option>

                            ))}
                          </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Space>
                        <Button
                            htmlType="submit"
                            type="primary">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            onClick={() => {
                                setSearchParams({})
                                formRef.resetFields()
                            }}
                            type="default"
                            >
                            {trans("contact.clear")}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
        </Card>
    )
}
