import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { IProductSku } from "@domain/version2/Product"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCreateCampaign } from "@reducer/version2/campaign.reducer"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callProductSuggest, resetDataDetail, resetDataProductsSuggest } from "@reducer/version2/product.reducer"
import { trans } from "@resources/localization"
import { Button, Form, message, Row, Space } from "antd"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { GeneralInfo } from "./General"
import { CreateProducts } from "./ListProduct"
import { callGetSkuComboSuggest, resetDataDetailSkuCombo } from "@reducer/version2/skuCombo.reducer"
import { CreateSkuCombos } from "./ListSkuCombo"
export const CreateCampaign: React.FC = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const loadingCreate = useAppSelector((state) => state.campaignVersion2.loadingCreateCampaign)
    const detailProduct = useAppSelector((state) => state.productVersion2.detailProduct)
    const detailSkuCombo = useAppSelector((state) => state.skuCombo.skuComboDetail)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("campaign_list.create")))
        dispatch(callProductSuggest({ keyword: "" }))
        dispatch(callGetSkuComboSuggest({ keyword: "" }))
        dispatch(resetDataProductsSuggest([]))
        dispatch(resetDataDetail({}))
        dispatch(resetDataDetailSkuCombo({}))
    }, [dispatch])

    const onCreateCampaign = (values: any) => {
        console.log("values", values)
        let allSkus: any = []
        if (values?.type === "product") {
            detailProduct?.productSkus?.forEach((item: IProductSku, index: number) => {
                const oneSku: any = values[`pricePolicies${index + 1}`]
                const finalOneSku = oneSku?.map((finalItem: any) => ({
                    ...finalItem,
                    sku: item.sku,
                }))
                allSkus = [...allSkus, ...finalOneSku]
            })
        }
        if (values?.type === "combo") {
            allSkus = values?.pricePolicies?.map((element: any) => ({
                ...element,
                sku: detailSkuCombo?.code,
            }))
        }
        dispatch(
            callCreateCampaign({
                ...(values.type === 'product' && {product: values?.productCode}),
                ...(values.type === 'combo' && {combo: values?.combo}),
                name: values?.name,
                refId: values?.fbCampaignIds?.join(",") || "",
                pricePolicies: allSkus,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                setTimeout(() => {
                    dispatch(resetDataProductsSuggest([]))
                    dispatch(resetDataDetail({}))
                    formRef.resetFields()
                    navigate("/campaign?tab=campaign")
                }, 500)
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: "sidebar.dashboard",
                        link: "/",
                    },
                    {
                        name: "sidebar.campaign",
                        link: "/campaign",
                    },
                    {
                        name: "campaign_list.create",
                        link: "",
                    },
                ]}
            />
            <Form
                form={formRef}
                layout="vertical"
                onFinish={onCreateCampaign}>
                <GeneralInfo formRef={formRef} />
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) => pre.type !== next.type}>
                    {({ getFieldValue }) => (
                        <>
                            {getFieldValue("type") === "product" && <CreateProducts formRef={formRef} />}
                            {getFieldValue("type") === "combo" && <CreateSkuCombos formRef={formRef} />}
                        </>
                    )}
                </Form.Item>
                <Row
                    justify="end"
                    className="mt-3 mx-3">
                    <Space>
                        <Button
                            loading={loadingCreate}
                            onClick={() => {
                                dispatch(resetDataProductsSuggest([]))
                                dispatch(resetDataDetail({}))
                                formRef.resetFields()
                                navigate("/campaign?tab=campaign")
                            }}
                            type="default">
                            {trans("setting.cancel")}
                        </Button>
                        <Button
                            loading={loadingCreate}
                            type="primary"
                            htmlType="submit">
                            {trans("setting.save")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </DefaultLayout>
    )
}
