import { IDetailProduct, IProduct, IProductsOfVendor, IProductVariants } from "@domain/version2/Product"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export default class ProductApi {
    static getProductsSuggest(params: { keyword: string }): Promise<{ data: IProduct[] }> {
        return apiClient.get(`products/autocomplete`, { params })
    }

    static getDetailProduct(code: string): Promise<{ data: IDetailProduct }> {
        return apiClient.get(`products/${code}`)
    }

    static getProductsByVariants(params: { keyword: string }): Promise<AxiosResponse<IProductVariants[]>> {
        //keyword: product name || product code || sku
        return apiClient.get(`products/variants`, { params })
    }

    static getProductsOfVendor(params: any): Promise<AxiosResponse<IProductsOfVendor[]>> {
        return apiClient.get(`products`, { params })
    }
}
