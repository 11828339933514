import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { formatDateShort } from "@util/Common"
import { Card, Col, Row, Typography } from "antd"
import { find } from "lodash"
import React from "react"
export const InfoPaymentState: React.FC = () => {
    const detailPaymentState = useAppSelector((state) => state.paymentDetail.paymentStateDetail)
    const listCurrencies = useAppSelector((state) => state.categoriesVersion2.listCurrencies)
    return (
        <Card className="space-layout">
        <Row>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 3 }}>
                <Typography.Text
                    className="block"
                    strong>
                    {trans("payment_statement.code")}
                </Typography.Text>
                <p className="m-0 mb-3">{detailPaymentState?.code}</p>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}>
                <Typography.Text
                    className="block"
                    strong>
                    {trans("payment_statement.cycle")}
                </Typography.Text>
                <p className="m-0 mb-3">
                    {formatDateShort(detailPaymentState?.startedAt || "")} - {formatDateShort(detailPaymentState?.endedAt || "")}
                </p>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 5 }}>
                <Typography.Text
                    className="block"
                    strong>
                    {trans("payment_statement.unit_original")}
                </Typography.Text>
                <p className="m-0 mb-3">{find(listCurrencies, { code: detailPaymentState?.currencyCode })?.name || ""}</p>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 5 }}>
                <Typography.Text
                    className="block"
                    strong>
                    {trans("payment_statement.unit_equivalent")}
                </Typography.Text>
                <p className="m-0 mb-3">{find(listCurrencies, { code: detailPaymentState?.conversionCurrencyCode })?.name || ""}</p>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 5 }}>
                <Typography.Text
                    className="block"
                    strong>
                    {trans("payment_statement.status")}
                </Typography.Text>
                <p className="m-0 mb-3">{trans(`payment_statement.${detailPaymentState?.statusCode}`)}</p>
            </Col>
        </Row>
    </Card>
    )
}
