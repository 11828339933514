import appConfig from "@config/app"
import { ITimezone, IUserInfo, IUserVendor } from "@domain/Common"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export default class CommonApi {
    static getTimezones(): Promise<{ data: ITimezone[] }> {
        return apiClient.get('time-zones')
    }
    static updateUserInfo(body: { zoneInfo: string }): Promise<any> {
        return apiClient.patch(`users`, body)
    }
    static getAuthUserInfo(): Promise<{ data: IUserInfo }> {
        return apiClient.get(`auth/user/info`, {
            baseURL: appConfig.apiUrl,
        })
    }
    static getVendorsOfUser(): Promise<AxiosResponse<IUserVendor[]>> {
        return apiClient.get(`auth/user/vendors`, {
            baseURL: appConfig.apiUrl,
        })
    }
}
