import appConfig from "@config/app";
import { IDetailOrderPurchasing, IOrderPurchasingAccount, IPurchasingAccountM28 } from "@domain/version2/InboundShipment";
import apiClient from "@util/ApiClient";
import { AxiosResponse } from "axios";

export class InboundShipmentApi {
  static getPurchasingAccount(): Promise<AxiosResponse<{data: IPurchasingAccountM28[]}>> {
      return apiClient.get(`m28/purchasing-accounts`,{baseURL: appConfig.apiUrl})
  }
  static getOrderWithPurchasingAccount(params:{purchasing_account_id: string}): Promise<AxiosResponse<IOrderPurchasingAccount[]>> {
      return apiClient.get(`m28/purchasing-orders`, {params, baseURL: appConfig.apiUrl})
  }
  static getDetailOrderPurchasing(orderId: string): Promise<AxiosResponse<IDetailOrderPurchasing>> {
      return apiClient.get(`m28/purchasing-orders/${orderId}`, {baseURL: appConfig.apiUrl})
  }
}