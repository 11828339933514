import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Card, Col, Form, Input, Row, Select, Spin } from "antd"
import React from "react"

export const OrderInfo = () => {
    const listCouriers = useAppSelector((state) => state.courierSetting.courierVendor)

    return (
        <div className="mt-3">
            <Card title={trans("order.create_order")}>
                <>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="code"
                                label={trans("contact-detail.order_code")}>
                                <Input
                                    maxLength={160}
                                    placeholder={trans("order.order_code_auto_generates")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="courierService"
                                label={trans("order_list.courier")}
                                rules={[
                                    {
                                        required: true,
                                        message: trans("message.required"),
                                    },
                                ]}>
                                <Select
                                    placeholder={trans("order_list.courier_placeholder")}
                                    showSearch
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    optionFilterProp="children"
                                    notFoundContent={<Spin />}
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}>
                                    {listCouriers?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.courierCode}>
                                            {item?.courierName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="trackingNumber"
                                label={trans("order_detail.tracking_number")}>
                                <Input placeholder={trans("import_tracking_more.title")} />
                            </Form.Item>
                        </Col>
                        {/* <Col span={24}>
                                <Form.Item
                                    name="exportWarehouse"
                                    label={trans("sell_channel.export_warehouse")}>
                                    <Select
                                        placeholder={trans("sell_channel.export_warehouse_placeholder")}
                                        showSearch
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        optionFilterProp="children"
                                        notFoundContent={<Spin />}
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                        }}>
                                        {[]?.map((item: any) => (
                                            <Select.Option
                                                key={item?.id}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                    </Row>
                </>
            </Card>
        </div>
    )
}
