import { IProductGroup } from "@domain/version2/Product"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { SearchOutlined } from "@ant-design/icons"
import { filterWithTimezone } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callSuggestUser } from "@reducer/version2/user.reducer"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.productVersion2.loadingListProductsOfVendor)
    const listSuggestUser = useAppSelector((state) => state.usersVersion2.listSuggestUsers)
    const listProductGroups = useAppSelector((state) => state.productGroups.listProductGroups)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("createdAtFrom") &&
                    searchParams.has("createdAtTo") && {
                        createdAt: [filterWithTimezone(oldSearch?.createdFrom), filterWithTimezone(oldSearch?.createdTo)],
                    }),
            })
            if (Object.keys(oldSearch).length === 1 && searchParams.has("tab")) {
                formRef.resetFields()
            }
        } else {
            formRef.resetFields()
        }
    }, [])
    const handleFilter = (values: any) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                // productGroupCode: values?.productGroupCode ? values?.productGroupCode?.join(",") : undefined,
                createdAtFrom: values?.createdAt ? filterWithTimezone(values?.createdAt[0]).startOf("day").toISOString() : undefined,
                createdAtTo: values?.createdAt ? filterWithTimezone(values?.createdAt[1]).endOf("day").toISOString() : undefined,
            }
            params.tab = "products"
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    const onSearchAutocomplete = (searchText: string, type: string) => {
        if (searchText.trim().length >= 3) {
            switch (type) {
                case "user":
                    dispatch(
                        callSuggestUser({
                            keyword: searchText.trim(),
                        })
                    )
                    break

                default:
                    break
            }
        } else if (searchText.trim() === "") {
            switch (type) {
                case "user":
                    dispatch(
                        callSuggestUser({
                            keyword: "",
                        })
                    )
                    break

                default:
                    break
            }
        }
    }
    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={handleFilter}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("products.search_name_code")}
                            name="keyword">
                            <Input
                                placeholder={trans("products.search_name_code")}
                                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("product.category")}
                            name="productGroupCode">
                            <Select placeholder={trans("products.select_group")}>
                                {listProductGroups?.map((iPro: IProductGroup) => (
                                    <Select.Option
                                        key={iPro.id}
                                        value={iPro.code}>
                                        {iPro.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("products.vendor_created_at")}
                            name="createdAt">
                            <DatePicker.RangePicker
                                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                placeholder={[trans("order_list.from"), trans("order_list.to")]}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("payment_statement.createdBy")}
                            name="createdBy">
                            <Select
                                allowClear
                                onBlur={() => onSearchAutocomplete("", "user")}
                                onSearch={(e) => {
                                    onSearchAutocomplete(e, "user")
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                placeholder={trans("placeholder.select_user")}>
                                {listSuggestUser?.map((user) => (
                                    <Select.Option
                                        key={user.id}
                                        value={user.username}>
                                        {user.username}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingList}
                            className="mg-r-10 _filter_button_filter">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({ tab: "products" })
                            }}
                            loading={loadingList}
                            className="mg-r-10 _filter_button_clear">
                            {trans("contact.clear")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
