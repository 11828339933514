import { UploadOutlined } from "@ant-design/icons"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callUploadFileOrder } from "@reducer/version2/order.reducer"
import { trans } from "@resources/localization"
import { Button, Col, Form, message, Row, Space, Typography } from "antd"
import React from "react"

type Props = {
    setIsUpload: (data: boolean) => void
    setClose: () => void
    setListDraft: (data: any) => void
}
export const CreateImport: React.FC<Props> = ({ setIsUpload, setClose, setListDraft }) => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const loadingUploadOrder = useAppSelector((state) => state.orderVersion2.loadingUploadOrder)

    const handleImportDeliveryCase = (values: { file: File }) => {
        if (!values.file) {
            message.error(trans("order.import_required"))
        } else if (values.file.size > 5000000) {
            message.error(trans("order.upload_file_less_than"))
        } else {
            dispatch(
                callUploadFileOrder({
                    file: values.file,
                })
            ).then((result: any) => {
                if (result?.payload?.status === 201) {
                    setListDraft(result?.payload?.data)
                    message.success(trans("message.success"))
                    setIsUpload(true)
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    return (
        <Form
            form={formRef}
            onFinish={handleImportDeliveryCase}
            layout="vertical">
            <Row gutter={[0, 15]}>
                <Col span={24}>
                    <ul className="px-5">
                        <li className="list-disc">
                            <Typography.Text>{trans("package.download_sample_file_here")}: </Typography.Text>
                            <a
                                href={require("@resources/files/import_order_template.xlsx")}
                                rel="noreferrer"
                                download="import_order_template.xlsx"
                                type="link">
                                {trans("package.download_template")}
                            </a>
                        </li>
                        <li className="list-disc">
                            <Typography.Text className="block">
                                {trans("order.maximum_file_lines")}:<span className="font-bold ml-1">500 lines</span>
                            </Typography.Text>
                        </li>
                        <li className="list-disc">
                            <Typography.Text className="block">
                                {trans("package.maximum_file_size")}:<span className="font-bold ml-1">5MB</span>
                            </Typography.Text>
                        </li>
                    </ul>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="file"
                        hidden
                    />
                    <Button
                        icon={<UploadOutlined />}
                        onClick={() => document.getElementById("uploadFile")?.click()}>
                        {trans("package.choose_file_xlsx")}
                    </Button>
                    <input
                        hidden
                        type="file"
                        id="uploadFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        value=""
                        onChange={(e) => {
                            formRef.setFieldsValue({
                                file: e.target.files ? e.target.files[0] : "",
                            })
                        }}
                    />
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.file !== next.file}>
                        {({ getFieldValue }) =>
                            getFieldValue("file") && (
                                <div className="flex mt-3">
                                    <p className="m-0 mt-1 ml-2">{getFieldValue("file")?.name}</p>
                                    <Button
                                        type="link"
                                        icon={<i className="fa-solid fa-trash"></i>}
                                        onClick={() => {
                                            formRef.setFieldsValue({
                                                file: "",
                                            })
                                        }}></Button>
                                </div>
                            )
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="mt-3">
                <Space>
                    <Button
                        loading={loadingUploadOrder}
                        type="default"
                        onClick={() => {
                            setIsUpload(false)
                            setClose()
                        }}>
                        {trans("button.cancel_button")}
                    </Button>
                    <Button
                        loading={loadingUploadOrder}
                        type="primary"
                        htmlType="submit">
                        {trans("order.upload")}
                    </Button>
                </Space>
            </Row>
        </Form>
    )
}
