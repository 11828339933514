import { PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCreatePaymentStatement, callGetListPaymentStatement, changeVisibleFormCreatePaymentState } from "@reducer/version2/paymentStatement.reducer"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Button, Col, DatePicker, Form, message, Row, Select, Space, Typography } from "antd"
import moment from "moment"
import React from "react"
import { useSearchParams } from "react-router-dom"

const InputForm = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const loadingCreate = useAppSelector((state) => state.paymentStatement.loadingCreate)
    const listCurrencies = useAppSelector((state) => state.currencies.listCurrencies)
    const vendorCode = SecurityService.getVendorCode()

    const onCreate = (values: any) => {
        const dataRequest: any = {
            ...values,
            startedAt: values?.periodAt ? moment(values?.periodAt[0])?.startOf("day").toISOString() : undefined,
            endedAt: values?.periodAt ? moment(values.periodAt[1])?.endOf("day").toISOString() : undefined,
        }

        delete dataRequest.periodAt
        dispatch(callCreatePaymentStatement(dataRequest)).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                const params = Object.fromEntries(searchParams.entries())
                dispatch(
                    callGetListPaymentStatement({
                        ...params,
                        size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                        vendorCode,
                    })
                )
                dispatch(changeVisibleFormCreatePaymentState(false))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <Form
            form={formRef}
            onFinish={onCreate}
            layout="vertical">
            <Row gutter={15}>
                <Col span={24}>
                    <Typography.Text className="block mb-2">
                        <span className="text-red-500 font-thin">*</span>
                        {` ${trans("payment_statement.period")}`}
                    </Typography.Text>
                    <Form.Item
                        name="periodAt"
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <DatePicker.RangePicker
                            format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                            disabledDate={(current: any) => current && current >= new Date()}
                            placeholder={[trans("contact.from"), trans("contact.to")]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Typography.Text className="block mb-2">
                        <span className="text-red-500 font-thin">*</span>
                        {` ${trans("payment_statement.currency")}`}
                    </Typography.Text>
                    <div className="flex justify-between items-center">
                        <Form.Item
                            name="currencyCode"
                            className="w-[49%]"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                placeholder={trans("payment_statement.currency_pl")}>
                                {listCurrencies?.map((item: any) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate>
                            {() => (
                                <Form.Item
                                    name="conversionCurrencyCode"
                                    className="w-[49%]"
                                    rules={[
                                        {
                                            required: true,
                                            message: trans("message.required"),
                                        },
                                    ]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                        }}
                                        // disabled={!getFieldValue("currencyCode")}
                                        placeholder={trans("payment_statement.currency_pl")}>
                                        {listCurrencies?.map((item: any) => (
                                            <Select.Option
                                                key={item?.id}
                                                // disabled={item?.code === getFieldValue("currencyCode")}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </Form.Item>
                    </div>
                </Col>
            </Row>
            <Row
                justify="end"
                className="mt-3">
                <Space>
                    <Button
                        type="default"
                        onClick={() => dispatch(changeVisibleFormCreatePaymentState(false))}
                        loading={loadingCreate}>
                        {trans("button.cancel")}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="ml-1"
                        loading={loadingCreate}>
                        {trans("button.save")}
                    </Button>
                </Space>
            </Row>
        </Form>
    )
}

export default InputForm
