import { CAMPAIGN_SCOPE } from "@config/permission"
import { IPricePolicy } from "@domain/version2/Campaign"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Button, Card, Col, Form, InputNumber, Row, Typography } from "antd"
import { isEmpty } from "lodash"
import React, { useEffect, useMemo } from "react"
type Props = {
    formRef: any
}
export const CreateProducts: React.FC<Props> = ({ formRef }) => {
    const detailCampaign = useAppSelector((state) => state.campaignDetailVer2.detailCampaign)
    const disabledUpdate = !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_UPDATE)

    const listHeader = [
        { title: trans("products.variant"), span: 3 },
        { title: trans("ads.sku"), span: 5 },
        { title: `${trans("ads.quantity")}(${trans("ads.from")})`, span: 4 },
        { title: `${trans("ads.quantity")}(${trans("ads.to")})`, span: 4 },
        { title: trans("ads.price"), span: 5 },
        { title: trans("table.action"), span: 3 },
    ]
    useEffect(() => {
        if (!isEmpty(detailCampaign)) {
            detailCampaign?.pricePolicies?.forEach((item: IPricePolicy, index: number) => {
                formRef.setFieldsValue({
                    [`pricePolicies${index + 1}`]: item?.wholeSalePrice || [
                        {
                            from: "",
                            to: "",
                            price: "",
                        },
                    ],
                })
            })
        }
    }, [formRef, detailCampaign])
    const pricePolicies: any[] = useMemo(() => detailCampaign?.pricePolicies || [], [detailCampaign])
    return (
        <Card className="space-layout" title={`${trans("campaign.product")}: ${detailCampaign?.product?.name || ''}`}>
            <div className="bg-gray-200 p-3 border-b-black">
                <Row gutter={15}>
                    {listHeader.map((item: { title: string; span: number }, index: number) => (
                        <Col
                            span={item.span}
                            key={index}>
                            <Typography.Text strong>{item.title}</Typography.Text>
                        </Col>
                    ))}
                </Row>
            </div>
            <div>
                {!isEmpty(pricePolicies) &&
                    pricePolicies?.map((item: IPricePolicy, index: number) => (
                        <div
                            key={index}
                            className={index < pricePolicies?.length - 1 ? "border-b p-2" : "p-2"}>
                            <Form.List name={`pricePolicies${index + 1}`}>
                                {(fields, { add, remove }) => (
                                    <div>
                                        {fields.map(({ name, ...restField }) => (
                                            <Row
                                                className="p-1"
                                                key={name}
                                                gutter={15}>
                                                <Col span={3}>
                                                    <Typography.Text>{item?.variant ? Object.values(item?.variant)?.join("/") : ""}</Typography.Text>
                                                </Col>
                                                <Col span={5}>
                                                    <Typography.Text>{item?.sku}</Typography.Text>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "from"]}
                                                        rules={[{ required: true, message: trans("message.required") }]}>
                                                        <InputNumber
                                                            parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                            placeholder={trans("ads.from")}
                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                            disabled={disabledUpdate}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(pre, next) =>
                                                            pre[`pricePolicies${index + 1}`][name]?.from !== next[`pricePolicies${index + 1}`][name]?.from
                                                        }>
                                                        {({ getFieldValue }) => {
                                                            const quantityFrom = getFieldValue([`pricePolicies${index + 1}`, name, "from"])
                                                            return (
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "to"]}
                                                                    rules={[
                                                                        { required: true, message: trans("message.required") },
                                                                        {
                                                                            validator(_, value) {
                                                                                if (!value || Number(value) >= Number(quantityFrom)) {
                                                                                    return Promise.resolve()
                                                                                }
                                                                                return Promise.reject(new Error(trans("campaign.required_from_to")))
                                                                            },
                                                                        },
                                                                    ]}>
                                                                    <InputNumber
                                                                        parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                                        placeholder={trans("ads.to")}
                                                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                                        disabled={disabledUpdate}
                                                                    />
                                                                </Form.Item>
                                                            )
                                                        }}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "price"]}
                                                        rules={[{ required: true, message: trans("message.required") }]}>
                                                        <InputNumber
                                                            placeholder={trans("ads.price")}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            parser={(value: any) =>
                                                                value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0
                                                            }
                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                            disabled={disabledUpdate}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    {name !== 0 && (
                                                        <Button
                                                            onClick={() => remove(name)}
                                                            type="link"
                                                            disabled={disabledUpdate}
                                                            icon={<i className="fa-regular fa-trash" />}
                                                        />
                                                    )}
                                                    {name === fields.length - 1 && name < 4 && (
                                                        <Button
                                                            onClick={() => add()}
                                                            type="default"
                                                            disabled={disabledUpdate}>
                                                            {trans("button.add")}
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                )}
                            </Form.List>
                        </div>
                    ))}
            </div>
        </Card>
    )
}
