import React from "react"
import { Navigate } from "react-router-dom"
import lodash, { every, isEmpty } from "lodash"
import { AccessDenied } from "@component/AccessDenied"
import SecurityService from "@util/SecurityService"
import DefaultLayout from "@component/Layout/Default"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { getVendorConfigs } from "@reducer/version2/vendor-detail.reducer"

interface Props {
    children?: any
    permissions?: string[]
}

export const AuthenticateRoute = (props: Props) => {
    const dispatch = useAppDispatch()
    if (!SecurityService.isLogged()) {
        if (!lodash.startsWith(window.location.pathname, "/login") || !lodash.startsWith(window.location.pathname, "/authentication")) {
            const redirectBackUrl: any = window.location.href
            return <Navigate to={`/login?redirectBackUrl=${btoa(redirectBackUrl)}`} />
        }

        return <Navigate to={"/login"} />
    }
    dispatch(getVendorConfigs())
    let component = props.children
    const checkPermissions: any = []
    props.permissions?.map((iPer) => {
        checkPermissions.push({
            iCan: !SecurityService.can(iPer),
        })
    })
    if (!isEmpty(props.permissions) && every(checkPermissions, (item) => item.iCan)) {
        dispatch(changePageTitleAction("PBlocked"))
        component = (
            <DefaultLayout loading={false}>
                <AccessDenied />
            </DefaultLayout>
        )
    }

    return component
}
