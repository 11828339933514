import { ICreateOrderRequest, IOrder, IOrderImportValidate, IOrderQuery, IOrderStatus } from "@domain/version2/Order"
import { IDetailOrder, IOrderRequest, ItemOrderRequest, IOrderFinancialStatus } from "@domain/version2/OrderDetail"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export default class OrderApi {
    static getListOrders(params: IOrderQuery): Promise<{ data: Array<IOrder>; headers: { [key: string]: any } }> {
        return apiClient.get("orders", {
            params,
        })
    }
    static getOrderDetail(code: string): Promise<{ data: IDetailOrder }> {
        return apiClient.get(`orders/${code}`)
    }
    static updateOrder(code: string, body: IOrderRequest): Promise<any> {
        return apiClient.patch(`orders/${code}`, body)
    }
    static updateOrderItem(code: string, body: { orderItems: ItemOrderRequest[], orderCombos: ItemOrderRequest[] }): Promise<any> {
        return apiClient.put(`orders/${code}/items`, body)
    }
    static updateOrderStatus(code: string, body: { status: string }): Promise<any> {
        return apiClient.patch(`orders/${code}/status`, body)
    }
    static updateOrderPaid(code: string, body: { isPaid: boolean }): Promise<any> {
        return apiClient.patch(`orders/${code}/paid`, body)
    }
    static getOrderStatuses(): Promise<{ data: IOrderStatus[] }> {
        return apiClient.get(`orders/statuses`)
    }
    static exportOrders(payload: IOrderQuery): Promise<any> {
        return apiClient.post(`orders/export`, payload)
    }
    static createOrder(body: ICreateOrderRequest): Promise<any> {
        return apiClient.post(`orders`, body)
    }
    static getOrderFinancialStatuses(): Promise<AxiosResponse<IOrderFinancialStatus[]>> {
        return apiClient.get(`financial-statuses`)
    }
    static updateOrderFinancialStatus(code: string, body: { financial: string }): Promise<AxiosResponse<any>> {
        return apiClient.patch(`orders/${code}/financial-statuses`, body)
    }
    static importOrderFinancialStatus(file: File) {
        const formData: any = new FormData()
        formData.append("file", file)
        return apiClient.patch(`orders/financial-statuses`, formData)
    }
    static uploadFileOrder(file: File) {
        const formData: any = new FormData()
        formData.append("file", file)
        return apiClient.post(`orders/parse-import-file`, formData)
    }
    static validateImportOrder(body: {orders: IOrderImportValidate[]}) {
        return apiClient.post(`orders/validate-import-data`, body)
    }
    static importOrder(body: {orders: IOrderImportValidate[]}) {
        return apiClient.post(`orders/import`, body)
    }
}
