import React, { useMemo } from "react"
import { Typography, Row, Col } from "antd"
import { trans } from "@resources/localization"
import DashboardCard from "@screen/Dashboard/DashboardCard"
import { useAppSelector } from "@hook/useAppSelector"
import { IContactDashboard } from "@domain/version2/Dashboard"

type Props = {
    titleHeader: string,
    today: IContactDashboard
    yesterday: IContactDashboard
}

export const DashboardContacts: React.FC<Props> = ({titleHeader, today,yesterday}) => {
    const loadingContact = useAppSelector(state => state.dashboard.loadingContact)
    
    const listContact = useMemo(
        () => [
            {
                title: trans("dashboard.new_contact"),
                dataToday: today?.newContact || 0,
                dataYesterday: yesterday?.newContact || 0,
                icon: "fa-address-card",
                bgIcon: "bg-[#ebf5e1]",
            },
            {
                title: trans("dashboard.new_call"),
                dataToday: today?.calledContact || 0,
                dataYesterday: yesterday?.calledContact || 0,
                icon: "fa-phone-flip",
                bgIcon: "bg-[#ebf5e1]",
            },
        ],
        [today, yesterday]
    )

    return (
        <div className="mx-3 mt-5">
            <Typography.Title level={4}>{titleHeader}</Typography.Title>

            <Row gutter={15}>
                {listContact.map((col) => (
                    <Col
                        key={col.title}
                        sm={24}
                        lg={12}>
                        <DashboardCard
                            title={col.title}
                            dataToday={col.dataToday}
                            dataYesterday={col.dataYesterday}
                            icon={col.icon}
                            bgIcon={col.bgIcon}
                            loading={loadingContact}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    )
}
