import IContactDetail from "@domain/version2/ContactDetail"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Card, Col, message, Row, Switch, Typography } from "antd"
import React from "react"
import { callUpdateContactDetailRemark, callGetContactDetail } from "@reducer/version2/contact-detail.reducer"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"

export const Header: React.FC = () => {
    const dispatch = useAppDispatch()
    const contactDetail: IContactDetail = useAppSelector((state) => state.contactDetail2.dataDetail)
    const loadingDetail: boolean = useAppSelector((state) => state.contactDetail2.loadingDetail)

    const handleUpdateUserRemark = () => {
        dispatch(callUpdateContactDetailRemark({ contactId: contactDetail?.id, data: { isSpam: !contactDetail?.isSpam } })).then((result) => {
            if (result.payload.status === 200) {
                message.success(trans("message.success"))
                dispatch(callGetContactDetail(contactDetail?.id))
            } else {
                message.error(trans("message.fail"))
                dispatch(callGetContactDetail(contactDetail?.id))
            }
        })
    }

    return (
        <Card className="space-layout custom-bottom">
            <Typography.Title
                level={4}
                className="block font-medium">
                {contactDetail?.name}
            </Typography.Title>
            <Row gutter={15}>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("contact-detail.campaign")}:</p>
                    <p className="ml-1">
                        {contactDetail?.campaignDetail?.code} - {contactDetail?.campaignDetail?.name || ""}
                    </p>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("contact.vendor")}:</p>
                    <p className="ml-1">
                        {contactDetail?.vendor} - {contactDetail?.vendorName}
                    </p>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("vendor.marketer")}:</p>
                    <p className="ml-1">{contactDetail?.marketer || ""}</p>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("contact.created_at")}:</p>
                    <p className="ml-1">{contactDetail?.createdAt ? formatDateFull(contactDetail?.createdAt) : ""}</p>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("contact-detail.created_by")}:</p>
                    <p className="ml-1">{contactDetail?.createdBy || ""}</p>
                </Col>
                <Col span={6}>
                    <p className="font-medium m-0">{trans("contact-detail.contact_state")}:</p>
                    <p className="ml-1">{`${contactDetail?.contactStatus?.name || ""}-${contactDetail?.contactStatus?.description || ""}`}</p>
                </Col>
                <Col span={6}>
                    {SecurityService.can(CONTACT_SCOPE.CONTACT_REMARK) ? (
                        <>
                            <p className="font-medium m-0">{trans("contact.mark_as_spam")}:</p>
                            {!loadingDetail && (
                                <Switch
                                    checkedChildren="On"
                                    unCheckedChildren="Off"
                                    defaultChecked={contactDetail?.isSpam}
                                    onChange={handleUpdateUserRemark}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <p className="font-medium m-0">{trans("contact.remark")}:</p>
                            {contactDetail?.isSpam ? <p className="ml-1">{trans("contact.spam")}</p> : <p className="ml-1">{trans("contact.blanks")}</p>}
                        </>
                    )}
                </Col>
            </Row>
        </Card>
    )
}
