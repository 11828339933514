import React, { useState } from "react"

import { Button, Card, Col, Form, Input, message, Row } from "antd"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"

import { useAppSelector } from "@hook/useAppSelector"
import { map } from "lodash"
import { formatDateFull } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetContactDetail, callUpdateContactDetail } from "@reducer/version2/contact-detail.reducer"
import { Address } from "./Address"
import { COUNTRY_CODE } from "@config/constant"

export const ContactInfo = () => {
    const [formRef] = Form.useForm()
    const [isUpdateContact, setIsUpdateContact] = useState<boolean>(false)
    const contactDetail = useAppSelector((state) => state.contactDetail2.dataDetail)
    const loadingUpdate = useAppSelector((state) => state.contactDetail2.loadingUpdateContact)
    const adsOfContact = useAppSelector((state) => state.contactDetail2.adsOfContact)
    const isCanUpdate = SecurityService.can(CONTACT_SCOPE.CONTACT_UPDATE)
    const dispatch = useAppDispatch()
    const areas = useAppSelector((state) => state.countriesVersion2?.areasSuggest)

    const handleToggleButtonUpdate = () => {
        setIsUpdateContact(!isUpdateContact)
        formRef.setFieldsValue({
            name: contactDetail?.name || "",
            note: contactDetail?.note || "",
            province: contactDetail?.provinceCode,
            district: contactDetail?.districtCode,
            ward: contactDetail?.ward,
            address: contactDetail?.address,
        })
    }

    const onUpdateContact = async (values: any) => {
        dispatch(
            callUpdateContactDetail({
                contactId: contactDetail?.id || "",
                data: {
                    ...values,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(callGetContactDetail(contactDetail?.id || ""))
                setIsUpdateContact(!isUpdateContact)
            } else message.error(result?.payload?.response?.data?.message || trans("message.fail"))
        })
    }

    const getExtraCard = () => {
        if (isUpdateContact) {
            return (
                <>
                    <Button
                        onClick={() => formRef.submit()}
                        loading={loadingUpdate}
                        type={"primary"}
                        className={"mg-r-5 _btn_submit_form_contact"}
                        htmlType={"submit"}>
                        {trans("button.update")}
                    </Button>
                    <Button
                        onClick={handleToggleButtonUpdate}
                        loading={loadingUpdate}
                        className={"_btn_cancel_form_contact"}>
                        {trans("button.cancel")}
                    </Button>
                </>
            )
        }

        return (
            isCanUpdate && (
                <Button
                    type="default"
                    icon={
                        <i
                            className="fa fa-pencil mr-2"
                            aria-hidden="true"></i>
                    }
                    className={"_btn_show_edit_form_contact"}
                    onClick={handleToggleButtonUpdate}>
                    {trans("button.edit")}
                </Button>
            )
        )
    }

    const getDivShowInfoContact = (keyTrans: string, data: string) => {
        return (
            <>
                <div className="flex">
                    <p className="font-medium ">{trans(keyTrans)}: </p>
                    <p className="ml-1">{data}</p>
                </div>
            </>
        )
    }

    const getRowInfoContact = () => {
        return (
            <div className="grid lg:grid-cols-2 md:grid-cols-1">
                {getDivShowInfoContact("contact-detail.fullname", contactDetail?.name || "")}
                {getDivShowInfoContact("contact-detail.phone", contactDetail?.phone || "")}
                {getDivShowInfoContact("contact.ads", map(adsOfContact, "name")?.join(",") || "")}
                {getDivShowInfoContact("contact.created_at", contactDetail?.createdAt ? formatDateFull(contactDetail?.createdAt) : "")}
                {getDivShowInfoContact("order_list.assign_to", contactDetail?.assignee || "")}
                {getDivShowInfoContact("contact.assigned_by", contactDetail?.assigner || "")}
                {getDivShowInfoContact("contact.assigned_at", contactDetail?.assignedAt ? formatDateFull(contactDetail?.assignedAt) : "")}
                {getDivShowInfoContact("contact-detail.last_contact", contactDetail?.lastCallAt ? formatDateFull(contactDetail?.lastCallAt) : "")}
                {getDivShowInfoContact("contact-detail.note", contactDetail?.note || "")}
                {getDivShowInfoContact("contact.country", contactDetail?.country?.name || "")}
                {getDivShowInfoContact("contact-detail.province", contactDetail?.province?.name || "")}
                {getDivShowInfoContact(contactDetail?.countryCode === COUNTRY_CODE?.VIETNAM ? "contact-detail.district" :  "order_list.district", contactDetail?.city?.name || "")}
                {getDivShowInfoContact(contactDetail?.countryCode === COUNTRY_CODE.VIETNAM ? "contact_activity.ward" : "contact-detail.district", contactDetail?.district?.name || "")}
                {getDivShowInfoContact("contact-detail.area", areas.find((el) => el.code === contactDetail?.ward)?.name || contactDetail?.ward || "")}
                {getDivShowInfoContact("contact-detail.address_detail", contactDetail?.address || "")}
            </div>
        )
    }

    const getFormUpdateContact = () => {
        return (
            <>
                <Form
                    form={formRef}
                    onFinish={onUpdateContact}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 8 } }}
                    wrapperCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 16 } }}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label={trans("contact-detail.fullname")}
                                rules={[{ required: true, message: trans("message.required") }]}>
                                <Input maxLength={160} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="note"
                                label={trans("contact-detail.note")}>
                                <Input.TextArea
                                    autoSize={{
                                        minRows: 4,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Address formRef={formRef} />
                    </Row>
                </Form>
            </>
        )
    }

    return (
        <>
            <Card
                className={"_summary_info_box_container"}
                title={trans("contact-detail.key_field")}
                extra={getExtraCard()}>
                {isUpdateContact ? getFormUpdateContact() : getRowInfoContact()}
            </Card>
        </>
    )
}
