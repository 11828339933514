import { IUser, IUserQuery, IUserRequest } from "@domain/version2/User"
import apiClient from "@util/ApiClient"

export default class UserApi {
    //version 2
    static getSuggestUser(params: { keyword: string }): Promise<{ data: IUser[] }> {
        return apiClient.get("vendors/users/autocomplete", { params })
    }
    static getUsers(params: IUserQuery): Promise<{ data: IUser[], headers: { [key: string]: any }; status: number }> {
        return apiClient.get("vendors/users", { params })
    }
    static getAllUsers(params: {keyword: string}): Promise<{ data: IUser[]}> {
        return apiClient.get("users/all/autocomplete", { params })
    }
    static createUser(body: IUserRequest): Promise<any> {
        return apiClient.patch("vendors/users", body)
    }
}
