export const CONTACT_SCOPE = {
    CONTACT_CREATE: "contact:create",
    CONTACT_IMPORT: "contact:import",
    CONTACT_VIEW_ALL: "contact:list:viewall",
    CONTACT_VIEW: "contact:list:view",
    CONTACT_DETAIL_ALL: "contact:detail:viewall",
    CONTACT_DETAIL: "contact:detail:view",
    CONTACT_UPDATE: "contact:detail:update",
    CONTACT_REMARK: "contact:remark:spam",
}

export const ORDER_SCOPE = {
    ORDER_VIEW: "order:list:view",
    ORDER_VIEW_ALL: "order:list:viewall",
    ORDER_DETAIL: "order:detail:view",
    ORDER_DETAIL_ALL: "order:detail:viewall",
    ORDER_STATUS_UPDATE: "order:status:update",
    ORDER_PAID_UPDATE: "order:paid:update",
    ORDER_ADDRESS_UPDATE: "order:address:update",
    ORDER_NOTE_UPDATE: "order:note:update",
    ORDER_ITEM_UPDATE: "order:order_item:update",
    ORDER_DELIVERY_FEE_UPDATE: "order:delivery_fee:update",
    ORDER_TOTAL_PAYMENT_UPDATE: "order:total_payment:update",
    ORDER_UPDATE: "order_update",
    ORDER_CREATE_MANUAL: "order:create:manual",
    ORDER_FINANCIAL_STATUS_UPDATE: "order:financial_status:update",
    ORDER_IMPORT_CREATE: "order:create:import",
}
export const CAMPAIGN_SCOPE = {
    CAMPAIGN_UPDATE: "campaign:update",
    CAMPAIGN_CREATE: "campaign:create",
    CAMPAIGN_VIEW: "campaign:list:view",
    CAMPAIGN_VIEW_ALL: "campaign:list:viewall",
    CAMPAIGN_DETAIL: "campaign:detail:view",
    CAMPAIGN_DETAIL_ALL: "campaign:detail:viewall",
}
export const ADS_SCOPE = {
    ADS_CREATE: "ads:create",
    ADS_VIEW: "ads:list:view",
    ADS_VIEW_ALL: "ads:list:viewall",
    ADS_DETAIL: "ads:detail:view",
    ADS_DETAIL_ALL: "ads:detail:viewall",
    ADS_UPDATE: "ads:update",
}
export const PAYMENT_STATEMENT_SCOPE = {
    PAYMENT_STATEMENT_CREATE: "payment_statement:create",
    PAYMENT_STATEMENT_LIST_VIEW: "payment_statement:list:view",
    PAYMENT_STATEMENT_DETAIL_VIEW: "payment_statement:detail:view",
    PAYMENT_STATEMENT_UPDATE: "payment_statement:detail:update",
}
export const USER_SCOPE = {
    STAFF_VIEW_LIST: "staff:view:list",
    STAFF_ADD: "staff:add",
}

export const SETTING_SCOPE = {
    COURIER_SETTING: "setting:courier",
    COURIER_VIEW: "setting:courier:view",
    COURIER_UPDATE: "setting:courier:update",
}
export const DASHBOARD_SCOPE = {
    DASHBOARD_CONTACT_VIEW_ALL: "dashboard:contact:view:all",
    DASHBOARD_CONTACT_VIEW_MKT: "dashboard:contact:view:mkt",
    DASHBOARD_ORDER_VIEW_ALL: "dashboard:order:view:all",
    DASHBOARD_ORDER_VIEW_MKT: "dashboard:order:view:mkt",
}
export const INVENTORY_SCOPE = {
    INVENTORY_LIST_VIEW: "stock:list:view",
    INVENTORY_DETAIL_VIEW: "stock:detail:view",
    INVENTORY_HISTORY_VIEW: "stock:history:view",
}
export const PACKAGE_SCOPE = {
    PACKAGE_LIST_VIEW: "package:list:view",
    PACKAGE_DETAIL_VIEW: "package:detail:view",
    PACKAGE_CANCEL: "package:cancel",
    PACKAGE_CREATE: "package:create",
}

export const PRODUCT_SCOPE = {
    PRODUCT_LIST_VIEW: "product:list:view",
    PRODUCT_DETAIL_VIEW: "product:detail:view",
}

export const SELLING_CHANNEL = {
    SELLING_CHANNEL_LIST_VIEW: "selling_channel:list:view",
    SELLING_CHANNEL_LIST_UPDATE: "selling_channel:list:update",
    SELLING_CHANNEL_CREATE: "selling_channel:create",
}

export const COMBO_SCOPE = {
    COMBO_LIST_VIEW: "combo:list:view",
    COMBO_CREATE: "combo:create",
    COMBO_DETAIL_VIEW: "combo:detail:view",
    COMBO_DETAIL_UPDATE: "combo:detail:update",
}

export const PURCHASING_ACCOUNT = {
    PURCHASING_ACCOUNT_VIEW: "purchasing_account:view",
    PURCHASING_ACCOUNT_CREATE: "purchasing_account:create",
    PURCHASING_ACCOUNT_DELETE: "purchasing_account:delete",
}
