import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB, PAGINATION } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callCampaignsSuggest } from "@reducer/version2/campaign.reducer"
import { callListContactEventStatuses, callListContactStatuses } from "@reducer/version2/categories.reducer"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callListContacts } from "@reducer/version2/contact.reducer"
import { callSuggestUser } from "@reducer/version2/user.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import AddContact from "./Create"
import { Filter } from "./Filter"
import { ImportComponent } from "./Import"
import { TableContact } from "./Table"
import { callProductSuggest } from "@reducer/version2/product.reducer"

export const Contact: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("contact.title")))
        dispatch(callCampaignsSuggest({ keyword: "" }))
        dispatch(callListContactStatuses())
        dispatch(callListContactEventStatuses())
        dispatch(callSuggestUser({ keyword: "" }))
        dispatch(callProductSuggest({keyword: ''}))
    }, [dispatch])

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        delete params.call
        dispatch(
            callListContacts({
                ...params,
                pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                sort: "createdAt:desc",
            })
        )
    }, [dispatch, searchParams])

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb breadCrumbs={[...HOME_BREADCRUMB, { name: "contact.title", link: "/contact" }]} />
            <Filter />
            <TableContact />
            <ImportComponent />
            <AddContact />
        </DefaultLayout>
    )
}
