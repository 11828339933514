import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB } from "@config/constant"
import { IPackageCreateRequest } from "@domain/version2/Package"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetCourierSuggest } from "@reducer/version2/courier-setting.reducer"
import { callGetPackageDetail } from "@reducer/version2/package-detail.reducer"
import { callCreatePackage, callGetWareHouses } from "@reducer/version2/package.reducer"
import { trans } from "@resources/localization"
import { Button, Form, message, Row, Space } from "antd"
import { isEmpty } from "lodash"
import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { InfoInboundShipment } from "./General"
import { ListProductInbound } from "./ListProductInbound"
export const CreateInboundShipment: React.FC = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const loadingCreate = useAppSelector((state) => state.package.loadingCreate)
    const detailPackage = useAppSelector(state => state.packageDetail.dataDetail)
    const loadingDetail = useAppSelector(state => state.packageDetail.loadingDetail)
    useEffect(() => {
        dispatch(changePageTitleAction(trans("package.create_handicraft")))
        dispatch(callGetWareHouses())
        dispatch(callGetCourierSuggest({ keyword: "" }))
    }, [])
    useEffect(()=>{
        if(searchParams.has('id')){
            const params = Object.fromEntries(searchParams.entries())
            dispatch(callGetPackageDetail(params?.id))
        }
    },[searchParams])
    useEffect(()=>{
        if(searchParams.has('id') && !isEmpty(detailPackage)){
            formRef.setFieldsValue({
                warehouse: detailPackage?.destination_warehouse?.id,
                courier: detailPackage?.courier?.toString(),
                trackingNumber: '',
                note: '',
                weight: '',
                packageItems: !isEmpty(detailPackage?.purchasing_package_item)
                    ? detailPackage?.purchasing_package_item?.map((item) => ({
                        code: item?.sku?.code,
                        name: item?.sku?.name,
                        id: item?.sku_id,
                        quantity: item?.declare_quantity,
                      }))
                    : [],
            })
        }
    },[searchParams, detailPackage])
    const onCreatePackage = (values: any) => {
        if (!isEmpty(values?.packageItems)) {
            const dataRequest: IPackageCreateRequest = {
                warehouse: values?.warehouse,
                ...(values.courier && {courier: values?.courier}),
                ...(values.trackingNumber && {trackingNumber: values?.trackingNumber}),
                ...(values.note && {note: values?.note}),
                ...(values.weight && {weight: Number(values?.weight)/1000}),
                packageItems: !isEmpty(values?.packageItems)
                    ? values?.packageItems?.map((item: any) => ({
                          quantity: item?.quantity,
                          sku: item?.id,
                      }))
                    : [],
            }
            dispatch(callCreatePackage(dataRequest)).then((result: any) => {
                if (result?.payload?.status === 201) {
                    message.success(trans("message.success"))
                    setTimeout(() => {
                        formRef.resetFields()
                        navigate("/package")
                    }, 500)
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        } else {
            message.error(`${trans("package.product_sku")} ${trans("message.required")}`)
        }
    }
    return (
        <DefaultLayout loading={searchParams.has('id') && loadingDetail}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    ...HOME_BREADCRUMB,
                    {
                        name: "package.title",
                        link: "/package",
                    },
                    {
                        name: "package.create_handicraft",
                        link: "",
                    },
                ]}
            />
            <Form
                onFinish={onCreatePackage}
                layout="vertical"
                form={formRef}>
                <InfoInboundShipment />
                <ListProductInbound formRef={formRef} />
                <div className="mt-3 mx-3">
                    <Row
                        justify="end"
                        className="mt-3">
                        <Space>
                            <Button
                                type="default"
                                loading={loadingCreate}
                                onClick={() => {
                                    navigate("/package")
                                }}>
                                {trans("setting.cancel")}
                            </Button>
                            <Button
                                loading={loadingCreate}
                                type="primary"
                                htmlType="submit">
                                {trans("setting.save")}
                            </Button>
                        </Space>
                    </Row>
                </div>
            </Form>
        </DefaultLayout>
    )
}
