import { PAGINATION } from "@config/constant"
import { USER_SCOPE } from "@config/permission"
import { IUser } from "@domain/version2/User"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeAddUserVisible } from "@reducer/version1/user.reducer"
import { callGetUsers, callCreateUser } from "@reducer/version2/user.reducer"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Button, Col, Form, Input, Row, Space, Tabs, Table, Modal, Typography, Card } from "antd"
import { ColumnsType } from "antd/lib/table"
import TabPane from "antd/lib/tabs/TabPane"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
export const ListUser: React.FC = () => {
    const dispatch = useAppDispatch()
    const size: number = useAppSelector((state) => state.usersVersion2.pagination.size)
    const currentPage: number = useAppSelector((state) => state.usersVersion2.pagination.currentPage)
    const total: number = useAppSelector((state) => state.usersVersion2.pagination.total)
    const totalPage: number = useAppSelector((state) => state.usersVersion2.pagination.totalPage)
    const listUsers = useAppSelector((state) => state.usersVersion2.users)
    const [searchParams, setSearchParams] = useSearchParams()
    const [formRef] = Form.useForm()
    const loadingListUsers = useAppSelector((state) => state.usersVersion2.loadingUser)
    const userInfo = SecurityService.getUser()
    const columns: ColumnsType<any> = [
        {
            title: trans("vendor.username"),
            className: "bold-400",
            dataIndex: "username",
        },
        {
            title: trans("vendor.email"),
            className: "bold-400",
            dataIndex: ["user", "email"],
        },
        {
            title: trans("vendor.added_by"),
            className: "bold-400",
            dataIndex: "addedBy",
        },
        {
            title: trans("vendor.added_at"),
            className: "bold-400",
            dataIndex: "addedAt",
            render: (text) => (text ? formatDateFull(text || "") : ""),
        },
        {
            title: "",
            className: "bold-400",
            dataIndex: "delete",
            width: 60,
            render: (text, record: IUser) =>
                userInfo?.sub !== record?.username && (
                    <Button
                        type="link"
                        icon={<i className="fa-regular fa-trash"></i>}
                        onClick={() => showConfirmDelete(record)}
                    />
                ),
        },
    ]
    const showConfirmDelete = (item: IUser) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            icon: <i className="fa-regular fa-circle-info"></i>,
            onOk() {
                dispatch(
                    callCreateUser({
                        username: item?.username || "",
                        isDelete: true,
                    })
                ).then(() => {
                    dispatch(
                        callGetUsers({
                            ...Object.fromEntries(searchParams.entries()),
                            size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                        })
                    )
                })
            },
        })
    }
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            page: !oldSearch.size || Number(oldSearch.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: pageSize,
        }
        setSearchParams(params)
    }
    const handleFilter = (values: { username: string }) => {
        setSearchParams((prev) => {
            let params: any = {
                ...prev,
                username: values.username,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const searchParam = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...searchParam,
            })
        } else {
            formRef.resetFields()
        }
    }, [searchParams])
    return (
        <Card className="space-layout">
            <Tabs
                tabBarExtraContent={
                    SecurityService.can(USER_SCOPE.STAFF_ADD) && (
                        <Row
                            justify="end"
                            className="mb-5">
                            <Button
                                type="primary"
                                onClick={() => {
                                    dispatch(changeAddUserVisible(true))
                                }}>
                                {trans("vendor.add_user")}
                            </Button>
                        </Row>
                    )
                }>
                <TabPane tab={trans("vendor.users")}>
                    <Form
                        form={formRef}
                        layout="horizontal"
                        onFinish={handleFilter}>
                        <Row
                            gutter={15}
                            className="mt-3">
                            <Col span={12}>
                                <Form.Item name="username">
                                    <Input placeholder={trans("vendor.username")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Space>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        loading={loadingListUsers}>
                                        {trans("vendor.filter_button")}
                                    </Button>
                                    <Button
                                        type="default"
                                        onClick={() => {
                                            setSearchParams({})
                                            formRef.resetFields()
                                        }}
                                        loading={loadingListUsers}
                                        danger>
                                        {trans("vendor.clear_button")}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                    <Row className="mb-3">
                        <Col span={24}>
                            {total !== 0 && <Typography.Text>{`${trans("contact.page")} ${currentPage}/${totalPage}(${total})`}</Typography.Text>}
                        </Col>
                    </Row>
                    <Table
                        rowKey={"id"}
                        columns={columns}
                        dataSource={listUsers}
                        loading={loadingListUsers}
                        className="bd-radius-5"
                        pagination={{
                            pageSize: size,
                            total: total,
                            current: currentPage,
                            pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                            showSizeChanger: true,
                            onChange: handleChangePage,
                        }}
                    />
                </TabPane>
            </Tabs>
        </Card>
    )
}
