import React from "react"
import { trans } from "@resources/localization"
import { Card, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useAppSelector } from "@hook/useAppSelector"
import { IProductSku } from "@domain/version2/Product"

export const PropertiesList: React.FC = () => {
    const detailProductOfVendor = useAppSelector((state) => state.productVersion2.detailProduct)
    const productSkus = detailProductOfVendor?.productSkus || []

    const columns: ColumnsType<any> = [
        {
            title: trans("products.variant"),
            className: "bold-400",
            dataIndex: "variant",
            width: "33%",
            render: (variant) => Object.values(variant).toString().replace(",", "/"),
        },
        {
            title: trans("ads.sku"),
            className: "bold-400",
            dataIndex: "sku",
            width: "33%",
        },
        {
            title: trans("product_list.status"),
            className: "bold-400",
            width: "33%",
            render: (record: IProductSku) => (record?.status ? trans("product_list.active") : trans("product_list.inactive")),
        },
    ]

    return (
        <Card
            className="space-layout"
            title={trans("products.properties")}>
            <Table
                dataSource={productSkus}
                rowKey={"id"}
                columns={columns}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
        </Card>
    )
}
