import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { Filter } from "./Filter"
import { List } from "./ListSkuCombo"
import { callSuggestUser } from "@reducer/version2/user.reducer"
import { PAGINATION } from "@config/constant"
import { callListSkuCombo } from "@reducer/version2/skuCombo.reducer"
import { callGetListSkuComboCategories } from "@reducer/version2/categories.reducer"

export const SKUCombo: React.FC = () => {
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        if (params.tab === "skuCombo") {
            dispatch(changePageTitleAction(trans("products.product")))
            dispatch(callSuggestUser({ keyword: "" }))
            dispatch(callGetListSkuComboCategories({ keyword: "" }))
            delete params.createdAt
            delete params.tab

            dispatch(
                callListSkuCombo({
                    ...params,
                    pageSize: searchParams.has("pageSize") ? Number(searchParams.get("pageSize")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                    sort: "createdAt:desc",
                })
            )
        }
    }, [dispatch, searchParams])

    return (
        <div>
            <Filter />
            <List />
        </div>
    )
}
