import { trans } from "@resources/localization"

export const PAGINATION: { [key: string]: any } = {
    DEFAULT_CURRENT_PAGE: 1,
    DEFAULT_PAGE_SIZE: 25,
    DEFAULT_TOTAL_ITEM: 0,
    DEFAULT_PAGE_SIZE_OPTIONS: ["25", "50", "100", "150", "200"],
    DEFAULT_TOTAL_PAGE: 1,
}

export const FORMAT_DATE_BEGIN = "YYYY-MM-DD 00:00:00"
export const FORMAT_DATE_END = "YYYY-MM-DD 23:59:59"

export const CALL_OPERATIONS: Array<{ [key: string]: string }> = [
    { key: "contact.equal", value: "equals" },
    { key: "contact.more", value: "gte" },
    { key: "contact.less", value: "lte" },
    { key: "contact.between", value: "bt" },
]

export const LIST_PAYMENT_TYPE: { title: string; value: string }[] = [
    { title: trans("order_pay.COD"), value: "COD" },
    { title: trans("order_pay.ADVANCE_PAYMENT"), value: "ADVANCE_PAYMENT" },
    { title: trans("order_pay.DEFERRED_PAYMENT"), value: "DEFERRED_PAYMENT" },
]

export interface IBreadCrumb {
    name: string
    link?: string
}

export const HOME_BREADCRUMB: Array<IBreadCrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
]

export const LIST_CURRENCY = [
    { title: "₱", value: "PHL", type: "left", code: "PHP" },
    { title: "Rp", value: "IDN", type: "left", code: "IDR" },
    { title: "฿", value: "THA", type: "left", code: "THB" },
    { title: "₫", value: "VNM", type: "right", code: "VND" },
    { title: "RM", value: "MYS", type: "left", code: "MYR" },
    { title: "K", value: "MMR", type: "left", code: "MMK" },
]
export const regexSpecial = new RegExp(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g)
export const STATUS_CAN_UPDATE = ["DRAFT", "NEW", "WAREHOUSE_RECEIVED"]

export const COUNTRY_CODE = {
    VIETNAM: "VNM",
    MALAYSIA: "MYS",
}
