import { COUNTRY_CODE } from "@config/constant"
import { ILocation } from "@domain/version2/Country"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { getAreaSuggest, getCitySuggest, getDistrictSuggest, resetDataArea, resetDataCity, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { trans } from "@resources/localization"
import { numberParser } from "@util/Common"
import { Form, Input, Select } from "antd"
import { filter, find, isEmpty } from "lodash"
import React from "react"
type Props = {
    formRef: any
    parentRestField: any
    parentName: number
    errors: any
}
export const AddressDraft: React.FC<Props> = ({ formRef, parentRestField, parentName, errors }) => {
    const dispatch = useAppDispatch()
    const detailVendor = useAppSelector((state) => state.vendorDetail.detailVendor)
    const listCouriers = useAppSelector((state) => state.courierSetting.courierVendor)
    const provinces = useAppSelector((state) => state.countriesVersion2.provincesSuggest)
    return (
        <>
            <div className="min-w-[150px] mr-2">
                <Form.Item
                    {...parentRestField}
                    name={[parentName, "fullName"]}
                    rules={[
                        { required: true, message: trans("message.required") },
                        {
                            validator() {
                                const itemError: any = find(errors, { field: "fullName" }) || {}
                                if (isEmpty(itemError)) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error(itemError?.error))
                            },
                        },
                    ]}>
                    <Input
                        placeholder={trans("products.enter_name")}
                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        onChange={(e: any) => {
                            const oldListOrders = formRef.getFieldValue("dataImports")
                            const customerName: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "")
                            oldListOrders[parentName] = {
                                ...oldListOrders[parentName],
                                fullName: customerName,
                                errors: filter(errors, (iFil: any) => iFil.field !== "fullName"),
                            }
                            formRef.setFieldsValue({
                                dataImports: oldListOrders,
                            })
                        }}
                        onBlur={(e) => {
                            const oldListOrders = formRef.getFieldValue("dataImports")
                            oldListOrders[parentName] = {
                                ...oldListOrders[parentName],
                                fullName: e.target.value.trim(),
                            }
                            formRef.setFieldsValue({
                                dataImports: oldListOrders,
                            })
                        }}
                    />
                </Form.Item>
            </div>
            <div className="min-w-[150px] mr-2">
                <Form.Item
                    {...parentRestField}
                    name={[parentName, "phoneNumber"]}
                    rules={[
                        { required: true, message: trans("message.required") },
                        {
                            validator() {
                                const itemError: any = find(errors, { field: "phoneNumber" }) || {}
                                if (isEmpty(itemError)) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error(itemError?.error))
                            },
                        },
                    ]}>
                    <Input
                        placeholder={trans("order.enter_phone")}
                        onChange={(e: any) => {
                            const customerPhone: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "").replace(/[a-zA-Z]/g, "")
                            const oldListOrders = formRef.getFieldValue("dataImports")
                            oldListOrders[parentName] = {
                                ...oldListOrders[parentName],
                                phoneNumber: customerPhone.trim(),
                                errors: filter(errors, (iFil: any) => iFil.field !== "phoneNumber"),
                            }
                            formRef.setFieldsValue({
                                dataImports: oldListOrders,
                            })
                        }}
                    />
                </Form.Item>
            </div>
            <div className="min-w-[150px] mr-2 text-center">{detailVendor?.country?.name}</div>
            <div className="min-w-[150px] mr-2">
                <Form.Item
                    {...parentRestField}
                    name={[parentName, "courier"]}
                    rules={[
                        { required: true, message: trans("message.required") },
                        {
                            validator() {
                                const itemError: any = find(errors, { field: "courier" }) || {}
                                if (isEmpty(itemError)) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error(itemError?.error))
                            },
                        },
                    ]}>
                    <Select
                        placeholder={trans("order_list.courier_placeholder")}
                        showSearch
                        optionFilterProp="children"
                        onSelect={() => {
                            const oldListOrders = formRef.getFieldValue("dataImports")
                            oldListOrders[parentName] = {
                                ...oldListOrders[parentName],
                                errors: filter(errors, (iFil: any) => iFil.field !== "courier"),
                            }
                            formRef.setFieldsValue({
                                dataImports: oldListOrders,
                            })
                        }}
                        filterOption={(input: any, option: any) => {
                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                        }}
                        filterSort={(optionA: any, optionB: any) => {
                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                        }}>
                        {listCouriers?.map((item) => (
                            <Select.Option
                                key={item?.id}
                                value={item?.courierCode}>
                                {item?.courierName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
            <div className="min-w-[150px] mr-2">
                <Form.Item
                    {...parentRestField}
                    name={[parentName, "postalCode"]}
                    rules={
                        COUNTRY_CODE.MALAYSIA === detailVendor?.countryCode
                            ? [
                                  {
                                      required: true,
                                      message: trans("message.required"),
                                  },
                              ]
                            : []
                    }>
                    <Input
                        onChange={(e) => {
                            const oldListOrders = formRef.getFieldValue("dataImports")
                            oldListOrders[parentName] = {
                                ...oldListOrders[parentName],
                                postalCode: e?.target?.value ? numberParser(e?.target?.value) : "",
                            }
                            formRef.setFieldsValue({
                                dataImports: oldListOrders,
                            })
                        }}
                        maxLength={detailVendor.countryCode === COUNTRY_CODE.MALAYSIA ? 5 : 20}
                        placeholder={trans("order.enter_postal_code")}
                    />
                </Form.Item>
            </div>
            <div className="min-w-[200px] mr-2">
                <Form.Item
                    {...parentRestField}
                    name={[parentName, "province"]}
                    rules={[
                        {
                            required: true,
                            message: trans("message.required"),
                        },
                        {
                            validator() {
                                const itemError: any = find(errors, { field: "province" }) || {}
                                if (isEmpty(itemError)) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error(itemError?.error))
                            },
                        },
                    ]}>
                    <Select
                        placeholder={trans("order_list.district_placeholder")}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => {
                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                        }}
                        filterSort={(optionA: any, optionB: any) => {
                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                        }}
                        onSelect={() =>{
                            const oldOrderItems = formRef.getFieldValue("dataImports")
                            oldOrderItems[parentName] = {
                                ...oldOrderItems[parentName],
                                errors: filter(errors, (iFil: any) => iFil.field !== "province"),
                                city: "",
                                district: "",
                                ward: "",
                            }
                            formRef.setFieldsValue({
                                dataImports: oldOrderItems,
                            })
                        }}
                        onChange={(e) => {
                            dispatch(resetDataArea([]))
                            dispatch(resetDataDistrict([]))
                            dispatch(resetDataCity([]))
                            if (e) {
                                dispatch(getCitySuggest(e)).then((result: any) => {
                                    const oldOrderItems = formRef.getFieldValue("dataImports")
                                    oldOrderItems[parentName] = {
                                        ...oldOrderItems[parentName],
                                        listCities: result?.payload?.data || [],
                                    }
                                    formRef.setFieldsValue({
                                        dataImports: oldOrderItems,
                                    })
                                })
                            }
                        }}>
                        {provinces?.map((item) => (
                            <Select.Option
                                key={item?.id}
                                value={item?.code}>
                                {item?.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
            <div className="min-w-[200px] mr-2">
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) =>
                        pre["dataImports"][parentName]?.province !== next["dataImports"][parentName].province ||
                        pre["dataImports"][parentName].listCities !== next["dataImports"][parentName].listCities
                    }>
                    {({ getFieldValue }) => {
                        const listCities = getFieldValue(["dataImports", parentName, "listCities"])
                        return (
                            <Form.Item
                                {...parentRestField}
                                name={[parentName, "city"]}
                                rules={[
                                    {
                                        required: true,
                                        message: trans("message.required"),
                                    },
                                    {
                                        validator() {
                                            const itemError: any = find(errors, { field: "city" }) || {}
                                            if (isEmpty(itemError)) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error(itemError?.error))
                                        },
                                    },
                                ]}>
                                <Select
                                    placeholder={
                                        detailVendor?.countryCode === COUNTRY_CODE.VIETNAM
                                            ? trans("contact-detail.district_pl")
                                            : trans("order_list.city_placeholder")
                                    }
                                    showSearch
                                    disabled={!getFieldValue(["dataImports", parentName, "province"])}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    onSelect={() =>{
                                        const oldOrderItems = formRef.getFieldValue("dataImports")
                                        oldOrderItems[parentName] = {
                                            ...oldOrderItems[parentName],
                                            errors: filter(errors, (iFil: any) => iFil.field !== "city"),
                                            district: "",
                                            ward: "",
                                        }
                                        formRef.setFieldsValue({
                                            dataImports: oldOrderItems,
                                        })
                                    }}
                                    onChange={(e) => {
                                        dispatch(resetDataArea([]))
                                        dispatch(resetDataDistrict([]))
                                        if (e) {
                                            dispatch(getDistrictSuggest(e)).then((result: any) => {
                                                const oldOrderItems = formRef.getFieldValue("dataImports")
                                                oldOrderItems[parentName] = {
                                                    ...oldOrderItems[parentName],
                                                    listDistricts: result?.payload?.data || [],
                                                }
                                                formRef.setFieldsValue({
                                                    dataImports: oldOrderItems,
                                                })
                                            })
                                        }
                                    }}>
                                    {listCities?.map((item: ILocation) => (
                                        <Select.Option
                                            key={item?.code}
                                            value={item?.code}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )
                    }}
                </Form.Item>
            </div>
            <div className="min-w-[200px] mr-2">
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) =>
                        pre["dataImports"][parentName]?.city !== next["dataImports"][parentName].city ||
                        pre["dataImports"][parentName].listDistricts !== next["dataImports"][parentName].listDistricts
                    }>
                    {({ getFieldValue }) => {
                        const listDistricts = getFieldValue(["dataImports", parentName, "listDistricts"])
                        return (
                            <Form.Item
                                {...parentRestField}
                                name={[parentName, "district"]}
                                rules={
                                    detailVendor?.countryCode !== COUNTRY_CODE.MALAYSIA
                                        ? [
                                              {
                                                  required: true,
                                                  message: trans("message.required"),
                                              },
                                              {
                                                  validator() {
                                                      const itemError: any = find(errors, { field: "district" }) || {}
                                                      if (isEmpty(itemError)) {
                                                          return Promise.resolve()
                                                      }
                                                      return Promise.reject(new Error(itemError?.error))
                                                  },
                                              },
                                          ]
                                        : []
                                }>
                                <Select
                                    placeholder={
                                        detailVendor.countryCode === COUNTRY_CODE.VIETNAM
                                            ? trans("order_list.ward_placeholder")
                                            : trans("contact-detail.district_pl")
                                    }
                                    showSearch
                                    disabled={!getFieldValue(["dataImports", parentName, "city"])}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    onSelect={() =>{
                                        const oldOrderItems = formRef.getFieldValue("dataImports")
                                        oldOrderItems[parentName] = {
                                            ...oldOrderItems[parentName],
                                            errors: filter(errors, (iFil: any) => iFil.field !== "district"),
                                            ward: "",
                                        }
                                        formRef.setFieldsValue({
                                            dataImports: oldOrderItems,
                                        })
                                    }}
                                    onChange={(e) => {
                                        dispatch(resetDataArea([]))
                                        if (e) {
                                            dispatch(getAreaSuggest(e)).then((result: any) => {
                                                const oldOrderItems = formRef.getFieldValue("dataImports")
                                                oldOrderItems[parentName] = {
                                                    ...oldOrderItems[parentName],
                                                    listAreas: result?.payload?.data || [],
                                                }
                                                formRef.setFieldsValue({
                                                    dataImports: oldOrderItems,
                                                })
                                            })
                                        }
                                    }}>
                                    {listDistricts?.map((item: ILocation) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )
                    }}
                </Form.Item>
            </div>
            <div className="min-w-[200px] mr-2">
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) =>
                        pre["dataImports"][parentName]?.district !== next["dataImports"][parentName].district ||
                        pre["dataImports"][parentName].listAreas !== next["dataImports"][parentName].listAreas
                    }>
                    {({ getFieldValue }) => {
                        const listAreas = getFieldValue(["dataImports", parentName, "listAreas"])
                        if (!isEmpty(listAreas)) {
                            return (
                                <Form.Item
                                    {...parentRestField}
                                    name={[parentName, "area"]}>
                                    <Select
                                        placeholder={trans("placeholder.select_area")}
                                        showSearch
                                        disabled={!getFieldValue(["dataImports", parentName, "district"])}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                        }}>
                                        {listAreas?.map((item: ILocation) => (
                                            <Select.Option
                                                key={item?.id}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )
                        } else {
                            return (
                                <Form.Item
                                    {...parentRestField}
                                    name={[parentName, "area"]}>
                                    <Input
                                        placeholder={trans("contact-detail.area")}
                                        onBlur={(e) => {
                                            const oldListOrders = formRef.getFieldValue("dataImports")
                                            oldListOrders[parentName] = {
                                                ...oldListOrders[parentName],
                                                area: e.target.value.trim(),
                                            }

                                            formRef.setFieldsValue({
                                                dataImports: oldListOrders,
                                            })
                                        }}
                                        disabled={!getFieldValue(["dataImports", parentName, "district"])}
                                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                    />
                                </Form.Item>
                            )
                        }
                    }}
                </Form.Item>
            </div>
            <div className="min-w-[200px] mr-2">
                <Form.Item
                    {...parentRestField}
                    name={[parentName, "completeAddress"]}
                    rules={[{ required: true, message: trans("message.required") }]}>
                    <Input
                        placeholder={trans("order.enter_address")}
                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                    />
                </Form.Item>
            </div>
            <div className="min-w-[200px] mr-2">
                <Form.Item
                    {...parentRestField}
                    name={[parentName, "note"]}>
                    <Input.TextArea
                        rows={1}
                        placeholder={trans("contact-detail.status_pl")}
                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                    />
                </Form.Item>
            </div>
        </>
    )
}
