import { SettingTable } from "@component/General/SettingTable"
import { LIST_CURRENCY, PAGINATION } from "@config/constant"
import { ORDER_SCOPE } from "@config/permission"
import { IOrder } from "@domain/version2/Order"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callListOrders, exportOrders } from "@reducer/version2/order.reducer"
import { trans } from "@resources/localization"
import { convertNumberToCurrency, formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Button, Card, Menu, message, Popover, Space, Table } from "antd"
import { filter, find, isEmpty } from "lodash"
import React, { useMemo, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { changeVisibleOrderImport } from "@reducer/version2/order.reducer"
import { ImportOrder } from "./ImportOrder"

export const TableOrder: React.FC = () => {
    const dispatch = useAppDispatch()
    const size: number = useAppSelector((state) => state.orderVersion2.pagination.size)
    const currentPage: number = useAppSelector((state) => state.orderVersion2.pagination.currentPage)
    const total: number = useAppSelector((state) => state.orderVersion2.pagination.total)
    const totalPage: number = useAppSelector((state) => state.orderVersion2.pagination.totalPage)
    const loadingList: boolean = useAppSelector((state) => state.orderVersion2.loadingList)
    const loadingExport: boolean = useAppSelector((state) => state.orderVersion2.loadingExport)
    const listOrders = useAppSelector((state) => state.orderVersion2.listOrders)
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedTable, setSelectedTable] = useState<string[]>([])
    const oldSearch = Object.fromEntries(searchParams.entries())
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [isOpenImport, setIsOpenImport] = useState(false)

    const columns: any[] = [
        {
            title: trans("contact-detail.order_code"),
            align: "left",
            dataIndex: "code",
            key: "code",
            default: true,
            width: 100,
            render: (code: string, record: IOrder) =>
                !SecurityService.can(ORDER_SCOPE.ORDER_DETAIL_ALL) && !SecurityService.can(ORDER_SCOPE.ORDER_DETAIL) ? (
                    code
                ) : (
                    <Link
                        className="flex justify-start"
                        to={`/order/${record?.code}`}>
                        {code}
                    </Link>
                ),
        },
        {
            title: trans("order_list.campaign"),
            align: "left",
            key: "campaign",
            default: true,
            dataIndex: ["campaign", "name"],
            width: 150,
        },
        {
            title: trans("contact-detail.product"),
            className: "bold-400",
            width: 150,
            dataIndex: ["productDetail", "name"],
            key: "productDetail",
            algin: "left",
            default: false,
        },
        {
            title: trans("vendor.marketer"),
            align: "left",
            key: "marketer",
            default: false,
            width: 150,
            render: (record: IOrder) => <p className="mb-0">{record?.campaign?.createdBy || ""}</p>,
        },

        {
            title: trans("order.customer_name"),
            width: 150,
            align: "left",
            key: "customerName",
            default: true,
            dataIndex: "customerName",
        },
        {
            title: trans("order.customer_phone"),
            width: 120,
            key: "customerPhone",
            default: true,
            align: "center",
            dataIndex: "customerPhone",
        },

        {
            title: trans("order.payment"),
            align: "right",
            key: "payment",
            default: true,
            dataIndex: "payment",
            render: (payment: string, record: IOrder) => (
                <>
                    {/* <p className="m-0">{find(LIST_PAYMENT_TYPE, { value: record?.payment_type })?.title}</p> */}
                    <p className="mb-0 text-red-400">
                        {find(LIST_CURRENCY, { value: record?.countryCode })?.type === "left" && (
                            <span>{find(LIST_CURRENCY, { value: record?.countryCode })?.title}</span>
                        )}
                        {convertNumberToCurrency(record?.totalPayment || 0)}
                        {find(LIST_CURRENCY, { value: record?.countryCode })?.type === "right" && (
                            <span>{find(LIST_CURRENCY, { value: record?.countryCode })?.title}</span>
                        )}
                    </p>
                </>
            ),
            width: 120,
        },
        {
            title: trans("order_detail.tracking_number"),
            dataIndex: "trackingNumber",
            key: "trackingNumber",
            align: "left",
            default: false,
            width: 150,
        },
        {
            title: trans("order.created_at"),
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            default: true,
            render: (createdAt: string) => (createdAt ? formatDateFull(createdAt) : ""),
            width: 120,
        },
        {
            title: trans("order_list.createdBy"),
            dataIndex: "createdBy",
            align: "left",
            key: "createdBy",
            default: false,
            width: 100,
        },
        {
            title: trans("order_list.status"),
            dataIndex: "status",
            key: "status",
            default: true,
            align: "center",
            width: 100,
            render: (status: string) => trans(`order_status.${status}`),
        },
        {
            title: trans("order_detail.financial_status"),
            dataIndex: "financial",
            key: "financial",
            default: false,
            align: "center",
            width: 100,
            render: (financial: string) => (financial ? trans(`order_detail.financial_status_${financial}`) : "---"),
        },
        {
            title: trans("order_list.payment_status"),
            dataIndex: "isPaid",
            key: "isPaid",
            align: "center",
            default: false,
            width: 100,
            render: (payment: boolean) => (payment ? `${trans("order_detail.paid")}` : `${trans("order_detail.unpaid")}`),
        },
        {
            title: trans("order.order_source"),
            align: "source",
            key: "source",
            default: false,
            width: 150,
            render: (record: IOrder) => <p className="mb-0">{record?.source ? trans(`order.type_${record?.source}`) : ""}</p>,
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            page: !searchParams.has("per_page") || Number(oldSearch.per_page) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            per_page: pageSize,
        }
        setSearchParams(params)
    }
    const dataTrees = useMemo(
        () =>
            !isEmpty(columns)
                ? columns?.map((item: any) => ({
                      title: item?.title,
                      key: item?.key,
                      disabled: item?.default,
                  }))
                : [],
        []
    )
    const itemsMenu: any = [
        {
            label: trans("order.create_order"),
            key: "manual",
            hidden: !SecurityService.can(ORDER_SCOPE.ORDER_CREATE_MANUAL),
            icon: <i className="fa-solid fa-plus mr-2" />,
            onClick: () => {
                navigate("/order/create")
            },
        },
        {
            label: trans("order.import_order"),
            key: "import",
            hidden: !SecurityService.can(ORDER_SCOPE.ORDER_IMPORT_CREATE),

            icon: <i className="fa-solid fa-plus mr-2" />,
            onClick: () => {
                setIsOpenImport(true)
                setOpen(false)
            },
        },
    ]
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}
            extra={
                <Space>
                    {SecurityService.can(ORDER_SCOPE.ORDER_FINANCIAL_STATUS_UPDATE) && (
                        <Button
                            type="primary"
                            className="m-0 ml-2"
                            onClick={() => {
                                dispatch(changeVisibleOrderImport(true))
                            }}
                            icon={<i className="fa-solid fa-cloud-arrow-up mr-2" />}>
                            {trans("order.import_financial")}
                        </Button>
                    )}
                    <Button
                        type="primary"
                        className="m-0 ml-2"
                        loading={loadingExport}
                        onClick={() =>
                            dispatch(exportOrders({ ...oldSearch, sort: "createdAt:desc" })).then((result: any) => {
                                if (result?.payload?.status === 201) {
                                    message.success(trans("message.success"))
                                } else message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                            })
                        }
                        icon={<i className="fa-solid fa-cloud-arrow-down mr-2" />}>
                        {trans("contact.export_order")}
                    </Button>
                    <Popover
                        content={
                            <Menu
                                selectedKeys={[]}
                                mode="vertical"
                                items={filter(itemsMenu, { hidden: false })}
                            />
                        }
                        placement="bottom"
                        trigger="click"
                        open={open}
                        onOpenChange={() => setOpen((old) => !old)}>
                        {SecurityService.can(ORDER_SCOPE.ORDER_CREATE_MANUAL) && (
                            <Button
                                type="primary"
                                icon={<i className="fa-regular fa-circle-plus mr-2"></i>}
                                className="m-0 ml-2"
                                loading={false}>
                                {trans("button.create")}
                            </Button>
                        )}
                    </Popover>
                    {!isEmpty(dataTrees) && (
                        <SettingTable
                            dataTree={dataTrees}
                            keyTable="orderVendor"
                            setSelectedTable={setSelectedTable}
                            selectedTable={selectedTable}
                        />
                    )}
                </Space>
            }>
            <Table
                rowKey={"id"}
                scroll={{
                    x: true,
                }}
                columns={filter(columns, (iFil: any) => selectedTable?.includes(iFil?.key))}
                loading={loadingList}
                dataSource={listOrders}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: size,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                }}
            />
            {isOpenImport && SecurityService.can(ORDER_SCOPE.ORDER_IMPORT_CREATE) && (
                <ImportOrder
                    isOpenModal={isOpenImport}
                    setClose={() => {
                        const params = Object.fromEntries(searchParams.entries())
                        dispatch(
                            callListOrders({
                                ...params,
                                pageSize: searchParams.has("per_page") ? Number(searchParams.get("per_page")) : PAGINATION.DEFAULT_PAGE_SIZE,
                                page: searchParams.has("page") ? Number(searchParams.get("page")) : PAGINATION.DEFAULT_CURRENT_PAGE,
                                sort: "createdAt:desc",
                            })
                        )
                        setIsOpenImport(false)
                    }}
                />
            )}
        </Card>
    )
}
