import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changeVisibleFormReasonCancel, fetchUpdateOrderStatus, getOrderDetail } from "@reducer/version2/order-detail.reducer"
import { trans } from "@resources/localization"
import { Button, Col, Form, Input, message, Modal, Row, Select, Space } from "antd"
import React from "react"
import { useParams } from "react-router-dom"

const AddReasonCancel = () => {
    const orderCode = useParams().id as string
    const dispatch = useAppDispatch()
    const visibleFormReasonCancel = useAppSelector((state) => state.orderDetailVersion2.visibleFormReasonCancel)
    const subOrderStatuses = useAppSelector((state) => state.categoriesVersion2.subOrderStatuses)
    const [formRef] = Form.useForm()

    const onHandleSubmit = (values: any) => {
        dispatch(
            fetchUpdateOrderStatus({
                code: orderCode,
                body: {
                    status: "CANCELLED",
                    ...values,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200 || result?.payload?.status === 201) {
                message.success(trans("message.success"))
                formRef.resetFields()
                dispatch(getOrderDetail(orderCode || ""))
                dispatch(changeVisibleFormReasonCancel(false))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <Modal
            destroyOnClose
            title={trans("order_detail.add_reason")}
            open={visibleFormReasonCancel}
            footer={null}
            width={500}
            onCancel={() => dispatch(changeVisibleFormReasonCancel(false))}>
            <Form
                layout="vertical"
                form={formRef}
                onFinish={onHandleSubmit}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={trans("order.cancel_reason")}
                            name="subStatus"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select
                                placeholder={trans("contact-detail.cancel_reason_pl")}
                                showSearch>
                                {subOrderStatuses.map((item) => (
                                    <Select.Option
                                        key={item.id}
                                        value={item.code}>
                                        {trans(`order_status_cancelled.${item.code.split(":")[1]}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.subStatus !== next.subStatus}>
                            {({ getFieldValue }) =>
                                getFieldValue("subStatus") === "CANCELLED:OTHER" && (
                                    <Form.Item
                                        label={trans("order_detail.reason")}
                                        name="cancelReason"
                                        rules={[
                                            {
                                                required: true,
                                                message: trans("message.required"),
                                            },
                                        ]}>
                                        <Input.TextArea
                                            rows={4}
                                            placeholder={trans("contact-detail.cancel_reason_pl")}
                                        />
                                    </Form.Item>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    justify="end"
                    className="mt-3">
                    <Space>
                        <Button
                            type="default"
                            onClick={() => {
                                formRef.resetFields()
                                dispatch(changeVisibleFormReasonCancel(false))
                            }}>
                            {trans("setting.cancel")}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit">
                            {trans("setting.save")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}

export default AddReasonCancel
