import SellChannelApi from "@api/version2/sellChannelApi"
import { IConnectChannelRequest, ISellChannel } from "@domain/version2/SellChannel"
import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"

interface State {
    visibleAddAccount: boolean
    listSellChannel: ISellChannel[]
    loading: boolean
    listChannels: any[]
    urlConnectChannel: string
    loadingDelete: boolean
    loadingUpdate: boolean
}

const initState: State = {
    visibleAddAccount: false,
    listSellChannel: [],
    loading: false,
    listChannels: [],
    urlConnectChannel: "",
    loadingDelete: false,
    loadingUpdate: false,
}

export const callGetSellChannel = createAsyncThunk("SELL_CHANNEL.GET_LIST", async (_, thunkApi) => {
    try {
        return await SellChannelApi.getListSellChannel()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const changeVisibleFormAddAccount = createAction<boolean>("SELL_CHANNEL.ADD_ACCOUNT")

export const callGetChannels = createAsyncThunk("SELL_CHANNEL.GET_LIST_CHANNELS", async (_, thunkApi) => {
    try {
        return await SellChannelApi.getListChannels()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetUrlConnectChannel = createAsyncThunk("SELL_CHANNEL.GET_URL_CONNECT_CHANNEL", async (payload: IConnectChannelRequest, thunkApi) => {
    try {
        return await SellChannelApi.getUrlConnectChannels(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callDeleteSellChannel = createAsyncThunk("SELL_CHANNEL.DELETE", async (payload: { sellingChannelId: number }, thunkApi) => {
    try {
        return await SellChannelApi.deleteSellChannel(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callUpdateWarehouseOfSellChannel = createAsyncThunk(
    "SELL_CHANNEL.UPDATE",
    async (payload: { sellingChannelId: number; warehouseId: number }, thunkApi) => {
        try {
            return await SellChannelApi.updateWarehouseOfSellChannel(payload)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const sellChannelReducer = createReducer(initState, (builder) => {
    builder.addCase(changeVisibleFormAddAccount, (state, action: PayloadAction<boolean>) => {
        state.visibleAddAccount = action.payload
    })
    builder
        .addCase(callGetSellChannel.pending, (state) => {
            state.loading = true
            state.listSellChannel = []
        })
        .addCase(callGetSellChannel.fulfilled, (state, { payload }) => {
            state.listSellChannel = payload.data ? payload.data : []
            state.loading = false
        })
        .addCase(callGetSellChannel.rejected, (state) => {
            state.listSellChannel = []
            state.loading = false
        })
    builder
        .addCase(callGetChannels.pending, (state) => {
            state.listChannels = []
        })
        .addCase(callGetChannels.fulfilled, (state, { payload }) => {
            state.listChannels = payload?.data?.data ? payload.data.data : []
        })
        .addCase(callGetChannels.rejected, (state) => {
            state.listChannels = []
        })
    builder
        .addCase(callGetUrlConnectChannel.pending, (state) => {
            state.urlConnectChannel = ""
        })
        .addCase(callGetUrlConnectChannel.fulfilled, (state, { payload }) => {
            state.urlConnectChannel = payload?.data?.data?.url ? payload.data.data.url : ""
        })
        .addCase(callGetUrlConnectChannel.rejected, (state) => {
            state.urlConnectChannel = ""
        })
    builder
        .addCase(callDeleteSellChannel.pending, (state) => {
            state.loadingDelete = true
        })
        .addCase(callDeleteSellChannel.fulfilled, (state) => {
            state.loadingDelete = false
        })
        .addCase(callDeleteSellChannel.rejected, (state) => {
            state.loadingDelete = false
        })
    builder
        .addCase(callUpdateWarehouseOfSellChannel.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateWarehouseOfSellChannel.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateWarehouseOfSellChannel.rejected, (state) => {
            state.loadingUpdate = false
        })
})
