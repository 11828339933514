import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { HOME_BREADCRUMB } from "@config/constant"
import React, { useEffect } from "react"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callVendorDetail } from "@reducer/version2/vendor-detail.reducer"
import { useAppDispatch } from "@hook/useAppDispatch"
import { Courier } from "./Courier"
import { trans } from "@resources/localization"
import { Card, Tabs } from "antd"

export const Setting: React.FC = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.setting")))
        dispatch(callVendorDetail())
    }, [])
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    ...HOME_BREADCRUMB,
                    {
                        name: "sidebar.setting",
                        link: "/setting",
                    },
                ]}
            />
            <Card className="space-layout">
                <Tabs
                    defaultActiveKey="1"
                    tabPosition="left"
                    items={[
                        {
                            label: trans("courier_setting.title"),
                            key: "courier",
                            children: <Courier />,
                        },
                    ]}
                />
            </Card>
        </DefaultLayout>
    )
}
