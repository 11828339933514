import React, { useEffect } from "react"
import DefaultLayout from "@component/Layout/Default"
import { trans } from "@resources/localization"
import { changePageTitleAction } from "@reducer/common.reducer"
import { DashboardContacts } from "./Contacts"
import { useAppSelector } from "@hook/useAppSelector"
import { useAppDispatch } from "@hook/useAppDispatch"
import Orders from "./Orders"
import {
    callGetContactTodayStatistics,
    callGetContactYesterdayStatistics,
    callGetOrderStatisticsToday,
    callGetOrderStatisticsYesterday,
} from "@reducer/version2/dashboard.reducer"
import moment from "moment-timezone"
import SecurityService from "@util/SecurityService"
import { DASHBOARD_SCOPE } from "@config/permission"

export const Dashboard = (props: any) => {
    const dispatch = useAppDispatch()
    const vendorName = useAppSelector((state) => state.vendorDetail.vendorConfigs.vendorName)
    const userInfo = useAppSelector((state) => state.common.userInfo)
    const dataOrderToday = useAppSelector((state) => state.dashboard.ordersStatisticsToday)
    const dataOrderYesterday = useAppSelector((state) => state.dashboard.ordersStatisticsYesterday)
    const dataContactToday = useAppSelector((state) => state.dashboard.tContactsStatistics)
    const dataContactYesterday = useAppSelector((state) => state.dashboard.yContactsStatistics)

    useEffect(() => {
        vendorName
            ? dispatch(changePageTitleAction(`${vendorName} - ${trans("sidebar.dashboard")}`))
            : dispatch(changePageTitleAction(trans("sidebar.dashboard")))
    }, [dispatch, vendorName])

    useEffect(() => {
        if (userInfo.zoneinfo) {
            const timezone: string = userInfo.zoneinfo
            const nowWithTimeZone = moment().tz(timezone)
            const yesterdayWithTimeZone = moment().tz(timezone).subtract(1, "day")
            dispatch(
                callGetContactTodayStatistics({
                    beginDate: nowWithTimeZone.startOf("day").toISOString(),
                    endDate: nowWithTimeZone.endOf("day").toISOString(),
                    timezone,
                })
            )
            dispatch(
                callGetContactYesterdayStatistics({
                    beginDate: yesterdayWithTimeZone.startOf("day").toISOString(),
                    endDate: yesterdayWithTimeZone.endOf("day").toISOString(),
                    timezone,
                })
            )
            dispatch(
                callGetOrderStatisticsYesterday({
                    beginDate: yesterdayWithTimeZone.startOf("day").toISOString(),
                    endDate: yesterdayWithTimeZone.endOf("day").toISOString(),
                    timezone,
                })
            )
            dispatch(
                callGetOrderStatisticsToday({
                    beginDate: nowWithTimeZone.startOf("day").toISOString(),
                    endDate: nowWithTimeZone.endOf("day").toISOString(),
                    timezone,
                })
            )
        }
    }, [dispatch, userInfo])

    return (
        <DefaultLayout
            {...props}
            loading={false}
            title={trans("sidebar.dashboard")}>
            <div className="white-box">
                <div>{trans("dashboard.dashboard")}</div>

                <h1 className="text-3xl font-bold underline">{trans("dashboard.vendor_management")}</h1>
            </div>
            {SecurityService.can(DASHBOARD_SCOPE.DASHBOARD_ORDER_VIEW_ALL) && (
                <Orders
                    titleHeader={trans("sidebar.all_order")}
                    today={dataOrderToday?.admin}
                    yesterday={dataOrderYesterday?.admin}
                />
            )}
            <Orders
                titleHeader={trans("dashboard.my_order")}
                today={dataOrderToday?.owner}
                yesterday={dataOrderYesterday?.owner}
            />
            {SecurityService.can(DASHBOARD_SCOPE.DASHBOARD_CONTACT_VIEW_ALL) && (
                <DashboardContacts
                    titleHeader={trans("sidebar.all_contact")}
                    today={dataContactToday?.admin}
                    yesterday={dataContactYesterday?.admin}
                />
            )}
            <DashboardContacts
                titleHeader={trans("dashboard.my_contact")}
                today={dataContactToday?.owner}
                yesterday={dataContactYesterday?.owner}
            />
        </DefaultLayout>
    )
}
