import { InboundShipmentApi } from "@api/version2/InboundShipmentApi"
import { IDetailOrderPurchasing, IOrderPurchasingAccount, IPurchasingAccountM28 } from "@domain/version2/InboundShipment"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    purchasingAccount: IPurchasingAccountM28[]
    listOrderPurchasing: IOrderPurchasingAccount[]
    detailOrderPurchasing: IDetailOrderPurchasing
    loadingOrderPurchasing: boolean
}
const initState: State = {
    purchasingAccount: [],
    listOrderPurchasing: [],
    detailOrderPurchasing: {} as IDetailOrderPurchasing,
    loadingOrderPurchasing: false
}
export const callGetPurchasingAccountM28 = createAsyncThunk("INBOUND_SHIPMENT.PURCHASING_ACCOUNT", async (_, thunkApi) => {
    try {
        return await InboundShipmentApi.getPurchasingAccount()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetOrderPurchasingAccount = createAsyncThunk(
    "INBOUND_SHIPMENT.ORDER_PURCHASING",
    async (params: { purchasing_account_id: string }, thunkApi) => {
        try {
            return await InboundShipmentApi.getOrderWithPurchasingAccount(params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const callDetailOrderPurchasing = createAsyncThunk("INBOUND_SHIPMENT.DETAIL_ORDER", async (orderId: string, thunkApi) => {
    try {
        return await InboundShipmentApi.getDetailOrderPurchasing(orderId)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const inboundShipmentReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetPurchasingAccountM28.pending, (state) => {
            state.purchasingAccount = []
        })
        .addCase(callGetPurchasingAccountM28.fulfilled, (state, { payload }) => {
            state.purchasingAccount = payload?.data?.data || []
        })
        .addCase(callGetPurchasingAccountM28.rejected, (state) => {
            state.purchasingAccount = []
        })
    builder
        .addCase(callGetOrderPurchasingAccount.pending, (state) => {
            state.listOrderPurchasing = []
        })
        .addCase(callGetOrderPurchasingAccount.fulfilled, (state, { payload }) => {
            state.listOrderPurchasing = payload?.data || []
        })
        .addCase(callGetOrderPurchasingAccount.rejected, (state) => {
            state.listOrderPurchasing = []
        })
    builder
        .addCase(callDetailOrderPurchasing.pending, (state) => {
            state.detailOrderPurchasing = {} as IDetailOrderPurchasing
            state.loadingOrderPurchasing = true
        })
        .addCase(callDetailOrderPurchasing.fulfilled, (state, { payload }) => {
            state.detailOrderPurchasing = payload?.data || {}
            state.loadingOrderPurchasing = false
        })
        .addCase(callDetailOrderPurchasing.rejected, (state) => {
            state.detailOrderPurchasing = {} as IDetailOrderPurchasing
            state.loadingOrderPurchasing = false
        })
})
