import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"
import { IPurchasingAccount, IPurchasingAccountCreateRequest, IServiceSuggest } from "@domain/version2/PurchasingAccount"

export default class PurchasingAccountApi {
    static getPurchasingAccounts(params: {size: number, page: number}): Promise<AxiosResponse<IPurchasingAccount[]>> {
        return apiClient.get(`purchasing-accounts`, { params })
    }
    static deletePurchasingAccount(id: number): Promise<AxiosResponse<any>> {
        return apiClient.delete(`purchasing-accounts/${id}`)
    }
    static createPurchasingAccount(body: IPurchasingAccountCreateRequest): Promise<any> {
        return apiClient.post(`purchasing-accounts`, body)
    }
    static getServicesSuggest(): Promise<AxiosResponse<IServiceSuggest[]>> {
        return apiClient.get(`purchasing-services/autocomplete`)
    }
}