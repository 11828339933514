import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callGetDetailSkuCombo, callGetSkuComboSuggest, resetDataDetailSkuCombo } from "@reducer/version2/skuCombo.reducer"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { AutoComplete, Button, Col, Divider, Empty, Form, InputNumber, Popover, Row, Spin, Typography } from "antd"
import { debounce, isEmpty, sum } from "lodash"
import React, { useEffect, useMemo } from "react"

interface IProps {
    formRef: any
}
export const Combo = (props: IProps) => {
    const listHeader = [
        { title: trans("warehouse.sku_combo"), span: 6 },
        { title: trans("contact-detail.quantity"), span: 6 },
        { title: `${trans("products.price")}`, span: 6 },
        { title: `${trans("contact-detail.price")}`, span: 5 },
        { title: "", span: 1 },
    ]

    const dispatch = useAppDispatch()
    const detailSkuCombo = useAppSelector((state) => state.skuCombo.skuComboDetail)
    const skuComboSuggest = useAppSelector((state) => state.skuCombo.skuComboSuggest)
    const loading = useAppSelector((state) => state.productVersion2.loadingListProducts)

    const options = useMemo(
        () =>
            skuComboSuggest?.map((item) => ({
                value: item?.name,
                key: item?.code,
            })),
        [skuComboSuggest]
    )

    const handleSearchSkuCombo = (searchText: string) => {
        if (searchText.trim().length >= 3) {
            dispatch(
                callGetSkuComboSuggest({
                    keyword: searchText.trim(),
                })
            )
        } else {
            dispatch(callGetSkuComboSuggest({ keyword: "" }))
            dispatch(resetDataDetailSkuCombo({}))
        }
    }

    const handleSelectCombo = (e: any, option: any) => {
        dispatch(resetDataDetailSkuCombo({}))
        props.formRef.setFieldsValue({
            searchCombo: "",
        })
        dispatch(callGetDetailSkuCombo(option?.key))
    }

    useEffect(() => {
        const tableCombo = props.formRef.getFieldValue("tableCombo")?.slice() || []
        if (tableCombo.length === 0 && !isEmpty(detailSkuCombo)) {
            const combo = {
                quantity: 1,
                unitPrice: 0,
                sku: detailSkuCombo.code,
                detailSkuCombo: detailSkuCombo,
            }
            tableCombo.push(combo)
            props.formRef.setFieldValue("tableCombo", tableCombo)
            props.formRef.setFieldValue("searchCombo", "")
        }
    }, [props.formRef, detailSkuCombo])

    return (
        <div className="w-full">
            <div className="w-full">
                <Form.Item
                    label={trans("warehouse.sku_combo_search")}
                    name="searchCombo"
                    className="w-full">
                    <AutoComplete
                        notFoundContent={loading ? <Spin /> : <Empty />}
                        disabled={!detailSkuCombo && true}
                        onSearch={debounce(handleSearchSkuCombo, 1000)}
                        placeholder={trans("warehouse.sku_combo_search")}
                        options={options}
                        onSelect={handleSelectCombo}
                        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        onBlur={() => props.formRef.getFieldValue("searchCombo") === "" && dispatch(resetDataDetailSkuCombo({}))}
                    />
                </Form.Item>
            </div>

            <div className="px-2">
                <Row
                    gutter={15}
                    className="bg-gray-50 mt-3 py-3 border-b-black">
                    {listHeader.map((item: { title: string; span: number }, index: number) => (
                        <Col
                            span={item.span}
                            key={index}>
                            <Typography.Text
                                strong
                                className="block text-left">
                                {item.title}
                            </Typography.Text>
                        </Col>
                    ))}
                </Row>

                <div className="">
                    <Form.List name="tableCombo">
                        {(fields, { remove }) =>
                            fields.length > 0 ? (
                                <div>
                                    {fields.map(({ name, ...restField }) => (
                                        <Row
                                            className={`border pt-6 pr-3 ${name !== fields.length - 1 ? "border-b-0" : ""}`}
                                            key={name}
                                            gutter={15}>
                                            <Col span={6}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) => pre.tableCombo !== next.tableCombo}>
                                                    {({ getFieldValue }) => (
                                                        <Popover
                                                            content={
                                                                <>
                                                                    {getFieldValue(["tableCombo", name, "detailSkuCombo"])?.skus?.map((item: any) => (
                                                                        <ul key={item.id}>
                                                                            <li className="ml-3 list-disc">{item?.sku?.product?.name || ""}</li>
                                                                            <li className="ml-3 list-none">
                                                                                {item?.sku?.variant ? Object?.values(item?.sku?.variant)?.join("/") : ""}(
                                                                                {item?.sku?.sku || ""})
                                                                            </li>
                                                                            <li className="ml-3 list-none">
                                                                                {item?.quantity || 0} {trans("campaign.items")}
                                                                            </li>
                                                                        </ul>
                                                                    ))}
                                                                </>
                                                            }>
                                                            <p className="m-0 cursor-pointer hover:text-blue-400">
                                                                {getFieldValue(["tableCombo", name, "detailSkuCombo"])?.name || ""} -{" "}
                                                                {getFieldValue(["tableCombo", name, "detailSkuCombo"])?.code}
                                                            </p>
                                                        </Popover>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item noStyle>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "quantity"]}
                                                        rules={[{ required: true, message: trans("message.required") }]}>
                                                        <InputNumber
                                                            step="1"
                                                            min={1}
                                                            placeholder={trans("contact-detail.quantity")}
                                                            parser={(value: any) => value?.toString()?.replace(/[-&/\\#,+()$~%.'":*?<>{}]/g, "")}
                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item noStyle>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "unitPrice"]}
                                                        rules={[
                                                            { required: true, message: trans("message.required") },
                                                            {
                                                                validator(_, value) {
                                                                    if (Number(value) > 0) {
                                                                        return Promise.resolve()
                                                                    }
                                                                    return Promise.reject(new Error(trans("order.price_gt_0")))
                                                                },
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            step="0.01"
                                                            placeholder={trans("contact-detail.price")}
                                                            parser={(value: any) =>
                                                                value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0
                                                            }
                                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(pre, next) =>
                                                        pre?.tableCombo[name]?.quantity !== next?.tableCombo[name]?.quantity ||
                                                        pre?.tableCombo[name]?.unitPrice !== next?.tableCombo[name]?.unitPrice
                                                    }>
                                                    {({ getFieldValue }) => {
                                                        let price =
                                                            Number(getFieldValue(["tableCombo", name, "quantity"])) *
                                                            Number(getFieldValue(["tableCombo", name, "unitPrice"]))
                                                        price = +price.toFixed(2)
                                                        return <Typography.Text className="block ml-4 mt-1">{convertNumberToCurrency(price)}</Typography.Text>
                                                    }}
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Button
                                                    type="link"
                                                    icon={<i className="fa-light fa-trash"></i>}
                                                    onClick={() => {
                                                        remove(name)
                                                        dispatch(resetDataDetailSkuCombo({}))
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            ) : (
                                <div className="mt-3">
                                    <Empty />
                                    <Divider />
                                </div>
                            )
                        }
                    </Form.List>
                </div>

                <Row
                    className="mt-3"
                    justify="end">
                    <Col
                        span={14}
                        className="flex mb-3">
                        <Typography.Text className="w-[30%] mb-5 mt-3">{trans("order_detail.total_amount")}:</Typography.Text>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.tableCombo !== next.tableCombo}>
                            {({ getFieldValue }) => {
                                const listAmount: any = getFieldValue("tableCombo")?.map((item: any) => Number(item?.quantity) * Number(item?.unitPrice))
                                let totalAmount = !isEmpty(listAmount) ? sum(listAmount) : 0
                                totalAmount = +totalAmount.toFixed(2)
                                return <Typography.Text className="mt-3 text-start w-[70%]">{convertNumberToCurrency(totalAmount)}</Typography.Text>
                            }}
                        </Form.Item>
                    </Col>
                    <Col
                        span={14}
                        className="flex">
                        <Typography.Text className="w-40 mr-1">{trans("order.delivery_fee")}:</Typography.Text>
                        <Form.Item name="deliveryFee">
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                placeholder={trans("order.delivery_fee")}
                                parser={(value: any) => (value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0)}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={14}
                        className="flex">
                        <Typography.Text className="w-40 mr-1">
                            {trans("order_detail.total_payment")}
                            <span className="text-red-500">*</span>:
                        </Typography.Text>
                        <Form.Item
                            name="totalPayment"
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <InputNumber
                                className="w-full"
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                placeholder={trans("order_detail.total_payment")}
                                parser={(value: any) => (value ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2) : 0)}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
