import { IConnectChannelRequest, ISellChannel } from "@domain/version2/SellChannel"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export default class SellChannelApi {
    static getListSellChannel(): Promise<AxiosResponse<ISellChannel[]>> {
        return apiClient.get("selling-channel")
    }
    static getListChannels(): Promise<AxiosResponse<any>> {
        return apiClient.get("selling-channel/marketplaces")
    }
    static getUrlConnectChannels(params: IConnectChannelRequest): Promise<AxiosResponse<any>> {
        return apiClient.get("selling-channel/connect", { params })
    }
    static deleteSellChannel(body: { sellingChannelId: number }): Promise<AxiosResponse<any>> {
        return apiClient.delete("selling-channel", { data: body })
    }
    static updateWarehouseOfSellChannel(body: { sellingChannelId: number; warehouseId: number }): Promise<AxiosResponse<any>> {
        return apiClient.patch("selling-channel/warehouse", body)
    }
}
