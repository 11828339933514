import { trans } from "@resources/localization"
import localStore from "@util/LocalStore"
import { Button, Popover, Tree } from "antd"
import { filter, isEmpty, map } from "lodash"
import React, { useEffect } from "react"
type Props = {
    dataTree: any[]
    keyTable: string
    setSelectedTable: (data: any) => void
    selectedTable: string[]
}
export const SettingTable: React.FC<Props> = ({ dataTree, keyTable, setSelectedTable, selectedTable }) => {
    useEffect(() => {
        if (!isEmpty(dataTree) && keyTable) {
            if (!isEmpty(localStore.getJson("settings"))) {
                const selectedTree = localStore.getJson("settings")
                if (!isEmpty(selectedTree[keyTable])) {
                    setSelectedTable(selectedTree[keyTable])
                }else{
                    const defaultKeySelected = map(filter(dataTree, { disabled: true }), "key")
                    const oldSettings = localStore.getJson("settings")
                    const newSettings = {
                        ...(!isEmpty(oldSettings) && { ...oldSettings }),
                        [keyTable]: defaultKeySelected,
                    }
                    setSelectedTable(defaultKeySelected)
                    localStore.setJson("settings", newSettings)
                }
            } else {
                const defaultKeySelected = map(filter(dataTree, { disabled: true }), "key")
                const oldSettings = localStore.getJson("settings")
                const newSettings = {
                    ...(!isEmpty(oldSettings) && { ...oldSettings }),
                    [keyTable]: defaultKeySelected,
                }
                setSelectedTable(defaultKeySelected)
                localStore.setJson("settings", newSettings)
            }
        }
    }, [])

    return (
        <Popover
            placement="bottomLeft"
            title=""
            className="w-40"
            content={
                <Tree
                    checkable
                    expandedKeys={["all"]}
                    defaultCheckedKeys={selectedTable}
                    onCheck={(checkedKeys: any) => {
                        const oldSettings = localStore.getJson("settings")
                        const newSettings = {
                            ...(!isEmpty(oldSettings) && { ...oldSettings }),
                            [keyTable]: checkedKeys,
                        }
                        setSelectedTable(checkedKeys)
                        localStore.setJson("settings", newSettings)
                    }}
                    treeData={[
                        {
                            title: trans("order_list.check_all"),
                            key: "all",
                            children: dataTree,
                        },
                    ]}
                />
            }
            trigger="click">
            <Button
                type="link"
                icon={<i className="fa-light fa-gear"></i>}
            />
        </Popover>
    )
}
