import { CAMPAIGN_SCOPE } from "@config/permission"
import { trans } from "@resources/localization"
import { optionType } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Card, Col, Form, Input, Row, Select } from "antd"
import React from "react"

type Props = {
    formRef: any
}
export const GeneralInfo: React.FC<Props> = ({ formRef }) => {
    const disabledUpdate = !SecurityService.can(CAMPAIGN_SCOPE.CAMPAIGN_UPDATE)
    return (
        <Card className="space-layout">
            <Row gutter={12}>
                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}>
                    <Form.Item
                        label={trans("campagin.type_create")}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            disabled
                            placeholder={trans("campaign.type_pl")}>
                            {optionType?.map((item) => (
                                <Select.Option
                                    key={item?.value}
                                    value={item?.value}>
                                    {item?.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.type !== next.type}>
                        {({ getFieldValue }) => (
                            <Form.Item
                                name="productName"
                                label={trans("campaign.product")}
                                rules={
                                    getFieldValue("type") === "product"
                                        ? [
                                              {
                                                  required: true,
                                                  message: trans("message.required"),
                                              },
                                          ]
                                        : []
                                }>
                                <Input disabled />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.type !== next.type}>
                        {({ getFieldValue }) => (
                            <Form.Item
                                name="skuName"
                                label={trans("skuCombo.title")}
                                rules={
                                    getFieldValue("type") === "combo"
                                        ? [
                                              {
                                                  required: true,
                                                  message: trans("message.required"),
                                              },
                                          ]
                                        : []
                                }>
                                <Input disabled />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="name"
                        label={trans("campaign.name")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Input
                            maxLength={256}
                            placeholder={trans("campaign.name_create_pl")}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    name: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            disabled={disabledUpdate}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="fbCampaignIds"
                        label={trans("campaign.facebook_id")}>
                        <Select
                            mode="tags"
                            allowClear
                            placeholder={trans("campaign.facebook_create_pl")}
                            disabled={disabledUpdate}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}
